import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MonthlyExpenseTableHead = (props) => {
  const { t } = useTranslation();
  const tableColumnsStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.tableColumns.monthlyTransferDetail";
  const detailsPageCommonStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.detailsPage";
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="glPeriod" width="10%">
          {props.showDetailsView &&
            `${t(`${detailsPageCommonStr}.sourceLabel`)} \n `}
          {t(`${tableColumnsStr}.glPeriod`)}
        </TableCell>
        <TableCell align="left" key="project">
          {t(`${tableColumnsStr}.project`)}
        </TableCell>
        <TableCell align="left" key="award">
          {t(`${tableColumnsStr}.award`)}
        </TableCell>
        <TableCell align="left" key="source">
          {t(`${tableColumnsStr}.source`)}
        </TableCell>
        <TableCell align="left" key="journalName" width="30%">
          {t(`${tableColumnsStr}.journalName`)}
        </TableCell>
        <TableCell align="left" key="deptReference">
          {t(`${tableColumnsStr}.deptReference`)}
        </TableCell>
        <TableCell align="left" key="objectCode">
          {t(`${tableColumnsStr}.objectCode`)}
        </TableCell>
        {!props.showDetailsView && (
          <TableCell align="left" key="journalLineText" width="30%">
            {t(`${tableColumnsStr}.journalLineText`)}
          </TableCell>
        )}
        {props.showDetailsView && (
          <TableCell align="left" key="redistributedAmount" width="15%">
            {t(`${detailsPageCommonStr}.redistributedAmount`)}
          </TableCell>
        )}
        {props.showDetailsView && (
          <TableCell align="left" key="mtdAmount" width="15%">
            {t(`${detailsPageCommonStr}.mtdAmount`)}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
