import React from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  Tooltip,
  CircularProgress,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  NavigateNext as NavigateNextIcon,
  AddCircle as AddCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStyles } from "./FinancialHistory.styles";
import { FinancialHistoryFiscalYearTableHead } from "./FinancialHistoryFiscalYearTableHead";
import { FinancialHistoryGLPeriodTableHead } from "./FinancialHistoryGLPeriodTableHead";
import { CurrencyFormat } from "../../../../Utils/CurrencyFormat";
import {
  getFiscalYearFinancialHistory,
  getGLPeriodFinancialHistory,
} from "../../../../services/componentsService";
import { ComponentTypes, TransactionTypes } from "../../../../constants";
import { useCommitmentContext } from "../../../../services/commitmentContext";
import { useUsers } from "../../../../services/usersService";
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
import { format } from "date-fns";

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  var pta = `${
    project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
  }-${task ? task : "\u00A0\u00A0\u00A0"}-${
    award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"
  }`;
  return pta;
}

export const FinancialHistory = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { financialHistoryPagination, setFinancialHistoryPagination } =
    useCommitmentContext();

  const { commitmentId, componentId } = useParams();
  const [rowsPerPage, setRowsPerPage] = React.useState(
    (financialHistoryPagination.componentId &&
      financialHistoryPagination.componentId === componentId &&
      financialHistoryPagination.rowsPerPage) ||
      10
  );
  const [page, setPage] = React.useState(
    (financialHistoryPagination.componentId &&
      financialHistoryPagination.componentId === componentId &&
      financialHistoryPagination.page) ||
      0
  );
  const [
    fiscalYearFinancialHistoryDetails,
    setFiscalYearFinancialHistoryDetails,
  ] = React.useState();

  const [
    fiscalYearFinancialHistoryTotalCount,
    setFiscalYearFinancialHistoryTotalCount,
  ] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const { clearAlert, setAlert, alert } = useAlertContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFinancialHistoryPagination({
      ...financialHistoryPagination,
      page: newPage,
      componentId: componentId,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      fiscalYearFinancialHistoryTotalCount -
        page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setFinancialHistoryPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: pageValue,
      componentId: componentId,
    });
  };

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  React.useEffect(() => {
    clearAlert();
    if (permissions.VIEW_COMPONENT_FINANCIAL_HISTORY) {
      getFiscalYearFinancialHistory(
        setFiscalYearFinancialHistoryDetails,
        setFiscalYearFinancialHistoryTotalCount,
        setLoading,
        commitmentId,
        componentId,
        page,
        rowsPerPage,
        clearAlert,
        setAlert
      );
    }
    // eslint-disable-next-line
  }, [commitmentId, componentId, page, rowsPerPage, permissions]);

  const commonTranslationStr = "Commitments.financialHistory.mainView";
  const commonTableColumnsStr = "Commitments.mainView.list.tableColumns";
  const commonComponentsTableColumnStr =
    "Commitments.commitmentComponents.mainView.list";

  return (
    permissions.VIEW_COMPONENT_FINANCIAL_HISTORY && (
      <Grid container>
        {props.pageType !== "newTab" && (
          <Grid container item xs={12} className={classes.mainContainer}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink to={`/commitments`}>
                  <Typography color="textSecondary" variant="body2">
                    {t("Commitments.mainView.title")}
                  </Typography>
                </RouterLink>
                <Typography color="textPrimary" variant="subtitle1">
                  {t(`${commonTranslationStr}.title`)}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <RouterLink to={`/commitments`}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  className={classes.backButtonPadding}
                >
                  {t(`${commonTranslationStr}.goBackButtonLabel`)}
                </Button>
              </RouterLink>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} className={classes.mainContainer}>
          <Typography variant="h1">
            {t(`${commonTranslationStr}.title`)}
          </Typography>
        </Grid>
        {alert.exists && (
          <Grid item xs={12} className={classes.mainContainer}>
            <ASAlert />
          </Grid>
        )}

        <Grid item xs={12} className={classes.mainContainer}>
          <Paper elevation={3}>
            <Box display="flex" p={2}>
              {loading && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  </Grid>
                </Grid>
              )}

              {!loading && fiscalYearFinancialHistoryDetails && (
                <Grid container>
                  <Grid item xs={2} className={classes.gridFlexBasis}>
                    <Typography variant="body2">
                      {t(`${commonTableColumnsStr}.commitmentName`)}:
                    </Typography>
                  </Grid>

                  <Grid item xs={10}>
                    <Typography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.commitment.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={2} className={classes.gridFlexBasis}>
                    <Typography variant="body2">
                      {t(
                        `${commonComponentsTableColumnStr}.tableColumns.componentName`
                      )}
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.componentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.gridFlexBasis}>
                    <Typography variant="body2">
                      {t(`${commonTableColumnsStr}.departmentName`)}:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.org.orgName} (
                      {fiscalYearFinancialHistoryDetails.org.orgCode})
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item container>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {t(
                            "Commitments.commitmentComponents.create.form.fields.componentDate.label"
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {fiscalYearFinancialHistoryDetails.componentDate}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {t(
                            `${commonTranslationStr}.financialHistoryDetails.projectTaskAward`
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {PTAFormat(
                            fiscalYearFinancialHistoryDetails.project,
                            fiscalYearFinancialHistoryDetails.task,
                            fiscalYearFinancialHistoryDetails.award
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item container>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {t(
                            `${commonTranslationStr}.financialHistoryDetails.componentAmount`
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {fiscalYearFinancialHistoryDetails.componentAmount !==
                            undefined &&
                            CurrencyFormat(
                              fiscalYearFinancialHistoryDetails.componentAmount
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {t(
                            `${commonComponentsTableColumnStr}.tableColumns.unspentAmount`
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {!fiscalYearFinancialHistoryDetails.isClosed &&
                            fiscalYearFinancialHistoryDetails.totalUnspentAmount !==
                              undefined &&
                            CurrencyFormat(
                              fiscalYearFinancialHistoryDetails.totalUnspentAmount
                            )}
                          {fiscalYearFinancialHistoryDetails.isClosed &&
                            fiscalYearFinancialHistoryDetails.totalUnspentAmount !==
                              undefined &&
                            CurrencyFormat(0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid item container>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {t(
                            "Commitments.commitmentDocuments.mainView.list.tableColumns.type"
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {t(
                            ComponentTypes[
                              fiscalYearFinancialHistoryDetails.componentType
                            ]
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={4}>
                        <Typography variant="body2">
                          {t(`${commonTableColumnsStr}.status`)}:
                        </Typography>
                      </Grid>
                      <Grid item>
                        {fiscalYearFinancialHistoryDetails.isClosed && (
                          <Typography variant="subtitle1">
                            {t(
                              `${commonComponentsTableColumnStr}.tableValues.statusClosedText`
                            )}{" "}
                            {fiscalYearFinancialHistoryDetails.closedDate}
                          </Typography>
                        )}
                        {!fiscalYearFinancialHistoryDetails.isClosed && (
                          <Typography variant="subtitle1">
                            {t(
                              `${commonComponentsTableColumnStr}.tableValues.statusOpenText`
                            )}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.mainContainer}>
          <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
            {permissions.CREATE_COMPONENT_FINANCIAL_PLAN && (
              <RouterLink
                to={`/commitments/${commitmentId}/components/${componentId}/financial-history/new`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  {t(`${commonTranslationStr}.addFiscalYearBtn`)}
                </Button>
              </RouterLink>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.mainContainer}>
          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              stickyHeader
              classes={{ root: classes.tableLayout }}
            >
              <FinancialHistoryFiscalYearTableHead />
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan="11">
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  fiscalYearFinancialHistoryTotalCount > 0 &&
                  fiscalYearFinancialHistoryDetails.componentFinancialHistory.values.map(
                    (row, index) => (
                      <CollapsibleTable
                        key={index}
                        fiscalYearRow={row}
                        commitmentId={
                          fiscalYearFinancialHistoryDetails.commitment.id
                        }
                        componentId={fiscalYearFinancialHistoryDetails.id}
                      />
                    )
                  )}
                {!loading && fiscalYearFinancialHistoryTotalCount === 0 && (
                  <TableRow>
                    <TableCell colSpan="11">
                      <Typography
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t(
                          `${commonTranslationStr}.list.noFinancialHistoryMsg`
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={fiscalYearFinancialHistoryTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        {props.pageType !== "newTab" && (
          <Grid item xs={12} className={classes.mainContainer}>
            <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
              <RouterLink to={`/commitments`}>
                <Button variant="outlined" startIcon={<ArrowBackIcon />}>
                  {t(`${commonTranslationStr}.goBackButtonLabel`)}
                </Button>
              </RouterLink>
            </Box>
          </Grid>
        )}
      </Grid>
    )
  );
};

function CollapsibleTable({ fiscalYearRow, commitmentId, componentId }) {
  const [open, setOpen] = React.useState(false);
  const [gLPeriodFinancialHistoryDetails, setGLPeriodFinancialHistoryDetails] =
    React.useState();
  const [gLPeriodFinancialHistoryCount, setGLPeriodFinancialHistoryCount] =
    React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const { setAlert } = useAlertContext();

  const {
    financialHistorySelectedFiscaYear,
    setFinancialHistorySelectedFiscaYear,
  } = useCommitmentContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [
    manualFinancialTransactionDetails,
    setManualFinancialTransactionDetails,
  ] = React.useState();

  const getGLPeriodDetails = React.useCallback(
    (fiscalYear) => {
      getGLPeriodFinancialHistory(
        setGLPeriodFinancialHistoryDetails,
        setGLPeriodFinancialHistoryCount,
        setLoading,
        setAlert,
        { commitmentId, componentId, fiscalYear }
      );
    },
    // eslint-disable-next-line
    [commitmentId, componentId]
  );

  const getDetails = React.useCallback(() => {
    if (financialHistorySelectedFiscaYear === fiscalYearRow.fiscalYear) {
      getGLPeriodDetails(financialHistorySelectedFiscaYear);
      setOpen(true);
    }
  }, [
    financialHistorySelectedFiscaYear,
    getGLPeriodDetails,
    fiscalYearRow.fiscalYear,
  ]);

  React.useEffect(() => {
    getDetails();
  }, [getDetails]);

  const commonTranslationStr =
    "Commitments.financialHistory.mainView.list.fiscalYearTable";

  const manualTrasactionEntryCommonTranslationStr =
    "Commitments.financialHistory.mainView.list.glPeriodTable.manualTransactionEntryDetails";

  const handlePopoverOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setManualFinancialTransactionDetails(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openWarningPopover = Boolean(anchorEl);

  function ManualTransactionEntryDetails() {
    return (
      <Popover
        id="mouse-over-popover"
        open={openWarningPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        classes={{
          paper: classes.manualEntryPopoverWidth,
        }}
      >
        <List className={classes.listItemPadding}>
          <ListItem alignItems="center" dense={true}>
            <ListItemText
              disableTypography
              primary={
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {manualFinancialTransactionDetails &&
                        manualFinancialTransactionDetails.glPeriod}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
        </List>
        {manualFinancialTransactionDetails &&
          manualFinancialTransactionDetails.manualFinancialTransactionDtos.map(
            (
              manualFinancialTransactionRow,
              manualFinancialTransactionIndex
            ) => (
              <React.Fragment key={manualFinancialTransactionIndex}>
                <List className={classes.listItemPadding}>
                  <ListItem alignItems="flex-start" dense={true}>
                    <ListItemText
                      disableTypography
                      secondary={
                        <Grid container>
                          <Grid item xs={2}>
                            <Typography variant="body2" color="textSecondary">
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.entryLabel`
                              )}{" "}
                              {manualFinancialTransactionRow.periodEntryNumber}:
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant="body2" color="textSecondary">
                              {t(
                                TransactionTypes[
                                  manualFinancialTransactionRow.transactionType
                                ]
                              )}{" "}
                              {manualFinancialTransactionRow.transactionAmount !==
                                undefined &&
                                CurrencyFormat(
                                  manualFinancialTransactionRow.transactionAmount
                                )}
                            </Typography>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant="body2" color="textSecondary">
                                {manualFinancialTransactionRow.transactionNotes}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.enteredByLabel`
                              )}{" "}
                              {manualFinancialTransactionRow.enteredBy}{" "}
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.onLabel`
                              )}{" "}
                              {format(
                                new Date(
                                  manualFinancialTransactionRow.enteredDate.toLocaleString()
                                ),
                                "MM/dd/yyyy"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                </List>
              </React.Fragment>
            )
          )}
      </Popover>
    );
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {fiscalYearRow.hasDetails && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                setFinancialHistorySelectedFiscaYear(fiscalYearRow.fiscalYear);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {`${fiscalYearRow.fiscalYear}${
            fiscalYearRow.fiscalYear === 2010
              ? t("Commitments.financialHistory.mainView.andPriorSuffix")
              : ""
          }`}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.expenseAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.transferAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.actualAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.capAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.yepAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.budgetAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.planAmount)}
        </TableCell>
        <TableCell align="right">
          {getAmount(fiscalYearRow.lrffAmount)}
        </TableCell>
        <TableCell align="center">
          {fiscalYearRow._links.edit && (
            <RouterLink
              to={`/commitments/${commitmentId}/components/${componentId}/financial-history/${fiscalYearRow.fiscalYear}/${fiscalYearRow.id}/edit`}
            >
              <Tooltip
                title={t(`${commonTranslationStr}.editFiscalYearTooltip`)}
              >
                <IconButton size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </RouterLink>
          )}
          {fiscalYearRow._links.create && (
            <Tooltip title={t(`${commonTranslationStr}.addTransactionTooltip`)}>
              <RouterLink
                to={`/commitments/${commitmentId}/components/${componentId}/financial-history/${fiscalYearRow.fiscalYear}/financial-transaction/new`}
              >
                <IconButton size="small">
                  <AddCircleIcon />
                </IconButton>
              </RouterLink>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {!loading && gLPeriodFinancialHistoryCount > 0 && (
        <TableRow className={classes.expandableRow}>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            colSpan={11}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={classes.collapseWidth}
            >
              <Box>
                <Table
                  size="small"
                  aria-label="purchases"
                  classes={{ root: classes.tableLayout }}
                >
                  <FinancialHistoryGLPeriodTableHead />
                  <TableBody>
                    {gLPeriodFinancialHistoryDetails.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{`${row.glPeriod}${
                          row.glPeriod === "AUG-2010"
                            ? t(
                                "Commitments.financialHistory.mainView.andPriorSuffix"
                              )
                            : ""
                        }`}</TableCell>
                        <TableCell align="right">
                          {getAmount(row.mtdExpense)}
                        </TableCell>
                        <TableCell align="right">
                          {getAmount(row.mtdTransfer)}
                        </TableCell>
                        <TableCell align="right">
                          {getAmount(row.mtdActual)}
                        </TableCell>
                        <TableCell>
                          {row.manualEntryInfoIcon && (
                            <>
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  handlePopoverOpen(e, row);
                                }}
                              >
                                <InfoIcon />
                              </IconButton>
                              {row.manualFinancialTransactionDtos.length >
                                0 && <ManualTransactionEntryDetails />}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

// getAmount - to format amount
export const getAmount = (amount) => {
  if (amount) {
    return CurrencyFormat(amount);
  } else {
    return "";
  }
};
