import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  textOpen: {
    color: theme.palette.success.main,
  },
  container: { "&.MuiGrid-root": { marginTop: 24, width: "100%" } },
  yellow: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.warning.main,
      verticalAlign: "text-bottom",
      marginRight: "5px",
    },
  },
  recruitmentIcon: {
    "&.MuiSvgIcon-root": {
      backgroundColor: theme.palette.warning.main,
      borderRadius: "30px",
      borderWidth: "1.5px",
      borderStyle: "dashed",
      color: "#ffffff",
      marginRight: "5px",
      padding: "2px",
      verticalAlign: "text-bottom",
    },
  },

  drawerOpen: {
    "&.MuiTableCell-root": {
      padding: "10px 3px 10px 45px",
    },
  },
  drawerClose: {
    "&.MuiTableCell-root": {
      padding: 0,
      borderBottomWidth: 0,
    },
  },

  tableLayout: {
    tableLayout: "fixed",
  },

  footerCell: {
    "&.MuiTableCell-footer": {
      borderBottomStyle: "none",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
    },
  },
  expandableRow: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiTableCell-head": {
      color: theme.palette.grey[700],
      fontSize: 13,
      fontWeight: 600,
    },
    "& .MuiTableCell-body": {
      borderBottom: "unset",
      fontSize: 13,
      fontWeight: 500,
    },
    "& .MuiTableCell-sizeSmall": { padding: "6px 5px 6px 5px" },
  },

  columnDrawerHandle: {
    width: "42px",
  },
  columnYear: {
    width: "40px",
  },
  columnStatus: {
    width: "115px",
  },
  columnDepartment: {},
  columnName: {},
  columnTotalRequested: {
    width: "135px",
  },
  columnTotalApproved: {
    width: "160px",
  },
  columnTotalFyProjection: {
    width: "120px",
  },
  columnTotalFy1Budget: {
    width: "120px",
  },
  columnDispositionNotes: {
    width: "120px",
    [theme.breakpoints.up("1700")]: {
      width: "200px",
    },
    [theme.breakpoints.up("1900")]: {
      width: "300px",
    },
  },
  columnRequestNotes: {
    width: "120px",
    [theme.breakpoints.up("1700")]: {
      width: "200px",
    },
    [theme.breakpoints.up("1900")]: {
      width: "300px",
    },
  },

  comboBox: {
    ".MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon & .MuiAutocomplete-inputRoot":
      {
        paddingRight: "30px",
      },
  },

  inputPadding: {
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
    ".MuiAutocomplete-root & .MuiOutlinedInput-root": {
      padding: 0,
    },
  },
  numericInput: {
    "& .MuiOutlinedInput-input, & .MuiInput-input": {
      textAlign: "right",
    },
  },
  multilineInput: {
    "&.MuiOutlinedInput-root": {
      padding: 8,
    },
  },

  rowFlag: {
    padding: "1px",
    borderLeft: "8px solid #ddd",
    "&.published": {
      borderLeftColor: "#7bdf89",
    },
    "&.finalized": {
      borderLeftColor: "#5ed2ff",
    },
  },
  finalizeCommitmentButton: {
    "&.MuiButtonBase-root": {
      color: "#00a1cc",
    },
  },
}));
