import axios from "axios";
import i18n from "./i18n";

// src/setupAxios.js
// Setup defaults and response interceptor for the axios HTTP library.
// Applications should not modify this file, and any changes to message strings in src/locals/en/common.json
//  should be made to the common.json file in the as-frontend-starter project.
export const setupAxios = () => {
  // If the base URL contains DEPLOY_ENV, update with the deployment environment from public/env.js
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL.replace(
    "DEPLOY_ENV",
    window.DEPLOY_ENV
  );
  axios.defaults.timeout = process.env.REACT_APP_API_TIMEOUT;

  // For any API error, set a default error message which the service can either accept or override
  axios.interceptors.response.use(
    (response) => {
      // Any status code of 2xx causes this function to trigger
      // Return the unmodified response back to the service for handling
      return response;
    },
    (error) => {
      // Any results other than status code 2xx causes this function to trigger
      if (error.response) {
        const commonApiErrorMessageKey = `common:apiErrorDefaultMessages.${error.response.data.status}.${error.response.data.code}`;
        if (i18n.exists(commonApiErrorMessageKey)) {
          // The request was made and the server responded with a status code other than 2xx
          if (error.response.data.detail) {
            error.message = i18n.t(commonApiErrorMessageKey, {
              name: error.response.data.detail.properties[0].name,
              value: error.response.data.detail.properties[0].value,
              resource: error.response.data.detail.properties[0].resource,
              message: error.response.data.detail.properties[0].message,
            });
          } else {
            error.message = i18n.t(commonApiErrorMessageKey);
          }
        } else {
          // The server returned an error response not yet mapped in common.json. Return a generic error
          error.message = i18n.t(
            "common:apiErrorDefaultMessages.REQUEST_ERROR"
          );
        }
        error.status = error.response.data.status;
        error.code = error.response.data.code;
      } else if (error.request) {
        // The request was made but no response was received
        // error.request is an instance of XMLHttpRequest in the browser
        error.message = i18n.t(
          "common:apiErrorDefaultMessages.REQUEST_TIMEOUT"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        error.message = i18n.t("common:apiErrorDefaultMessages.REQUEST_ERROR");
      }

      // Return the default error message to the service in error.message
      // Also return error.status and error.code if the error came from the backend
      return Promise.reject(error);
    }
  );
};
