import React from "react";
import { useTranslation } from "react-i18next";
import { TableFooter, TableRow, TableCell } from "@mui/material";
import { useStyles } from "./Budgeting.styles";
import { CurrencyFormatMarkup } from "../Budgeting/BudgetTable";
import {
  getThreeYearsAgoActualsHeading,
  getYearBeforeLastActualsHeading,
  getLastYearActualsHeading,
  getLastYearBudgetHeading,
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
  getYearAfterNextAvailableHeading,
} from "./Budgeting";

const getAmount = (amount) => {
  if (amount || amount === 0) {
    return CurrencyFormatMarkup(amount);
  }
};

export const BudgetTableFooter = ({ budget, budgetFiscalYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonTranslationStr = "Budgeting.mainView.list.tableColumns";

  return (
    <TableFooter classes={{ root: classes.budgetTableFooter }}>
      <TableRow>
        <TableCell
          align="left"
          key="commitmentName"
          /*variant="head"*/ scope="row"
          classes={{ root: classes.footerCell }}
        ></TableCell>
        <TableCell
          align="right"
          key="totalCommitment"
          classes={{ root: classes.footerCell }}
        >
          {t(`${commonTranslationStr}.totalCommitment`)}
        </TableCell>
        <TableCell
          align="right"
          key="fyActualsThree"
          classes={{ root: classes.footerCell }}
        >
          {getThreeYearsAgoActualsHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyActualsTwo"
          classes={{ root: classes.footerCell }}
        >
          {getYearBeforeLastActualsHeading(t, budgetFiscalYear)}
        </TableCell>

        <TableCell
          align="right"
          key="totalExpendituresTotal"
          classes={{ root: classes.footerCell }}
        >
          {t(`${commonTranslationStr}.totalExpenditures`)}
        </TableCell>
        <TableCell
          align="right"
          key="unspentCommitments"
          classes={{ root: classes.footerCell }}
        >
          {t(`${commonTranslationStr}.unspentCommitments`)}
        </TableCell>
        <TableCell
          align="right"
          key="fyActualsOne"
          className={`${classes.newLine} ${classes.footerCell}`}
        >
          {getLastYearActualsHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyBudgetOne"
          classes={{ root: classes.footerCell }}
        >
          {getLastYearBudgetHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyProjection"
          classes={{ root: classes.footerCell }}
        >
          {getLastYearProjectionHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyBudgetTwo"
          classes={{ root: classes.footerCell }}
        >
          {getCurrentYearBudgetHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyPlanOne"
          classes={{ root: classes.footerCell }}
        >
          {getNextYearPlanHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyPlanTwo"
          classes={{ root: classes.footerCell }}
        >
          {getYearAfterNextPlanHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="availableAfter"
          classes={{ root: classes.footerCell }}
        >
          {getYearAfterNextAvailableHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell
          align="right"
          key="fyBudgetCap"
          classes={{ root: classes.footerCell }}
        ></TableCell>
        <TableCell
          align="left"
          key="comments"
          classes={{ root: classes.footerCell }}
        ></TableCell>
        <TableCell
          align="left"
          key="actions"
          classes={{ root: classes.footerCell }}
        ></TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          align="left"
          key="commitmentName"
          classes={{ root: classes.footerCell }}
        >
          {t(`${commonTranslationStr}.grantTotal`)}
        </TableCell>
        <TableCell
          align="right"
          key="totalCommitment"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.totalCommitmentRequested)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalActualsThree"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalActualsThree)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalActualsTwo"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalActualsTwo)}
        </TableCell>
        <TableCell
          align="right"
          key="totalExpendituresTotal"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.totalExpenditure)}
        </TableCell>
        <TableCell
          align="right"
          key="totalUnspentCommitment"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.totalUnspentCommitment)}
        </TableCell>
        <TableCell
          align="right"
          key="fyTotalActualsOne"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalActualsOne)}
        </TableCell>

        <TableCell
          align="right"
          key="fYTotalBudgetOne"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalBudgetOne)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalProjectionOne"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalProjectionOne)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalBudget"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalBudget)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalPlanOne"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalPlanOne)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalPlanTwo"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalPlanTwo)}
        </TableCell>
        <TableCell
          align="right"
          key="fYTotalAvailableAfterTwo"
          classes={{ root: classes.footerCell }}
        >
          {getAmount(budget.fyTotalAvailableAfterTwo)}
        </TableCell>
        <TableCell
          align="right"
          key="fyBudgetCap"
          classes={{ root: classes.footerCell }}
        ></TableCell>
        <TableCell
          align="left"
          key="comments"
          classes={{ root: classes.footerCell }}
        ></TableCell>
        <TableCell
          align="left"
          key="actions"
          classes={{ root: classes.footerCell }}
        ></TableCell>
      </TableRow>
    </TableFooter>
  );
};
