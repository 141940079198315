import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Backdrop,
  Box,
} from "@mui/material";
import { useStyles } from "./UploadTransfers.styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { DropZone } from "../../../Utils/DropZone/DropZone";
import { Done as DoneIcon } from "@mui/icons-material";
import { postUploadTransfers } from "../../../services/transfersService";
import { format, addMonths } from "date-fns";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useTransfersContext } from "../../../services/transfersContext";
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";

export const UploadTransfers = ({
  openDialog,
  setOpenDialog,
  glPeriod,
  history,
}) => {
  const [open, setOpen] = React.useState(openDialog);
  const [loading, setLoading] = React.useState(false);
  const [uploadDocumentError, setUploadDocumentError] = React.useState(null);

  const [uploadedDocument, setUploadedDocument] = React.useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const { setAlert, clearAlert, alert } = useAlertContext();

  const uploadTranfersDialogStr = "UploadedTransfers.uploadTransfersDialog";

  const allowedMimeTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  const formData = {
    file: "",
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  const handleDocumentUpload = (files) => {
    files && files[0] && setUploadedDocument(files[0]);
  };

  const { lastUploadedInfo, setFilters, setLastUploadedInfo } =
    useTransfersContext();

  const uploadTranfers = () => {
    postUploadTransfers(
      uploadedDocument,
      setLoading,
      setUploadDocumentError,
      setLastUploadedInfo,
      handleClose,
      setAlert,
      clearAlert,
      setFilters
    );
  };

  const getFormattedFileName = (file) => {
    return (
      file.name.split(".")[0].substring(0, 40) +
      (file.name.length > 40 ? "... ." : ".") +
      file.name.split(".").pop()
    );
  };

  const getNextGLPeriod = (glPeriod) => {
    return glPeriod
      ? format(addMonths(new Date(glPeriod), 1), "LLL-yyyy").toUpperCase()
      : "";
  };

  React.useEffect(() => {
    setOpen(openDialog);
    clearAlert();
    // eslint-disable-next-line
  }, [openDialog]);

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validationSchema={uploadTransfersValidation}
    >
      {(props) => {
        const {
          // values,
          // handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
        } = props;
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Dialog
                open={open}
                fullWidth={true}
                classes={{
                  paperFullWidth: classes.paperFullWidth,
                }}
              >
                <DialogTitle
                  classes={{
                    root: classes.title,
                  }}
                >
                  {t(`${uploadTranfersDialogStr}.title`)}
                </DialogTitle>
                <DialogContent>
                  {open && alert.exists && (
                    <Box mb={1}>
                      <ASAlert />
                    </Box>
                  )}
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={6}>
                      <TextField
                        label={`${t(
                          `${uploadTranfersDialogStr}.fields.glPeriod.label`
                        )}`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={getNextGLPeriod(glPeriod)}
                        readOnly={true}
                        fullWidth={true}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.buttonWrapper }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setShowConfirmationDialog(true);
                        }}
                        disabled={!lastUploadedInfo}
                      >
                        {t(
                          `${uploadTranfersDialogStr}.advanceGLPeriod.ctaButtonText`
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Backdrop
                        open={loading}
                        className={classes.backdrop}
                        invisible={true}
                      >
                        <CircularProgress />
                      </Backdrop>
                      <DropZone
                        acceptedFiles={allowedMimeTypes}
                        dropzoneText={t(
                          `${uploadTranfersDialogStr}.fields.transferUploader.label`
                        )}
                        handleDocumentUpload={handleDocumentUpload}
                        handleDrop={() => {
                          setUploadDocumentError(null);
                          setFieldValue("file", handleDocumentUpload);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {uploadDocumentError && (
                        <Typography color="error" variant="body2">
                          {`${getFormattedFileName(
                            uploadedDocument
                          )} ${uploadDocumentError}`}
                        </Typography>
                      )}
                      {!uploadDocumentError &&
                        uploadedDocument &&
                        uploadedDocument.name && (
                          <Typography display="block" variant="body2">
                            <DoneIcon
                              size="small"
                              classes={{ root: classes.doneIcon }}
                            />{" "}
                            {getFormattedFileName(uploadedDocument)}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  classes={{
                    root: classes.actionDialogRoot,
                  }}
                >
                  <Button onClick={handleClose} color="primary">
                    {t(`${uploadTranfersDialogStr}.actionButtons.cancel.label`)}
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      uploadTranfers();
                    }}
                    disabled={!dirty || !isValid}
                  >
                    {t(`${uploadTranfersDialogStr}.actionButtons.upload.label`)}
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
            {
              <ConfirmationDialog
                open={showConfirmationDialog}
                setShowConfirmationDialog={setShowConfirmationDialog}
                setOpenUploadTransfersDialog={setOpenDialog}
                handleClose={handleCloseConfirmationDialog}
                advanceGLPeriod={getNextGLPeriod(glPeriod)}
                history={history}
              />
            }
          </>
        );
      }}
    </Formik>
  );
};

const uploadTransfersValidation = Yup.object().shape({
  file: Yup.mixed().required(),
});
