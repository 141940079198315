import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export const PtaContext = React.createContext();

export const PtaProvider = ({ children }) => {
  // Getting history object from useHistory (react-router-dom)
  const history = useHistory();

  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const qs = queryString.parse(location.search);
  const [ptaFilters, setPtaFilters] = React.useState({
    project: qs.project || "",
    task: qs.task || "",
    award: qs.award || "",
    projectName: qs.projectName || "",
    projectOwner: qs.projectOwner || "",
    taskOwner: qs.taskOwner || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "ptaNumber",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 25,
  });

  React.useEffect(() => {
    if (history.location.pathname.split("/")[1] !== "ptas") {
      setPtaFilters({
        project: qs.project || "",
        task: qs.task || "",
        award: qs.award || "",
        projectName: qs.projectName || "",
        projectOwner: qs.projectOwner || "",
        taskOwner: qs.taskOwner || "",
      });
      setSort({
        column: "ptaNumber",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
    }
  }, [
    history.location,
    qs.project,
    qs.task,
    qs.award,
    qs.projectName,
    qs.projectOwner,
    qs.taskOwner,
  ]);

  return (
    <PtaContext.Provider
      value={{
        sort,
        setSort,
        pagination,
        setPagination,
        ptaFilters,
        setPtaFilters,
      }}
    >
      {children}
    </PtaContext.Provider>
  );
};

export const usePtaContext = () => {
  return React.useContext(PtaContext);
};
