import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: "25px",
  },
  calenderIconColor: {
    "& .MuiIconButton-label": { color: theme.palette.grey[700] },
  },
  input: {
    "& .MuiFormControl-root": { width: "185px" },
    "& .MuiIconButton-root": { padding: 0 },
  },
}));
