import React from "react";
import axios from "axios";
import { useAlertContext } from "@stanford-tds/as-components";
import { getBudgetFiscalYear } from "./budgetingService";
import { getDepartmentByMatch } from "./orgsService";

export const BudgetingContext = React.createContext();

export const BudgetingProvider = ({ children }) => {
  const { setAlert, clearAlert } = useAlertContext();
  const [loading, setLoading] = React.useState(false);

  // State for departments & dept selection
  const [orgs, setOrgs] = React.useState([]);
  const [orgCode, setOrgCode] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [deptSelection, setDeptSelection] = React.useState([]);
  const [orgByCodeError, setOrgByCodeError] = React.useState({
    exist: false,
    message: null,
  });

  // Commitment Type Filters
  const [budgetType, setBudgetType] = React.useState("ALL");

  // Fiscal Year values
  const [budgetFiscalYear, setBudgetFiscalYear] = React.useState("");
  const [budgetFiscalYearResponse, setBudgetFiscalYearResponse] =
    React.useState({});

  // Fiscal Year Edit Dialog states
  const [refreshInformationPanel, setRefreshInformationPanel] =
    React.useState(true);
  const [infoPanelDisabled, setInfoPanelDisabled] = React.useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);

  const getQueryParams = () => {
    return {
      budgetFiscalYear,
      orgCode: orgCode || undefined,
      budgetType,
    };
  };

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    refreshInformationPanel &&
      getBudgetFiscalYear(
        setBudgetFiscalYearResponse,
        setBudgetFiscalYear,
        setAlert,
        setLoading,
        clearAlert,
        cancelSource
      );

    getDepartmentByMatch(
      true,
      setOrgs,
      setLoading,
      setOrgByCodeError,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [refreshInformationPanel, setBudgetFiscalYear, setAlert, clearAlert]);

  if (orgs.length > 0 && orgCode === "" && orgName === "") {
    //console.log("Initializing deptSelection...");
    setDeptSelection(orgs[0]);
    setOrgCode(orgs[0].value.orgCode);
    setOrgName(orgs[0].displayText);
  }

  return (
    <BudgetingContext.Provider
      value={{
        orgs,
        setOrgs,
        orgCode,
        setOrgCode,
        orgName,
        setOrgName,
        deptSelection,
        setDeptSelection,
        orgByCodeError,
        setOrgByCodeError,
        infoPanelDisabled,
        setInfoPanelDisabled,
        infoDialogOpen,
        setInfoDialogOpen,
        budgetType,
        setBudgetType,
        budgetFiscalYear,
        setBudgetFiscalYear,
        budgetFiscalYearResponse,
        setBudgetFiscalYearResponse,
        refreshInformationPanel,
        setRefreshInformationPanel,
        loading,
        setLoading,
        getQueryParams,
      }}
    >
      {children}
    </BudgetingContext.Provider>
  );
};

export const useBudgetingContext = () => {
  return React.useContext(BudgetingContext);
};
