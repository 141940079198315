import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  FormLabel,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Yup from "yup";
import { FormButton } from "../../UI/Button/FormButton";
import { useStyles } from "./BudgetingFormDialog.styles";
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
import {
  postBudgetFiscalYear,
  putBudgetFiscalYear,
} from "../../../services/budgetingService";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { useBudgetingContext } from "../../../services/budgetingContext";
import { getIsValidDate } from "../../../Utils/ValidationUtils";

export const BudgetingFormDialog = ({ openDialog, setOpenDialog }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setAlert, clearAlert, alert } = useAlertContext();

  const {
    infoDialogOpen,
    setInfoDialogOpen,
    budgetFiscalYearResponse,
    setRefreshInformationPanel,
  } = useBudgetingContext();

  const [budgetFiscalYear] = React.useState(
    (openDialog === "EDIT" &&
      parseInt(budgetFiscalYearResponse.budgetFiscalYear) - 1) ||
      budgetFiscalYearResponse.budgetFiscalYear
  );

  const [newBudgetFiscalYear] = React.useState(parseInt(budgetFiscalYear) + 1);

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  const [isInitialEditPeriodStartDate, setIsInitialEditPeriodStartDate] =
    React.useState(openDialog === "EDIT" || false);

  const [isInitialEditPeriodEndDate, setIsInitialEditPeriodEndDate] =
    React.useState(openDialog === "EDIT" || false);

  const [budgetFiscalYearValues, setBudgetFiscalYearValues] = React.useState();

  const [isValidDate, setIsValidDate] = React.useState(true);

  const [formDataResponse] = React.useState({
    // Object keys use to create/edit form data
    initialEditPeriodStartDate:
      (openDialog === "EDIT" &&
        budgetFiscalYearResponse.initialEditStartDate) ||
      null,
    initialEditPeriodEndDate:
      (openDialog === "EDIT" && budgetFiscalYearResponse.initialEditEndDate) ||
      null,
    revisionEditPeriodStartDate:
      (openDialog === "EDIT" &&
        budgetFiscalYearResponse.revisionEditStartDate) ||
      null,
    revisionEditPeriodEndDate:
      (openDialog === "EDIT" && budgetFiscalYearResponse.revisionEditEndDate) ||
      null,
  });
  const budgetingValidation = Yup.object().shape({
    initialEditPeriodStartDate: Yup.date().required(),
    initialEditPeriodEndDate: Yup.date().required(),
  });

  const budgetingCreateDialog = "Budgeting.createDialog";
  const budgetingCreateDialogFormFields = "Budgeting.createDialog.form.fields";

  const getDialogTitle = (dialogState, fiscalYear) => {
    let title = "";
    if (dialogState === "ADD") {
      title = t(`${budgetingCreateDialog}.addtitle`) + " " + fiscalYear;
    }
    if (dialogState === "EDIT") {
      title = t(`${budgetingCreateDialog}.editTitle`) + " " + fiscalYear;
    }
    return title;
  };

  const handleClose = () => {
    setOpenDialog(false);
    setInfoDialogOpen(false);
    clearAlert();
  };

  const _handleSubmit = (values) => {
    openDialog === "ADD" && setShowConfirmationDialog(true);
    openDialog === "EDIT" &&
      putBudgetFiscalYear(
        newBudgetFiscalYear,
        values,
        setRefreshInformationPanel,
        setOpenDialog,
        setInfoDialogOpen,
        setAlert,
        clearAlert
      );
    setBudgetFiscalYearValues(values);
  };

  return (
    <>
      {openDialog === "ADD" && showConfirmationDialog && (
        <ASConfirmation
          open={showConfirmationDialog}
          title={t(`${budgetingCreateDialog}.addtitle`)}
          message={t(`${budgetingCreateDialog}.confirmationMessage`, {
            fiscalYear: newBudgetFiscalYear,
          })}
          handleCancel={() => {
            setShowConfirmationDialog(false);
          }}
          handleOk={() => {
            setShowConfirmationDialog(false);
            postBudgetFiscalYear({
              newBudgetFiscalYear,
              budgetFiscalYearValues,
              setRefreshInformationPanel,
              setOpenDialog,
              setInfoDialogOpen,
              setAlert,
              clearAlert,
            });
          }}
          okLabel={t(
            `${budgetingCreateDialog}.confirmationDialogActionButtons.addButton`
          )}
          cancelLabel={t("globals.form.actionButtons.cancel")}
        />
      )}

      <Dialog
        open={!!openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <Formik
          // initialValues - User to store the Formik form's intial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize={true}
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting }) => {
            _handleSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={budgetingValidation}
        >
          {(props) => {
            const /** !Object */ {
                values,
                dirty,
                isValid,
                handleSubmit,
                setFieldValue,
              } = props;
            return (
              // Native form element to submit the form values
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  {getDialogTitle(openDialog, newBudgetFiscalYear)}
                </DialogTitle>

                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {infoDialogOpen && alert.exists && <ASAlert />}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormLabel required={true}>
                          {t(
                            `${budgetingCreateDialogFormFields}.initialEditPeriod.label`
                          )}
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="baseline"
                        className={classes.input}
                        wrap="nowrap"
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            id="initialEditPeriodStartDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.initialEditPeriodStartDate
                            )}
                            onChange={(value) => {
                              value !== "" &&
                                setIsInitialEditPeriodStartDate(true);
                              value === null &&
                                setIsInitialEditPeriodStartDate(false);
                              setFieldValue(
                                "initialEditPeriodStartDate",
                                value
                              );
                            }}
                            inputVariant={"outlined"}
                            maxDate={
                              (values.initialEditPeriodEndDate &&
                                new Date(values.initialEditPeriodEndDate)) ||
                              undefined
                            }
                            minDate={undefined}
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.initialEditPeriod.validation.maxDateMessage`
                            )}
                            inputProps={{ autoComplete: "off" }}
                            clearable={true}
                            onError={(reason, value) => {
                              setIsValidDate(true);
                              reason && setIsValidDate(false);
                            }}
                            className={classes.calenderIconColor}
                            renderInput={(innerProps) => (
                              <TextField {...innerProps} />
                            )}
                          />
                        </LocalizationProvider>
                        <FormLabel>
                          {t(`${budgetingCreateDialogFormFields}.to.label`)}
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            id="initialEditPeriodEndDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.initialEditPeriodEndDate
                            )}
                            onChange={(value) => {
                              value !== "" &&
                                setIsInitialEditPeriodEndDate(true);
                              value === null &&
                                setIsInitialEditPeriodEndDate(false);
                              setFieldValue("initialEditPeriodEndDate", value);
                            }}
                            maxDate={undefined}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            minDate={
                              (values.initialEditPeriodStartDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodStartDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodStartDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.initialEditPeriod.validation.minDateMessage`
                            )}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable={true}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            className={classes.calenderIconColor}
                            renderInput={(innerProps) => (
                              <TextField {...innerProps} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormLabel>
                          {t(
                            `${budgetingCreateDialogFormFields}.revisionEditPeriod.label`
                          )}
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="baseline"
                        className={classes.input}
                        wrap="nowrap"
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            id="revisionEditPeriodStartDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.revisionEditPeriodStartDate
                            )}
                            onChange={(value) => {
                              setFieldValue(
                                "revisionEditPeriodStartDate",
                                value
                              );
                            }}
                            maxDate={
                              (values.revisionEditPeriodEndDate &&
                                new Date(values.revisionEditPeriodEndDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.maxDateMessage`
                            )}
                            minDate={
                              (values.initialEditPeriodEndDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodEndDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodEndDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.revisionEditPeriodStartDateAfterInitialEndDate`
                            )}
                            disabled={
                              values.revisionEditPeriodStartDate === null &&
                              (!isInitialEditPeriodEndDate ||
                                !isInitialEditPeriodStartDate)
                            }
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                              values.revisionEditPeriodStartDate === null &&
                                values.revisionEditPeriodEndDate &&
                                setIsValidDate(false);
                            }}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable={true}
                            className={classes.calenderIconColor}
                            renderInput={(innerProps) => (
                              <TextField {...innerProps} />
                            )}
                          />
                        </LocalizationProvider>
                        <FormLabel>
                          {t(`${budgetingCreateDialogFormFields}.to.label`)}
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            id="revisionEditPeriodEndDate"
                            format="MM/dd/yyyy"
                            value={getIsValidDate(
                              values.revisionEditPeriodEndDate
                            )}
                            onChange={(value) => {
                              setFieldValue("revisionEditPeriodEndDate", value);
                            }}
                            maxDate={undefined}
                            maxDateMessage={t(
                              `${budgetingCreateDialogFormFields}.fiscalYearDateRangeMessage`
                            )}
                            minDate={
                              (values.revisionEditPeriodStartDate &&
                                new Date(
                                  new Date(
                                    values.revisionEditPeriodStartDate
                                  ).setDate(
                                    new Date(
                                      values.revisionEditPeriodStartDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              (values.initialEditPeriodEndDate &&
                                new Date(
                                  new Date(
                                    values.initialEditPeriodEndDate
                                  ).setDate(
                                    new Date(
                                      values.initialEditPeriodEndDate
                                    ).getDate() + 1
                                  )
                                )) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${budgetingCreateDialogFormFields}.revisionEditPeriod.validation.minDateMessage`
                            )}
                            disabled={
                              values.revisionEditPeriodEndDate === null &&
                              (!isInitialEditPeriodEndDate ||
                                !isInitialEditPeriodStartDate)
                            }
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                              values.revisionEditPeriodEndDate === null &&
                                values.revisionEditPeriodStartDate !== null &&
                                setIsValidDate(false);
                            }}
                            inputVariant={"outlined"}
                            inputProps={{ autoComplete: "off" }}
                            clearable={true}
                            className={classes.calenderIconColor}
                            renderInput={(innerProps) => (
                              <TextField {...innerProps} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    xs={7}
                    spacing={2}
                  >
                    <FormButton
                      cancel={handleClose}
                      save={{
                        disabled: !dirty || !isValid || !isValidDate,
                      }}
                    />
                  </Grid>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
