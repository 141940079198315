import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ComponentTypes } from "../../../constants";

export const ComponentTypeField = ({
  required,
  includeRequestTypes,
  labelText,
  error,
  helperText,
  size,
  value = "",
  ...passedParams
}) => {
  const { t } = useTranslation();

  const label =
    labelText ??
    t(
      `Commitments.commitmentComponents.create.form.fields.componentType.label`
    );

  // Create an initial list of values that excludes 'DEAN_COMMITMENT'
  const componentTypeMap = Object.assign(ComponentTypes);
  delete componentTypeMap.DEAN_COMMITMENT;

  // Optionally exclude 'REQUEST'
  const nonRequestValues = (() => {
    let opts = Object.assign({}, componentTypeMap);
    delete opts.REQUEST;
    return opts;
  })();

  const options = !!includeRequestTypes ? componentTypeMap : nonRequestValues;
  const labelNum = Math.floor(Math.random() * 99999);

  return (
    <>
      <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id={`componentTypeLabel${labelNum}`}>{label}</InputLabel>
        <Select
          {...passedParams}
          value={value}
          labelId={`componentTypeSelectLabel${labelNum}`}
          id="componentType"
          notched
          size={size}
          required={required}
          error={error}
        >
          {Object.entries(options).map(([key, val], index) => (
            <MenuItem key={index} value={key}>
              {t(val)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

ComponentTypeField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
