import React from "react";
import { ResetFilterContainer } from "./FilterPanel.styles";
import { useTranslation } from "react-i18next";
import { Typography, Link } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

export const ResetFilter = ({ resetFn }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  return (
    <ResetFilterContainer>
      {/* Reset Filter Container, containing reset interface. */}
      <ClearIcon fontSize="small" />
      <Link
        component="button"
        underline="none"
        color="textPrimary"
        /**
         * On click of Reset Filter
         *
         * 1. Calling up the function named resetFilter
         * to reset all of the state values and reloading
         * the PTAs data based on that.
         * 2. Definition of reset filter function will
         * vary with different components it belongs to.
         * */
        onClick={() => resetFn()}
      >
        <Typography variant="body2">
          {t("PTAs.filterPanelItems.resetFilterText")}
        </Typography>
      </Link>
    </ResetFilterContainer>
  );
};
