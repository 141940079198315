import React, { Fragment } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";
import { addDays } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FieldArray } from "formik";
import { useStyles } from "./CommitmentComponentRequestForm.styles";
import { CommitmentComponentRequestHeaders } from "./CommitmentComponentRequestHeaders";
import { CommitmentComponentRequestFooter } from "./CommitmentComponentRequestFooter";
import { FormattedCurrencyField } from "../../../../Utils/CurrencyFormat";
import { useTranslation } from "react-i18next";
import { getIsValidDate } from "../../../../Utils/ValidationUtils";

export const CommitmentComponentRequestTable = ({
  loading,
  isViewOnly,
  isEditRecord,
  isDeleteRecord,
  inputVariant,
  currentFiscalYear,
  getEmptyComponent,
  values,
  errors,
  handleChange,
  setFieldValue,
  budgetEditableByCurrentUser,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";
  const rowRef = React.useRef(null);
  const lastRowRef = React.useRef(null);
  const size = "small";

  isViewOnly = isViewOnly || isDeleteRecord || false;

  const [scrollRowIntoView, setScrollRowIntoView] = React.useState(false);

  React.useEffect(() => {
    if (scrollRowIntoView && lastRowRef.current) {
      //lastRowRef.current.scrollIntoView();
    }
  });

  const arrayHelpersRef = React.useRef(null);

  const getInputVariant = (readOnly) => {
    return readOnly ? "standard" : inputVariant || "outlined";
  };

  return (
    <>
      {/*Component Datatable*/}
      <TableContainer component={Paper}>
        <Table
          aria-label="Commitment Components"
          aria-live="polite"
          stickyHeader
          size={size}
          classes={{ root: classes.componentTable }}
        >
          <CommitmentComponentRequestHeaders
            currentFiscalYear={currentFiscalYear}
            isViewOnly={isViewOnly}
          />
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={!isViewOnly ? "10" : "9"}
                  classes={{ root: classes.progressBar }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading && !values.components && (
              <TableRow>
                <TableCell
                  colSpan={!isViewOnly ? "10" : "9"}
                  classes={{ root: classes.progressBar }}
                >
                  {t("globals.list.messages.noData")}
                </TableCell>
              </TableRow>
            )}
            {!loading && values.components && (
              <FieldArray name="components">
                {(arrayHelpers) => {
                  arrayHelpersRef.current = arrayHelpers;
                  return values.components.map((i, indexI, componentList) => (
                    <Fragment key={indexI}>
                      {/*console.log(i)*/}
                      <TableRow
                        className={indexI % 2 ? "" : "odd"}
                        ref={
                          values.components.length === indexI + 1
                            ? lastRowRef
                            : rowRef
                        }
                      >
                        <TableCell align="left">
                          <TextField
                            id={`components[${indexI}].componentName`}
                            required={true}
                            value={i?.componentName || ""}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            helperText={
                              errors.components &&
                              errors?.components[indexI]?.componentName
                                ? errors.components[indexI].componentName
                                : ""
                            }
                            error={
                              errors.components &&
                              errors?.components[indexI]?.componentName &&
                              Boolean(errors.components[indexI].componentName)
                            }
                            variant={getInputVariant(
                              i?.hasOwnProperty("templateSourceType")
                            )}
                            disabled={isViewOnly}
                            autoComplete="none"
                            classes={{ root: classes.readOnlyPadding }}
                            size={size}
                            inputProps={{
                              maxLength: 250,
                              readOnly: i?.hasOwnProperty("templateSourceType"),
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedCurrencyField
                            id={`components[${indexI}].componentAmount`}
                            required={false}
                            value={i?.componentAmount || 0}
                            onChange={(event, _value) => {
                              setFieldValue(
                                `components[${indexI}].componentAmount`,
                                event.target.value.replace(/[^0-9.-]+/g, "")
                              );
                            }}
                            setFieldValue={setFieldValue}
                            variant={getInputVariant()}
                            isViewOnly={isViewOnly}
                            disabled={isViewOnly}
                            size={size}
                            classes={{ root: classes.currencyField }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedCurrencyField
                            id={`components[${indexI}].budgetPlan.fyProjectionOne`}
                            required={false}
                            value={i?.budgetPlan?.fyProjectionOne}
                            onChange={(event, _value) => {
                              setFieldValue(
                                `components[${indexI}].budgetPlan.fyProjectionOne`,
                                event.target.value.replace(/[^0-9.-]+/g, "")
                              );
                            }}
                            setFieldValue={setFieldValue}
                            variant={getInputVariant()}
                            isViewOnly={isViewOnly}
                            disabled={isViewOnly}
                            size={size}
                            classes={{ root: classes.currencyField }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedCurrencyField
                            id={`components[${indexI}].budgetPlan.fyBudget`}
                            required={false}
                            value={i?.budgetPlan?.fyBudget}
                            onChange={(event, _value) => {
                              setFieldValue(
                                `components[${indexI}].budgetPlan.fyBudget`,
                                event.target.value.replace(/[^0-9.-]+/g, "")
                              );
                            }}
                            setFieldValue={setFieldValue}
                            variant={getInputVariant()}
                            isViewOnly={isViewOnly}
                            disabled={isViewOnly}
                            size={size}
                            classes={{ root: classes.currencyField }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedCurrencyField
                            id={`components[${indexI}].budgetPlan.fyPlanOne`}
                            required={false}
                            value={i?.budgetPlan?.fyPlanOne}
                            onChange={(event, _value) => {
                              setFieldValue(
                                `components[${indexI}].budgetPlan.fyPlanOne`,
                                event.target.value.replace(/[^0-9.-]+/g, "")
                              );
                            }}
                            setFieldValue={setFieldValue}
                            variant={getInputVariant()}
                            isViewOnly={isViewOnly}
                            disabled={isViewOnly}
                            size={size}
                            classes={{ root: classes.currencyField }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormattedCurrencyField
                            id={`components[${indexI}].budgetPlan.fyPlanTwo`}
                            required={false}
                            value={i?.budgetPlan?.fyPlanTwo}
                            onChange={(event, _value) => {
                              setFieldValue(
                                `components[${indexI}].budgetPlan.fyPlanTwo`,
                                event.target.value.replace(/[^0-9.-]+/g, "")
                              );
                            }}
                            setFieldValue={setFieldValue}
                            variant={getInputVariant()}
                            isViewOnly={isViewOnly}
                            disabled={isViewOnly}
                            size={size}
                            classes={{ root: classes.currencyField }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ConfiguredDateField
                            id={`components[${indexI}].startDate`}
                            required={false}
                            value={getIsValidDate(i.startDate)}
                            onChange={(value) => {
                              setFieldValue(
                                `components[${indexI}].startDate`,
                                value
                              );
                            }}
                            inputVariant={getInputVariant()}
                            maxDate={
                              (i?.expirationDate &&
                                new Date(i?.expirationDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${componentFormField}.startDate.validation.maxDateMessage`
                            )}
                            isViewOnly={isViewOnly}
                            size={size}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <ConfiguredDateField
                            id={`components[${indexI}].expirationDate`}
                            required={false}
                            value={getIsValidDate(i.expirationDate)}
                            onChange={(value) => {
                              setFieldValue(
                                `components[${indexI}].expirationDate`,
                                value
                              );
                            }}
                            inputVariant={getInputVariant()}
                            minDate={
                              (i?.startDate &&
                                addDays(new Date(i?.startDate), 1)) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${componentFormField}.expirationDate.validation.minDateMessage`
                            )}
                            isViewOnly={isViewOnly}
                            size={size}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            id={`components[${indexI}].componentRequestNotes`}
                            required={false}
                            value={i?.componentRequestNotes}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            helperText={
                              errors[
                                `components[${indexI}].componentRequestNotes`
                              ]
                                ? errors[
                                    `components[${indexI}].componentRequestNotes`
                                  ]
                                : ""
                            }
                            error={
                              errors[
                                `components[${indexI}].componentRequestNotes`
                              ] &&
                              Boolean(
                                errors[
                                  `components[${indexI}].componentRequestNotes`
                                ]
                              )
                            }
                            variant={getInputVariant()}
                            disabled={isViewOnly}
                            autoComplete="off"
                            size={size}
                            inputProps={{
                              maxLength: 30,
                            }}
                          />
                        </TableCell>
                        {!isViewOnly && (
                          <TableCell align="center">
                            {componentList.length > 1 &&
                              budgetEditableByCurrentUser && (
                                <Tooltip
                                  title={"Delete Component"}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                >
                                  <span>
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        arrayHelpers.remove(indexI)
                                      }
                                      disabled={
                                        isEditRecord &&
                                        i?.hasOwnProperty("templateSourceType")
                                      }
                                    >
                                      <RemoveCircleIcon size="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                          </TableCell>
                        )}
                      </TableRow>
                    </Fragment>
                  ));
                }}
              </FieldArray>
            )}
          </TableBody>
          {/*console.log(budgetEditableByCurrentUser) */}
          <CommitmentComponentRequestFooter
            isViewOnly={isViewOnly}
            components={values.components}
            arrayHelpers={arrayHelpersRef}
            getEmptyComponent={getEmptyComponent}
            setScrollRowIntoView={setScrollRowIntoView}
            budgetEditableByCurrentUser={budgetEditableByCurrentUser}
          />
        </Table>
      </TableContainer>
    </>
  );
};

const ConfiguredDateField = (props) => {
  const isViewOnly = props.isViewOnly || false;
  const shrinkProps = isViewOnly && { shrink: true };
  let otherProps = Object.assign({}, props);
  Object.hasOwn(otherProps, "isViewOnly") && delete otherProps.isViewOnly;
  Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="MM/dd/yyyy"
        clearable={true}
        disabled={isViewOnly}
        keyboardIcon={isViewOnly ? false : undefined}
        fullWidth={true}
        inputProps={{ autoComplete: "off" }}
        InputLabelProps={{ ...shrinkProps }}
        renderInput={(innerProps) => (
          <TextField label={props.label} size={props.size} {...innerProps} />
        )}
        OpenPickerButtonProps={{ sx: { fontSize: 5 } }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
};
