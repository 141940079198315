import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export const CommitmentContext = React.createContext();

export const CommitmentProvider = ({ children }) => {
  // Getting history object from useHistory (react-router-dom)
  const history = useHistory();

  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const qs = queryString.parse(location.search);
  const [filters, setFilters] = React.useState({
    dealName: qs.dealName || "",
    departmentName: qs.departmentName || "",
    departmentValue: qs.departmentName
      ? qs.departmentName.split("(").pop().split(")")[0].trim()
      : "",
    status: qs.status || "",
    project: qs.project || "",
    task: qs.task || "",
    award: qs.award || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "name",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 25,
  });

  // State for keeping commitment id for opened component drawer
  const [openCommitmentId, setOpenCommitmentId] = React.useState(0);

  // For commitment documents

  // State for commitment document Sort
  const [docSort, setDocSort] = React.useState({
    column: "name",
    orderBy: "asc",
  });

  // State for commitemnt document pagination
  const [docPagination, setDocPagination] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  // State for commitemnt document pagination
  const [financialHistoryPagination, setFinancialHistoryPagination] =
    React.useState({
      page: 0,
      rowsPerPage: 10,
      componentId: "",
    });

  const [
    financialHistorySelectedFiscaYear,
    setFinancialHistorySelectedFiscaYear,
  ] = React.useState(0);

  React.useEffect(() => {
    if (history.location.pathname.split("/")[1] !== "commitments") {
      setFilters({
        dealName: qs.dealName || "",
        departmentName: qs.departmentName || "",
        departmentValue: qs.departmentValue || "",
        status: qs.status || "",
        project: qs.project || "",
        task: qs.task || "",
        award: qs.award || "",
      });
      setSort({
        column: "name",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
      // For documents

      setDocSort({
        column: "name",
        orderBy: "asc",
      });
      setDocPagination({
        page: 1,
        rowsPerPage: 10,
      });
      setFinancialHistoryPagination({
        page: 0,
        rowsPerPage: 10,
        componentId: "",
      });
    }
  }, [
    history.location,
    qs.dealName,
    qs.departmentName,
    qs.departmentValue,
    qs.status,
    qs.project,
    qs.task,
    qs.award,
  ]);

  return (
    <CommitmentContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        openCommitmentId,
        setOpenCommitmentId,
        docPagination,
        setDocPagination,
        docSort,
        setDocSort,
        financialHistoryPagination,
        setFinancialHistoryPagination,
        financialHistorySelectedFiscaYear,
        setFinancialHistorySelectedFiscaYear,
      }}
    >
      {children}
    </CommitmentContext.Provider>
  );
};

export const useCommitmentContext = () => {
  return React.useContext(CommitmentContext);
};
