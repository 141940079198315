import React from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import NumberFormat from "react-number-format";

export const NumberFormatField = (props) => {
  let otherProps = Object.assign({}, props);
  Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue;
  Object.hasOwn(otherProps, "formValues") && delete otherProps.formValues;
  Object.hasOwn(otherProps, "restrictions") && delete otherProps.restrictions;
  if (otherProps.variant === undefined) {
    otherProps.variant = "outlined";
  }

  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={2}
      customInput={TextField}
      value={props.formValues}
      isAllowed={(values) => {
        const { value } = values;
        if (props.restrictions) {
          return value !== "-" && value >= 0 && value <= 999999999;
        } else {
          return value === "-" || (value >= -999999999 && value <= 999999999);
        }
      }}
      fixedDecimalScale={true}
      autoComplete="off"
      allowNegative={!props.restrictions}
      InputProps={{
        startAdornment: (
          <InputAdornment component="div" position="start">
            <Typography variant="body2">$</Typography>
          </InputAdornment>
        ),
      }}
      onValueChange={(data) => {
        props.setFieldValue(props.id, data.value);
      }}
      {...otherProps}
    />
  );
};
