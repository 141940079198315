import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  actionDialogRoot: {
    marginRight: 8,
  },
  paperFullWidth: {
    width: "532px",
  },
  title: {
    padding: "16px 24px 0px",
  },
  doneIcon: {
    verticalAlign: "text-bottom",
  },
  backdrop: {
    zIndex: 99,
  },
  buttonWrapper: {
    textAlign: "right",
    marginTop: 8,
  },
  confirmationDialogWidth: {
    width: 430,
  },
});
