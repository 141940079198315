import React from "react";
import axios from "axios";
import { useStyles } from "./CommitmentComponentForm.styles";
import {
  Breadcrumbs,
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  Button,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  NavigateNext as NavigateNextIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Formik } from "formik";
import { ComponentTypes } from "../../../../constants";
import {
  getExpenditureRestrictionsByMatch,
  getPIOrComponentOwnerByMatch,
  getComponentById,
  postComponent,
  putComponent,
  getCommitmentNameById,
  getAwardDetails,
  getProjectDetails,
  getTaskDetails,
  getAwardAutocomplete,
  getProjectAutocomplete,
  getTaskAutocomplete,
} from "../../../../services/componentsService";
import * as Yup from "yup";
import { format, addDays } from "date-fns";
import { useUsers } from "../../../../services/usersService";
import { FormButton } from "../../../UI/Button/FormButton";
import NumberFormat from "react-number-format";
import { ListPopover } from "./ListPopover/ListPopover";
import { CurrencyFormat } from "../../../../Utils/CurrencyFormat";
import { ASConfirmation } from "../../../UI/ASConfirmation/ASConfirmation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { getIsValidDate } from "../../../../Utils/ValidationUtils";

export const CommitmentComponentForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // `useStyles` will provide styles from CommitmentComponentForm.styles
  const classes = useStyles();

  const params = useParams();

  const viewOnly = props.pageType === "view";
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const otherParent = props.parent === "other";

  const history = useHistory();
  const returnRoute = history.location.pathname.split("/").filter(Boolean)[0];

  const { clearAlert, setAlert, alert } = useAlertContext();

  const handleClose = () => {
    history.push(`/${returnRoute}`);
  };

  const _handleSubmit = (values, setFieldError, setSubmitting) => {
    const args = [
      values,
      piOrComponentOwnerValue,
      setLoading,
      params.commitmentId,
      params.componentId,
      commitmentName,
      setFieldError,
      setSubmitting,
      clearAlert,
      setAlert,
      history,
      t,
      returnRoute,
    ];
    params.componentId ? putComponent(...args) : postComponent(...args);
  };

  const shrinkProps = viewOnly && { shrink: true };

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";
  const commitmentComponentCreateStr =
    "Commitments.commitmentComponents.create";
  const commitmentComponentViewStr = "Commitments.commitmentComponents.view";
  const commitmentComponentEditStr = "Commitments.commitmentComponents.edit";
  const commitmentComponentList =
    "Commitments.commitmentComponents.mainView.list";

  // State variable and its function to maintain commitment name to show in title
  const [commitmentName, setCommitmentName] = React.useState("");

  const [awardEvent, setAwardEvent] = React.useState();
  const [projectEvent, setProjectEvent] = React.useState();
  const [taskEvent, setTaskEvent] = React.useState();

  // Custom hooks for getting detail for Award, Project and Task.
  const [awardDetails, setAwardDetails] = React.useState([]);
  const [projectDetails, setProjectDetails] = React.useState([]);
  const [taskDetails, setTaskDetails] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [awardList, setAwardList] = React.useState([]);
  const [projectList, setProjectList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);

  // Custom hooks for Award validation
  const [validateAward, setValidateAward] = React.useState();
  // Custom hooks for Project validation
  const [validateProject, setValidateProject] = React.useState();
  // Custom hooks for task validation
  const [validateTask, setValidateTask] = React.useState();

  // State variable to show/hide confirmation dialog for piOrComponentOwner change action
  const [showChangeOwnerDialog, setShowChangeOwnerDialog] =
    React.useState(false);
  const [piOrComponentOwnerValue, setPiOrComponentOwnerValue] =
    React.useState("");
  const [ptaTaskName, setPtaTaskName] = React.useState("");

  // State variable and its function for orgOptions
  const [expenditureRestrictions, setExpenditureRestrictions] = React.useState(
    []
  );
  // State variable and its function for piOrComponentOwner
  const [piOrComponentOwner, setPiOrComponentOwner] = React.useState([]);
  // State variable and its function to maintain loading indicator of PIOrComponentOwner data
  const [loadingPIOrComponentOwner, setLoadingPIOrComponentOwner] =
    React.useState(false);

  // State variable and its function to maintain closed notes and closed details view
  const [closed, setClosed] = React.useState(false);
  // State variable and its function to maintain error state for invalid
  const [isValidDate, setIsValidDate] = React.useState(true);

  const [formDataResponse, setFormDataResponse] = React.useState({
    // Object keys use to create/edit form data
    componentName /* Component Name */: "",
    componentDescription /* Component Description */: "",
    componentDate /* Component Date */: format(new Date(), "MM/dd/yyyy"),
    componentAmount /* Total Component Amount */: "0.00",
    project /* Project */: "",
    task /* Task */: "",
    award /* Award */: "",
    piOrComponentOwner /* PI/ComponentOwner */: "",
    componentType /* Component Type */: null,
    purpose1 /* Purpose 1 */: "",
    purpose2 /* Purpose 2 */: "",
    expenditures /* Expenditure Restrictions */: [],
    startDate /* Start Date */: null,
    expirationDate /* Expiration Date */: null,
    durationNotes /* Duratoin Notes */: "",
    closed /* Closed */: false,
    closedDate /* Closed Date */: null,
    closedDetails /* Closed Details */: "",
    unspentComponent /* Outstanding Component */: 0,
  });

  const commitmentValidation = Yup.object().shape({
    componentName: Yup.string().required(),
    componentDescription: Yup.string().required(),
    componentDate: Yup.string().required(),
    closedDate: Yup.mixed().when("closed", {
      is: true,
      then: Yup.mixed().required(),
      otherwise: undefined,
    }),
    closedDetails: Yup.string().when("closed", {
      is: true,
      then: Yup.string().required(),
      otherwise: undefined,
    }),
  });

  // Create a list of values that excludes 'DEAN_COMMITMENT & REQUEST'
  const componentTypeMap = Object.assign(ComponentTypes);
  delete componentTypeMap.DEAN_COMMITMENT;
  delete componentTypeMap.REQUEST;
  const componentTypeValues = Object.values(componentTypeMap);

  React.useEffect(() => {
    getCommitmentNameById(
      setCommitmentName,
      params.commitmentId,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [params.commitmentId]);

  React.useEffect(() => {
    params.componentId &&
      getComponentById(
        setFormDataResponse,
        setClosed,
        setPiOrComponentOwnerValue,
        setValidateAward,
        setValidateProject,
        setValidateTask,
        setPtaTaskName,
        params.commitmentId,
        params.componentId,
        setAlert,
        clearAlert
      );
    // eslint-disable-next-line
  }, [params.commitmentId, params.componentId]);
  return (
    ((props.pageType === "new" && permissions.CREATE_COMPONENTS) ||
      ((props.pageType === "edit" || props.pageType === "newTab") &&
        permissions.EDIT_COMPONENTS) ||
      (props.pageType === "view" && permissions.VIEW_COMPONENTS)) && (
      <Grid
        container
        classes={{
          root: classes.mainContainer,
        }}
      >
        {props.pageType !== "newTab" && (
          <Grid container item xs={12}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink to={`/commitments`}>
                  <Typography color="textSecondary" variant="body2">
                    {t("Commitments.mainView.title")}
                  </Typography>
                </RouterLink>
                <Typography color="textPrimary" variant="subtitle1">
                  {props.pageType === "new"
                    ? t(`${commitmentComponentCreateStr}.breadcrumbTitle`)
                    : viewOnly
                    ? t(`${commitmentComponentViewStr}.breadcrumbTitle`)
                    : t(`${commitmentComponentEditStr}.breadcrumbTitle`)}
                </Typography>
              </Breadcrumbs>
            </Grid>
            {viewOnly && !otherParent && (
              <Grid item container xs={4} justifyContent="flex-end" spacing={2}>
                <RouterLink to={`/${returnRoute}`}>
                  <Button startIcon={<ArrowBackIcon />}>
                    {t("Commitments.view.goBackButtonLabel")}
                  </Button>
                </RouterLink>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} className={classes.mainHeading}>
          <Typography variant="h1">
            {props.pageType === "new"
              ? t(`${commitmentComponentCreateStr}.title`, {
                  commitmentName: commitmentName,
                })
              : viewOnly
              ? t(`${commitmentComponentViewStr}.title`, {
                  commitmentName: commitmentName,
                })
              : t(`${commitmentComponentEditStr}.title`, {
                  commitmentName: commitmentName,
                })}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {alert.exists && <ASAlert />}
        </Grid>

        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment Component*/}
        <Formik
          // initialValues - User to store the Formik form's intial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize={true}
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError, setSubmitting);
          }}
          validationSchema={commitmentValidation}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                handleChange,
                handleSubmit,
                dirty,
                isValid,
                setFieldValue,
                touched,
                errors,
                setFieldError,
              } = formikProps;

            return (
              // Native form element to submit the form values
              <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                  {/* FormControlContainer - Flex container to wrap all the form flex items */}
                  <Grid
                    item
                    container
                    xs={12}
                    direction="column"
                    className={classes.formContainer}
                  >
                    <Grid
                      item
                      xs={10}
                      container
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography align="right">
                          {t(`${componentFormField}.unspentComponent.label`)}
                          {`: `}
                          {!values.closed &&
                            CurrencyFormat(values.unspentComponent)}
                          {values.closed && CurrencyFormat(0)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Name - Multiline Textfield Input */}
                      <Grid item xs={12}>
                        <TextField
                          id="componentName"
                          label={t(`${componentFormField}.componentName.label`)}
                          required={viewOnly ? false : true}
                          value={values.componentName}
                          onChange={handleChange}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 250 }}
                          helperText={
                            touched.componentName ? errors.componentName : ""
                          }
                          error={
                            touched.componentName &&
                            Boolean(errors.componentName)
                          }
                          disabled={viewOnly}
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Description - Multiline Textfield Input */}
                      <Grid item xs={12}>
                        <TextField
                          id="componentDescription"
                          label={t(
                            `${componentFormField}.componentDescription.label`
                          )}
                          required={viewOnly ? false : true}
                          value={values.componentDescription}
                          onChange={handleChange}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 250 }}
                          disabled={viewOnly}
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Component Date - DatePicker Input */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="componentDate"
                            required={viewOnly ? false : true}
                            format="MM/dd/yyyy"
                            label={t(
                              `${componentFormField}.componentDate.label`
                            )}
                            value={new Date(values.componentDate)}
                            onChange={(value) => {
                              setFieldValue("componentDate", value);
                            }}
                            onError={(reason, value) => {
                              setIsValidDate(true);
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={viewOnly ? "standard" : "outlined"}
                            inputProps={{ autoComplete: "off" }}
                            disabled={viewOnly}
                            keyboardIcon={viewOnly ? false : undefined}
                            clearable={true}
                            fullWidth={true}
                            className={classes.disabled}
                            renderInput={(props) => <TextField {...props} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        {/* Total Component Amount - Textfield Input */}
                        <NumberFormat
                          id="componentAmount"
                          label={t(
                            `${componentFormField}.totalComponentAmount.label`
                          )}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          customInput={TextField}
                          isAllowed={(values) => {
                            const { value } = values;
                            return value <= 999999999.99;
                          }}
                          allowNegative={false}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className={classes.paddingTop}
                                position="start"
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={values.componentAmount}
                          onChange={(event, value) => {
                            setFieldValue(
                              "componentAmount",
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth={true}
                          className={classes.disabled}
                          disabled={viewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6} container>
                        {/* Project - Textfield Input */}
                        <Grid item xs={4}>
                          <Autocomplete
                            id="project"
                            value={
                              values.award && values.project
                                ? values.project.trim()
                                : ""
                            }
                            onChange={(event, value) => {
                              const projectValue = value || "";
                              setFieldValue("project", projectValue);
                              getProjectDetails(
                                values.award,
                                projectValue,
                                setProjectDetails,
                                setValidateProject
                              );
                            }}
                            getOptionLabel={(option) => option}
                            options={projectList || []}
                            loading={loading}
                            label={t(`${componentFormField}.project.label`)}
                            variant="outlined"
                            freeSolo={true}
                            disabled={viewOnly || !values.award}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(`${componentFormField}.project.label`)}
                                autoComplete="off"
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={
                                  touched.project ? errors.project : ""
                                }
                                error={
                                  touched.project && Boolean(errors.project)
                                }
                                onChange={(e, value) => {
                                  setFieldValue("project", e.target.value);
                                  if (e.target.value === "") {
                                    setValidateProject();
                                    setValidateTask();
                                    setFieldValue("task", "");
                                  }
                                  getProjectAutocomplete(
                                    e.target.value,
                                    values.award,
                                    setProjectList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getProjectDetails(
                                    values.award,
                                    e.target.value,
                                    setProjectDetails,
                                    setValidateProject
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateProject === "EXIST" && (
                                        <InputAdornment position="end">
                                          <CheckCircleIcon
                                            className={classes.success}
                                            onMouseEnter={(e) => {
                                              e.persist();
                                              setProjectEvent(e);
                                              getProjectDetails(
                                                values.award,
                                                values.project,
                                                setProjectDetails,
                                                setValidateProject
                                              );
                                            }}
                                          />
                                        </InputAdornment>
                                      )}
                                      {validateProject === "NOTEXIST" && (
                                        <InputAdornment>
                                          <WarningIcon
                                            className={classes.warning}
                                          />
                                        </InputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 7,
                                }}
                                className={`${classes.disabled} ${classes.paddingRight}`}
                              />
                            )}
                          />
                          {projectDetails && (
                            <ListPopover
                              data={projectDetails}
                              entity="project"
                              entityValue={values.project}
                              header={t(`${componentFormField}.project.label`)}
                              trigger={projectEvent}
                              setListEvent={setProjectEvent}
                            />
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {/* Task - Textfield Input */}
                          <Autocomplete
                            id="task"
                            value={
                              values.award && values.project && values.task
                                ? values.task.trim()
                                : ""
                            }
                            onChange={(event, value) => {
                              const taskValue = value || "";
                              setFieldValue("task", taskValue);
                              getTaskDetails(
                                values.award,
                                values.project,
                                taskValue,
                                setTaskDetails,
                                setValidateTask,
                                setPtaTaskName,
                                piOrComponentOwnerValue,
                                setPiOrComponentOwnerValue,
                                setShowChangeOwnerDialog,
                                true
                              );
                            }}
                            getOptionLabel={(option) => option}
                            options={taskList || []}
                            loading={loading}
                            label={t(`${componentFormField}.task.label`)}
                            variant="outlined"
                            freeSolo={true}
                            clearIcon={false}
                            disabled={
                              viewOnly ||
                              (!values.project && true) ||
                              (!values.award && true)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(`${componentFormField}.task.label`)}
                                autoComplete="off"
                                className={`${classes.disabled} ${classes.paddingRight}`}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={touched.task ? errors.task : ""}
                                error={touched.task && Boolean(errors.task)}
                                onChange={(e, value) => {
                                  e.target.value === "" && setValidateTask();
                                  setFieldValue("task", e.target.value);
                                  getTaskAutocomplete(
                                    e.target.value,
                                    values.award,
                                    values.project,
                                    setTaskList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getTaskDetails(
                                    values.award,
                                    values.project,
                                    e.target.value,
                                    setTaskDetails,
                                    setValidateTask,
                                    setPtaTaskName,
                                    piOrComponentOwnerValue,
                                    setPiOrComponentOwnerValue,
                                    setShowChangeOwnerDialog,
                                    true
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateTask === "EXIST" && (
                                        <>
                                          <InputAdornment position="end">
                                            <CheckCircleIcon
                                              className={classes.success}
                                              onMouseEnter={(e) => {
                                                e.persist();
                                                setTaskEvent(e);
                                                getTaskDetails(
                                                  values.award,
                                                  values.project,
                                                  values.task,
                                                  setTaskDetails,
                                                  setValidateTask,
                                                  setPtaTaskName,
                                                  piOrComponentOwnerValue,
                                                  setPiOrComponentOwnerValue,
                                                  setShowChangeOwnerDialog,
                                                  false
                                                );
                                              }}
                                            />
                                          </InputAdornment>
                                        </>
                                      )}
                                      {validateTask === "NOTEXIST" && (
                                        <InputAdornment>
                                          <WarningIcon
                                            className={classes.warning}
                                          />
                                        </InputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                          {taskDetails && (
                            <ListPopover
                              data={taskDetails}
                              entity="task"
                              entityValue={values.task}
                              header={t(`${componentFormField}.task.label`)}
                              trigger={taskEvent}
                              setListEvent={setTaskEvent}
                            />
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {/* Project - Textfield Input */}
                          <Autocomplete
                            id="award"
                            value={values.award ? values.award.trim() : ""}
                            onChange={(event, value) => {
                              const awardValue = value || "";
                              setFieldValue("award", awardValue);
                              getAwardDetails(
                                awardValue,
                                setAwardDetails,
                                setValidateAward
                              );
                            }}
                            getOptionLabel={(option) => option}
                            options={awardList || []}
                            loading={loading}
                            variant="outlined"
                            disabled={viewOnly}
                            freeSolo={true}
                            clearIcon={false}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t(`${componentFormField}.award.label`)}
                                autoComplete="off"
                                className={classes.disabled}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={touched.award ? errors.award : ""}
                                error={touched.award && Boolean(errors.award)}
                                onChange={(e, value) => {
                                  setFieldValue("award", e.target.value);
                                  if (e.target.value === "") {
                                    setValidateAward();
                                    setValidateProject();
                                    setValidateTask();
                                    setFieldValue("award", "");
                                    setFieldValue("task", "");
                                    setFieldValue("project", "");
                                  }
                                  getAwardAutocomplete(
                                    e.target.value,
                                    setAwardList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getAwardDetails(
                                    e.target.value,
                                    setAwardDetails,
                                    setValidateAward
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateAward === "EXIST" && (
                                        <InputAdornment position="end">
                                          <CheckCircleIcon
                                            className={classes.success}
                                            onMouseEnter={(e) => {
                                              e.persist();
                                              // Setting awardNumber param
                                              setAwardEvent(e);
                                              getAwardDetails(
                                                values.award,
                                                setAwardDetails,
                                                setValidateAward
                                              );
                                            }}
                                          />
                                        </InputAdornment>
                                      )}
                                      {validateAward === "NOTEXIST" && (
                                        <InputAdornment>
                                          <WarningIcon
                                            className={classes.warning}
                                          />
                                        </InputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 5,
                                }}
                              />
                            )}
                          />
                          {awardDetails && (
                            <ListPopover
                              data={awardDetails}
                              entity="award"
                              entityValue={values.award}
                              header={t(`${componentFormField}.award.label`)}
                              trigger={awardEvent}
                              setListEvent={setAwardEvent}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {/* Task Name - Textfield Input */}
                        {values.award &&
                        values.project &&
                        values.task &&
                        validateAward === "EXIST" &&
                        validateProject === "EXIST" &&
                        validateTask === "EXIST" ? (
                          <>
                            <TextField
                              id="taskName"
                              label={t(`${componentFormField}.taskName.label`)}
                              fullWidth
                              value={ptaTaskName}
                              disabled={true}
                              InputLabelProps={{ ...shrinkProps }}
                              className={classes.disabled}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* piOrComponentOwner - Autocomplete Input */}
                        <Autocomplete
                          id="piOrComponentOwner"
                          options={piOrComponentOwner || []}
                          loading={loadingPIOrComponentOwner}
                          freeSolo={true}
                          loadingText={t(
                            `${commitmentComponentCreateStr}.form.fieldDataLoadingText`
                          )}
                          getOptionLabel={(option) =>
                            option.displayText ? option.displayText : ""
                          }
                          value={piOrComponentOwnerValue}
                          onChange={(event, value) => {
                            setPiOrComponentOwnerValue(value);
                            setFieldValue("piOrComponentOwner", value);
                          }}
                          disabled={viewOnly}
                          onInputChange={(event, value) => {
                            // to allow only on synthetic events
                            if (event) {
                              const cancelSource = axios.CancelToken.source();

                              getPIOrComponentOwnerByMatch(
                                value,
                                () => {
                                  //onBeforeSend
                                  setFieldError("piOrComponentOwner");
                                  setLoadingPIOrComponentOwner(true);
                                },
                                () => {
                                  //onError
                                  setLoadingPIOrComponentOwner(false);
                                },
                                (responseData) => {
                                  //onSuccess
                                  setPiOrComponentOwner(
                                    responseData.values || []
                                  );
                                  setLoadingPIOrComponentOwner(false);
                                },
                                cancelSource
                              );

                              setFieldValue("piOrComponentOwner", value);
                            }
                          }}
                          clearIcon={false}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label={t(
                                  `${componentFormField}.piOrComponentOwner.label`
                                )}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                className={classes.disabled}
                                helperText={
                                  touched.piOrComponentOwner
                                    ? errors.piOrComponentOwner
                                    : ""
                                }
                                error={
                                  touched.piOrComponentOwner &&
                                  Boolean(errors.piOrComponentOwner)
                                }
                                InputProps={{
                                  ...params.InputProps,
                                  onBlur: (event) => {
                                    setPiOrComponentOwnerValue({
                                      displayText: event.target.value,
                                    });
                                  },
                                }}
                              />
                            );
                          }}
                        />
                        {taskDetails.taskOwnerFullName &&
                          piOrComponentOwnerValue && (
                            <ASConfirmation
                              open={showChangeOwnerDialog}
                              title={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.title`
                              )}
                              message={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.message`,
                                {
                                  taskOwnerName: taskDetails.taskOwnerFullName,
                                  componentOwnerName:
                                    piOrComponentOwnerValue.displayText,
                                }
                              )}
                              handleCancel={() => {
                                setShowChangeOwnerDialog(false);
                              }}
                              handleOk={() => {
                                setPiOrComponentOwnerValue({
                                  displayText: taskDetails.taskOwnerFullName,
                                });
                                setShowChangeOwnerDialog(false);
                              }}
                              okLabel={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.okLabel`
                              )}
                              cancelLabel={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.cancelLabel`
                              )}
                            />
                          )}
                      </Grid>
                      <Grid item xs={6}>
                        {/* Component Type - Autocomplete Input */}
                        <Autocomplete
                          id="componentType"
                          name="componentType"
                          options={componentTypeValues}
                          getOptionLabel={(option) => t(option)}
                          value={
                            values.componentType &&
                            t(ComponentTypes[values.componentType])
                          }
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onChange={(event, valueObj) => {
                            setFieldValue(
                              "componentType",
                              Object.keys(ComponentTypes).find(
                                (key) => ComponentTypes[key] === valueObj
                              )
                            );
                          }}
                          clearIcon={false}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                `${componentFormField}.componentType.label`
                              )}
                              variant={viewOnly ? "standard" : "outlined"}
                              InputLabelProps={{ ...shrinkProps }}
                              className={classes.disabled}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Purpose1 - Multiline Textfield Input */}
                        <TextField
                          id="purpose1"
                          label={t(`${componentFormField}.purpose1.label`)}
                          value={values.purpose1}
                          onChange={handleChange}
                          variant={viewOnly ? "standard" : "outlined"}
                          disabled={viewOnly}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ ...shrinkProps }}
                          className={classes.disabled}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* purpose2 - Multiline Textfield Input */}
                        <TextField
                          id="purpose2"
                          label={t(`${componentFormField}.purpose2.label`)}
                          value={values.purpose2}
                          onChange={handleChange}
                          variant={viewOnly ? "standard" : "outlined"}
                          disabled={viewOnly}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ ...shrinkProps }}
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Expenditure Restrictions - Autocomplete Multiselect Input */}
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          limitTags={(viewOnly && -1) || 3}
                          id="expenditures"
                          options={
                            expenditureRestrictions || values.expenditures || []
                          }
                          getOptionLabel={(option) =>
                            option && option.displayText
                          }
                          onChange={(event, valueObj) => {
                            setFieldValue(
                              "expenditures",
                              valueObj ? valueObj : ""
                            );
                          }}
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onInputChange={(e, value) => {
                            getExpenditureRestrictionsByMatch(
                              setLoading,
                              setExpenditureRestrictions,
                              value,
                              setFieldError
                            );
                          }}
                          value={values.expenditures}
                          clearIcon={false}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                `${componentFormField}.expenditureRestrictions.label`
                              )}
                              variant={viewOnly ? "standard" : "outlined"}
                              helperText={
                                touched.expenditures ? errors.expenditures : ""
                              }
                              error={
                                touched.expenditures &&
                                Boolean(errors.expenditures)
                              }
                              InputLabelProps={{ ...shrinkProps }}
                              className={classes.disabled}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Component Date - DatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="startDate"
                            format="MM/dd/yyyy"
                            label={t(`${componentFormField}.startDate.label`)}
                            value={getIsValidDate(values.startDate)}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={viewOnly ? "standard" : "outlined"}
                            inputProps={{ autoComplete: "off" }}
                            maxDate={
                              (values.expirationDate &&
                                new Date(values.expirationDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${componentFormField}.startDate.validation.maxDateMessage`
                            )}
                            clearable={true}
                            disabled={viewOnly}
                            keyboardIcon={viewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth={true}
                            className={classes.disabled}
                            renderInput={(props) => <TextField {...props} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        {/* Component Date - DatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="expirationDate"
                            format="MM/dd/yyyy"
                            label={t(
                              `${componentFormField}.expirationDate.label`
                            )}
                            value={getIsValidDate(values.expirationDate)}
                            onChange={(value) => {
                              setFieldValue("expirationDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={viewOnly ? "standard" : "outlined"}
                            inputProps={{ autoComplete: "off" }}
                            minDate={
                              (values.startDate &&
                                addDays(new Date(values.startDate), 1)) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${componentFormField}.expirationDate.validation.minDateMessage`
                            )}
                            clearable={true}
                            disabled={viewOnly}
                            keyboardIcon={viewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth={true}
                            className={classes.disabled}
                            renderInput={(props) => <TextField {...props} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Duration Notes - Textfield Input */}
                        <TextField
                          id="durationNotes"
                          label={t(`${componentFormField}.durationNotes.label`)}
                          value={values.durationNotes}
                          onChange={handleChange}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={viewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          className={classes.disabled}
                        />
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Closed - Checkbox Input */}
                      {!viewOnly ? (
                        <Grid item xs={6} container alignItems="center">
                          <Checkbox
                            id="closed"
                            className={classes.checkbox}
                            color="default"
                            checked={values.closed}
                            onChange={(event) => {
                              values.closedDate = event.target.checked
                                ? format(new Date(), "MM/dd/yyyy")
                                : null;
                              setFieldValue("closed", event.target.checked);
                              setClosed(event.target.checked);
                            }}
                          />{" "}
                          &nbsp;{" "}
                          <Typography display="inline">
                            {t(`${componentFormField}.closed.label`)}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={6}>
                          <TextField
                            label={t(
                              `${commitmentComponentList}.tableColumns.status`
                            )}
                            value={
                              values.closed
                                ? t(
                                    `${commitmentComponentList}.tableValues.statusClosedText`
                                  )
                                : t(
                                    `${commitmentComponentList}.tableValues.statusOpenText`
                                  )
                            }
                            disabled={true}
                            fullWidth={true}
                            className={classes.disabled}
                          />
                        </Grid>
                      )}

                      {closed && (
                        <Grid item xs={6}>
                          {/* Closed Date - Date Picker Input */}
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              id="closedDate"
                              required={viewOnly ? false : true}
                              format="MM/dd/yyyy"
                              label={t(
                                `${componentFormField}.closedDate.label`
                              )}
                              value={new Date(values.closedDate)}
                              onChange={(value) => {
                                setFieldValue("closedDate", value);
                              }}
                              onError={(reason, value) => {
                                reason && setIsValidDate(false);
                              }}
                              inputvariant={viewOnly ? "standard" : "outlined"}
                              disabled={viewOnly}
                              keyboardIcon={viewOnly ? false : undefined}
                              inputProps={{ autoComplete: "off" }}
                              clearable={true}
                              fullWidth={true}
                              className={classes.disabled}
                              renderInput={(props) => <TextField {...props} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                      )}
                    </Grid>
                    {closed && (
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                            id="closedDetails"
                            label={t(
                              `${componentFormField}.closedDetails.label`
                            )}
                            required={viewOnly ? false : true}
                            value={values.closedDetails}
                            onChange={handleChange}
                            variant={viewOnly ? "standard" : "outlined"}
                            disabled={viewOnly}
                            autoComplete="off"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{ ...shrinkProps }}
                            className={classes.disabled}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Record Number - Textfield Input */}
                      <Grid item xs={2}>
                        <TextField
                          id="recordNumber"
                          variant="standard"
                          label={t(`${componentFormField}.recordNumber.label`)}
                          value={
                            formDataResponse.recordNumber
                              ? formDataResponse.recordNumber
                              : " "
                          }
                          disabled
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>

                    {!viewOnly ? (
                      <Grid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled: !dirty || !isValid || !isValidDate,
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      !otherParent && (
                        <Grid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {/* Back Button */}
                          <Grid container item justifyContent="flex-end">
                            <RouterLink to={`/${returnRoute}`}>
                              <Button
                                variant="outlined"
                                size="large"
                                startIcon={<ArrowBackIcon />}
                              >
                                {t("Commitments.view.goBackButtonLabel")}
                              </Button>
                            </RouterLink>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </form>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    )
  );
};
