import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ResetFilter } from "../../components/Layout/FilterPanel/ResetFilter";
import {
  ExpensesPosted,
  ExpensesConditionType,
  ExpensesNotification,
} from "../../constants";
import { useHistory } from "react-router-dom";
import {
  getFiscalYearsByMatch,
  getGLPeriodsByMatch,
  getJournalSourcesByMatch,
  getGLPeriod,
} from "../../services/transfersService";
import { useTransfersContext } from "../../services/transfersContext";

export const TransfersFilter = ({ changeFilterData }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  // useHistory hook from "react-router-dom"
  const history = useHistory();

  const {
    filters,
    setFilters,
    pagination,
    setPagination,
    lastUploadedInfo,
    // setLastUploadedInfo,
  } = useTransfersContext();

  const [fiscalYearOptions, setFiscalYearOptions] = React.useState([]);
  const [glPeriodOptions, setGLPeriodOptions] = React.useState([]);
  const [journalSourceOptions, setJournalSourceOptions] = React.useState([]);

  // Controllable States - Fiscal year
  const [fiscalYear, setFiscalYear] = React.useState(
    lastUploadedInfo.lastUploadFiscalYear || ""
  );

  // Controllable States - GL Period
  const [glPeriod, setGLPeriod] = React.useState(
    lastUploadedInfo.lastUploadGLPeriod || ""
  );

  // Controllable State - Posted

  const [posted, setPosted] = React.useState(filters.posted);

  // Controllable State - Condition Type

  const [conditionType, setConditionType] = React.useState(
    filters.conditionType
  );

  // Controllable State - Notification Type

  const [notification, setNotification] = React.useState(filters.notification);

  // Controllable State - Journal Source

  const [journalSource, setJournalSource] = React.useState(
    filters.journalSource
  );

  const [fiscalYearError, setFiscalYearError] = React.useState({
    exist: false,
    message: null,
  });

  const [GLPeriodError, setGLPeriodError] = React.useState({
    exist: false,
    message: null,
  });

  const [journalSourcesError, setJournalSourcesError] = React.useState({
    exist: false,
    message: null,
  });

  React.useEffect(() => {
    getFiscalYearsByMatch("", setFiscalYearOptions, setFiscalYearError);
    getGLPeriodsByMatch(
      "",
      setGLPeriodOptions,
      setGLPeriodError,
      lastUploadedInfo.lastUploadFiscalYear || ""
    );
    getJournalSourcesByMatch(
      "",
      setJournalSourceOptions,
      setJournalSourcesError
    );
  }, [lastUploadedInfo.lastUploadFiscalYear]);

  React.useEffect(() => {
    const params = {
      fiscalYear,
      glPeriod,
      posted,
      conditionType,
      notification,
      journalSource,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    history.push(`${history.location.pathname}?${queryParams}`);
  }, [
    fiscalYear,
    glPeriod,
    posted,
    conditionType,
    notification,
    journalSource,
    history,
  ]);

  React.useEffect(() => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
  }, [lastUploadedInfo]);

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    setFiscalYear(lastUploadedInfo.lastUploadFiscalYear || "");
    setGLPeriod(lastUploadedInfo.lastUploadGLPeriod || "");
    setPosted("");
    setConditionType("");
    setNotification("");
    setJournalSource("");
    setFilters({
      fiscalYear: lastUploadedInfo.lastUploadFiscalYear || "",
      glPeriod: lastUploadedInfo.lastUploadGLPeriod || "",
      posted: "",
      conditionType: "",
      notification: "",
    });
    setPagination({
      page: 0,
      rowsPerPage: 10,
    });
  };

  const getPostedJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesPosted).map((key, index) => (
        <option value={key === "POSTED" ? true : false} key={key}>
          {t(ExpensesPosted[key])}
        </option>
      ))}
    </>
  );

  const getConditionTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesConditionType).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesConditionType[key])}
        </option>
      ))}
    </>
  );

  const getNotificationTypeJSX = () => (
    <>
      <option value="" />
      {Object.keys(ExpensesNotification).map((key, index) => (
        <option value={key.toLowerCase()} key={key}>
          {t(ExpensesNotification[key])}
        </option>
      ))}
    </>
  );
  return (
    <>
      <FormControl variant="outlined">
        <Autocomplete
          id="fiscalYear"
          options={fiscalYearOptions}
          getOptionLabel={(option) =>
            (option && String(option.displayText)) || ""
          }
          clearOnEscape={true}
          clearIcon={false}
          blurOnSelect={true}
          value={{ displayText: fiscalYear }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "UploadedTransfers.filterPanelItems.options.fiscalYear.label"
              )}
              variant="outlined"
              error={fiscalYearError.exist}
              helperText={fiscalYearError.message}
            />
          )}
          onChange={(event, newValue) => {
            const fiscalYear = newValue ? newValue.displayText : "";
            setFiscalYear(fiscalYear);
            getGLPeriod(
              setGLPeriodOptions,
              setGLPeriod,
              filters,
              setFilters,
              pagination,
              setPagination,
              setGLPeriodError,
              {
                fiscalYear: (newValue && newValue.displayText) || "",
                size: 25,
                value: "",
              }
            );
            setFilters({
              ...filters,
              fiscalYear,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="glPeriod"
          options={glPeriodOptions}
          getOptionLabel={(option) => option.displayText || ""}
          clearOnEscape={true}
          clearIcon={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("UploadedTransfers.mainView.list.tableColumns.glPeriod")}
              variant="outlined"
              error={GLPeriodError.exist}
              helperText={GLPeriodError.message}
            />
          )}
          value={{ displayText: glPeriod }}
          onChange={(event, newValue) => {
            const glPeriod = newValue ? newValue.displayText : "";
            setGLPeriod(glPeriod);
            setFilters({
              ...filters,
              glPeriod,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="postedLabel">
          {t("UploadedTransfers.filterPanelItems.options.posted.label")}
        </InputLabel>
        <Select
          native
          labelId="postedSelectLabel"
          label={t("UploadedTransfers.filterPanelItems.options.posted.label")}
          id="posted"
          value={posted}
          onChange={(event) => {
            const posted = event.target.value;
            setPosted(posted);
            setFilters({
              ...filters,
              posted,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getPostedJSX()}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="conditionTypeLabel">
          {t("UploadedTransfers.filterPanelItems.options.conditionType.label")}
        </InputLabel>
        <Select
          native
          labelId="conditionTypeSelectLabel"
          label={t(
            "UploadedTransfers.filterPanelItems.options.conditionType.label"
          )}
          id="conditionType"
          value={conditionType}
          onChange={(event) => {
            const conditionType = event.target.value;
            setConditionType(conditionType);
            setFilters({
              ...filters,
              conditionType,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getConditionTypeJSX()}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="notificationLabel">
          {t("UploadedTransfers.filterPanelItems.options.notification.label")}
        </InputLabel>
        <Select
          native
          labelId="notificationSelectLabel"
          label={t(
            "UploadedTransfers.filterPanelItems.options.notification.label"
          )}
          id="notification"
          value={notification}
          onChange={(event) => {
            const notification = event.target.value;
            setNotification(notification);
            setFilters({
              ...filters,
              notification,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {getNotificationTypeJSX()}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="journalSource"
          options={journalSourceOptions}
          getOptionLabel={(option) =>
            (option.displayText && option.displayText) || ""
          }
          clearOnEscape={true}
          clearIcon={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "UploadedTransfers.mainView.list.tableColumns.journalSource"
              )}
              variant="outlined"
              error={journalSourcesError.exist}
              helperText={journalSourcesError.message}
            />
          )}
          value={{ displayText: journalSource }}
          onChange={(event, newValue) => {
            const journalSource = newValue ? newValue.displayText : "";
            setJournalSource(journalSource);
            setFilters({
              ...filters,
              journalSource,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        />
      </FormControl>
      <ResetFilter resetFn={() => resetFn()} />
    </>
  );
};
