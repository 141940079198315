import React from "react";
import { PageTitle } from "../PageTitle/PageTitle";
import { FilterPanel } from "../Layout/FilterPanel/FilterPanel";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { useStyles } from "./Page.styles";

export const Page = (props) => {
  const [open, setOpen] = React.useState(true);
  const [filterData, setFilterData] = React.useState({});
  const classes = useStyles();

  function handleDrawerToggle() {
    setOpen(!open);
  }
  const changeFilterData = React.useCallback((data) => {
    setFilterData(data);
  }, []);

  // useHistory hook from "react-router-dom"
  const history = useHistory();

  return (
    <Grid container alignContent="flex-start">
      <Grid
        item
        xs={(open && 3) || 1}
        className={`${classes.filterFlexBasis} ${classes.filterPosition}`}
      >
        {props.filter ? (
          <FilterPanel
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            changeFilterData={changeFilterData}
          >
            {props.filter}
          </FilterPanel>
        ) : null}
      </Grid>
      <Grid
        item
        xs={(props.filter && ((open && 9) || 11)) || 12}
        className={`${props.filter && open && classes.tableFlexBasis} ${
          classes.padding
        }`}
      >
        <Grid container>
          <Grid item xs={12}>
            {props.title && <PageTitle title={props.title} />}
          </Grid>
          <Grid item xs={12}>
            {props.main &&
              React.Children.map(props.main, (child) => {
                return React.cloneElement(child, {
                  filterData: filterData,
                  history,
                });
              })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
