// src/services/authService.js
// Authentication service handling keycloak integration, authentication state management,
//  token refresh, and the axios authorization header. These features are provided to
//  applications automatically so application-specific code never needs to call this service.
// Applications should not modify this file since this is a common service.

// Keycloak documentation: https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter

import React from "react";
import Keycloak from "keycloak-js";
import axios from "axios";

const AuthContext = React.createContext();

const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const TokenRefreshTimer = () => {
  const { keycloak } = React.useContext(AuthContext);
  const interval = React.useRef(null);

  // Every 60 seconds request a token refresh if the token is within 70 seconds of expiring
  const TIMER_INTERVAL_MSEC = 60000;
  const TOKEN_MIN_VALIDITY_SEC = 70;

  React.useEffect(() => {
    interval.current = setInterval(() => {
      keycloak
        .updateToken(TOKEN_MIN_VALIDITY_SEC)
        .then((refreshed) => {
          if (refreshed) {
            // Token was successfully refreshed

            setAuthorizationHeader(keycloak.token);
          } else {
            // Token is still valid
          }
        })
        .catch(() => {
          // Failed to refresh the token, or the session has expired

          // Clear the authentication state which triggers SSO authentication
          keycloak.clearToken();
        });
    }, TIMER_INTERVAL_MSEC);

    return () => clearInterval(interval.current);
  }, [keycloak]);

  return null;
};

// Context provider
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const keycloakEnv =
    process.env.NODE_ENV === "production" ? window.DEPLOY_ENV : "development";
  const [keycloak] = React.useState(
    new Keycloak(`/keycloak-${keycloakEnv}.json`)
  );

  React.useEffect(() => {
    const authenticate = () => {
      return keycloak
        .init({
          onLoad: "login-required",
          promiseType: "native",
        })
        .then((authenticated) => {
          // Either authenticated or not authenticated
        })
        .catch((error) => {
          // Failed to initialize
        });
    };

    (async () => {
      await authenticate();

      setAuthorizationHeader(keycloak.token);
      setIsAuthenticated(keycloak.authenticated);
    })();
  }, [keycloak]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, keycloak }}>
      <TokenRefreshTimer />
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook
export const useAuth = () => {
  return React.useContext(AuthContext);
};
