import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../services/usersService";

export const ComponentRequestTableHeaders = ({ budgetFiscalYear }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" scope="col" className={classes.columnYear}>
          {t(`Requests.mainView.list.tableColumns.year`)}
        </TableCell>
        <TableCell
          align="left"
          key="department"
          scope="col"
          className={classes.columnStatus}
        >
          {t(`Requests.mainView.list.tableColumns.dispositionStatus`)}
        </TableCell>
        <TableCell
          align="left"
          key="commitmentName"
          scope="col"
          className={classes.columnName}
        >
          {t(`Requests.mainView.list.tableColumns.componentName`)}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalRequested}
        >
          {t(`Requests.mainView.list.tableColumns.requested`)}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalApproved}
        >
          {t(`Requests.mainView.list.tableColumns.approved`)}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalFyProjection}
        >
          {t(`Requests.mainView.list.tableColumns.fyProjection`, {
            fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
          })}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalFy1Budget}
        >
          {t(`Requests.mainView.list.tableColumns.fyBudget`, {
            fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
          })}
        </TableCell>
        {isCommitmentAdmin && (
          <>
            <TableCell
              align="left"
              scope="col"
              className={classes.columnDispositionNotes}
            >
              {t(`Requests.mainView.list.tableColumns.dispositionNotes`)}
            </TableCell>
          </>
        )}
        <TableCell
          align="left"
          scope="col"
          className={classes.columnRequestNotes}
        >
          {t(`Requests.mainView.list.tableColumns.dispositionComments`)}
        </TableCell>
        <TableCell align="center" scope="col" sx={{ width: "82px" }}>
          {" "}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
