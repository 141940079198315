import React from "react";
import axios from "axios";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FileDownload as FileDownloadIcon,
  Visibility as VisibilityIcon,
  Report as ReportIcon,
} from "@mui/icons-material";
import { RequestsCompletionDialog } from "./RequestsCompletionDialog/RequestsCompletionDialog";
import { CommitmentRequestTableFooter } from "./CommitmentRequestTableFooter";
import { CommitmentRequestTableHeaders } from "./CommitmentRequestTableHeaders";
import { CommitmentRequestTableRow } from "./CommitmentRequestTableRow";
import { PositionedSnackbar } from "../UI/SnackBar/SnackBar";
import { useUsers } from "../../services/usersService";
import { useRequestsContext } from "../../services/requestsContext";
import { useDownload } from "../UI/Download";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { ASConfirmation } from "../UI/ASConfirmation/ASConfirmation";
import {
  getCommitmentRequests,
  patchCommitmentRequests,
} from "../../services/requestsService";
import { getBudgetFiscalYear } from "../../services/budgetingService";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";
import qs from "qs";

/**
 *
 * @returns
 */
export const Requests = () => {
  const { t } = useTranslation();

  const { filters, sort, setSort, pagination, setPagination, getQueryParams } =
    useRequestsContext();
  const { getDocument, downloadInProgress } = useDownload();

  const [rowsPerPage, setRowsPerPage] = React.useState(pagination.rowsPerPage);
  const [page, setPage] = React.useState(pagination.page);
  const [totalCount, setTotalCount] = React.useState(0);
  const [order, setOrder] = React.useState(sort.orderBy);
  const [orderBy, setOrderBy] = React.useState(sort.column);

  const [commitments, setCommitments] = React.useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [totals, setTotals] = React.useState({});
  const [openPublishConfirmation, setOpenPublishConfirmation] =
    React.useState(false);

  const [budgetFiscalYear, setBudgetFiscalYear] = React.useState("");

  const [inlineEditRow, setInlineEditRow] = React.useState(0);

  const [snackPack, setSnackPack] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  // Open/Close dialog
  const [openDialog, setOpenDialog] = React.useState(false);

  const defaults = {
    pagination: [10, 25, 50, 100],
  };
  const classes = useStyles();

  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { clearAlert, setAlert, alert } = useAlertContext();

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  const onBeforeSend = () => {
    clearAlert();
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      setAlert("error", error.message);
    }
  };
  const onSuccess = (responseData) => {
    setCommitments(responseData.values || []);
    setTotalCount(responseData.totalCount);
    setTotals(responseData.meta);
    setLoading(false);
  };

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();

    if (permissions.VIEW_COMMITMENTS && filters.budgetYear !== "") {
      getCommitmentRequests(
        {
          page,
          rowsPerPage,
          orderBy,
          order,
          ...getQueryParams(),
        },
        onBeforeSend,
        onError,
        onSuccess,
        cancelSource
      );
      setRefresh(false);
      setInlineEditRow(false);
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    permissions,
    filters.budgetYear,
    filters.name,
    filters.departmentCode,
    filters.dispositionStatus,
    refresh,
  ]);

  // XXX - refactor to get the fiscal year from the 'meta' property of the requests collection
  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getBudgetFiscalYear(
      () => void 0,
      setBudgetFiscalYear,
      setAlert,
      () => void 0,
      clearAlert,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [setBudgetFiscalYear, setAlert, clearAlert]);

  /**
   * Snackbar Methods
   */
  React.useEffect(() => {
    if (snackPack.length > 0) {
      if (messageInfo === undefined) {
        // Set a new snack when we don't have an active one
        setMessageInfo({ ...snackPack[0] });
        setSnackPack((prev) => prev.slice(1));
        setOpen(true);
      } else if (open) {
        // Close an active snack when a new one is added
        setOpen(false);
      }
    }
  }, [snackPack, messageInfo, open]);

  const pushSnackbarMessage = (severity, message) => {
    setSnackPack((prev) => [
      ...prev,
      { severity, message, key: new Date().getTime() },
    ]);
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSnackbarExited = () => {
    setMessageInfo(undefined);
  };
  // End Snackbar

  //console.log("Requests.js render");

  return (
    <>
      <PositionedSnackbar
        key={messageInfo?.key || undefined}
        open={open}
        onClose={handleSnackbarClose}
        message={messageInfo?.message || undefined}
        severity={messageInfo?.severity || "info"}
        TransitionProps={{ onExited: handleSnackbarExited }}
      />

      {/* Confirmation Dialog for publishing Results Table */}
      <ASConfirmation
        open={openPublishConfirmation}
        title={
          <>
            <ReportIcon
              fontSize={"large"}
              sx={{ verticalAlign: "bottom", marginRight: "5px" }}
            />
            {t(`Requests.mainView.publishConfirmDialog.title`)}
          </>
        }
        message={t(`Requests.mainView.publishConfirmDialog.message`)}
        handleCancel={() => {
          setOpenPublishConfirmation(false);
        }}
        handleOk={() => {
          const cancelSource = axios.CancelToken.source();
          setOpenPublishConfirmation(false);

          patchCommitmentRequests(
            commitments,
            () => {
              setLoading(true);
            },
            onError,
            () => {
              setLoading(false);
              setRefresh(true);
            },
            cancelSource
          );
        }}
        okLabel={t(`Requests.mainView.publishConfirmDialog.okLabel`)}
        cancelLabel={t("globals.form.actionButtons.cancel")}
      />

      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        className={classes.container}
      >
        {alert.exists && (
          <Grid item xs={12}>
            <ASAlert />
          </Grid>
        )}

        {permissions.DOWNLOAD_REQUESTS && commitments?.length > 0 && (
          <>
            <Grid item container justifyContent="space-between" xs={12}>
              {/* Publish the Results table to other users */}
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<VisibilityIcon />}
                  disabled={loading}
                  onClick={() => {
                    // Open confirmation dialog
                    setOpenPublishConfirmation(true);
                  }}
                  sx={{
                    backgroundColor: "#47ac5b",
                    "&:hover": { backgroundColor: "#007c2f" },
                  }}
                >
                  {t(`Requests.mainView.publishButtonText`)}
                </Button>
              </Grid>

              {/* Export Table as Excel */}
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={
                    downloadInProgress ? (
                      <CircularProgress size={15} />
                    ) : (
                      <FileDownloadIcon />
                    )
                  }
                  disabled={!!downloadInProgress || loading}
                  onClick={() => {
                    const queryParams = getQueryParams();
                    getDocument(
                      `${axios.defaults.baseURL}/requests/excel?${qs.stringify({
                        commitmentName: queryParams.name,
                        ...queryParams,
                      })}`,
                      "requests_table_export.xlsx"
                    );
                  }}
                >
                  {t(`Requests.mainView.exportButtonText`)}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {permissions.VIEW_REQUESTS && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-label="Commitments Table"
                stickyHeader
                classes={{ root: classes.tableLayout }}
                sx={{ tableLayout: "fixed" }}
              >
                <CommitmentRequestTableHeaders
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  budgetFiscalYear={budgetFiscalYear}
                />

                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell
                        colSpan={getColSpan(permissions, roles)}
                        classes={{ root: classes.progressBar }}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Display message to non commitment admins explaining this years' requests are not yet visible */}
                  {!loading && !commitments.length && !isCommitmentAdmin && (
                    <TableRow>
                      <TableCell colSpan={getColSpan(permissions, roles)}>
                        <Typography
                          variant="body1"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          <h2>
                            The {budgetFiscalYear} fiscal year commitment
                            requests have not yet been approved. <br />
                            Once finalized, they will appear here.
                          </h2>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading && !commitments.length && isCommitmentAdmin && (
                    <TableRow>
                      <TableCell colSpan={getColSpan(permissions, roles)}>
                        <Typography
                          variant="body1"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          {t("globals.list.messages.noData")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {!loading &&
                    commitments.map((row, index) => (
                      <CommitmentRequestTableRow
                        commitment={row}
                        key={index}
                        budgetFiscalYear={budgetFiscalYear}
                        inlineEditRow={inlineEditRow}
                        setInlineEditRow={setInlineEditRow}
                        pushSnackbarMessage={pushSnackbarMessage}
                        setRefresh={setRefresh}
                        setLoading={setLoading}
                        setOpenDialog={setOpenDialog}
                      />
                    ))}
                </TableBody>

                <CommitmentRequestTableFooter
                  budgetFiscalYear={budgetFiscalYear}
                  totals={totals}
                />
              </Table>
              <TablePagination
                rowsPerPageOptions={defaults.pagination}
                component="div"
                count={totalCount}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        )}
      </Grid>

      {openDialog && (
        <RequestsCompletionDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          pushSnackbarMessage={pushSnackbarMessage}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export const getColSpan = (permissions, roles) => {
  let numColumns = 9;
  roles?.includes("COMMITMENT_ADMIN") && numColumns++;
  //permissions.MEMO_COMMITMENT && numColumns++;
  return numColumns;
};

const getIdFromHateoas = (hateoasUrl) => {
  return new URL(hateoasUrl).pathname.split("/").pop();
};

export const getViewUrl = (url) => {
  return `/commitments/${getIdFromHateoas(url)}/view`;
};
export const getEditUrl = (url) => {
  return `/commitments/${getIdFromHateoas(url)}/edit`;
};
