import React from "react";
import {
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { NumberFormatField } from "../UI/NumberFormatField";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { useStyles } from "./Requests.styles";
import { ComponentDispositionTypes } from "../../constants";
import { patchComponentRequest } from "../../services/requestsService";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const ComponentRequestTableRowForm = ({
  component,
  commitmentName,
  commitmentId,
  setLoading,
  pushSnackbarMessage,
  formData,
  setFormData,
  setRefresh,
  setInlineEditRow,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openToolTip, setOpenToolTip] = React.useState(true);

  const onBeforeSend = () => {
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      pushSnackbarMessage("error", error.message);
    }
  };
  const onSuccess = (values) => {
    setLoading(false);
    pushSnackbarMessage(
      "success",
      t("Commitments.commitmentComponents.edit.notification.success", {
        componentName: `"${component.componentName.trim()}"`,
        commitmentName: `"${commitmentName.trim()}"`,
      })
    );
    setFormData(values);
    setRefresh((value) => value + 1);
    setInlineEditRow(false);
  };

  const saveComponent = (values) => {
    const cancelSource = axios.CancelToken.source();
    patchComponentRequest(
      values,
      commitmentId,
      component.componentId,
      onBeforeSend,
      onError,
      onSuccess,
      cancelSource
    );
  };

  const validationSchema = Yup.object().shape({
    dispositionStatus: Yup.mixed().required(
      t("globals.form.fieldIsRequired", {
        fieldName: t(`Requests.inlineEdit.form.fields.dispositionStatus.label`),
      })
    ),
    totalComponentAmountApproved: Yup.number().when("dispositionStatus", {
      is: (i) => i && i.toLowerCase() === "approved",
      then: Yup.number()
        .moreThan(
          0,
          t(
            `Requests.inlineEdit.form.fields.totalComponentAmountApproved.validation.greaterThanZero`
          )
        )
        .required(),
      otherwise: undefined,
    }),
  });

  // Cancel out of inline editing with the ESC key
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setInlineEditRow(false);
      }
    };
    window.addEventListener("keydown", handleEsc, false);
    return () => {
      window.removeEventListener("keydown", handleEsc, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validateOnMount
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        saveComponent(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          values,
          setFieldValue,
          dirty,
          isValid,
          errors,
          handleChange,
          handleSubmit,
        } = formikProps;
        return (
          <>
            {/* Year */}
            <TableCell>{component?.budgetFiscalYear}</TableCell>

            {/* Disposition Status */}
            <TableCell>
              <Tooltip
                title={errors?.dispositionStatus ?? ""}
                placement="bottom"
                open={!!errors.dispositionStatus && openToolTip}
              >
                <span>
                  <Select
                    autoFocus
                    labelId="statusSelectLabel"
                    inputProps={{
                      "aria-label": t(
                        "Commitments.filterPanelItems.options.dispositionStatus.label"
                      ),
                    }}
                    id="statusFilter"
                    value={values?.dispositionStatus || ""}
                    onChange={(e) => {
                      setFieldValue("dispositionStatus", e.target.value);
                      //console.log(e.target.value, t(ComponentDispositionTypes["APPROVED"]).toUpperCase())

                      if (
                        e.target.value !==
                        t(ComponentDispositionTypes["APPROVED"]).toUpperCase()
                      ) {
                        // Using setTimeout as a workaround to Formik validating with the previous values
                        setTimeout(() => {
                          setFieldValue(
                            "totalComponentAmountApproved",
                            0,
                            false
                          );
                        }, "200");
                      } else {
                        setTimeout(() => {
                          setFieldValue(
                            "totalComponentAmountApproved",
                            values?.totalComponentAmountRequested,
                            false
                          );
                          document
                            .getElementById("totalComponentAmountApproved")
                            .focus();
                        }, "200");
                      }
                    }}
                    onBlur={() => setOpenToolTip(true)}
                    onFocus={() => setOpenToolTip(false)}
                    className={`${classes.inputPadding} ${classes.comboBox}`}
                    error={!!errors.dispositionStatus}
                  >
                    {Object.entries(ComponentDispositionTypes).map(
                      ([key, value], index) => (
                        <MenuItem key={index} value={key}>
                          {t(value)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </span>
              </Tooltip>
            </TableCell>

            {/* Component Name */}
            <TableCell>
              {component.componentName && (
                <Tooltip
                  title={component?.componentName ?? ""}
                  placement="left"
                >
                  <Typography noWrap={true} variant="caption" component="div">
                    <span>{component?.componentName}</span>
                  </Typography>
                </Tooltip>
              )}
            </TableCell>

            {/* Amounts */}
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(values?.totalComponentAmountRequested || 0)}
            </TableCell>
            <TableCell align="right">
              <Tooltip
                title={errors?.totalComponentAmountApproved ?? ""}
                placement="bottom"
                open={!!errors.totalComponentAmountApproved && openToolTip}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 20],
                      },
                    },
                  ],
                }}
              >
                <span>
                  <NumberFormatField
                    id="totalComponentAmountApproved"
                    setFieldValue={setFieldValue}
                    formValues={values?.totalComponentAmountApproved}
                    restrictions={true}
                    className={`${classes.inputPadding} ${classes.numericInput}`}
                    disabled={values?.dispositionStatus !== "APPROVED"}
                    variant={
                      values?.dispositionStatus !== "APPROVED"
                        ? "standard"
                        : "outlined"
                    }
                    onBlur={() => {
                      setOpenToolTip(true);
                    }}
                    onFocus={() => {
                      setOpenToolTip(false);
                    }}
                    error={!!errors.totalComponentAmountApproved}
                  />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(values?.fyProjectionOne)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(values?.fyBudget)}
            </TableCell>

            {/* Note Columns */}
            <TableCell
              align="left"
              classes={{ root: classes.childCellSecondary }}
            >
              <OutlinedInput
                id="dispositionNotes"
                multiline
                fullWidth
                classes={{
                  multiline: classes.multilineInput,
                }}
                value={values?.dispositionNotes}
                onChange={handleChange}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </TableCell>
            <TableCell
              align="left"
              classes={{ root: classes.childCellSecondary }}
            >
              <OutlinedInput
                id="dispositionComments"
                multiline
                fullWidth
                classes={{
                  multiline: classes.multilineInput,
                }}
                value={values?.dispositionComments}
                onChange={handleChange}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </TableCell>

            {/* Actions */}
            <TableCell align="left">
              {/* Submit */}
              <IconButton
                size="small"
                disabled={!dirty || !isValid}
                onClick={() => handleSubmit()}
              >
                <CheckIcon size="small" />
              </IconButton>
              {/* Cancel */}
              <IconButton
                size="small"
                onClick={() => {
                  setInlineEditRow(false);
                }}
              >
                <CloseIcon size="small" />
              </IconButton>
            </TableCell>
          </>
        );
      }}
    </Formik>
  );
};
