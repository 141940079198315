import React from "react";
import {
  Autocomplete,
  Breadcrumbs,
  Typography,
  Box,
  TextField,
  Checkbox,
  Grid,
  Button,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useClasses } from "../../../../Utils/MakeStylesWithEmotion";
import { styles } from "./CreateEditCommitmentDocumentForm.styles";
import {
  NavigateNext as NavigateNextIcon,
  CloudDownload as CloudDownloadIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import {
  Link as RouterLink,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import { DropZone } from "../../../../Utils/DropZone/DropZone";
import { DocumentTypes } from "../../../../constants";
import * as Yup from "yup";
import {
  postDocument,
  putDocument,
  patchDocument,
  getDocumentById,
  getDocumentBrief,
} from "../../../../services/commitmentsService";
import { useDownload } from "../../../UI/Download";
import { useUsers } from "../../../../services/usersService";
import { FormButton } from "../../../UI/Button/FormButton";
import { useAlertContext } from "@stanford-tds/as-components";
import axios from "axios";

export const CreateEditCommitmentDocumentForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // `useStyles` will provide styles from CommitmentForm.styles
  const classes = useClasses(styles);
  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  // useHistory hook from "react-router-dom"
  const history = useHistory();
  const location = useLocation();

  const { getDocument, downloadInProgress } = useDownload();

  const {
    state: {
      docVariant,
      documentType,
      documentName,
      documentNotes,
      departmentAdminView,
      acceptedFiles,
      dropzoneText,
    } = {},
  } = location;

  const { pageType } = props;
  const params = useParams();
  const isNewHireTemplate = docVariant === "NEW_HIRE_EXCEL_TEMPLATE";
  const extraDropZoneProps = isNewHireTemplate
    ? { acceptedFiles, dropzoneText }
    : {};

  const handleClose = () => {
    if (documentType === "RECRUITMENT") {
      history.push(`/budgeting`);
    } else {
      history.push(`/commitments/${params.commitmentId}/documents`);
    }
  };
  const { clearAlert, setAlert } = useAlertContext();

  const documentFormFieldsStr =
    "Commitments.commitmentDocuments.create.form.fields";
  const documentCreateStr = "Commitments.commitmentDocuments.create";
  const documentEditStr = "Commitments.commitmentDocuments.edit";
  const documentsMainView = "Commitments.commitmentDocuments.mainView";

  const [uploadedDocument, setUploadedDocument] = React.useState("");
  const [commitmentName, setCommitmentName] = React.useState("");

  /**
   * `formDataResponse` - Use to store the response of create/edit form data
   * `setFormDataResponse` - Use to set/modify the value of state/variable formDataResponse
   *
   */
  const [formDataResponse, setFormDataResponse] = React.useState({
    // Object keys use to create/edit form data
    documentType /* Document Type */: isNewHireTemplate ? documentType : null,
    documentName /* Document Name */: isNewHireTemplate ? documentName : "",
    documentNotes /* Notes */: isNewHireTemplate ? documentNotes : "",
    departmentAdminView /* Department Admin View */: isNewHireTemplate
      ? departmentAdminView
      : false,
    file /* Uploaded Document */: "",
    uploadedBy: "",
    uploadedDate: "",
  });

  const handleDocumentUpload = (files) => {
    files && files[0] && setUploadedDocument(files[0]);
  };

  const _handleSubmit = (values, setSubmitting, setFieldError) => {
    try {
      if (uploadedDocument instanceof File) {
        params.documentId
          ? putDocument(
              values,
              uploadedDocument,
              params.commitmentId,
              params.documentId,
              props,
              clearAlert,
              setAlert,
              t,
              setFieldError
            )
          : postDocument(
              values,
              uploadedDocument,
              params.commitmentId,
              props,
              clearAlert,
              setAlert,
              t,
              setFieldError
            );
      } else {
        params.documentId &&
          patchDocument(
            values,
            params.commitmentId,
            params.documentId,
            props,
            clearAlert,
            setAlert,
            t,
            setFieldError
          );
      }
    } catch (e) {}
  };

  const commitmentValidation = Yup.object().shape({
    documentType: Yup.string().required(),
    documentName: Yup.string().required(),
    documentNotes: Yup.string().when("documentType", {
      is: (val) => (val ? val === "OTHER" : false),
      then: Yup.string().required(),
      otherwise: undefined,
    }),
    departmentAdminView: Yup.boolean(),
    file: Yup.mixed().required(),
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    params.commitmentId &&
      getDocumentBrief(
        params.commitmentId,
        setCommitmentName,
        setAlert,
        clearAlert,
        cancelSource
      );
    // eslint-disable-next-line
  }, [params.CommitmentId]);

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    params.documentId &&
      getDocumentById(
        params.commitmentId,
        params.documentId,
        setFormDataResponse,
        setCommitmentName,
        setUploadedDocument,
        setAlert,
        clearAlert,
        cancelSource
      );

    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.CommitmentId, params.documentId]);

  const getMainHeadingTxt = () => {
    let txt;
    if (pageType === "new") {
      if (isNewHireTemplate) {
        txt = t(`${documentCreateStr}.newHireTemplate`);
      } else {
        txt = t(`${documentCreateStr}.title`);
      }
    } else if (pageType === "edit") {
      if (isNewHireTemplate) {
        txt = t(`${documentEditStr}.newHireTemplate`);
      } else {
        txt = t(`${documentEditStr}.title`);
      }
    }
    return txt + ": " + commitmentName;
  };

  return (
    ((pageType === "new" &&
      (permissions.ADD_DOCUMENTS ||
        permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE)) ||
      (pageType === "edit" &&
        (permissions.EDIT_DOCUMENTS ||
          permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE))) && (
      <Grid
        container
        classes={{
          root: classes.mainContainer,
        }}
      >
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouterLink to={`/commitments`}>
              <Typography color="textSecondary" variant="body2">
                {t("Commitments.mainView.title")}
              </Typography>
            </RouterLink>
            <RouterLink to={`/commitments/${params.commitmentId}/documents`}>
              <Typography color="textSecondary" variant="body2">
                {t(`${documentsMainView}.documentsBreadCrumbLink`)}
              </Typography>
            </RouterLink>
            <Typography color="textPrimary" variant="subtitle1">
              {pageType === "new"
                ? t(`${documentCreateStr}.breadcrumbTitle`)
                : t(`${documentEditStr}.breadcrumbTitle`)}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} className={classes.mainHeading}>
          <Typography variant="h1">{getMainHeadingTxt()}</Typography>
        </Grid>

        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment */}
        <Formik
          // initialValues - User to store the Formik form's intial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize={true}
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            _handleSubmit(values, setSubmitting, setFieldError);
          }}
          validationSchema={commitmentValidation}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                handleChange,
                setFieldValue,
                dirty,
                isValid,
                handleSubmit,
              } = formikProps;
            return (
              // Native form element to submit the form values
              <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.formContainer}
                  >
                    {!isNewHireTemplate && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Autocomplete
                            id="documentType"
                            name="documentType"
                            options={Object.values(DocumentTypes).filter(
                              (x) => x !== DocumentTypes["RECRUITMENT"]
                            )}
                            getOptionLabel={(option) => t(option)}
                            value={
                              values.documentType &&
                              t(DocumentTypes[values.documentType])
                            }
                            onChange={(event, valueObj) => {
                              setFieldValue(
                                "documentType",
                                Object.keys(DocumentTypes).find(
                                  (key) => DocumentTypes[key] === valueObj
                                )
                              );
                            }}
                            clearIcon={false}
                            renderInput={(args) => (
                              <TextField
                                {...args}
                                label={t(
                                  `${documentFormFieldsStr}.documentType.label`
                                )}
                                required={true}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={7} />
                        <Grid item xs={12} md={5}>
                          {/* Document Name - TextField Input */}
                          <TextField
                            id="documentName"
                            label={t(
                              `${documentFormFieldsStr}.documentName.label`
                            )}
                            required={true}
                            value={values.documentName}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item md={7} />
                        <Grid item xs={12} md={5}>
                          {/* Notes - Multiline Textfield Input */}
                          <TextField
                            id="documentNotes"
                            label={t(
                              `${documentFormFieldsStr}.documentNotes.label`
                            )}
                            required={
                              values.documentType === "OTHER" ? true : false
                            }
                            value={values.documentNotes}
                            onChange={(event) => {
                              setFieldValue(
                                "documentNotes",
                                event.target.value
                              );
                            }}
                            multiline
                            variant="outlined"
                            autoComplete="off"
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item md={7} />
                        <Grid item xs={12} md={5}>
                          {/* Department Admin View - Checkbox Input */}
                          <FormControlLabel
                            label={t(
                              `${documentFormFieldsStr}.departmentAdminView.label`
                            )}
                            classes={{ root: classes.inputLabel }}
                            control={
                              <Checkbox
                                id="departmentAdminView"
                                color="default"
                                className={classes.checkbox}
                                checked={values.departmentAdminView}
                                onChange={(event) => {
                                  setFieldValue(
                                    "departmentAdminView",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item md={7} />
                      </>
                    )}
                    <Grid item xs={12} md={5}>
                      <DropZone
                        handleDocumentUpload={handleDocumentUpload}
                        handleDrop={() => {
                          setFieldValue("file", handleDocumentUpload);
                        }}
                        {...extraDropZoneProps}
                      />
                      <Box className={classes.error}>
                        <ErrorMessage name="file" />
                      </Box>
                      {/* Display the updloaded file */}
                      <Box mt={1}>
                        <Grid container>
                          {uploadedDocument && uploadedDocument.name && (
                            <Grid
                              container
                              item
                              xs={12}
                              md={8}
                              justifyContent="flex-start"
                            >
                              <DoneIcon />
                              <Typography component="span" variant="body2">
                                {uploadedDocument.name
                                  .split(".")[0]
                                  .substring(0, 40) +
                                  (uploadedDocument.name.length > 40
                                    ? "... ."
                                    : ".") +
                                  uploadedDocument.name.split(".").pop()}
                              </Typography>
                            </Grid>
                          )}
                          {formDataResponse && formDataResponse.file && (
                            <Grid
                              container
                              item
                              xs={12}
                              md={4}
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="text"
                                color="inherit"
                                className={classes.downloadButton}
                                startIcon={
                                  downloadInProgress ? (
                                    <CircularProgress size={15} />
                                  ) : (
                                    <CloudDownloadIcon />
                                  )
                                }
                                disabled={!!downloadInProgress}
                                onClick={() => {
                                  getDocument(
                                    `/commitments/${params.commitmentId}/documents/${params.documentId}/download`,
                                    uploadedDocument?.name
                                  );
                                }}
                              >
                                {t(
                                  "Commitments.commitmentDocuments.mainView.list.downloadCommitmentDocumentsTooltip"
                                )}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={7} />
                    {formDataResponse && formDataResponse.uploadedBy && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Typography variant="caption">
                            <Typography
                              component="span"
                              variant="caption"
                              color="textSecondary"
                            >
                              {t(
                                `${documentsMainView}.list.tableColumns.uploadedBy`
                              )}{" "}
                            </Typography>
                            {formDataResponse.uploadedBy}
                            <Typography
                              component="span"
                              variant="caption"
                              color="textSecondary"
                            >
                              {" "}
                              {t(
                                "UploadedExpenses.mainView.list.notificationStatusTooltip.on"
                              )}{" "}
                            </Typography>
                            {formDataResponse.uploadedDate}
                          </Typography>
                        </Grid>
                        <Grid item md={7} />
                      </>
                    )}
                    <Grid item xs={12} md={5}>
                      <Grid item md={12} container justifyContent="flex-end">
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          md={6}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{ disabled: !dirty || !isValid }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={7} />
                  </Grid>
                </form>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    )
  );
};
