import React from "react";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * A component for Cancel & Save buttons in forms.
 *
 * @param {{cancel: function, save: Object}} props
 * @returns the Cancel & Save buttons.
 * @example
 * <FormButton
 *   cancel={handleClose}
 *   save={{ disabled: true }}
 * />
 *
 * @author Paras Sahu
 */
export const FormButton = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  const isDeleteRecord = props.delete !== undefined;
  return (
    <>
      <Grid item xs={6}>
        <Button
          onClick={props.cancel}
          color="primary"
          variant="outlined"
          fullWidth={true}
        >
          {t("globals.form.actionButtons.cancel")}
        </Button>
      </Grid>
      {isDeleteRecord ? (
        <Grid item xs={6}>
          <Button
            onClick={props.delete}
            color="primary"
            variant="outlined"
            fullWidth={true}
          >
            {t("globals.form.actionButtons.delete")}
          </Button>
        </Grid>
      ) : (
        <Grid item xs={6}>
          <Button
            type="submit"
            color="primary"
            fullWidth={true}
            variant="contained"
            {...props.save}
          >
            {t("globals.form.actionButtons.save")}
          </Button>
        </Grid>
      )}
    </>
  );
};
