import React from "react";
import {
  TableRow,
  TableCell,
  OutlinedInput,
  InputAdornment,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import NumberFormat from "react-number-format";
import { useStyles } from "./Budgeting.styles";
import { RenderPTAs, getAmount, getComponentLink } from "./BudgetTable";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  NewReleases as NewReleasesIcon,
} from "@mui/icons-material";
import { Formik } from "formik";
import { useAlertContext } from "@stanford-tds/as-components";
import { getBudgetByComponentId } from "../../services/budgetingService";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const InlineForm = (props) => {
  const { t } = useTranslation();
  const row = props.row;
  const commitmentId = props.commitmentId;
  const componentId = props.componentId;
  const classes = useStyles();
  const setEditComponent = props.setEditComponent;
  const putBudgetByComponentId = props.putBudgetByComponentId;
  const budgetFiscalYear = props.budgetFiscalYear;
  const setSnackBar = props.setSnackBar;
  const setRefresh = props.setRefresh;
  const editComponent = props.editComponent;
  const setInfoPanelDisabled = props.setInfoPanelDisabled;

  const { setAlert, clearAlert } = useAlertContext();

  const [formData, setFormData] = React.useState({
    fyProjectionOne: "",
    fyBudget: "",
    fyPlanOne: "",
    fyPlanTwo: "",
    comments: "",
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getBudgetByComponentId(
      componentId,
      budgetFiscalYear,
      setAlert,
      clearAlert,
      setFormData,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [budgetFiscalYear, componentId, setAlert, clearAlert]);

  return (
    <Formik initialValues={formData} enableReinitialize={true}>
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        return (
          <TableRow classes={{ root: classes.childRow }}>
            <TableCell classes={{ root: classes.childCellPrimary }}>
              <Typography noWrap={true}>
                {row.componentType === "REQUEST" && (
                  <Tooltip title={t(`Budgeting.mainView.newComponent`)}>
                    <NewReleasesIcon size="small" className={classes.yellow} />
                  </Tooltip>
                )}
                <RenderPTAs component={row} editComponent={editComponent} />{" "}
                <Tooltip
                  title={row.componentName}
                  placement="top"
                  classes={{
                    tooltip: classes.tooltipStyle,
                  }}
                >
                  {getComponentLink(
                    editComponent,
                    commitmentId,
                    componentId,
                    row.componentName
                  )}
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.totalComponentAmountRequested)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.fyActualsThree)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.fyActualsTwo)}
              </Typography>
            </TableCell>

            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.totalExpenditure)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.totalUnspentAmount)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.fyActualsOne)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.fyBudgetOne)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <NumberFormatField
                id="fyProjectionOne"
                setFieldValue={setFieldValue}
                formValues={values.fyProjectionOne}
                restrictions={false}
              />
            </TableCell>
            <TableCell classes={{ root: classes.childCellSecondary }}>
              <NumberFormatField
                id="fyBudget"
                setFieldValue={setFieldValue}
                formValues={values.fyBudget}
                restrictions={true}
              />
            </TableCell>
            <TableCell classes={{ root: classes.childCellSecondary }}>
              <NumberFormatField
                id="fyPlanOne"
                setFieldValue={setFieldValue}
                formValues={values.fyPlanOne}
                restrictions={true}
              />
            </TableCell>
            <TableCell classes={{ root: classes.childCellSecondary }}>
              <NumberFormatField
                id="fyPlanTwo"
                setFieldValue={setFieldValue}
                formValues={values.fyPlanTwo}
                restrictions={true}
              />
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.availableAfterFYPlanTwo)}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              classes={{ root: classes.childCellSecondary }}
            >
              <Typography variant="body2" align="right">
                {getAmount(row.fyBudgetCap)}
              </Typography>
            </TableCell>
            <TableCell classes={{ root: classes.childCellSecondary }}>
              <OutlinedInput
                id="comments"
                multiline
                classes={{
                  multiline: classes.multilineInput,
                }}
                value={values.comments}
                onChange={(event) => {
                  setFieldValue("comments", event.target.value);
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </TableCell>
            <TableCell classes={{ root: classes.childCellSecondary }}>
              <IconButton
                size="small"
                onClick={() => {
                  setSnackBar(false);
                  putBudgetByComponentId(
                    values,
                    componentId,
                    budgetFiscalYear,
                    setEditComponent,
                    setSnackBar,
                    setRefresh,
                    setInfoPanelDisabled,
                    setAlert,
                    clearAlert
                  );
                }}
              >
                <CheckIcon size="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setInfoPanelDisabled(false);
                  setEditComponent(0);
                }}
              >
                <CloseIcon size="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      }}
    </Formik>
  );
};

const NumberFormatField = (props) => {
  const classes = useStyles();

  return (
    <NumberFormat
      classes={{ root: classes.numberFormatInput }}
      thousandSeparator={true}
      decimalScale={2}
      customInput={OutlinedInput}
      value={props.formValues}
      isAllowed={(values) => {
        const { value } = values;
        if (props.restrictions) {
          return value !== "-" && value >= 0 && value <= 999999999;
        } else {
          return value === "-" || (value >= -999999999 && value <= 999999999);
        }
      }}
      fixedDecimalScale={true}
      autoComplete="off"
      allowNegative={!props.restrictions}
      startAdornment={
        <InputAdornment component="div" position="start">
          <Typography variant="body2">$</Typography>
        </InputAdornment>
      }
      className={classes.inputPadding}
      onValueChange={(data) => {
        props.setFieldValue(props.id, data.value);
      }}
    />
  );
};
