import makeStyles from "@mui/styles/makeStyles";

// useStyles - Contains styles for variaous parts of User Interface
export const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.text.primary,
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  paddingTop: {
    paddingTop: "1px",
  },
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
      width: "calc(100% - 32px)",
    },
  },
  mainHeading: {
    padding: "8px 0",
  },
  formContainer: {
    "&.MuiGrid-root > .MuiGrid-item": {
      padding: "8px 0",
      maxWidth: "83.333333%",
    },
  },
  disabled: {
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  paddingRight: {
    "&.MuiFormControl-root": {
      paddingRight: "8px",
    },
  },
}));
