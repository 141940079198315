import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays } from "date-fns";
import { getIsValidDate } from "../../../Utils/ValidationUtils";

export const ComponentExpirationDateField = ({
  value,
  startDate,
  onChange,
  required = false,
  size = "medium",
  ...passedParams
}) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...passedParams}
        required={required}
        value={getIsValidDate(value)}
        onChange={(val) => {
          onChange(val);
        }}
        inputVariant="outlined"
        minDate={(startDate && addDays(new Date(startDate), 1)) || undefined}
        minDateMessage={t(
          `Commitments.commitmentComponents.create.form.fields.expirationDate.validation.minDateMessage`
        )}
        size={size}
        inputFormat="MM/dd/yyyy"
        clearable={true}
        fullWidth={true}
        inputProps={{ autoComplete: "off" }}
        renderInput={(innerProps) => (
          <TextField {...innerProps} required={required} size={size} />
        )}
      />
    </LocalizationProvider>
  );
};
