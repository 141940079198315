import React from "react";
import { useTranslation } from "react-i18next";
import { TableFooter, TableRow, TableCell, Button } from "@mui/material";
import { useStyles } from "./CommitmentComponentRequestForm.styles";
import { CurrencyFormatMarkup } from "../../../Budgeting/BudgetTable";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";

export const CommitmentComponentRequestFooter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const records = props.components || [];
  let totals = {
    amount: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.componentAmount || 0),
      0
    ),
    fyBudget: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyBudget || 0),
      0
    ),
    fyPlanOne: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyPlanOne || 0),
      0
    ),
    fyPlanTwo: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyPlanTwo || 0),
      0
    ),
    fyProjectionOne: records.reduce(
      (accum, currentObj) =>
        accum + parseFloat(currentObj?.budgetPlan?.fyProjectionOne || 0),
      0
    ),
  };

  return (
    <TableFooter classes={{ root: classes.budgetTableFooter }}>
      <TableRow>
        <TableCell align="right" /*variant="head"*/ scope="row">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableFooters.commitTotal"
          ) + ":"}
        </TableCell>
        <TableCell align="right" classes={{ root: classes.columnTotal }}>
          {CurrencyFormatMarkup(totals.amount)}
        </TableCell>
        <TableCell align="right" classes={{ root: classes.columnTotal }}>
          {CurrencyFormatMarkup(totals.fyProjectionOne)}
        </TableCell>
        <TableCell align="right" classes={{ root: classes.columnTotal }}>
          {CurrencyFormatMarkup(totals.fyBudget)}
        </TableCell>
        <TableCell align="right" classes={{ root: classes.columnTotal }}>
          {CurrencyFormatMarkup(totals.fyPlanOne)}
        </TableCell>
        <TableCell align="right" classes={{ root: classes.columnTotal }}>
          {CurrencyFormatMarkup(totals.fyPlanTwo)}
        </TableCell>

        <TableCell align="right" colSpan={!props.isViewOnly ? "4" : "3"}>
          {/*console.log(props.budgetEditableByCurrentUser) */}
          {!props.isViewOnly && props.budgetEditableByCurrentUser && (
            <Button
              color="inherit"
              size="small"
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                props?.setScrollRowIntoView(true);
                props?.arrayHelpers.current.push(props?.getEmptyComponent());
              }}
            >
              {t("Commitments.mainView.list.addComponentTooltip")}
            </Button>
          )}
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
