import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  actionContent: {
    display: "flex",
  },
  ml5: {
    marginLeft: "5px",
  },
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  blue: {
    color: theme.palette.primary.main,
  },
  icon: {
    cursor: "pointer",
    marginLeft: 5,
  },
  progressBar: {
    textAlign: "center",
  },
  subtitleColor: { color: theme.palette.grey[700] },
  notificationStatusTooltipTitle: { fontWeight: 600 },
  notificationStatusTooltip: {
    width: "155px",
    backgroundColor: theme.palette.grey[700],
    whiteSpace: "pre-line",
  },
  tableLayout: {
    "&.MuiTable-root": {
      tableLayout: "fixed",
    },
  },
}));
