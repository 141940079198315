import React from "react";
import { PTAs } from "../components/PTAs/PTAs";
import { PTAsFilter } from "../components/PTAs/PTAs.filters";
import { PTADetails } from "../components/PTAs/PTADetails";
import { Commitments } from "../components/Commitments/Commitments";
import { CommitmentsFilter } from "../components/Commitments/Commitments.filters";
import { CommitmentForm } from "../components/Commitments/CommitmentForm/CommitmentForm";
import { CommitmentRequestForm } from "../components/Commitments/CommitmentRequest/CommitmentRequestForm";
import { Expenses } from "../components/Expenses/Expenses";
import { ExpensesFilter } from "../components/Expenses/Expenses.filters";
import { Transfers } from "../components/Transfers/Transfers";
import { TransfersFilter } from "../components/Transfers/Transfers.filters";
import { Budgeting } from "../components/Budgeting/Budgeting";
import { CommitmentDocuments } from "../components/Commitments/CommitmentDocuments/CommitmentDocuments";
import { CreateEditCommitmentDocumentForm } from "../components/Commitments/CommitmentDocuments/CreateEditCommitmentDocumentForm/CreateEditCommitmentDocumentForm";
import { CommitmentComponentForm } from "../components/Commitments/CommitmentComponents/CommitmentComponentForm/CommitmentComponentForm";
import { CommitmentComponentRequestForm } from "../components/Commitments/CommitmentComponents/CommitmentComponentRequest/CommitmentComponentRequestForm";
import { CommitmentProvider } from "../services/commitmentContext";
import { RequestsProvider } from "../services/requestsContext";
import { ExpensesProvider } from "../services/expensesContext";
import { TransfersProvider } from "../services/transfersContext";
import { FinancialHistoryForm } from "../components/Commitments/CommitmentComponents/FinancialHistory/FinancialHistoryForm/FinancialHistoryForm";
import { FinancialHistory } from "../components/Commitments/CommitmentComponents/FinancialHistory/FinancialHistory";
import { BudgetingProvider } from "../services/budgetingContext";
import { FinancialTransactionForm } from "../components/Commitments/CommitmentComponents/FinancialHistory/FinancialTransactionForm/FinancialTransactionForm";
import { PtaProvider } from "../services/ptasContext";
import { Requests } from "../components/Requests/Requests";
import { RequestsFilter } from "../components/Requests/Requests.filters";

export const RoutesList = {
  /** Routes for PTAS */
  ptas: [
    {
      path: "/ptas",
      title: "PTAs",
      exact: true,
      filter: <PTAsFilter />,
      main: <PTAs />,
      provider: PtaProvider,
      permissions: ["VIEW_PTAS"],
    },
    {
      path: "/ptas/:PtaId",
      title: null,
      exact: true,
      filter: null,
      main: <PTADetails />,
      provider: PtaProvider,
      permissions: ["VIEW_PTA_DETAILS"],
    },
  ],

  budgeting: [
    {
      path: "/budgeting",
      title: "Budgeting",
      exact: true,
      filter: null,
      main: <Budgeting />,
      provider: BudgetingProvider,
      permissions: ["VIEW_BUDGET"],
    },
    {
      path: "/budgeting/ptas/:PtaId",
      title: null,
      exact: true,
      filter: null,
      main: <PTADetails pageType="newTab" />,
      permissions: ["VIEW_BUDGET"],
    },
    {
      path: "/budgeting/commitments/:CommitmentId/view",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="view" parent="other" />,
      permissions: ["VIEW_BUDGET"],
    },
  ],
  /** Routes for Budgeting */

  commitments: [
    {
      path: "/commitments",
      title: "Commitments",
      exact: true,
      filter: <CommitmentsFilter />,
      main: <Commitments />,
      provider: CommitmentProvider,
      permissions: ["VIEW_COMMITMENTS"],
    },
    {
      path: "/commitments/:CommitmentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="edit" />,
      provider: CommitmentProvider,
      permissions: ["EDIT_COMMITMENTS"],
    },
    {
      path: "/commitments/requests/:CommitmentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentRequestForm pageType="edit" />,
      provider: CommitmentProvider,
      permissions: ["EDIT_REQUEST_COMMITMENTS"],
    },
    {
      path: "/commitments/requests/:CommitmentId/delete",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentRequestForm pageType="delete" />,
      provider: CommitmentProvider,
      permissions: ["DELETE_REQUEST_COMMITMENTS"],
    },
    {
      path: "/commitments/new",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: ["CREATE_COMMITMENTS"],
    },
    {
      path: "/commitments/requests/new",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentRequestForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: ["CREATE_REQUEST_COMMITMENTS"],
    },
    {
      path: "/commitments/:CommitmentId/view",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="view" />,
      provider: CommitmentProvider,
      permissions: ["VIEW_COMMITMENTS"],
    },
    {
      path: "/commitments/:commitmentId/documents",
      title: "",
      exact: true,
      filter: null,
      main: <CommitmentDocuments />,
      provider: CommitmentProvider,
      permissions: ["VIEW_DOCUMENTS"],
    },
    {
      path: "/commitments/:commitmentId/documents/new",
      title: null,
      exact: true,
      filter: null,
      main: <CreateEditCommitmentDocumentForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: [
        "ADD_DOCUMENTS",
        "UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE",
      ],
    },
    {
      path: "/commitments/:commitmentId/documents/:documentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CreateEditCommitmentDocumentForm pageType="edit" />,
      provider: CommitmentProvider,
      permissions: [
        "EDIT_DOCUMENTS",
        "UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE",
      ],
    },
    {
      path: "/commitments/:commitmentId/components/new",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: ["CREATE_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentForm pageType="edit" />,
      provider: CommitmentProvider,
      permissions: ["EDIT_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/view",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentForm pageType="view" />,
      provider: CommitmentProvider,
      permissions: ["VIEW_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/requests/:componentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentRequestForm pageType="edit" />,
      provider: CommitmentProvider,
      permissions: ["EDIT_REQUEST_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/requests/:componentId/delete",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentRequestForm pageType="delete" />,
      provider: CommitmentProvider,
      permissions: ["DELETE_REQUEST_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/requests/new",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentRequestForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: ["CREATE_REQUEST_COMPONENTS"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/financial-history/new",
      title: null,
      exact: true,
      filter: null,
      main: <FinancialHistoryForm pageType="new" />,
      permissions: ["CREATE_COMPONENT_FINANCIAL_PLAN"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/financial-history/:fiscalYear/:componentPlanId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <FinancialHistoryForm pageType="edit" />,
      permissions: ["EDIT_COMPONENT_FINANCIAL_PLAN"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/financial-history",
      title: null,
      exact: true,
      filter: null,
      main: <FinancialHistory pageType="newTab" />,
      provider: CommitmentProvider,
      permissions: ["VIEW_COMPONENT_FINANCIAL_HISTORY"],
    },
    {
      path: "/commitments/:commitmentId/components/:componentId/financial-history/:fiscalYear/financial-transaction/new",
      title: null,
      exact: true,
      filter: null,
      main: <FinancialTransactionForm pageType="new" />,
      provider: CommitmentProvider,
      permissions: ["ADD_MANUAL_FINANCIAL_TRANSACTION"],
    },
  ],
  /** Routes for Commitments */

  expenses: [
    {
      path: "/expenses",
      title: "Expenses",
      exact: true,
      filter: <ExpensesFilter />,
      main: <Expenses />,
      provider: ExpensesProvider,
      permissions: ["VIEW_EXPENSES"],
    },
    {
      path: "/expenses/:expenseId/ptas/:PtaId",
      title: null,
      exact: true,
      filter: null,
      main: <PTADetails pageType="newTab" />,
      provider: ExpensesProvider,
      permissions: ["VIEW_EXPENSES"],
    },
    {
      path: "/expenses/:expenseId/commitments/:CommitmentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="newTab" />,
      provider: ExpensesProvider,
      permissions: ["VIEW_EXPENSES"],
    },
    {
      path: "/expenses/:expenseId/commitments/:commitmentId/components/:componentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentForm pageType="newTab" />,
      provider: ExpensesProvider,
      permissions: ["VIEW_EXPENSES"],
    },
  ],
  /** Routes for Expenses */

  transfers: [
    /** Routes for Transfers */

    {
      path: "/transfers",
      title: "Transfers",
      exact: true,
      filter: <TransfersFilter />,
      main: <Transfers />,
      provider: TransfersProvider,
      permissions: ["VIEW_TRANSFER"],
    },
    {
      path: "/transfers/:expenseId/ptas/:PtaId",
      title: null,
      exact: true,
      filter: null,
      main: <PTADetails pageType="newTab" />,
      provider: TransfersProvider,
      permissions: ["VIEW_TRANSFER"],
    },
    {
      path: "/transfers/:expenseId/commitments/:CommitmentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentForm pageType="newTab" />,
      provider: TransfersProvider,
      permissions: ["VIEW_TRANSFER"],
    },
    {
      path: "/transfers/:expenseId/commitments/:commitmentId/components/:componentId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CommitmentComponentForm pageType="newTab" />,
      provider: TransfersProvider,
      permissions: ["VIEW_TRANSFER"],
    },
  ],

  requests: [
    {
      path: "/requests",
      title: "Requests",
      exact: true,
      filter: <RequestsFilter />,
      main: <Requests />,
      provider: RequestsProvider,
      permissions: ["VIEW_BUDGET"],
    },
  ],
};
