import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  Tooltip,
  Typography,
  Box,
  Collapse,
  IconButton,
  CircularProgress,
  TableCell,
  Grid,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Attachment as AttachmentIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ListAlt as ListAltIcon,
  MonetizationOn as MonetizationOnIcon,
  Link as LinkIcon,
  Warning as WarningIcon,
  PlaylistAddRounded,
} from "@mui/icons-material";
import { useStyles } from "./Commitments.styles";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useCommitmentContext } from "../../services/commitmentContext";
import { useUsers } from "../../services/usersService";
import {
  getAllCommitments,
  getComponentsByCommitmentId,
  getComponentWarningDetails,
} from "../../services/commitmentsService";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { LinkToPTARequestForm, ExpensesNotification } from "../../constants";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import axios from "axios";

function CollapsibleTable(props) {
  const { row } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { openCommitmentId, setOpenCommitmentId, filters } =
    useCommitmentContext();
  const [open, setOpen] = React.useState(
    (row.id === openCommitmentId && true) || false
  );
  const [components, setComponents] = React.useState([]);
  const [componentWarningDetails, setComponentWarningDetails] = React.useState(
    []
  );
  const [componentId, setComponentId] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { clearAlert, setAlert } = useAlertContext();

  const handlePopoverOpen = (event, componentId) => {
    setAnchorEl(event.currentTarget);
    setComponentId(componentId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openWarningPopover = Boolean(anchorEl);

  React.useEffect(() => {
    openWarningPopover &&
      getComponentWarningDetails(
        setComponentWarningDetails,
        openCommitmentId,
        componentId,
        setAlert,
        clearAlert
      );
    // eslint-disable-next-line
  }, [openWarningPopover, componentId, openCommitmentId]);

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    if (row.id === openCommitmentId) {
      const project = filters.project || "";
      const task = filters.task || "";
      const award = filters.award || "";
      getComponentsByCommitmentId(
        setLoading,
        setComponents,
        openCommitmentId,
        project,
        task,
        award,
        setAlert,
        cancelSource
      );
    }
    // eslint-disable-next-line
    return () => {
      cancelSource.cancel();
    };
  }, [
    openCommitmentId,
    row.id,
    filters.project,
    filters.task,
    filters.award,
    setAlert,
  ]);

  const commitmentTableColumnsStr = "Commitments.mainView.list";
  const commitmentComponentTableColumnsStr =
    "Commitments.commitmentComponents.mainView.list";
  const uploadExpensesStr = "UploadedExpenses.mainView.list";
  const uploadTransferStr = "UploadedTransfers.mainView.list";

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {row.componentCount > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                setOpenCommitmentId(row.id);
              }}
            >
              {!open && <KeyboardArrowDownIcon />}
              {open && <KeyboardArrowUpIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            className={(row.isClosed && classes.textClosed) || classes.textOpen}
          >
            {!row.isClosed &&
              t(`${commitmentTableColumnsStr}.tableValues.statusOpenText`)}
            {row.isClosed &&
              t(`${commitmentTableColumnsStr}.tableValues.statusClosedText`)}
          </Typography>
        </TableCell>
        <TableCell align="left">{row.hasDocs && <CheckIcon />}</TableCell>
        <TableCell align="left">
          <RouterLink
            to={{
              pathname:
                "/commitments/" +
                new URL(row._links.self.href).pathname.split("/").pop() +
                "/view",
            }}
          >
            <Tooltip title={row.name} placement="left">
              <Typography noWrap={true} variant="body2">
                {row.name}
              </Typography>
            </Tooltip>
          </RouterLink>
        </TableCell>
        {permissions.PLACEHOLDER_COMMITMENT && (
          <TableCell align="left">
            {row.commitmentType.toLowerCase() === "placeholder" && (
              <CheckIcon />
            )}
          </TableCell>
        )}
        <TableCell align="left">
          <Tooltip title={row.departmentName} placement="left">
            <Typography noWrap={true} variant="body2">
              {row.departmentName}
            </Typography>
          </Tooltip>
        </TableCell>
        {permissions.MEMO_COMMITMENT && (
          <TableCell align="left">
            {row.commitmentType.toLowerCase() === "memo" && <CheckIcon />}
          </TableCell>
        )}
        <TableCell align="right">
          {CurrencyFormat(row.outstandingAmount)}
        </TableCell>
        <TableCell align="left" width="11%">
          <Grid container justifyContent="space-around" flexWrap="nowrap">
            {row._links.edit && (
              <Grid item>
                <RouterLink
                  to={{
                    pathname:
                      "/commitments/" +
                      new URL(row._links.edit.href).pathname.split("/").pop() +
                      "/edit",
                  }}
                >
                  <Tooltip
                    title={t(
                      `${commitmentTableColumnsStr}.editCommitmentsTooltip`
                    )}
                  >
                    <EditIcon fontSize="small" />
                  </Tooltip>
                </RouterLink>
              </Grid>
            )}
            {permissions.VIEW_DOCUMENTS &&
              (permissions.ADD_DOCUMENTS || row.hasDocs) && (
                <Grid item>
                  <Tooltip
                    title={t(
                      `${commitmentTableColumnsStr}.viewDocumentsTooltip`
                    )}
                  >
                    <RouterLink
                      to={{
                        pathname:
                          "/commitments/" +
                          new URL(row._links.self.href).pathname
                            .split("/")
                            .pop() +
                          "/documents",
                      }}
                    >
                      <AttachmentIcon fontSize="small" />
                    </RouterLink>
                  </Tooltip>
                </Grid>
              )}
            {permissions.CREATE_COMPONENTS && (
              <Grid item>
                <Tooltip
                  title={t(`${commitmentTableColumnsStr}.addComponentTooltip`)}
                >
                  <RouterLink
                    to={{
                      pathname:
                        "/commitments/" +
                        new URL(row._links.self.href).pathname
                          .split("/")
                          .pop() +
                        "/components/new",
                    }}
                  >
                    <PlaylistAddRounded fontSize="small" />
                  </RouterLink>
                </Tooltip>
              </Grid>
            )}
            {permissions.VIEW_COMPONENTS && (
              <Grid item>
                <Tooltip
                  title={t(
                    `${commitmentTableColumnsStr}.viewCommitmentsTooltip`
                  )}
                >
                  <RouterLink
                    to={{
                      pathname:
                        "/commitments/" +
                        new URL(row._links.self.href).pathname
                          .split("/")
                          .pop() +
                        "/view",
                    }}
                  >
                    <ListAltIcon fontSize="small" />
                  </RouterLink>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow key={row.id} className={classes.expandableRow}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 40,
            paddingRight: 20,
          }}
          colSpan={getColSpan(permissions)}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table
                size="small"
                aria-label="purchases"
                classes={{ root: classes.tableLayout }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "30px" }}></TableCell>
                    <TableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.pta`
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.componentName`
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.date`
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.description`
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.unspentAmount`
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.status`
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        `${commitmentComponentTableColumnsStr}.tableColumns.actions`
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={getColSpan(permissions)}>
                        <Typography
                          variant="body2"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          {t("globals.list.messages.fetchingInfo")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {components.map((row, componentsIndex) => (
                    <TableRow key={componentsIndex}>
                      <TableCell component="th" scope="row">
                        {row.componentWarning && (
                          <>
                            <WarningIcon
                              className={classes.yellow}
                              fontSize="small"
                              onClick={(e) => {
                                handlePopoverOpen(e, row.id);
                              }}
                              sx={{ cursor: "help" }}
                            />
                            {componentWarningDetails.length > 0 && (
                              <Popover
                                id="mouse-over-popover"
                                open={openWarningPopover}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                                classes={{ paper: classes.popoverWidth }}
                              >
                                <List className={classes.listHeader}>
                                  <ListItem alignItems="center" dense={true}>
                                    <ListItemText
                                      classes={{
                                        root: classes.listItemRoot,
                                      }}
                                      disableTypography
                                      primary={
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography align="center">
                                              {t(
                                                `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      }
                                    />
                                  </ListItem>
                                </List>
                                {componentWarningDetails.map(
                                  (warningDetailsRow, warningDetailsIndex) => (
                                    <React.Fragment key={warningDetailsIndex}>
                                      <List>
                                        <ListItem
                                          alignItems="flex-start"
                                          dense={true}
                                          classes={{
                                            dense: classes.denseListItem,
                                          }}
                                        >
                                          <ListItemText
                                            disableTypography
                                            classes={{
                                              root: classes.listItemRoot,
                                            }}
                                            secondary={
                                              <Grid container>
                                                <Grid item xs={3}>
                                                  <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      "Commitments.financialHistory.mainView.list.glPeriodTable.tableColumns.glPeriod"
                                                    )}
                                                    :
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.glPeriod}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem
                                          alignItems="flex-start"
                                          dense={true}
                                          classes={{
                                            dense: classes.denseListItem,
                                          }}
                                        >
                                          <ListItemText
                                            disableTypography
                                            classes={{
                                              root: classes.listItemRoot,
                                            }}
                                            secondary={
                                              <Grid container>
                                                <Grid item xs={3}>
                                                  <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${uploadTransferStr}.redistributeTransfersDialog.tableColumns.monthlyTransferDetail.source`
                                                    )}
                                                    :
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.source}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem
                                          alignItems="flex-start"
                                          dense={true}
                                          classes={{
                                            dense: classes.denseListItem,
                                          }}
                                        >
                                          <ListItemText
                                            disableTypography
                                            classes={{
                                              root: classes.listItemRoot,
                                            }}
                                            secondary={
                                              <Grid container>
                                                <Grid item xs={3}>
                                                  <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${uploadTransferStr}.tableColumns.amount`
                                                    )}
                                                    :
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {warningDetailsRow.amount !==
                                                      undefined &&
                                                      CurrencyFormat(
                                                        warningDetailsRow.amount
                                                      )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem alignItems="flex-start">
                                          <ListItemText
                                            disableTypography
                                            secondary={
                                              <Grid container>
                                                <Grid item xs={3}>
                                                  <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      `${commitmentComponentTableColumnsStr}.componentWarningPopOver.warningLabel`
                                                    )}
                                                    :
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                  {warningDetailsRow.warnings &&
                                                    warningDetailsRow.warnings.map(
                                                      (
                                                        anObjectMapped,
                                                        index
                                                      ) => {
                                                        return (
                                                          <Typography
                                                            variant="subtitle1"
                                                            color="textSecondary"
                                                            key={index}
                                                          >
                                                            {anObjectMapped}
                                                          </Typography>
                                                        );
                                                      }
                                                    )}
                                                </Grid>
                                              </Grid>
                                            }
                                          />
                                        </ListItem>
                                        <ListItem
                                          alignItems="flex-start"
                                          classes={{
                                            root: classes.paddingTop,
                                          }}
                                        >
                                          <ListItemText
                                            disableTypography
                                            secondary={
                                              <Grid container>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                  >
                                                    {t(
                                                      ExpensesNotification[
                                                        warningDetailsRow
                                                          .notificationStatus
                                                      ]
                                                    )}{" "}
                                                    {t(
                                                      `${uploadExpensesStr}.notificationStatusTooltip.by`
                                                    )}{" "}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "UNRESOLVED" &&
                                                      warningDetailsRow.unresolvedBy}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "INPROCESS" &&
                                                      warningDetailsRow.inprocessBy}{" "}
                                                    {t(
                                                      `${uploadExpensesStr}.notificationStatusTooltip.on`
                                                    )}{" "}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "UNRESOLVED" &&
                                                      warningDetailsRow.unresolvedDate}
                                                    {warningDetailsRow.notificationStatus ===
                                                      "INPROCESS" &&
                                                      warningDetailsRow.inprocessDate}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            }
                                          />
                                        </ListItem>
                                      </List>
                                      <Divider />
                                    </React.Fragment>
                                  )
                                )}
                              </Popover>
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {PTAFormat(row.project, row.task, row.award)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={row.componentName} placement="left">
                          <Typography
                            noWrap={true}
                            variant="caption"
                            component="div"
                          >
                            {row.componentName}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{row.componentDate}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={row.componentDescription}
                          placement="left"
                        >
                          <Typography
                            noWrap={true}
                            variant="caption"
                            component="div"
                          >
                            {row.componentDescription}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        {!row.isClosed &&
                          CurrencyFormat(row.totalUnspentAmount)}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="caption"
                          className={
                            (row.isClosed && classes.textClosed) ||
                            classes.textOpen
                          }
                        >
                          {!row.isClosed &&
                            t(
                              `${commitmentComponentTableColumnsStr}.tableValues.statusOpenText`
                            )}
                          {row.isClosed &&
                            t(
                              `${commitmentComponentTableColumnsStr}.tableValues.statusClosedText`
                            )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" width="8%">
                        <Grid container justifyContent="space-around">
                          {row._links.edit && (
                            <Grid item>
                              <RouterLink
                                to={`${
                                  new URL(row._links.edit.href).pathname
                                }/edit`}
                              >
                                <Tooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.editCommitmentComponentsTooltip`
                                  )}
                                >
                                  <EditIcon fontSize="small" />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                          {row._links.view && (
                            <Grid item>
                              <RouterLink
                                to={`${
                                  new URL(row._links.self.href).pathname
                                }/view`}
                              >
                                <Tooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.viewComponentsTooltip`
                                  )}
                                >
                                  <ListAltIcon fontSize="small" />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                          {row._links.viewFinancialDetail && (
                            <Grid item>
                              <RouterLink
                                to={`${
                                  new URL(row._links.self.href).pathname
                                }/financial-history`}
                              >
                                <Tooltip
                                  title={t(
                                    `${commitmentComponentTableColumnsStr}.financialHistoryTooltip`
                                  )}
                                >
                                  <MonetizationOnIcon fontSize="small" />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  return `${project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-${
    task ? task : "\u00A0\u00A0\u00A0"
  }-${award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"}`;
}

function EnhancedTableHead(props) {
  const { order, onRequestSort } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { sort } = useCommitmentContext();

  const commitmentTableColumnsStr = "Commitments.mainView.list.tableColumns";
  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          className={classes.columnDrawerHandle}
        ></TableCell>
        <TableCell
          align="left"
          key="status"
          sortDirection={sort.column === "isClosed" ? sort.orderBy : false}
          className={classes.columnStatus}
        >
          <TableSortLabel
            active={sort.column === "isClosed"}
            direction={sort.column === "isClosed" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isClosed")}
          >
            {t(`${commitmentTableColumnsStr}.status`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="docs"
          sortDirection={sort.column === "hasDocs" ? sort.orderBy : false}
          className={classes.columnDocs}
        >
          <TableSortLabel
            active={sort.column === "hasDocs"}
            direction={sort.column === "hasDocs" ? sort.orderBy : "asc"}
            onClick={createSortHandler("hasDocs")}
          >
            {t(`${commitmentTableColumnsStr}.docs`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="commitmentName"
          sortDirection={sort.column === "name" ? sort.orderBy : "asc"}
          className={classes.columnName}
        >
          <TableSortLabel
            active={sort.column === "name"}
            direction={sort.column === "name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("name")}
          >
            {t(`${commitmentTableColumnsStr}.commitmentName`)}
          </TableSortLabel>
        </TableCell>
        {permissions.PLACEHOLDER_COMMITMENT && (
          <TableCell
            align="left"
            key="placeholder"
            sortDirection={sort.column === "placeholder" ? sort.orderBy : false}
            className={classes.columnPlaceholder}
          >
            <TableSortLabel
              active={sort.column === "placeholder"}
              direction={sort.column === "placeholder" ? sort.orderBy : "asc"}
              onClick={createSortHandler("placeholder")}
            >
              {t(`${commitmentTableColumnsStr}.placeholder`)}
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell
          align="left"
          key="deptName"
          sortDirection={sort.column === "org.orgName" ? order : false}
          className={classes.columnDept}
        >
          <TableSortLabel
            active={sort.column === "org.orgName"}
            direction={sort.column === "org.orgName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("org.orgName")}
          >
            {t(`${commitmentTableColumnsStr}.departmentName`)}
          </TableSortLabel>
        </TableCell>
        {permissions.MEMO_COMMITMENT && (
          <TableCell
            align="left"
            key="memo"
            sortDirection={sort.column === "memo" ? sort.orderBy : false}
            className={classes.columnMemo}
          >
            <TableSortLabel
              active={sort.column === "memo"}
              direction={sort.column === "memo" ? sort.orderBy : "asc"}
              onClick={createSortHandler("memo")}
            >
              {t(`${commitmentTableColumnsStr}.memo`)}
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell
          align="right"
          key="outstandingAmount"
          sortDirection={
            sort.column === "outstandingAmount" ? sort.orderBy : false
          }
          className={classes.columnOutstanding}
        >
          <TableSortLabel
            active={sort.column === "outstandingAmount"}
            direction={
              sort.column === "outstandingAmount" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("outstandingAmount")}
          >
            {t(`${commitmentTableColumnsStr}.outstandingAmount`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="center"
          key="actions"
          className={classes.columnActions}
        >
          {t(`${commitmentTableColumnsStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Commitments = (props) => {
  const { t } = useTranslation();

  const { filters, sort, setSort, pagination, setPagination } =
    useCommitmentContext();
  const [rowsPerPage, setRowsPerPage] = React.useState(pagination.rowsPerPage);
  const [page, setPage] = React.useState(pagination.page);
  const [totalCount, setTotalCount] = React.useState(0);
  const [order, setOrder] = React.useState(sort.orderBy);
  const [orderBy, setOrderBy] = React.useState(sort.column);

  const [commitments, setCommitments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const defaults = {
    pagination: [10, 25, 50, 100],
  };
  const classes = useStyles();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { clearAlert, setAlert, alert } = useAlertContext();

  React.useEffect(() => {
    clearAlert();
    if (permissions.VIEW_COMMITMENTS) {
      const dealName = filters.dealName || "";
      const departmentValue = filters.departmentValue || "";
      const status = filters.status || "";
      const project = filters.project || "";
      const task = filters.task || "";
      const award = filters.award || "";

      getAllCommitments(
        setCommitments,
        setTotalCount,
        setLoading,
        setAlert,
        clearAlert,
        {
          page,
          rowsPerPage,
          orderBy,
          order,
          dealName,
          departmentValue,
          status,
          project,
          task,
          award,
        }
      );
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    permissions,
    filters.dealName,
    filters.departmentValue,
    filters.status,
    filters.project,
    filters.task,
    filters.award,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  const CommitmentActionButton = () => {
    const commitmentButtonStr = "Commitments.mainView";
    if (permissions.CREATE_COMMITMENTS) {
      return (
        <Grid container justifyContent="flex-end">
          <RouterLink to="/commitments/new">
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
            >
              {t(`${commitmentButtonStr}.ctaTextForCreateCommitment`)}
            </Button>
          </RouterLink>
        </Grid>
      );
    } else if (!permissions.CREATE_COMMITMENTS) {
      return (
        <Grid container justifyContent="flex-end">
          <RouterLink to={LinkToPTARequestForm} target="_blank">
            <Button
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              {t(`${commitmentButtonStr}.ctaTextForLinkToPTARequestForm`)}
            </Button>
          </RouterLink>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
      >
        {alert.exists && (
          <Grid item xs={12}>
            <ASAlert />
          </Grid>
        )}

        <Grid item xs={12}>
          <CommitmentActionButton />
        </Grid>

        {permissions.VIEW_COMMITMENTS && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-label="Commitment table"
                stickyHeader
                classes={{ root: classes.tableLayout }}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell
                        colSpan={getColSpan(permissions)}
                        classes={{ root: classes.progressBar }}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!loading &&
                    commitments.map((row, index) => (
                      <CollapsibleTable row={row} key={index} />
                    ))}
                  {!loading && !commitments.length && (
                    <TableRow>
                      <TableCell colSpan={getColSpan(permissions)}>
                        <Typography
                          variant="body1"
                          color="inherit"
                          align="center"
                          paragraph={false}
                        >
                          {t("globals.list.messages.noData")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={defaults.pagination}
                component="div"
                count={totalCount}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const getColSpan = (permissions) => {
  let numColumns = 7;
  permissions.PLACEHOLDER_COMMITMENT && numColumns++;
  permissions.MEMO_COMMITMENT && numColumns++;
  return numColumns;
};
