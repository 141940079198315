import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  filterFlexBasis: { "&.MuiGrid-root": { flexBasis: 0, maxWidth: "23%" } },
  tableFlexBasis: { "&.MuiGrid-root": { flexBasis: "77%", maxWidth: "77%" } },
  padding: {
    "&.MuiGrid-root": {
      paddingLeft: "16px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "initial",
    },
  },
  filterPosition: {
    "&.MuiGrid-root": {
      position: "relative",
      right: "initial",
      width: "initial",
      height: "initial",
      bottom: "initial",
    },
  },
});
