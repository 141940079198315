import React from "react";
import { Formik } from "formik";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  NavigateNext as NavigateNextIcon,
  ArrowBack as ArrowBackIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import { useStyles } from "./CommitmentRequestForm.styles";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  getCommitmentByIdFull,
  getAllCommitmentNamesLowercase,
  getMyDepartmentsByMatch,
  postCommitment,
  putCommitment,
  deleteCommitment,
  prepareCommitmentData,
  getCurrentFiscalYear,
} from "../../../services/commitmentsService";
import { getComponentRequestTemplatesByType } from "../../../services/componentsService";
import { LinkToFacultyNewHireTemplate } from "../../../constants";
import { useUsers } from "../../../services/usersService";
import { FormButton } from "../../UI/Button/FormButton";
import { CommitmentComponentRequestTable } from "../CommitmentComponents/CommitmentComponentRequest/CommitmentComponentRequestTable";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { get } from "lodash";
import axios from "axios";

/**
 * Component export definition for `CommitmentForm`
 * It will be used as a form to create and edit a commitment
 *
 */
export const CommitmentRequestForm = ({ pageType, parent }) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // `useStyles` will provide styles from CommitmentRequestForm.styles
  const classes = useStyles();

  const params = useParams();

  const isViewOnly = pageType === "view" || pageType === "delete";
  const isDeleteRecord = pageType === "delete";
  const isEditRecord = pageType === "edit";
  const shrinkProps = isViewOnly && { shrink: true };
  const otherParent = parent === "other";
  const history = useHistory();
  const location = useLocation();
  const commitmentType = "REQUEST";

  let {
    state: {
      orgCode: sourceOrgCode,
      orgName: sourceOrgName,
      currentFiscalYear,
      budgetEditableByCurrentUser,
    } = {},
  } = location;
  currentFiscalYear = currentFiscalYear || getCurrentFiscalYear();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const { clearAlert, setAlert, alert } = useAlertContext();

  const formFieldsStr = "Commitments.create.form.fields";

  const [loading, setLoading] = React.useState(false);

  // State variable and its function for orgOptions
  const [orgOptions, setOrgOptions] = React.useState([]);
  const [commitmentNames, setCommitmentNames] = React.useState([]);
  const [commitmentRequestType, setcommitmentRequestType] =
    React.useState("NON_RECRUITMENT");
  const [templatedComponents, setTemplatedComponents] = React.useState([]);

  const [departmentNameValue, setDepartmentNameValue] = React.useState({
    orgName: sourceOrgName,
    orgCode: sourceOrgCode,
  });

  const getEmptyComponent = () => ({
    componentType: "REQUEST",
    budgetPlan: {
      budgetFiscalYear: currentFiscalYear,
      fyProjectionOne: 0,
      fyBudget: 0,
      fyPlanOne: 0,
      fyPlanTwo: 0,
    },
    componentAmount: 0,
    startDate: null,
    expirationDate: null,
  });

  const getBreadcrumbTxt = () => {
    if (pageType === "new") {
      return t(`Commitments.createRequest.breadcrumbTitle`);
    } else if (isDeleteRecord) {
      return t(`Commitments.deleteRequest.breadcrumbTitle`);
    } else if (isViewOnly) {
      return t(`Commitments.viewRequest.breadcrumbTitle`);
    } else {
      return t(`Commitments.editRequest.breadcrumbTitle`);
    }
  };

  const getMainHeadingTxt = () => {
    if (pageType === "new") {
      return t(`Commitments.createRequest.title`);
    } else if (isDeleteRecord) {
      return (
        t(`Commitments.deleteRequest.title`) + ": " + formDataResponse.name
      );
    } else if (isViewOnly) {
      return t(`Commitments.viewRequest.title`) + ": " + formDataResponse.name;
    } else {
      return t(`Commitments.editRequest.title`) + ": " + formDataResponse.name;
    }
  };

  const inputVariant = isViewOnly ? "standard" : "outlined";
  const isRequired = !isViewOnly;

  /**
   * `formDataResponse` - Use to store the response of create/edit form data
   * `setFormDataResponse` - Use to set/modify the value of state/variable formDataResponse
   */
  const formPreFetchData = {
    commitmentType: commitmentType, //Enum implementation
  };

  const [, setHasDocument] = React.useState({});
  const [formDataResponse, setFormDataResponse] = React.useState({
    // Object keys use to create/edit form data
    name /* Commitment Name */: "",
    departmentName /* Department Name */: {
      orgName: sourceOrgName,
      orgCode: sourceOrgCode,
    },
    commitmentType /* Commitment Type */: commitmentType,
    commitmentNotes /* Commitment Notes */: "",
    orgCode: sourceOrgCode,
    components: [getEmptyComponent()],
  });

  const commitmentValidation = Yup.object().shape({
    name: Yup.string()
      .required(t(`${formFieldsStr}.commitmentName.validation.isEmpty`))
      .test(
        "existsCheck",
        t(`${formFieldsStr}.commitmentName.validation.isDuplicate`),
        (value) => {
          if (
            value &&
            (!isEditRecord || (isEditRecord && formDataResponse.name !== value))
          ) {
            return !commitmentNames.includes(value.toLowerCase());
          } else return true;
        }
      ),
    departmentName: Yup.mixed().required(),
    downloadClicked: Yup.object().test(
      "isRequired",
      "You must click the download button to continue",
      (value) => {
        return (
          !!!value ||
          value?.requestType === "NON_RECRUITMENT" ||
          (value?.requestType === "RECRUITMENT" && value?.clicked === true)
        );
      }
    ),
    components: Yup.array()
      .of(
        Yup.object().shape({
          componentName: Yup.string()
            .required(
              t(
                "Commitments.commitmentComponents.create.form.fields.componentName.validation.isEmpty"
              )
            )
            .test(
              "isDuplicate",
              t(
                "Commitments.commitmentComponents.create.form.fields.componentName.validation.isDuplicate"
              ),
              function () {
                let testedObject = this.parent;
                let propertyName = "componentName";
                if (
                  this.from[1].value.components
                    // Exclude the same object (by reference)
                    .filter((object) => object !== testedObject)

                    // Check for property match among some of the other objects
                    .some((object) => {
                      const objectValue = get(object, propertyName);
                      const testedObjectValue = get(testedObject, propertyName);
                      return objectValue === testedObjectValue;
                    })
                ) {
                  return false;
                }
                return true;
              }
            ),
        })
      )
      .compact(),
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    // Fetch data to be edited
    params.CommitmentId &&
      getCommitmentByIdFull(
        params.CommitmentId,
        {
          setFormDataResponse,
          setHasDocument,
          setDepartmentNameValue,
          setLoading,
        },
        {
          setAlert,
          clearAlert,
        },
        cancelSource
      );

    // Get exiting commitments names for uniqueness validation
    getAllCommitmentNamesLowercase(
      setCommitmentNames,
      setLoading,
      setAlert,
      clearAlert,
      t,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.CommitmentId]);

  // Load the templated components for recruitment commitments
  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();
    if (
      commitmentRequestType === "RECRUITMENT" &&
      templatedComponents?.length === 0
    ) {
      getComponentRequestTemplatesByType(
        commitmentRequestType,
        (valueArr) => {
          setTemplatedComponents(
            valueArr.map((tmp) => {
              let o = getEmptyComponent();
              return {
                ...o,
                componentName: tmp.name,
                templateSourceType: commitmentRequestType,
              };
            })
          );
        },
        setLoading,
        clearAlert,
        setAlert
      );
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [commitmentRequestType]);

  const handleClose = () => {
    redirectToBudgetingTab(history);
  };

  const handleDelete = () => {
    // Defines what to do after deleting the data
    const afterSave = () => {
      redirectToBudgetingTab(history);
      const textKey = "Commitments.delete.notification.success";
      setAlert("success", t(textKey), true);
    };

    deleteCommitment(
      params.CommitmentId,
      setLoading,
      t,
      clearAlert,
      setAlert,
      afterSave
    );
  };

  const _handleSubmit = (values, setFieldError) => {
    const cancelSource = axios.CancelToken.source();
    values = prepareCommitmentData(values, formPreFetchData, currentFiscalYear);

    // Defines what to do after saving the data
    const afterSave = () => {
      redirectToBudgetingTab(history);
      const textKey = params.CommitmentId
        ? "Commitments.create.notification.update"
        : "Commitments.create.notification.success";
      setAlert(
        "success",
        t(textKey, { commitmentName: `"${values.name.trim()}"` }),
        true
      );
    };

    params.CommitmentId
      ? putCommitment(
          values,
          params.CommitmentId,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        )
      : postCommitment(
          values,
          setLoading,
          setFieldError,
          t,
          clearAlert,
          setAlert,
          afterSave,
          cancelSource
        );
  };

  return (
    ((pageType === "new" && permissions.CREATE_REQUEST_COMMITMENTS) ||
      (pageType === "edit" && permissions.EDIT_REQUEST_COMMITMENTS) ||
      (pageType === "delete" && permissions.DELETE_REQUEST_COMMITMENTS) ||
      (pageType === "view" && permissions.VIEW_REQUEST_COMMITMENTS)) && (
      <>
        <Grid
          container
          classes={{
            root: classes.mainContainer,
          }}
        >
          {/* Breadcrumb Navigation & Back Button */}
          {pageType !== "newTab" && (
            <Grid container item xs={12}>
              <Grid item container xs={6} justifyContent="flex-start">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <RouterLink to={`/budgeting`}>
                    <Typography color="textSecondary" variant="body2">
                      {t("Budgeting.mainView.title")}
                    </Typography>
                  </RouterLink>
                  <Typography color="textPrimary" variant="subtitle1">
                    {getBreadcrumbTxt()}
                  </Typography>
                </Breadcrumbs>
              </Grid>

              {isViewOnly && !otherParent && (
                <Grid
                  item
                  container
                  xs={4}
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <RouterLink to={`/budgeting`}>
                    <Button startIcon={<ArrowBackIcon />}>
                      {t(`Commitments.view.goBackButtonLabel`)}
                    </Button>
                  </RouterLink>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.formContainer}>
            <Typography variant="h1">{getMainHeadingTxt()}</Typography>
          </Grid>

          <Grid item xs={12}>
            {alert.exists && <ASAlert />}
          </Grid>

          {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
          to create/edit a commitment */}
          <Formik
            // initialValues - User to store the Formik form's intial form values
            /** !Object */ initialValues={formDataResponse}
            /** !Boolean */ enableReinitialize={true}
            // onSubmit - Callback definition to execute on the click of Form Submit
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              setSubmitting(false);
              _handleSubmit(values, setFieldError);
            }}
            validationSchema={commitmentValidation}
            validateOnMount={isEditRecord}
          >
            {(formikProps) => {
              const /** !Object */ {
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  dirty,
                  isValid,
                  setFieldError,
                  setValues,
                } = formikProps;
              if (!isEditRecord && !isDeleteRecord) {
                handleIfIsRecruitment({
                  templatedComponents,
                  values,
                  setValues,
                  getEmptyComponent,
                });
              }
              return (
                // Native form element to submit the form values
                <Grid item xs={12}>
                  <form onSubmit={handleSubmit}>
                    {/* FormControlContainer - Flex container to wrap all the form flex items */}
                    <Grid
                      item
                      container
                      xs={12}
                      direction="column"
                      className={classes.formContainer}
                    >
                      <Grid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography align="right">&nbsp;</Typography>
                        </Grid>
                      </Grid>

                      {/* Commitment Name - TextField Input */}
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <TextField
                            id="name"
                            label={t(`${formFieldsStr}.commitmentName.label`)}
                            required={isRequired}
                            value={values.name}
                            onChange={handleChange}
                            fullWidth
                            helperText={errors.name ? errors.name : ""}
                            error={errors.name && Boolean(errors.name)}
                            variant={inputVariant}
                            disabled={isViewOnly}
                            autoComplete="off"
                            inputProps={{ maxLength: 100 }}
                            className={classes.disabled}
                          />
                        </Grid>
                      </Grid>

                      {/* Deparment Name - Autocomplete Input */}
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <Autocomplete
                            id="departmentName"
                            name="departmentName"
                            options={orgOptions || []}
                            getOptionLabel={(option) =>
                              (option &&
                                option.orgName &&
                                option.orgName + " (" + option.orgCode + ")") ||
                              ""
                            }
                            value={departmentNameValue}
                            clearIcon={false}
                            disabled={isViewOnly}
                            forcePopupIcon={!isViewOnly}
                            onChange={(_event, newValue) => {
                              const orgCode =
                                (newValue && newValue.orgCode) || "";
                              setDepartmentNameValue(newValue);
                              setFieldValue("orgCode", orgCode);
                              setFieldValue("departmentName", newValue);
                            }}
                            onInputChange={(_e, value) => {
                              const searchText = value.includes(sourceOrgCode)
                                ? ""
                                : value;
                              getMyDepartmentsByMatch(
                                searchText,
                                setOrgOptions,
                                setLoading,
                                setFieldError
                              );
                            }}
                            renderInput={(args) => (
                              <TextField
                                {...args}
                                helperText={
                                  errors.departmentName
                                    ? errors.departmentName
                                    : ""
                                }
                                error={Boolean(errors?.departmentName)}
                                className={classes.disabled}
                                label={t(
                                  `${formFieldsStr}.departmentName.label`
                                )}
                                required={isRequired}
                                variant={isViewOnly ? "standard" : "outlined"}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Commitment Notes - Multiline Textfield Input */}
                      <Grid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <TextField
                            id="commitmentNotes"
                            label={t(`${formFieldsStr}.commitmentNotes.label`)}
                            multiline
                            value={values.commitmentNotes}
                            onChange={(event) => {
                              setFieldValue(
                                "commitmentNotes",
                                event.target.value
                              );
                            }}
                            variant={inputVariant}
                            autoComplete="off"
                            fullWidth={true}
                            inputProps={{ maxLength: 1000 }}
                            disabled={isViewOnly}
                            InputLabelProps={{ ...shrinkProps }}
                            className={classes.disabled}
                          />
                        </Grid>
                      </Grid>

                      {/* Component Table */}
                      <Grid item container xs={10}>
                        {/*Recruitment - Checkbox Input */}
                        <Grid item xs={12}>
                          <FormControlLabel
                            label={t(`${formFieldsStr}.isRecruitment.label`)}
                            classes={{ root: classes.inputLabel }}
                            control={
                              <Checkbox
                                checked={
                                  commitmentRequestType === "RECRUITMENT" ||
                                  values.commitmentRequestType === "RECRUITMENT"
                                }
                                onChange={(e) => {
                                  if (!e.target.readOnly) {
                                    const requestType = e.target.checked
                                      ? "RECRUITMENT"
                                      : "NON_RECRUITMENT";
                                    setcommitmentRequestType(requestType);
                                    setTemplatedComponents([]);
                                    setValues({
                                      ...values,
                                      downloadClicked: {
                                        requestType,
                                        clicked: false,
                                      },
                                    });
                                  }
                                }}
                                inputProps={{
                                  readOnly: isEditRecord,
                                }}
                              />
                            }
                          />
                        </Grid>

                        {(commitmentRequestType === "RECRUITMENT" ||
                          values.commitmentRequestType === "RECRUITMENT") && (
                          <Grid item xs={12}>
                            <Paper elevation={4} className={classes.calloutBox}>
                              <p>
                                {t("Commitments.create.recruitment.dialogText")}
                              </p>
                              <p>
                                <Button
                                  component={Link}
                                  href={
                                    LinkToFacultyNewHireTemplate.downloadUrl
                                  }
                                  download={
                                    LinkToFacultyNewHireTemplate.filename
                                  }
                                  variant="outlined"
                                  color={
                                    errors.downloadClicked ? "error" : "inherit"
                                  }
                                  className={classes.downloadButton}
                                  startIcon={<FileDownloadIcon />}
                                  onClick={(_event) => {
                                    setValues({
                                      ...values,
                                      downloadClicked: {
                                        requestType: commitmentRequestType,
                                        clicked: true,
                                      },
                                    });
                                  }}
                                >
                                  {t(
                                    "Commitments.create.recruitment.templateDownloadTooltip"
                                  )}
                                  <sup>*</sup>
                                </Button>
                              </p>
                            </Paper>
                          </Grid>
                        )}
                        {/*console.log(budgetEditableByCurrentUser) */}
                        <CommitmentComponentRequestTable
                          loading={loading}
                          isViewOnly={isViewOnly}
                          isEditRecord={isEditRecord}
                          isDeleteRecord={isDeleteRecord}
                          inputVariant={inputVariant}
                          currentFiscalYear={currentFiscalYear}
                          getEmptyComponent={getEmptyComponent}
                          budgetEditableByCurrentUser={
                            budgetEditableByCurrentUser
                          }
                          {...formikProps}
                        />
                      </Grid>

                      {/* Bottom Navigation / Form Controls */}
                      {!isViewOnly && (
                        <>
                          <Grid
                            item
                            xs={10}
                            container
                            className={classes.formContainer}
                            justifyContent="flex-end"
                            spacing={2}
                          >
                            <Grid
                              container
                              item
                              justifyContent="flex-end"
                              xs={3}
                              spacing={2}
                            >
                              <FormButton
                                cancel={handleClose}
                                save={{
                                  disabled: !dirty || !isValid || loading,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {isDeleteRecord && (
                        <>
                          <Grid
                            item
                            xs={10}
                            container
                            justifyContent="flex-end"
                            className={classes.formContainer}
                            spacing={2}
                          >
                            <Grid
                              container
                              item
                              justifyContent="flex-end"
                              xs={3}
                              spacing={2}
                            >
                              <FormButton
                                cancel={handleClose}
                                delete={handleDelete}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {isViewOnly && !isDeleteRecord && !otherParent && (
                        <>
                          <Grid
                            item
                            xs={10}
                            container
                            justifyContent="flex-end"
                            className={classes.formContainer}
                            spacing={2}
                          >
                            {/* Back Button */}
                            <Grid container item justifyContent="flex-end">
                              <RouterLink to={`/commitments`}>
                                <Button
                                  variant="outlined"
                                  size="large"
                                  startIcon={<ArrowBackIcon />}
                                >
                                  {t(`Commitments.view.goBackButtonLabel`)}
                                </Button>
                              </RouterLink>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </Grid>
              );
            }}
          </Formik>
        </Grid>
      </>
    )
  );
};

const redirectToBudgetingTab = (history) => {
  history.push("/budgeting");
};

const handleIfIsRecruitment = ({
  templatedComponents,
  values,
  setValues,
  getEmptyComponent,
}) => {
  const currentTemplatedRows = values.components?.filter((f) =>
    f?.hasOwnProperty("templateSourceType")
  );

  // Add templated rows
  if (templatedComponents?.length > 0 && currentTemplatedRows?.length === 0) {
    let newValues = values;
    let newSet = templatedComponents
      // Exclude templated objects already in the array
      .filter(
        (x) =>
          !currentTemplatedRows.some((y) => y.componentName === x.componentName)
      );
    newValues.components = [...newSet, ...values.components];

    // Remove any extra/blank rows
    newValues.components = newValues.components.filter(
      (obj) => obj?.componentName && obj?.componentName?.trim() !== ""
    );
    setValues(newValues);
  }

  // Remove templated rows
  if (templatedComponents?.length === 0 && currentTemplatedRows?.length > 0) {
    let newValues = values;
    newValues.components = values.components.filter(
      (o) => !o?.hasOwnProperty("templateSourceType")
    );
    // Ensure at least one component row is left in the table
    if (newValues?.components?.length === 0) {
      newValues?.components.push(getEmptyComponent());
    }
    setValues(newValues);
  }
};
