import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Page } from "../components/Page/Page";
import { RoutesList } from "./RoutesList";
import { useUsers } from "../services/usersService";
import { ASAccessDenied } from "@stanford-tds/as-components";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

const ContextRoute = ({ routes }) => {
  const Provider = routes[0].provider || Fragment;
  const { currentUser } = useUsers();
  const { t } = useTranslation();

  const isRouteAllowed = (permArray) => {
    return Object.entries(currentUser.permissions).some(
      ([prop, value]) => !permArray || (permArray.includes(prop) && value)
    );
  };

  return (
    <Provider>
      <Switch>
        {routes.map((route, index) =>
          isRouteAllowed(route.permissions) ? (
            <Route
              key={index}
              exact
              path={route.path}
              render={(props) => handleDependantInputs(route, props)}
            ></Route>
          ) : (
            <Route
              key={index}
              exact
              path={route.path}
              render={(props) => (
                <ASAccessDenied supportEmail={t("app.supportEmail")} />
              )}
            ></Route>
          )
        )}
      </Switch>
    </Provider>
  );
};

export const RoutesMapping = () => {
  const { currentUser } = useUsers();
  const { t } = useTranslation();

  if (isEmpty(currentUser.permissions)) {
    return <ASAccessDenied supportEmail={t("app.supportEmail")} />;
  }

  return Object.keys(RoutesList).map((route, index) => (
    <ContextRoute key={index} routes={RoutesList[route]} />
  ));
};

const handleDependantInputs = (route, props) => {
  /*Redirect users to the budgeting page if an orgcode is undefined*/
  return route.path === "/commitmentrequests/new" &&
    !!!props.location?.state?.orgCode ? (
    <Redirect to="/budgeting" />
  ) : (
    <Page title={route.title} filter={route.filter} main={route.main} />
  );
};
