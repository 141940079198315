import { useState, useEffect } from "react";
import axios from "axios";
import { useAlertContext } from "@stanford-tds/as-components";

/**
 * A hook to facilitate file downloads.
 *
 * (NOTE: This component assumes files will obtained via an API request.)
 *
 * @returns a function to initiate the download, and a stateful value indicating when a download is in progress.
 *
 * The getDocument method takes two arguments:
 * - docUrl: the API url to retrive the file contents
 * - docName: the download filename
 *
 * @example
 * const DownloadExample = () => {
 *   const { getDocument, downloadInProgress } = useDownload();
 *   return
 *     <Button
 *      disabled={!!downloadInProgress}
 *      onClick={() => {
 *        getDocument("/ap1/v1/download", "example.xls");
 *      }}
 *    >Download</Button>
 * }
 *
 * @author Chris Bricker
 */
export const useDownload = () => {
  const [url, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAlert, clearAlert } = useAlertContext();

  useEffect(() => {
    if (url) {
      downloadDocument(url, fileName, clearAlert, setLoading, setAlert);
    }
    // Perform cleanup
    return () => {
      setUrl("");
      setFileName("");
    };
  }, [url, fileName, clearAlert, setAlert]);

  return {
    /**
     * Starts the file download.
     * @param {string} docUrl
     * @param {string} docName The filename of the document. Defaults to "file_download" if not specified.
     */
    getDocument: (docUrl, docName) => {
      setUrl(docUrl);
      setFileName(docName || "file_download");
    },
    downloadInProgress: loading,
  };
};

const downloadDocument = async (
  url,
  fileName,
  clearAlert,
  setLoading,
  setAlert
) => {
  clearAlert();
  try {
    setLoading(true);
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        accept: "application/octet-stream",
      },
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(response.data, fileName);
    } else {
      const docUrl = window.URL.createObjectURL(
        new File([response.data], fileName, {
          type: response.headers["content-type"],
        })
      );
      saveFile(docUrl, fileName);
      window.URL.revokeObjectURL(docUrl);
    }
  } catch (err) {
    setAlert("error", "Unable to open document");
  } finally {
    setLoading(false);
  }
};

/**
 * window.open(docUrl) was not saving the file with the correct filename.
 * As a work-around, this funciton creates a temporary anchor/link to
 * specify the filename of the download
 */
const saveFile = (fileUrl, fileName) => {
  let a = document.createElement("a");
  window.document.body.appendChild(a);
  a.style = "display: none";
  a.href = fileUrl;
  a.download = fileName;
  a.click();
  a.remove();
};
