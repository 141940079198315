import React from "react";
import { useStyles } from "./CommitmentComponentRequestForm.styles";
import {
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Grid,
} from "@mui/material";
import {
  Link as RouterLink,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  NavigateNext as NavigateNextIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Formik } from "formik";
import {
  getComponentByIdBrief,
  getComponentNamesLowercase,
  postComponent,
  putComponent,
  getCommitmentNameById,
  deleteComponent,
} from "../../../../services/componentsService";
import { getCurrentFiscalYear } from "../../../../services/commitmentsService";
import * as Yup from "yup";
import { format, addDays } from "date-fns";
import { useUsers } from "../../../../services/usersService";
import { FormButton } from "../../../UI/Button/FormButton";
import NumberFormat from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { FormattedCurrencyField } from "../../../../Utils/CurrencyFormat";
import {
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
} from "../../../Budgeting/Budgeting";
import axios from "axios";
import { getIsValidDate } from "../../../../Utils/ValidationUtils";

export const CommitmentComponentRequestForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // `useStyles` will provide styles from CommitmentComponentForm.styles
  const classes = useStyles();

  const params = useParams();

  const isViewOnly = props.pageType === "view" || props.pageType === "delete";
  const isDeleteRecord = props.pageType === "delete";
  const isEditRecord = props.pageType === "edit";
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const otherParent = props.parent === "other";

  const history = useHistory();
  const location = useLocation();

  let { state: { currentFiscalYear } = {} } = location;

  currentFiscalYear = currentFiscalYear || getCurrentFiscalYear();

  const { clearAlert, setAlert, alert } = useAlertContext();

  const handleClose = () => {
    history.push("/budgeting");
  };

  const handleDelete = () => {
    setLoading(true);
    deleteComponent(
      params.commitmentId,
      params.componentId,
      setLoading,
      clearAlert,
      setAlert,
      history,
      t
    );
  };

  const _handleSubmit = (values, setFieldError, setSubmitting) => {
    values.budgetPlan = values.budgetPlan || {};
    values.budgetPlan.budgetFiscalYear = currentFiscalYear;

    const args = [
      values,
      piOrComponentOwnerValue,
      setLoading,
      params.commitmentId,
      params.componentId,
      commitmentName,
      setFieldError,
      setSubmitting,
      clearAlert,
      setAlert,
      history,
      t,
    ];
    params.componentId ? putComponent(...args) : postComponent(...args);
  };

  const shrinkProps = isViewOnly && { shrink: true };

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";

  const getBreadcrumbTxt = () => {
    if (props.pageType === "new") {
      return t(
        `Commitments.commitmentComponents.createRequest.breadcrumbTitle`
      );
    } else if (isDeleteRecord) {
      return t(
        `Commitments.commitmentComponents.deleteRequest.breadcrumbTitle`
      );
    } else if (isViewOnly) {
      return t(`Commitments.commitmentComponents.viewRequest.breadcrumbTitle`);
    } else {
      return t(`Commitments.commitmentComponents.editRequest.breadcrumbTitle`);
    }
  };

  const getMainHeadingTxt = () => {
    if (props.pageType === "new") {
      return t(`Commitments.commitmentComponents.createRequest.title`, {
        commitmentName: commitmentName,
      });
    } else if (isDeleteRecord) {
      return t(`Commitments.commitmentComponents.deleteRequest.title`, {
        commitmentName: commitmentName,
      });
    } else if (isViewOnly) {
      return t(`Commitments.commitmentComponents.viewRequest.title`, {
        commitmentName: commitmentName,
      });
    } else {
      return t(`Commitments.commitmentComponents.editRequest.title`, {
        commitmentName: commitmentName,
      });
    }
  };

  const inputVariant = isViewOnly ? "standard" : "outlined";
  const isRequired = !isViewOnly;

  // State variable and its function to maintain commitment name to show in title
  const [commitmentName, setCommitmentName] = React.useState("");

  const [, setLoading] = React.useState(false);

  const [piOrComponentOwnerValue] = React.useState("");

  const [componentNameList, setComponentNameList] = React.useState([]);

  // State variable and its function to maintain closed notes and closed details view
  const [, setClosed] = React.useState(false);
  // State variable and its function to maintain error state for invalid
  const [isValidDate, setIsValidDate] = React.useState(true);

  const getInputVariant = (readOnly) => {
    return readOnly ? "standard" : inputVariant || "outlined";
  };

  const [formDataResponse, setFormDataResponse] = React.useState({
    componentName: "",
    componentDescription: "",
    componentDate: format(new Date(currentFiscalYear - 1, 8, 1), "MM/dd/yyyy"),
    componentAmount: "0.00",
    project: "",
    componentType: "REQUEST",
    startDate: null,
    expirationDate: null,
    componentNotes: "",
    budgetPlan: {
      budgetFiscalYear: currentFiscalYear,
      comments: "",
      fyProjectionOne: 0,
      fyBudget: 0,
      fyPlanOne: 0,
      fyPlanTwo: 0,
    },
  });

  const commitmentValidation = Yup.object().shape({
    componentName: Yup.string()
      .required(
        t("globals.form.fieldIsRequired", {
          fieldName: t(`${componentFormField}.componentName.label`),
        })
      )
      .test(
        "existsCheck",
        t(`${componentFormField}.componentName.validation.isDuplicate`),
        (value) => {
          if (
            value &&
            (!isEditRecord ||
              (isEditRecord && formDataResponse.componentName !== value))
          ) {
            return !componentNameList?.includes(value.toLowerCase());
          } else return true;
        }
      ),
    componentAmount: Yup.string().required(),
    componentDate: Yup.string().required(),
    closedDate: Yup.mixed().when("closed", {
      is: true,
      then: Yup.mixed().required(),
      otherwise: undefined,
    }),
    closedDetails: Yup.string().when("closed", {
      is: true,
      then: Yup.string().required(),
      otherwise: undefined,
    }),
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    getCommitmentNameById(
      setCommitmentName,
      params.commitmentId,
      setAlert,
      clearAlert,
      cancelSource
    );
    getComponentNamesLowercase(
      params.commitmentId,
      setComponentNameList,
      setLoading,
      setAlert,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, [params.commitmentId, setAlert, clearAlert]);

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const beforeSend = () => {
      clearAlert();
    };
    const onSuccess = (data) => {
      setFormDataResponse({
        componentName: data.componentName,
        componentDescription: data.componentDescription,
        componentDate: data.componentDate,
        componentAmount: data.componentAmount,
        totalComponentAmountRequested: data.totalComponentAmountRequested,
        totalComponentAmountApproved: data.totalComponentAmountApproved,
        componentType: data.componentType,
        startDate: data.startDate ?? null,
        expirationDate: data.expirationDate ?? null,
        durationNotes: data.durationNotes,
        componentNotes: data.componentNotes,
        closed: data.closed,
        recordNumber: data.recordNumber,
        budgetPlan: data.budgetPlan,
        templateSourceType: data.templateSourceType,
      });
      setClosed(data.closed);
    };
    const onError = (error) => {
      if (!axios.isCancel(error)) {
        setAlert("error", error.message);
      }
    };

    if (params.componentId) {
      getComponentByIdBrief(
        params.commitmentId,
        params.componentId,
        cancelSource,
        beforeSend,
        onSuccess,
        onError
      );
    }
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [params.commitmentId, params.componentId]);

  return (
    ((props.pageType === "new" && permissions.CREATE_REQUEST_COMPONENTS) ||
      (props.pageType === "edit" && permissions.EDIT_REQUEST_COMPONENTS) ||
      (props.pageType === "delete" && permissions.DELETE_REQUEST_COMPONENTS) ||
      (props.pageType === "view" && permissions.VIEW_REQUEST_COMPONENTS)) && (
      <Grid
        container
        classes={{
          root: classes.mainContainer,
        }}
      >
        {props.pageType !== "newTab" && (
          <Grid container item xs={12}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink to={`/budgeting`}>
                  <Typography color="textSecondary" variant="body2">
                    {t("Budgeting.mainView.title")}
                  </Typography>
                </RouterLink>
                <Typography color="textPrimary" variant="subtitle1">
                  {getBreadcrumbTxt()}
                </Typography>
              </Breadcrumbs>
            </Grid>
            {isViewOnly && !otherParent && (
              <Grid item container xs={4} justifyContent="flex-end" spacing={2}>
                <RouterLink to={`/budgeting`}>
                  <Button startIcon={<ArrowBackIcon />}>
                    {t("Commitments.view.goBackButtonLabel")}
                  </Button>
                </RouterLink>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} className={classes.mainHeading}>
          <Typography variant="h1">{getMainHeadingTxt()}</Typography>
        </Grid>

        <Grid item xs={12}>
          {alert.exists && <ASAlert />}
        </Grid>

        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment Component*/}
        <Formik
          // initialValues - User to store the Formik form's intial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize={true}
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError, setSubmitting);
          }}
          validationSchema={commitmentValidation}
          validateOnMount={false}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                dirty,
                isValid,
              } = formikProps;
            return (
              // Native form element to submit the form values
              <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                  {/* FormControlContainer - Flex container to wrap all the form flex items */}
                  <Grid
                    item
                    container
                    xs={12}
                    direction="column"
                    className={classes.formContainer}
                  >
                    <Grid
                      item
                      xs={10}
                      container
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography align="right">&nbsp;</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Name - Multiline Textfield Input */}
                      <Grid item xs={12}>
                        <TextField
                          id="componentName"
                          label={t(`${componentFormField}.componentName.label`)}
                          required={isRequired}
                          value={values.componentName}
                          onChange={handleChange}
                          multiline
                          variant={getInputVariant(
                            formDataResponse?.templateSourceType
                          )}
                          autoComplete="off"
                          fullWidth
                          inputProps={{
                            maxLength: 250,
                            readOnly: formDataResponse?.templateSourceType,
                          }}
                          helperText={
                            errors.componentName ? errors.componentName : ""
                          }
                          error={Boolean(errors.componentName)}
                          disabled={isViewOnly}
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Total Component Amount - Textfield Input */}
                        <NumberFormat
                          id="componentAmount"
                          label={t(
                            `${componentFormField}.totalComponentAmountRequested.label`
                          )}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          customInput={TextField}
                          isAllowed={(args) => {
                            const { value } = args;
                            return value <= 999999999.99;
                          }}
                          allowNegative={false}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className={classes.paddingTop}
                                position="start"
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          value={values.componentAmount}
                          onChange={(event, value) => {
                            setFieldValue(
                              "componentAmount",
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          variant={inputVariant}
                          autoComplete="off"
                          fullWidth={true}
                          className={classes.disabled}
                          disabled={isViewOnly}
                          required={isRequired}
                          InputLabelProps={{ ...shrinkProps }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        {/* Component Date - DatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="startDate"
                            format="MM/dd/yyyy"
                            label={t(`${componentFormField}.startDate.label`)}
                            value={getIsValidDate(values.startDate)}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={inputVariant}
                            inputProps={{ autoComplete: "off" }}
                            maxDate={
                              (values.expirationDate &&
                                new Date(values.expirationDate)) ||
                              undefined
                            }
                            maxDateMessage={t(
                              `${componentFormField}.startDate.validation.maxDateMessage`
                            )}
                            clearable={true}
                            disabled={isViewOnly}
                            keyboardIcon={isViewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth={true}
                            className={classes.disabled}
                            renderInput={(args) => <TextField {...args} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        {/* Component Date - DatePicker */}
                        {/* Closed Date - Date Picker Input */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            id="expirationDate"
                            format="MM/dd/yyyy"
                            label={t(
                              `${componentFormField}.expirationDate.label`
                            )}
                            value={getIsValidDate(values.expirationDate)}
                            onChange={(value) => {
                              setFieldValue("expirationDate", value);
                            }}
                            onError={(reason, value) => {
                              reason && setIsValidDate(false);
                            }}
                            inputVariant={inputVariant}
                            inputProps={{ autoComplete: "off" }}
                            minDate={
                              (values.startDate &&
                                addDays(new Date(values.startDate), 1)) ||
                              undefined
                            }
                            minDateMessage={t(
                              `${componentFormField}.expirationDate.validation.minDateMessage`
                            )}
                            clearable={true}
                            disabled={isViewOnly}
                            keyboardIcon={isViewOnly ? false : undefined}
                            InputLabelProps={{ ...shrinkProps }}
                            fullWidth={true}
                            className={classes.disabled}
                            renderInput={(args) => <TextField {...args} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        {/* Duration Notes - Textfield Input */}
                        <TextField
                          id="componentNotes"
                          label={t(
                            `${componentFormField}.componentNotes.label`
                          )}
                          value={values.componentNotes}
                          onChange={handleChange}
                          multiline
                          variant={inputVariant}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={isViewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          className={classes.disabled}
                        />
                      </Grid>
                    </Grid>

                    {/* Budget Plan fields */}
                    <Grid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyProjectionOne`}
                          required={false}
                          label={getLastYearProjectionHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyProjectionOne}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyProjectionOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          classes={{ root: classes.currencyField }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyBudget`}
                          required={false}
                          label={getCurrentYearBudgetHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyBudget}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyBudget`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          classes={{ root: classes.currencyField }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyPlanOne`}
                          required={false}
                          label={getNextYearPlanHeading(t, currentFiscalYear)}
                          value={values.budgetPlan?.fyPlanOne}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyPlanOne`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          classes={{ root: classes.currencyField }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormattedCurrencyField
                          id={`budgetPlan.fyPlanTwo`}
                          required={false}
                          label={getYearAfterNextPlanHeading(
                            t,
                            currentFiscalYear
                          )}
                          value={values.budgetPlan?.fyPlanTwo}
                          onChange={(event, value) => {
                            setFieldValue(
                              `budgetPlan.fyPlanTwo`,
                              event.target.value.replace(/[^0-9.-]+/g, "")
                            );
                          }}
                          setFieldValue={setFieldValue}
                          variant={inputVariant}
                          isViewOnly={isViewOnly}
                          disabled={isViewOnly}
                          classes={{ root: classes.currencyField }}
                        />
                      </Grid>
                    </Grid>

                    {isDeleteRecord ? (
                      <Grid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            delete={handleDelete}
                          />
                        </Grid>
                      </Grid>
                    ) : !isViewOnly ? (
                      <Grid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Grid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled: !dirty || !isValid || !isValidDate,
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      !otherParent && (
                        <Grid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {/* Back Button */}
                          <Grid container item justifyContent="flex-end">
                            <RouterLink to={`/commitments`}>
                              <Button
                                variant="outlined"
                                size="large"
                                startIcon={<ArrowBackIcon />}
                              >
                                {t("Commitments.view.goBackButtonLabel")}
                              </Button>
                            </RouterLink>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </form>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    )
  );
};
