import React from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  CloudDownload as CloudDownloadIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  NewReleases as NewReleasesIcon,
  Person as PersonIcon,
  PriceChange as PriceChangeIcon,
} from "@mui/icons-material";
import { useRequestsContext } from "../../services/requestsContext";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { useDownload } from "../UI/Download";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../services/usersService";
import { Link as RouterLink } from "react-router-dom";
import { getViewUrl } from "./Requests";
import { ComponentRequestsTableDrawer } from "./ComponentRequestsTableDrawer";
import { CommitmentRequestTableRowForm } from "./CommitmentRequestTableRowForm";
import axios from "axios";

export const CommitmentRequestTableRow = ({
  commitment,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
  setOpenDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { getDocument, downloadInProgress } = useDownload();

  const { openCommitmentId, setOpenCommitmentId } = useRequestsContext();
  const [isDrawerOpen, setDrawerOpen] = React.useState(
    commitment.id === openCommitmentId || false
  );

  const [formData, setFormData] = React.useState({
    commitmentRequestType: commitment?.commitmentRequestType,
    commitmentType: commitment?.commitmentType || "REQUEST",
    controllerComments: commitment?.controllerComments || "",
    controllerNotes: commitment?.controllerNotes || "",
    departmentName: commitment?.departmentName || "",
    fyBudget: commitment?.fyBudget || 0,
    fyProjectionOne: commitment?.fyProjectionOne || 0,
    id: commitment?.id,
    name: commitment?.name || "",
    orgCode: commitment?.orgCode,
    totalComponentAmountRequested:
      commitment?.totalComponentAmountRequested || 0,
    totalComponentAmountApproved: commitment?.totalComponentAmountApproved || 0,
    _links: commitment?._links,
  });

  const rowId = `commitment${commitment.id}`;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");
  const publishedClassName = commitment?.published ? "published" : "";
  const finalizedClassName =
    commitment?.finalized && !commitment?.published ? "finalized" : "";

  return (
    <>
      <TableRow>
        {rowId === inlineEditRow ? (
          <CommitmentRequestTableRowForm
            commitment={commitment}
            budgetFiscalYear={budgetFiscalYear}
            pushSnackbarMessage={pushSnackbarMessage}
            formData={formData}
            setFormData={setFormData}
            setRefresh={setRefresh}
            setInlineEditRow={setInlineEditRow}
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
            setOpenCommitmentId={setOpenCommitmentId}
            setLoading={setLoading}
          />
        ) : (
          <>
            {/* Drawer Buttons */}
            <TableCell
              className={`drawerHandleCell ${classes.rowFlag} ${publishedClassName} ${finalizedClassName}`}
            >
              {commitment?.components?.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setDrawerOpen(!isDrawerOpen);
                    setOpenCommitmentId(commitment.id);
                  }}
                >
                  {!isDrawerOpen && <KeyboardArrowDownIcon />}
                  {isDrawerOpen && <KeyboardArrowUpIcon />}
                </IconButton>
              )}
            </TableCell>

            {/* Departments */}
            <TableCell align="left" className="contentCell">
              {commitment.departmentName && (
                <Tooltip title={commitment.departmentName} placement="left">
                  <Typography noWrap={true} variant="body2">
                    {commitment.departmentName}
                  </Typography>
                </Tooltip>
              )}
            </TableCell>
            {/* Commitment Name */}
            <TableCell align="left" className="contentCell">
              <Typography noWrap={true} variant="body2">
                {getCommitmentIcon(commitment, t, classes)}
                {getCommitmentTitleLink(commitment)}
              </Typography>
            </TableCell>

            {/* Amounts */}
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.totalCommitmentAmountRequested)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.totalCommitmentAmountApproved)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.fyProjectionOne)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.fyBudget)}
            </TableCell>

            {/* Note Columns */}
            {isCommitmentAdmin && (
              <>
                <TableCell
                  align="left"
                  className="contentCell"
                  classes={{ root: classes.childCellSecondary }}
                >
                  {commitment.controllerNotes && (
                    <Tooltip
                      title={commitment.controllerNotes}
                      placement="left"
                    >
                      <Typography noWrap={true} variant="body2" component="div">
                        <span>{commitment.controllerNotes}</span>
                      </Typography>
                    </Tooltip>
                  )}
                </TableCell>
              </>
            )}
            <TableCell
              align="left"
              className="contentCell"
              classes={{ root: classes.childCellSecondary }}
            >
              {commitment.controllerComments && (
                <Tooltip title={commitment.controllerComments} placement="left">
                  <Typography noWrap={true} variant="body2" component="div">
                    <span>{commitment.controllerComments}</span>
                  </Typography>
                </Tooltip>
              )}
            </TableCell>

            {/* Actions */}
            <TableCell align="left" className="contentCell">
              <Grid
                container
                justifyContent={isCommitmentAdmin ? "flex-start" : "center"}
                wrap="nowrap"
              >
                {commitment._links?.disposition &&
                  permissions.DISPOSITION_REQUESTS && (
                    <Grid item>
                      <Tooltip
                        title={t(
                          "Requests.mainView.list.editCommitmentTooltip"
                        )}
                        placement="top"
                        classes={{
                          tooltip: classes.tooltipStyle,
                        }}
                        disableFocusListener={rowId === inlineEditRow}
                        disableHoverListener={rowId === inlineEditRow}
                        disableTouchListener={rowId === inlineEditRow}
                      >
                        <span>
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (rowId !== inlineEditRow) {
                                setInlineEditRow(rowId);
                              }
                            }}
                            className={classes.actionButton}
                            disabled={!!inlineEditRow}
                          >
                            <PriceChangeIcon size="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                {commitment._links?.disposition &&
                  permissions.DISPOSITION_REQUESTS && (
                    <>
                      <Grid>
                        <Tooltip
                          title={getFinalizeCommitmentTooltipText(
                            commitment,
                            t
                          )}
                          placement="top"
                          classes={{
                            tooltip: classes.tooltipStyle,
                          }}
                        >
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setOpenDialog(commitment.id);
                              }}
                              className={`${classes.finalizeCommitmentButton} ${publishedClassName} ${finalizedClassName}`}
                              disabled={
                                !!inlineEditRow ||
                                commitment.overallDispositionStatus ===
                                  "NOT_DISPOSITIONED" ||
                                commitment?.published
                              }
                            >
                              <CheckCircleIcon size="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                {commitment._links?.completedNewHireDocument && (
                  <Grid item justifyContent="flex-end">
                    <Tooltip
                      title={t(
                        "Commitments.commitmentDocuments.mainView.viewNewHireTemplate"
                      )}
                      placement="top"
                      classes={{
                        tooltip: classes.tooltipStyle,
                      }}
                      disableFocusListener={rowId === inlineEditRow}
                      disableHoverListener={rowId === inlineEditRow}
                      disableTouchListener={rowId === inlineEditRow}
                    >
                      <span>
                        <IconButton
                          size="small"
                          className={classes.downloadButton}
                          disabled={!!inlineEditRow || !!downloadInProgress}
                          onClick={() => {
                            let url = parseUrl(
                              commitment._links?.completedNewHireDocument.href
                            );
                            getDocument(
                              axios.defaults.baseURL + url.pathname,
                              commitment._links?.completedNewHireDocument?.title
                            );
                          }}
                        >
                          {downloadInProgress ? (
                            <CircularProgress size={15} />
                          ) : (
                            <CloudDownloadIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </TableCell>
          </>
        )}
      </TableRow>

      <ComponentRequestsTableDrawer
        commitment={commitment}
        components={commitment?.components}
        open={isDrawerOpen}
        budgetFiscalYear={budgetFiscalYear}
        inlineEditRow={inlineEditRow}
        setInlineEditRow={setInlineEditRow}
        pushSnackbarMessage={pushSnackbarMessage}
        setRefresh={setRefresh}
        setLoading={setLoading}
      />
    </>
  );
};

const getFinalizeCommitmentTooltipText = (commitment, t) => {
  if (commitment?.published) {
    return t("Commitments.editRequest.complete.disabledButtonIsPublishedTitle");
  } else {
    return commitment.overallDispositionStatus === "DISPOSITIONED"
      ? t("Commitments.editRequest.complete.enabledButtonTitle")
      : t("Commitments.editRequest.complete.disabledButtonTitle");
  }
};

const getCommitmentIcon = (commitment, t, classes) => {
  if (commitment.commitmentRequestType === "RECRUITMENT") {
    return (
      <>
        <Tooltip title={t(`Budgeting.mainView.newHireRequest`)}>
          <PersonIcon size="small" className={classes.recruitmentIcon} />
        </Tooltip>
      </>
    );
  } else if (commitment.commitmentType === "REQUEST") {
    return (
      <>
        <Tooltip title={t(`Budgeting.mainView.newRequest`)}>
          <NewReleasesIcon size="small" className={classes.yellow} />
        </Tooltip>
      </>
    );
  }
};

/**
 * Parses any URL into it's component parts.
 *
 * @param {string} url to be parsed
 * @returns {object} map of URL components
 */
const parseUrl = (url) => {
  var tmpLink = document.createElement("a");
  tmpLink.href = url;

  let { protocol, host, hostname, port, pathname, hash, search, origin } =
    tmpLink;

  // IE does not prefix with a slash
  if (pathname && pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};

const getCommitmentTitleLink = (commitment) => {
  return commitment?._links?.self?.href ? (
    <RouterLink to={{ pathname: getViewUrl(commitment?._links?.self?.href) }}>
      <Tooltip title={commitment.name} placement="left">
        <span>{commitment.name}</span>
      </Tooltip>
    </RouterLink>
  ) : (
    <Tooltip title={commitment.name} placement="left">
      <span>{commitment.name}</span>
    </Tooltip>
  );
};
