import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableSortLabel,
  TablePagination,
  Typography,
  Paper,
  Tooltip,
  TableCell,
  TableRow,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ListAlt as ListAltIcon } from "@mui/icons-material";
import { useUsers } from "../../services/usersService";
import { useStyles } from "./PTAs.styles";
import { getAllPtas } from "../../services/ptasService";
import { useTranslation } from "react-i18next";
import { usePtaContext } from "../../services/ptasContext";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const ptaTableStr = "PTAs.mainView.list.tableColumns";

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          key="ptaNumber"
          width="150px"
          sortDirection={orderBy === "ptaNumber" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "ptaNumber"}
            direction={orderBy === "ptaNumber" ? order : "asc"}
            onClick={createSortHandler("ptaNumber")}
          >
            {t(`${ptaTableStr}.pta`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="ptaStatus"
          sortDirection={orderBy === "project.projectStatus" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "project.projectStatus"}
            direction={orderBy === "project.projectStatus" ? order : "asc"}
            onClick={createSortHandler("project.projectStatus")}
          >
            {t(`${ptaTableStr}.ptaStatus`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="taskStatus"
          sortDirection={orderBy === "task.taskStatus" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "task.taskStatus"}
            direction={orderBy === "task.taskStatus" ? order : "asc"}
            onClick={createSortHandler("task.taskStatus")}
          >
            {t(`${ptaTableStr}.taskStatus`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="projectName"
          sortDirection={orderBy === "project.projectName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "project.projectName"}
            direction={orderBy === "project.projectName" ? order : "asc"}
            onClick={createSortHandler("project.projectName")}
          >
            {t(`${ptaTableStr}.projectName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="taskName"
          sortDirection={orderBy === "task.taskName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "task.taskName"}
            direction={orderBy === "task.taskName" ? order : "asc"}
            onClick={createSortHandler("task.taskName")}
          >
            {t(`${ptaTableStr}.taskName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="projectOrg"
          sortDirection={orderBy === "project.org.orgName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "project.org.orgName"}
            direction={orderBy === "project.org.orgName" ? order : "asc"}
            onClick={createSortHandler("project.org.orgName")}
          >
            {t(`${ptaTableStr}.projectOrg`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="projectOwner"
          sortDirection={
            orderBy === "project.projectOwnerFullName" ? order : false
          }
        >
          <TableSortLabel
            active={orderBy === "project.projectOwnerFullName"}
            direction={
              orderBy === "project.projectOwnerFullName" ? order : "asc"
            }
            onClick={createSortHandler("project.projectOwnerFullName")}
          >
            {t(`${ptaTableStr}.projectOwner`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="taskOrg"
          sortDirection={orderBy === "task.org.orgName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "task.org.orgName"}
            direction={orderBy === "task.org.orgName" ? order : "asc"}
            onClick={createSortHandler("task.org.orgName")}
          >
            {t(`${ptaTableStr}.taskOrg`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="taskOwner"
          sortDirection={orderBy === "task.taskOwnerFullName" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "task.taskOwnerFullName"}
            direction={orderBy === "task.taskOwnerFullName" ? order : "asc"}
            onClick={createSortHandler("task.taskOwnerFullName")}
          >
            {t(`${ptaTableStr}.taskOwner`)}
          </TableSortLabel>
        </TableCell>
        <TableCell align="center" key="actions">
          {t(`${ptaTableStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export const PTAs = ({ filterData }) => {
  const { t } = useTranslation();

  const { sort, setSort, pagination, setPagination, ptaFilters } =
    usePtaContext();

  const [rowsPerPage, setRowsPerPage] = React.useState(pagination.rowsPerPage);
  const [page, setPage] = React.useState(pagination.page);
  const [ptas, setPtas] = React.useState([]);
  const [ptaCount, setPtaCount] = React.useState(0);
  const [order, setOrder] = React.useState(sort.orderBy);
  const [orderBy, setOrderBy] = React.useState(sort.column);
  const [loading, setLoading] = React.useState(true);
  const { clearAlert, setAlert, alert } = useAlertContext();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const classes = useStyles();

  React.useEffect(() => {
    if (permissions.VIEW_PTAS) {
      const project = ptaFilters.project || "";
      const task = ptaFilters.task || "";
      const award = ptaFilters.award || "";
      const projectName = ptaFilters.projectName || "";
      const projectOwner = ptaFilters.projectOwner || "";
      const taskOwner = ptaFilters.taskOwner || "";

      getAllPtas(setPtas, setPtaCount, setLoading, setAlert, clearAlert, {
        page,
        rowsPerPage,
        orderBy,
        order,
        project,
        task,
        award,
        projectName,
        projectOwner,
        taskOwner,
      });
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    permissions,
    ptaFilters.project,
    ptaFilters.task,
    ptaFilters.award,
    ptaFilters.projectName,
    ptaFilters.projectOwner,
    ptaFilters.taskOwner,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      ptaCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-end"
      direction="row"
      spacing={2}
    >
      <Grid item xs={12}>
        {alert.exists && <ASAlert />}
      </Grid>
      {permissions.VIEW_PTAS && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" stickyHeader>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan="10"
                      classes={{ root: classes.progressBar }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  false
                )}
                {!loading && ptas && Array.isArray(ptas) && ptas.length > 0 ? (
                  ptas.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" component="th" scope="row">
                        <RouterLink
                          to={{
                            pathname:
                              "/ptas/" +
                              new URL(row._links.self.href).pathname
                                .split("/")
                                .pop(),
                          }}
                        >
                          <Typography variant="body2">
                            {row.project.projectNumber}-{row.task.taskNumber}-
                            {row.award.awardNumber}
                          </Typography>
                        </RouterLink>
                      </TableCell>
                      <TableCell align="left">{row.ptaStatus}</TableCell>
                      <TableCell align="left">{row.task.taskStatus}</TableCell>
                      <TableCell align="left">
                        {row.project.projectName}
                      </TableCell>
                      <TableCell align="left">{row.task.taskName}</TableCell>
                      <TableCell align="left">
                        {row.project.projectOrgCodeName}
                      </TableCell>
                      <TableCell align="left">
                        {row.project.projectOwnerFullName}
                      </TableCell>
                      <TableCell align="left">
                        {row.task.taskOrgCodeName}
                      </TableCell>
                      <TableCell align="left">
                        {row.task.taskOwnerFullName}
                      </TableCell>
                      <TableCell align="center">
                        {row._links.viewDetail && (
                          <RouterLink
                            to={{
                              pathname:
                                "/ptas/" +
                                new URL(row._links.viewDetail.href).pathname
                                  .split("/")
                                  .pop(),
                            }}
                          >
                            <Tooltip
                              title={t("PTAs.mainView.list.actionsColToolTip")}
                            >
                              <ListAltIcon fontSize="small" />
                            </Tooltip>
                          </RouterLink>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : !loading ? (
                  <TableRow>
                    <TableCell colSpan="10">
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  false
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={ptaCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};
