import React from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useRequestsContext } from "../../services/requestsContext";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../services/usersService";
import PropTypes from "prop-types";

export function CommitmentRequestTableHeaders(props) {
  const { /*order, orderBy,*/ onRequestSort, budgetFiscalYear } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { sort } = useRequestsContext();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="drawerHandle"
          scope="col"
          className={classes.columnDrawerHandle}
        >
          {" "}
        </TableCell>
        <TableCell
          align="left"
          key="department"
          scope="col"
          className={classes.columnDepartment}
        >
          <TableSortLabel
            active={sort.column === "org.orgName"}
            direction={sort.column === "org.orgName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("org.orgName")}
          >
            {t(`Requests.mainView.list.tableColumns.department`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="commitmentName"
          scope="col"
          sortDirection={sort.column === "name" ? sort.orderBy : "asc"}
          className={classes.columnName}
        >
          <TableSortLabel
            active={sort.column === "name"}
            direction={sort.column === "name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("name")}
          >
            {t(`Requests.mainView.list.tableColumns.commitmentName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalRequested}
        >
          {t(`Requests.mainView.list.tableColumns.totalCommitmentRequested`)}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalApproved}
        >
          {t(`Requests.mainView.list.tableColumns.totalCommitmentApproved`)}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalFyProjection}
        >
          {t(`Requests.mainView.list.tableColumns.totalFyProjection`, {
            fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
          })}
        </TableCell>
        <TableCell
          align="right"
          scope="col"
          className={classes.columnTotalFy1Budget}
        >
          {t(`Requests.mainView.list.tableColumns.totalFyBudget`, {
            fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
          })}
        </TableCell>
        {isCommitmentAdmin && (
          <>
            <TableCell
              align="left"
              scope="col"
              className={classes.columnDispositionNotes}
            >
              {t(`Requests.mainView.list.tableColumns.controllerNotes`)}
            </TableCell>
          </>
        )}
        <TableCell
          align="left"
          scope="col"
          className={classes.columnRequestNotes}
        >
          {t(`Requests.mainView.list.tableColumns.controllerComments`)}
        </TableCell>
        <TableCell
          align="center"
          scope="col"
          key="actions"
          sx={{ width: "110px" }}
        >
          {t(`Requests.mainView.list.tableColumns.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

CommitmentRequestTableHeaders.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
