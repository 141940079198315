import React from "react";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useUsers } from "../../services/usersService";
import { useStyles } from "./Requests.styles";
import { ComponentRequestTableHeaders } from "./ComponentRequestTableHeaders";
import { ComponentRequestTableRow } from "./ComponentRequestTableRow";
import { getColSpan } from "./Requests";

export const ComponentRequestsTableDrawer = ({
  commitment,
  components,
  open,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
}) => {
  const classes = useStyles();

  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;

  return (
    <>
      <TableRow key={commitment.id} className={classes.expandableRow}>
        <TableCell
          colSpan={getColSpan(permissions, roles)}
          className={open ? classes.drawerOpen : classes.drawerClose}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table
                size="small"
                aria-label="Component Requests Table"
                classes={{ root: classes.tableLayout }}
              >
                <ComponentRequestTableHeaders
                  budgetFiscalYear={budgetFiscalYear}
                />

                <TableBody>
                  {components &&
                    components.map((row, index) => (
                      <ComponentRequestTableRow
                        component={row}
                        commitmentId={commitment.id}
                        commitmentName={commitment.name}
                        commitmentFinalized={commitment.finalized}
                        key={index}
                        inlineEditRow={inlineEditRow}
                        setInlineEditRow={setInlineEditRow}
                        pushSnackbarMessage={pushSnackbarMessage}
                        budgetFiscalYear={budgetFiscalYear}
                        setRefresh={setRefresh}
                        setLoading={setLoading}
                      />
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
