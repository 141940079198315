export const ComponentTypes = {
  BRIDGE_FUNDING:
    "Commitments.commitmentComponents.create.form.fields.componentType.bridgeFunding",
  DC: "Commitments.commitmentComponents.create.form.fields.componentType.dc",
  HOUSING:
    "Commitments.commitmentComponents.create.form.fields.componentType.housingOptionValue",
  MCD_BACKSTOP:
    "Commitments.commitmentComponents.create.form.fields.componentType.mcdBackstop",
  MOCK_ENDOWMENT:
    "Commitments.commitmentComponents.create.form.fields.componentType.mockEndowment",
  SEED_GRANTS:
    "Commitments.commitmentComponents.create.form.fields.componentType.seedGrants",
  SUBSIDY:
    "Commitments.commitmentComponents.create.form.fields.componentType.subsidy",
  REQUEST:
    "Commitments.commitmentComponents.create.form.fields.componentType.request",
  DEAN_COMMITMENT: "Budgeting.mainView.list.tableColumns.deansCommitments",
};

export const ComponentDispositionTypes = {
  APPROVED: "Commitments.commitmentComponents.dispositionTypes.approved",
  DEFER: "Commitments.commitmentComponents.dispositionTypes.defer",
  NOT_APPROVED: "Commitments.commitmentComponents.dispositionTypes.notApproved",
  REQUEST: "Commitments.commitmentComponents.dispositionTypes.requested",
};

export const DocumentTypes = {
  OFFER_LETTER:
    "Commitments.commitmentDocuments.create.form.fields.documentType.offerLetterOptionValue",
  EMAIL:
    "Commitments.commitmentDocuments.create.form.fields.documentType.emailOptionValue",
  RECRUITMENT:
    "Commitments.commitmentDocuments.create.form.fields.documentType.recruitmentOptionValue",
  OTHER:
    "Commitments.commitmentDocuments.create.form.fields.documentType.otherOptionValue",
};

export const CommitmentTypes = {
  REGULAR: "Commitments.create.form.fields.commitmentType.regularOptionValue",
  PLACEHOLDER:
    "Commitments.create.form.fields.commitmentType.placeholderOptionValue",
  MEMO: "Commitments.create.form.fields.commitmentType.memoOptionValue",
  REQUEST: "Commitments.create.form.fields.commitmentType.requestOptionValue",
  DEFERRED: "Commitments.create.form.fields.commitmentType.deferredOptionValue",
};

export const ExpensesPosted = {
  POSTED:
    "UploadedExpenses.filterPanelItems.options.postedDropdownValues.posted",
  UNPOSTED:
    "UploadedExpenses.filterPanelItems.options.postedDropdownValues.unPosted",
};

export const ExpensesConditionType = {
  ERROR:
    "UploadedExpenses.filterPanelItems.options.conditionTypeDropdownValues.error",
  WARNING:
    "UploadedExpenses.filterPanelItems.options.conditionTypeDropdownValues.warning",
  REDISTRIBUTED:
    "UploadedExpenses.filterPanelItems.options.conditionTypeDropdownValues.redistributed",
};

export const ExpensesNotification = {
  UNRESOLVED:
    "UploadedExpenses.filterPanelItems.options.notificationDropdownValues.unprocessed",
  INPROCESS:
    "UploadedExpenses.filterPanelItems.options.notificationDropdownValues.inProcess",
  RESOLVED:
    "UploadedExpenses.filterPanelItems.options.notificationDropdownValues.resolved",
};

export const TransferTypes = {
  TRANSFER_IN: "UploadedTransfers.mainView.list.transferTypeValues.transferIn",
  TRANSFER_OUT:
    "UploadedTransfers.mainView.list.transferTypeValues.transferOut",
  RECEIPT: "UploadedTransfers.mainView.list.transferTypeValues.receipt",
};

export const TransactionTypes = {
  EXPENSE:
    "Commitments.financialHistory.financialTransaction.create.transactionTypes.expenses",
  TRANSFER:
    "Commitments.financialHistory.financialTransaction.create.transactionTypes.transfers",
};

export const LinkToPTARequestForm =
  "//app.smartsheet.com/b/form/0d254b303eae42a9a2d7d8f411d2df20";

/*
  For reference, this file lives in the "Application Service > MedCommit" folder here:
  https://drive.google.com/drive/u/1/folders/1Jj-RX1Zd-Wu4bVOSdo7Wy4AaXEwrhUFX
*/
export const LinkToFacultyNewHireTemplate = {
  downloadUrl:
    "https://drive.google.com/uc?export=download&id=1INjZRRsOHrF7_wYjpkqLwQUETZyoIpjS",
  filename: "Faculty New Hire Commitments.xlsx",
};
