import React from "react";
import { useTranslation } from "react-i18next";
import { TextField, Autocomplete } from "@mui/material";
import { useBudgetingContext } from "../../services/budgetingContext";

export const DepartmentNameControl = (props) => {
  const { t } = useTranslation();

  // context
  const {
    orgs,
    setOrgCode,
    setOrgName,
    deptSelection,
    setDeptSelection,
    orgByCodeError,
    loading,
  } = useBudgetingContext();
  return (
    <Autocomplete
      id="departmentName"
      loading={loading}
      options={orgs}
      getOptionLabel={(option) => option.displayText || ""}
      value={deptSelection}
      autoHighlight={true}
      disableClearable={true}
      handleHomeEndKeys={true}
      openOnFocus={true}
      onChange={(event, val) => {
        setOrgCode((val && val.value.orgCode) || "");
        setOrgName((val && val.displayText) || "");
        setDeptSelection(val);
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${t("Budgeting.mainView.departmentNameDropdown.title")}`}
          variant="outlined"
          error={orgByCodeError.exist}
          helperText={orgByCodeError.message}
        />
      )}
    />
  );
};
