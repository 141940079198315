import React from "react";
import { Snackbar, Alert } from "@mui/material";

export const PositionedSnackbar = (props) => {
  let otherProps = Object.assign({}, props);
  Object.hasOwn(otherProps, "message") && delete otherProps.message;
  Object.hasOwn(otherProps, "open") && delete otherProps.open;
  Object.hasOwn(otherProps, "onClose") && delete otherProps.onClose;
  Object.hasOwn(otherProps, "autoHideDuration") &&
    delete otherProps.autoHideDuration;

  const [snackbarOpen, setSnackbarOpen] = React.useState(true);
  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const open = props.open !== undefined ? props.open : snackbarOpen;
  const onClose = props.onClose !== undefined ? props.onClose : handleClose;
  const autoHideDuration =
    props.autoHideDuration !== undefined ? props.autoHideDuration : 6000;
  const severity = props.severity !== undefined ? props.severity : "success";

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      {...otherProps}
    >
      <Alert
        severity={severity}
        onClose={onClose}
        sx={{
          "&.MuiAlert-root": {
            backgroundColor: "black",
          },
          "& .MuiAlert-icon": {
            color: "#ffffff",
          },
          "& .MuiAlert-message": {
            color: "#ffffff",
          },
          "& .MuiButtonBase-root": {
            color: "#ffffff",
          },
        }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};
