import React from "react";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ResetFilter } from "../../components/Layout/FilterPanel/ResetFilter";
import { useRequestsContext } from "../../services/requestsContext";
import { useHistory } from "react-router-dom";
import {
  getCommitmentByMatch,
  getInitialRequestsData,
} from "../../services/requestsService";
import { ComponentDispositionTypes } from "../../constants";

export const RequestsFilter = () => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  // useHistory hook from "react-router-dom"
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);

  // Filter Context
  const { filters, setFilters, pagination, setPagination } =
    useRequestsContext();

  // State variable and its function for Budget Year
  const [budgetYear, setBudgetYear] = React.useState(filters.budgetYear);
  // State variable and its function for Commitment Name
  const [name, setCommitmentName] = React.useState(filters.name);
  // State variable and its function for Department Name
  const [departmentName, setDepartmentName] = React.useState(
    filters.departmentName
  );
  // State variable and its function for Department Code
  const [departmentCode, setDepartmentCode] = React.useState(
    filters.departmentCode
  );
  // State variable and its function for Status
  const [dispositionStatus, setDispositionStatus] = React.useState(
    filters.dispositionStatus
  );

  // State variable and its function for Budget year Options
  const [budgetYearOptions, setBudgetYearOptions] = React.useState([]);
  // State variable and its function for Commitment Options
  const [commitmentNameOptions, setCommitmentNameOptions] = React.useState([]);
  // State variable and its function for Department Options
  const [departmentNameOptions, setDepartmentNameOptions] = React.useState([]);

  const [budgetYearFilterError, setBudgetYearFilterError] = React.useState({
    exist: false,
    message: null,
  });

  const [departmentFilterError, setDepartmentFilterError] = React.useState({
    exist: false,
    message: null,
  });

  const [commitmentNameFilterError, setCommitmentNameFilterError] =
    React.useState({
      exist: false,
      message: null,
    });

  // Function to reset the Filter value and re-load Deals data accordingly
  const resetFn = () => {
    setBudgetYear("");
    setCommitmentName("");
    setDepartmentName("");
    setDepartmentCode("");
    setDispositionStatus("");
    setFilters({
      budgetYear: "",
      name: "",
      departmentName: "",
      departmentCode: "",
      dispositionStatus: "",
    });
    setBudgetYearOptions([]);
    setCommitmentNameOptions([]);
    setDepartmentNameOptions([]);
  };

  // Get initial data to populate the filters. Here we are setting the Budget Year filter.
  React.useEffect(() => {
    getInitialRequestsData(
      setBudgetYearOptions,
      setBudgetYear,
      filters,
      setFilters,
      setBudgetYearFilterError,
      {
        size: 25,
      }
    );
    // eslint-disable-next-line
  }, []);

  // React.useEffect to act on the change of Filter Data variables.
  React.useEffect(() => {
    const params = {
      budgetYear,
      name,
      deptCode: departmentCode,
      dispositionStatus,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
    history.push(`${history.location.pathname}?${queryParams}`);
  }, [
    budgetYear,
    name,
    departmentName,
    departmentCode,
    dispositionStatus,
    history,
  ]);

  return (
    <>
      {/* Commitments Filter function to show Autocomplete inputs and filter data accordingly. */}
      <FormControl variant="outlined">
        <Autocomplete
          id="budgetYear"
          options={budgetYearOptions || []}
          getOptionLabel={(option) => option && String(option.displayText)}
          value={{ displayText: budgetYear }}
          onChange={(event, value) => {
            const budgetYear = value ? value.displayText : "";
            setBudgetYear(budgetYear);
            setFilters({
              ...filters,
              budgetYear: value ? value.displayText : "",
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          clearOnEscape={true}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Commitments.filterPanelItems.options.budgetYear.label")}
              variant="outlined"
              error={budgetYearFilterError.exist}
              helperText={budgetYearFilterError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          clearIcon={false}
          blurOnSelect
          clearOnEscape
          id="commitmentNameFilter"
          freeSolo
          autoSelect
          options={commitmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={name}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(_e, value) => {
            setCommitmentName(value);
            setFilters({
              ...filters,
              name: value,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          //onInputChange={onChangeSearchQuery}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.commitmentName.label"
              )}
              variant="outlined"
              onChange={(e) => {
                getCommitmentByMatch(
                  e.target.value,
                  "name",
                  budgetYear,
                  setCommitmentNameOptions,
                  setLoading,
                  setCommitmentNameFilterError
                );
              }}
              error={commitmentNameFilterError.exist}
              helperText={commitmentNameFilterError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          clearIcon={false}
          blurOnSelect
          clearOnEscape
          id="departmentNameFilter"
          options={departmentNameOptions || []}
          getOptionLabel={(option) => option}
          value={departmentName}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const departmentName = value;
            const departmentCode = value
              ? value.split("(").pop().split(")")[0].trim()
              : "";
            setDepartmentName(departmentName);
            setDepartmentCode(departmentCode);
            setFilters({
              ...filters,
              departmentName,
              departmentCode,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                "Commitments.filterPanelItems.options.departmentName.label"
              )}
              variant="outlined"
              onChange={(e) => {
                getCommitmentByMatch(
                  e.target.value,
                  "org",
                  budgetYear,
                  setDepartmentNameOptions,
                  setLoading,
                  setDepartmentFilterError
                );
              }}
              error={departmentFilterError.exist}
              helperText={departmentFilterError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="statusLabel">
          {t("Commitments.filterPanelItems.options.dispositionStatus.label")}
        </InputLabel>
        <Select
          labelId="statusSelectLabel"
          label={t(
            "Commitments.filterPanelItems.options.dispositionStatus.label"
          )}
          id="statusFilter"
          value={dispositionStatus || ""}
          onChange={(e) => {
            const statusVal = e.target.value;
            setDispositionStatus(statusVal);
            document.getElementById("statusFilter").blur();
            setFilters({
              ...filters,
              dispositionStatus: statusVal,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
        >
          {Object.entries(ComponentDispositionTypes).map(
            ([key, value], index) => (
              <MenuItem key={index} value={key}>
                {t(value)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>

      <ResetFilter resetFn={resetFn} />
    </>
  );
};
