import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FastField, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { FormButton } from "../../UI/Button/FormButton";
import { NumberFormatField } from "../../UI/NumberFormatField";
import { CurrencyFormat } from "../../../Utils/CurrencyFormat";
import { getIsRequiredText } from "../../../Utils/ValidationUtils";
//import { CommitmentCoInvestmentEntityField } from "../../Commitments/CommitmentForm/CommitmentCoInvestmentEntityField";
import { CommitmentPresentationField } from "../../Commitments/CommitmentForm/CommitmentPresentationField";
import { CommitmentPresentationSubcategoryField } from "../../Commitments/CommitmentForm/CommitmentPresentationSubcategoryField";
import { CommitmentProgramField } from "../../Commitments/CommitmentForm/CommitmentProgramField";
import { CommitmentProjectGroupField } from "../../Commitments/CommitmentForm/CommitmentProjectGroupField";
import { CommitmentTypeField } from "../../Commitments/CommitmentForm/CommitmentTypeField";
import { CommitmentUnitField } from "../../Commitments/CommitmentForm/CommitmentUnitField";
import { ComponentExpirationDateField } from "../../Commitments/CommitmentComponents/ComponentExpirationDateField";
import { ComponentOwnerField } from "../../Commitments/CommitmentComponents/ComponentOwnerField";
import { ComponentStartDateField } from "../../Commitments/CommitmentComponents/ComponentStartDateField";
import { ComponentTypeField } from "../../Commitments/CommitmentComponents/ComponentTypeField";
import { useStyles } from "./RequestsCompletionDialog.styles";
import { useAlertContext } from "@stanford-tds/as-components";
import { CommitmentTypes } from "../../../constants";
import {
  getCoInvestmentByMatch,
  prepareCommitmentData,
} from "../../../services/commitmentsService";
import {
  getCommitmentRequestById,
  patchCommitmentRequest,
} from "../../../services/requestsService";
import axios from "axios";

export const RequestsCompletionDialog = ({
  openDialog: commitmentId,
  setOpenDialog,
  pushSnackbarMessage,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setAlert, clearAlert } = useAlertContext();

  const [showMemoAmount, setShowMemoAmount] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [coInvestmentValue, setCoInvestmentValue] = React.useState("");
  const [coInvestmentInputValue, setCoInvestmentInputValue] =
    React.useState("");
  const [coInvestmentOptions, setCoInvestmentOptions] = React.useState([]);
  const [departmentNameValue, setDepartmentNameValue] = React.useState({
    orgName: "",
    orgCode: "",
  });

  const arrayHelpersRef = React.useRef(null);

  const formPreFetchData = {
    commitmentType: "REGULAR", //Enum implementation
  };
  const [formDataResponse, setFormDataResponse] = React.useState({
    // Object keys use to create/edit form data
    name /* Commitment Name */: "",
    departmentName /* Department Name */: "",
    commitmentType /* Commitment Type */: "",
    projectGroup /* Project Group */: "",
    unit /* Unit */: "",
    program /* Program */: "",
    presentation /* Presentation */: "",
    presentationSubcategory /* Presentation Sub Category */: null,
    memoAmount: 0.0,
    orgCode: "",
    components: [],
  });

  const budgetingValidation = Yup.object().shape({
    memoAmount: Yup.number().when("commitmentType", {
      is: (i) => i && i.toLowerCase() === "memo",
      then: Yup.number().required(),
      otherwise: undefined,
    }),
    projectGroup: Yup.mixed().required(),
    unit: Yup.mixed().required(),
    program: Yup.mixed().required(),
    presentation: Yup.mixed().required(),
    coInvestmentEntity: Yup.mixed().when("presentationSubcategory", {
      is: (i) => i && i.name && i.name.toLowerCase() === "co-investment",
      then: Yup.object().required(),
      otherwise: undefined,
    }),
    components: Yup.array()
      .of(
        Yup.object().shape({
          componentType: Yup.string().when("dispositionStatus", {
            is: (i) => i === "APPROVED",
            then: Yup.string().required(),
            otherwise: undefined,
          }),
        })
      )
      .compact(),
  });

  const handleClose = () => {
    setOpenDialog(false);
    clearAlert();
  };

  const _handleSubmit = (values) => {
    //console.log("Submitting", values);

    const cancelSource = axios.CancelToken.source();
    values = prepareCommitmentData(values, formPreFetchData, null);

    // Defines what to do after saving the data
    const onSuccess = () => {
      setOpenDialog(false);
      pushSnackbarMessage(
        "success",
        t("Commitments.create.notification.update", {
          commitmentName: `"${values.name.trim()}"`,
        }),
        true
      );
      setRefresh((value) => value + 1);
    };

    const onError = (error) => {
      pushSnackbarMessage("error", error);
    };

    patchCommitmentRequest(
      values,
      commitmentId,
      () => void 0,
      onError,
      onSuccess,
      cancelSource
    );
  };

  const commitmentIsDeferred = (commitment) => {
    let isDeferred = true;
    commitment.components.forEach((component) => {
      if (component.dispositionStatus === "APPROVED") {
        isDeferred = false;
      }
    });

    return isDeferred;
  };

  // Initialize the value of commitmentType if not defined
  const getCommitmentTypeValue = (values) => {
    if (!values.commitmentType) {
      if (commitmentIsDeferred(values)) {
        values.commitmentType = "DEFERRED";
      } else {
        values.commitmentType = "REGULAR";
      }
    }

    return values.commitmentType;
  };

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    const onBeforeSend = () => {
      clearAlert();
      setLoading(true);
    };
    const onError = (error) => {
      if (!axios.isCancel(error)) {
        setLoading(false);
        setOpenDialog(false);
        setAlert("error", error.message);
      }
    };
    const onSuccess = (responseData) => {
      setLoading(false);
      responseData.components = responseData.components.map((c) => {
        c.componentType =
          c.dispositionStatus === "APPROVED" && c.componentType === "REQUEST"
            ? ""
            : c.componentType;
        c.piOrComponentOwner = c.piOrComponentOwner
          ? { displayText: c.piOrComponentOwner }
          : "";
        return c;
      });
      setFormDataResponse({
        requestOperation: "COMPLETE_REQUEST",
        name: responseData.name,
        departmentName: responseData.departmentName,
        commitmentType:
          responseData.commitmentType === "REQUEST"
            ? ""
            : responseData.commitmentType,
        projectGroup: responseData.projectGroup,
        unit: responseData.unit,
        program: responseData.program,
        presentation: responseData.presentation,
        presentationSubcategory: responseData.presentationSubcategory,
        memoAmount: responseData.memoAmount ? responseData.memoAmount : 0.0,
        coInvestmentEntity: {
          displayText: responseData.coInvestmentEntity || "",
        },
        commitmentRequestType: responseData.commitmentRequestType,
        components: responseData.components,
      });
      setDepartmentNameValue({
        orgName: responseData.departmentName,
        orgCode: responseData.orgCode,
      });
    };

    commitmentId &&
      getCommitmentRequestById(
        commitmentId,
        onBeforeSend,
        onError,
        onSuccess,
        cancelSource
      );

    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [commitmentId]);

  return (
    <>
      <Dialog
        open={!!commitmentId}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <Formik
          initialValues={formDataResponse}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            _handleSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={budgetingValidation}
        >
          {(props) => {
            const /** !Object */ {
                values,
                errors,
                //touched,
                dirty,
                isValid,
                handleSubmit,
                setFieldValue,
                setFieldError,
              } = props;
            return (
              // Native form element to submit the form values
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">
                  {t(`Commitments.editRequest.complete.title`, {
                    commitmentName: values?.name,
                  })}
                  <Typography variant="subtitle1" component="div">
                    {departmentNameValue?.orgName}
                  </Typography>
                </DialogTitle>
                {/*console.log({errors, dirty, isValid, values})*/}

                <DialogContent className={classes.formContainer}>
                  {loading && (
                    <>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {!loading && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {/* Commitment Type - Autocomplete Input */}
                          <CommitmentTypeField
                            id="commitmentType"
                            name="commitmentType"
                            value={t(
                              CommitmentTypes[getCommitmentTypeValue(values)]
                            )}
                            required={true}
                            includeRequestTypes={false}
                            commitmentIsDeferred={commitmentIsDeferred(values)}
                            onChange={(_event, value) => {
                              setFieldValue(
                                "commitmentType",
                                Object.keys(CommitmentTypes).find(
                                  (key) => CommitmentTypes[key] === value
                                )
                              );

                              if (
                                Object.keys(CommitmentTypes).find(
                                  (key) => CommitmentTypes[key] === value
                                ) === "MEMO"
                              ) {
                                setShowMemoAmount(true);
                                setFieldValue("memoAmount", 0.0);
                              } else {
                                setShowMemoAmount(false);
                                setFieldValue("memoAmount", "");
                              }
                            }}
                            error={!!errors.commitmentType}
                            helperText={errors.commitmentType}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          {showMemoAmount ||
                          values.commitmentType === "MEMO" ? (
                            <NumberFormatField
                              id="memoAmount"
                              name="memoAmount"
                              label={t(
                                `Commitments.create.form.fields.memoAmount.label`
                              )}
                              setFieldValue={setFieldValue}
                              formValues={values?.memoAmount}
                              restrictions={true}
                              fullWidth={true}
                              autoComplete="off"
                              allowNegative={false}
                              required={true}
                              className={`${classes.numericInput}`}
                              variant="outlined"
                              helperText={(() => {
                                return (
                                  errors.memoAmount &&
                                  getIsRequiredText(
                                    t,
                                    t(
                                      `Commitments.create.form.fields.memoAmount.label`
                                    )
                                  )
                                );
                              })()}
                              error={!!errors.memoAmount}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          {/* Project Group - Auto populate from Unit */}
                          <CommitmentProjectGroupField
                            name="projectGroup"
                            value={
                              values.projectGroup
                                ? values.projectGroup.name
                                : ""
                            }
                            required={true}
                            error={!!errors.projectGroup}
                            className={classes.disabled}
                            helperText={
                              !!errors.projectGroup &&
                              getIsRequiredText(
                                t,
                                t(
                                  `Commitments.create.form.fields.projectGroup.label`
                                )
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {/* Commitment Unit - Autocomplete Input */}
                          <CommitmentUnitField
                            id="unit"
                            name="unit"
                            value={values.unit || ""}
                            required={true}
                            onChange={(_event, valueObj) => {
                              setFieldValue("unit", valueObj);
                              setFieldValue(
                                "projectGroup",
                                valueObj.projectGroup
                              );
                            }}
                            error={!!errors.unit}
                            helperText={
                              !!errors.unit &&
                              getIsRequiredText(
                                t,
                                t(
                                  `Commitments.create.form.fields.commitmentUnit.label`
                                )
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {/* Program - Autocomplete Input */}
                          <CommitmentProgramField
                            id="program"
                            name="program"
                            value={values.program || ""}
                            required={true}
                            onChange={(_event, valueObj) => {
                              setFieldValue("program", valueObj);
                            }}
                            error={!!errors.program}
                            helperText={
                              !!errors.program &&
                              getIsRequiredText(
                                t,
                                t(
                                  `Commitments.create.form.fields.program.label`
                                )
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          {/* Presentation - Autocomplete Input */}
                          <CommitmentPresentationField
                            id="presentation"
                            name="presentation"
                            value={values.presentation || ""}
                            required={true}
                            onChange={(_event, valueObj) => {
                              setFieldValue("presentation", valueObj);
                            }}
                            error={!!errors.presentation}
                            helperText={
                              !!errors.presentation &&
                              getIsRequiredText(
                                t,
                                t(
                                  `Commitments.create.form.fields.presentation.label`
                                )
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {/* Presentation Sub Category - Autocomplete Input */}
                          <CommitmentPresentationSubcategoryField
                            id="presentationSubcategory"
                            name="presentationSubcategory"
                            value={values.presentationSubcategory || ""}
                            onChange={(_event, valueObj) => {
                              if (valueObj.id === undefined) {
                                valueObj = { name: valueObj };
                              }
                              setFieldValue(
                                "presentationSubcategory",
                                valueObj
                              );
                            }}
                            error={!!errors.presentationSubcategory}
                            helperText={
                              !!errors.presentationSubcategory &&
                              getIsRequiredText(
                                t,
                                t(
                                  `Commitments.create.form.fields.presentationSubCategory.label`
                                )
                              )
                            }
                          />
                        </Grid>
                        {values?.presentationSubcategory?.name?.toLowerCase() ===
                          "co-investment" && (
                          <Grid item xs={4}>
                            {/* Co-Investment Entity - Autocomplete Input */}
                            {/*<CommitmentCoInvestmentEntityField
                          id="coInvestmentEntity"
                          name="coInvestmentEntity"
                          label={t(`Commitments.create.form.fields.coInvestmentEntity.label`)}
                          isValid={
                            touched.coInvestmentEntity &&
                            Boolean(errors.coInvestmentEntity)
                          }
                          onChange={(_event, value) => {
                            setFieldValue("coInvestmentEntity", value);
                          }}
                          value={values?.coInvestmentEntity}
                          required={true}
                          setFieldError={setFieldError}
                          helperText={
                            !!errors.coInvestmentEntity && getIsRequiredText(
                              t, t(`Commitments.create.form.fields.coInvestmentEntity.label`)
                            )
                          }
                          error={!!errors.coInvestmentEntity}
                        />*/}
                            <Autocomplete
                              id="coInvestmentEntity"
                              name="coInvestmentEntity"
                              options={coInvestmentOptions}
                              getOptionLabel={(option) =>
                                option && option.displayText
                              }
                              value={
                                coInvestmentValue ||
                                values.coInvestmentEntity ||
                                ""
                              }
                              onChange={(_event, value) => {
                                setCoInvestmentValue(value);
                                setFieldValue("coInvestmentEntity", value);
                              }}
                              inputValue={coInvestmentInputValue}
                              onInputChange={(_event, value) => {
                                setCoInvestmentInputValue(value);
                                value &&
                                  getCoInvestmentByMatch(
                                    value,
                                    setCoInvestmentOptions,
                                    () => void 0,
                                    setFieldError
                                  );
                              }}
                              clearIcon={false}
                              freeSolo={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t(
                                    `Commitments.create.form.fields.coInvestmentEntity.label`
                                  )}
                                  required
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    onBlur: (event) => {
                                      const value = event.target.value
                                        ? {
                                            displayText: event.target.value,
                                          }
                                        : "";
                                      setCoInvestmentValue(value);
                                      setFieldValue(
                                        "coInvestmentEntity",
                                        value
                                      );
                                    },
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    maxLength: 50,
                                  }}
                                  className={classes.disabled}
                                  helperText={
                                    !!errors.coInvestmentEntity &&
                                    getIsRequiredText(
                                      t,
                                      t(
                                        `Commitments.create.form.fields.coInvestmentEntity.label`
                                      )
                                    )
                                  }
                                  error={!!errors.coInvestmentEntity}
                                />
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}

                  {/* Components Table */}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2">
                        {t(`Commitments.editRequest.complete.componentTitle`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="Commitment Components"
                          aria-live="polite"
                          stickyHeader
                          classes={{ root: classes.componentTable }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.editRequest.complete.tableColumns.componentName`
                                )}
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.commitmentComponents.create.form.fields.componentType.label`
                                )}{" "}
                                <sup>*</sup>
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.editRequest.complete.tableColumns.componentDescription`
                                )}
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.editRequest.complete.tableColumns.approvedAmount`
                                )}
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.commitmentComponents.create.form.fields.piOrComponentOwner.label`
                                )}
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.commitmentComponents.mainView.list.budgetTableHeaders.startDate`
                                )}
                              </TableCell>
                              <TableCell sx={{ width: "14.28571%" }}>
                                {t(
                                  `Commitments.commitmentComponents.mainView.list.budgetTableHeaders.endDate`
                                )}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loading && (
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  classes={{ root: classes.progressBar }}
                                >
                                  <CircularProgress />
                                </TableCell>
                              </TableRow>
                            )}
                            {!loading && values.components && (
                              <>
                                <FieldArray name="components">
                                  {(arrayHelpers) => {
                                    arrayHelpersRef.current = arrayHelpers;
                                    return values.components.map(
                                      (i, indexI) => (
                                        <Fragment key={indexI}>
                                          {i.dispositionStatus ===
                                            "APPROVED" && (
                                            <>
                                              <TableRow>
                                                <TableCell>
                                                  <span>
                                                    {i?.componentName || ""}
                                                  </span>
                                                </TableCell>
                                                <TableCell>
                                                  <ComponentTypeField
                                                    id={`components[${indexI}].componentType`}
                                                    value={i.componentType}
                                                    required={true}
                                                    onChange={(event) => {
                                                      setFieldValue(
                                                        `components[${indexI}].componentType`,
                                                        event.target.value
                                                      );
                                                    }}
                                                    labelText=""
                                                    size="small"
                                                    error={
                                                      errors?.components &&
                                                      errors?.components[indexI]
                                                        ?.componentType &&
                                                      Boolean(
                                                        errors.components[
                                                          indexI
                                                        ].componentType
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <FastField
                                                    as={TextField}
                                                    id={`components[${indexI}].componentDescription`}
                                                    value={
                                                      i?.componentDescription ||
                                                      ""
                                                    }
                                                    onChange={(event) => {
                                                      setFieldValue(
                                                        `components[${indexI}].componentDescription`,
                                                        event.currentTarget
                                                          .value
                                                      );
                                                    }}
                                                    fullWidth
                                                    multiline
                                                    helperText={
                                                      errors?.components &&
                                                      errors?.components[indexI]
                                                        ?.componentDescription
                                                        ? errors.components[
                                                            indexI
                                                          ].componentDescription
                                                        : ""
                                                    }
                                                    error={
                                                      errors?.components &&
                                                      errors?.components[indexI]
                                                        ?.componentDescription &&
                                                      Boolean(
                                                        errors.components[
                                                          indexI
                                                        ].componentDescription
                                                      )
                                                    }
                                                    autoComplete="none"
                                                    variant="outlined"
                                                    className={`${classes.multilineInput}`}
                                                    inputProps={{
                                                      maxLength: 250,
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell align="right">
                                                  {CurrencyFormat(
                                                    i.totalComponentAmountApproved ||
                                                      0
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  <ComponentOwnerField
                                                    id={`components[${indexI}].piOrComponentOwner`}
                                                    value={i.piOrComponentOwner}
                                                    onChange={(
                                                      _event,
                                                      value
                                                    ) => {
                                                      setFieldValue(
                                                        `components[${indexI}].piOrComponentOwner`,
                                                        value
                                                      );
                                                    }}
                                                    setFieldError={
                                                      setFieldError
                                                    }
                                                    className={classes.disabled}
                                                    size="small"
                                                    labelText=""
                                                    helperText={
                                                      errors?.components &&
                                                      errors?.components[indexI]
                                                        ?.piOrComponentOwner
                                                        ? errors.components[
                                                            indexI
                                                          ].piOrComponentOwner
                                                        : ""
                                                    }
                                                    error={
                                                      errors?.components &&
                                                      errors?.components[indexI]
                                                        ?.piOrComponentOwner &&
                                                      Boolean(
                                                        errors.components[
                                                          indexI
                                                        ].piOrComponentOwner
                                                      )
                                                    }
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <ComponentStartDateField
                                                    id={`components[${indexI}].startDate`}
                                                    value={i?.startDate}
                                                    expirationDate={
                                                      i?.expirationDate
                                                    }
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `components[${indexI}].startDate`,
                                                        value
                                                      );
                                                    }}
                                                    size="small"
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  <ComponentExpirationDateField
                                                    id={`components[${indexI}].expirationDate`}
                                                    value={i?.expirationDate}
                                                    startDate={i?.startDate}
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        `components[${indexI}].expirationDate`,
                                                        value
                                                      );
                                                    }}
                                                    size="small"
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          )}
                                        </Fragment>
                                      )
                                    );
                                  }}
                                </FieldArray>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    xs={7}
                    spacing={2}
                  >
                    <FormButton
                      cancel={handleClose}
                      save={{
                        disabled: !dirty || !isValid,
                      }}
                    />
                  </Grid>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
