import React from "react";
import {
  IconButton,
  OutlinedInput,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  NewReleases as NewReleasesIcon,
} from "@mui/icons-material";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { useStyles } from "./Requests.styles";
import { patchCommitmentRequest } from "../../services/requestsService";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const CommitmentRequestTableRowForm = ({
  commitment,
  setLoading,
  pushSnackbarMessage,
  formData,
  setFormData,
  setRefresh,
  setInlineEditRow,
  isDrawerOpen,
  setDrawerOpen,
  setOpenCommitmentId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onBeforeSend = () => {
    setLoading(true);
  };

  const onError = (error) => {
    if (!axios.isCancel(error)) {
      setLoading(false);
      pushSnackbarMessage("error", error.message);
    }
  };
  const onSuccess = (values) => {
    setLoading(false);
    pushSnackbarMessage(
      "success",
      t("Commitments.create.notification.update", {
        commitmentName: `"${commitment.name.trim()}"`,
      })
    );
    setFormData(values);
    setRefresh((value) => value + 1);
    setInlineEditRow(false);
  };

  const saveComponent = (values) => {
    const cancelSource = axios.CancelToken.source();
    patchCommitmentRequest(
      values,
      commitment.id,
      onBeforeSend,
      onError,
      onSuccess,
      cancelSource
    );
  };

  // Cancel out of inline editing with the ESC key
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setInlineEditRow(false);
      }
    };
    window.addEventListener("keydown", handleEsc, false);
    return () => {
      window.removeEventListener("keydown", handleEsc, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Formik initialValues={formData} enableReinitialize={true}>
      {(formikProps) => {
        const { values, setFieldValue, dirty, isValid } = formikProps;
        return (
          <>
            {/* Drawer Buttons */}
            <TableCell className="drawerHandleCell">
              {commitment?.components?.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setDrawerOpen(!isDrawerOpen);
                    setOpenCommitmentId(commitment.id);
                  }}
                >
                  {!isDrawerOpen && <KeyboardArrowDownIcon />}
                  {isDrawerOpen && <KeyboardArrowUpIcon />}
                </IconButton>
              )}
            </TableCell>

            {/* Departments */}
            <TableCell align="left" className="contentCell">
              {commitment.departmentName && (
                <Tooltip title={commitment.departmentName} placement="left">
                  <Typography noWrap={true} variant="body2">
                    {commitment.departmentName}
                  </Typography>
                </Tooltip>
              )}
            </TableCell>
            {/* Commitment Name */}
            <TableCell align="left" className="contentCell">
              <Typography noWrap={true} variant="body2">
                {commitment.commitmentType === "REQUEST" && (
                  <Tooltip title={t(`Budgeting.mainView.newRequest`)}>
                    <NewReleasesIcon size="small" className={classes.yellow} />
                  </Tooltip>
                )}
                <Tooltip title={commitment.name} placement="left">
                  <span>{commitment.name}</span>
                </Tooltip>
              </Typography>
            </TableCell>

            {/* Amounts */}
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.totalCommitmentAmountRequested)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.totalCommitmentAmountApproved)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.fyProjectionOne)}
            </TableCell>
            <TableCell align="right" className="contentCell">
              {CurrencyFormat(commitment.fyBudget)}
            </TableCell>

            {/* Note Columns */}
            <TableCell
              align="left"
              classes={{ root: classes.childCellSecondary }}
            >
              <OutlinedInput
                id="controllerNotes"
                multiline
                fullWidth
                classes={{
                  multiline: classes.multilineInput,
                }}
                value={values?.controllerNotes}
                onChange={(event) => {
                  setFieldValue("controllerNotes", event.target.value);
                }}
                inputProps={{
                  maxLength: 1000,
                }}
                autoFocus
              />
            </TableCell>
            <TableCell
              align="left"
              classes={{ root: classes.childCellSecondary }}
            >
              <OutlinedInput
                id="controllerComments"
                multiline
                fullWidth
                classes={{
                  multiline: classes.multilineInput,
                }}
                value={values?.controllerComments}
                onChange={(event) => {
                  setFieldValue("controllerComments", event.target.value);
                }}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </TableCell>

            {/* Actions */}
            <TableCell align="left" className="contentCell">
              <IconButton
                size="small"
                onClick={() => {
                  if (dirty && isValid) {
                    saveComponent(values);
                  }
                }}
              >
                <CheckIcon size="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setInlineEditRow(false);
                }}
              >
                <CloseIcon size="small" />
              </IconButton>
            </TableCell>
          </>
        );
      }}
    </Formik>
  );
};
