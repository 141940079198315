import makeStyles from "@mui/styles/makeStyles";

// useStyles - Contains styles for variaous parts of User Interface
export const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: 0,
    color: theme.palette.text.primary,
  },

  documentAttachedIcon: {
    verticalAlign: "sub",
  },
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
      width: "calc(100% - 32px)",
    },
  },
  mainHeading: {
    padding: "8px 0",
  },
  formContainer: {
    "&.MuiGrid-root > .MuiGrid-item": {
      padding: "8px 0",
      maxWidth: "83.333333%",
    },
  },
  disabled: {
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: theme.palette.text.primary,
      "-webkit-text-fill-color": "inherit",
    },
  },
  backButtonPadding: { padding: 0 },
}));
