import React from "react";
import { TextField, FormControl, Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ResetFilter } from "../../components/Layout/FilterPanel/ResetFilter";
import { getTaskByMatch, getPtaByMatch } from "../../services/ptasService";
import { useHistory } from "react-router-dom";
import { usePtaContext } from "../../services/ptasContext";

export const PTAsFilter = ({ changeFilterData, filterData, setFilterData }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);

  // useHistory hook from "react-router-dom"
  const history = useHistory();

  const { ptaFilters, setPtaFilters, pagination, setPagination } =
    usePtaContext();

  // State variable and its function for Project Number
  const [project, setProject] = React.useState(ptaFilters.project);
  // State variable and its function Project Name
  const [projectName, setProjectName] = React.useState(ptaFilters.projectName);
  // State variable and its function Task Number
  const [task, setTask] = React.useState(
    ptaFilters.project ? ptaFilters.task : null
  );
  // State variable and its function Award Number
  const [award, setAward] = React.useState(ptaFilters.award);
  // State variable and its function Task Number
  const [taskOwner, setTaskOwner] = React.useState(ptaFilters.taskOwner);
  // State variable and its function Project Owner
  const [projectOwner, setProjectOwner] = React.useState(
    ptaFilters.projectOwner
  );
  /**
   * State variable and its function disabling/enabling
   * Task Number Autocomplete box
   * */

  const [disableTaskNumber, setDisableTaskNumber] = React.useState(
    ptaFilters.task || ptaFilters.project ? false : true
  );

  const [ptaList, setPtaList] = React.useState([]);

  const [taskList, setTaskList] = React.useState([]);

  // Function to reset the Filter value and re-load PTAs data accordingly
  const resetFn = () => {
    setProject("");
    setProjectName("");
    setTask("");
    setAward("");
    setTaskOwner("");
    setProjectOwner("");
    setDisableTaskNumber(true);
    setPtaList([]);
    setTaskList([]);
    setPtaFilters({
      project: "",
      task: "",
      award: "",
      projectName: "",
      projectOwner: "",
      taskOwner: "",
    });
  };

  const [ptaError, setPtaError] = React.useState({
    matchOn: null,
    exist: false,
    message: null,
  });

  const [taskError, setTaskError] = React.useState({
    exist: false,
    message: null,
  });

  // React.useEffect to act on the change of Filter Data variables.
  React.useEffect(() => {
    const params = {
      project,
      task,
      award,
      projectName,
      projectOwner,
      taskOwner,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
    history.push(`${history.location.pathname}?${queryParams}`);
  }, [project, task, award, projectName, projectOwner, taskOwner, history]);

  return (
    <>
      <FormControl variant="outlined">
        <Autocomplete
          id="projectFilters"
          options={ptaList || []}
          getOptionLabel={(option) => option}
          value={project}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * 2. Loading the Task list of corresponding Project Number.
           * 3. Enabling the Task Number list after calling up the
           * function for loading up the data for Task list.
           * */
          onChange={(e, value) => {
            const project = value;
            setProject(project);
            setPtaFilters({
              ...ptaFilters,
              project,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
            setDisableTaskNumber(false);
          }}
          onOpen={(event) => {
            setPtaList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.project.label")}
              variant="outlined"
              onChange={(e, value) => {
                getPtaByMatch(
                  { matchOn: "projectNumber" },
                  e.target.value,
                  setPtaList,
                  setLoading,
                  setPtaError
                );

                if (e.target.value === "" && task === null) {
                  setDisableTaskNumber(true);
                }
              }}
              error={ptaError.exist && ptaError.matchOn === "projectNumber"}
              helperText={
                ptaError.matchOn === "projectNumber" && ptaError.message
              }
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="taskFilters"
          options={taskList || []}
          getOptionLabel={(option) => option}
          value={task}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const task = value;
            setTask(task);
            setPtaFilters({
              ...ptaFilters,
              task,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          onOpen={(event) => {
            setTaskList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          // Disabling/Enabling the Task Number Autocomplete
          disabled={disableTaskNumber}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.task.label")}
              variant="outlined"
              onChange={(e, value) => {
                getTaskByMatch(
                  e.target.value,
                  project,
                  setTaskList,
                  setLoading,
                  setTaskError
                );

                if (e.target.value === "" && project === null)
                  setDisableTaskNumber(true);
              }}
              error={taskError.exist}
              helperText={taskError.message}
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="awardFilters"
          options={ptaList || []}
          getOptionLabel={(option) => option}
          value={award}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const award = value;
            setAward(award);
            setPtaFilters({
              ...ptaFilters,
              award,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          onOpen={(event) => {
            setPtaList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.award.label")}
              variant="outlined"
              onChange={(e, value) => {
                getPtaByMatch(
                  { matchOn: "awardNumber" },
                  e.target.value,
                  setPtaList,
                  setLoading,
                  setPtaError
                );
              }}
              error={ptaError.exist && ptaError.matchOn === "awardNumber"}
              helperText={
                ptaError.matchOn === "awardNumber" && ptaError.message
              }
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="projectNameFilters"
          options={ptaList || []}
          getOptionLabel={(option) => option}
          value={projectName}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const projectName = value;
            setProjectName(projectName);
            setPtaFilters({
              ...ptaFilters,
              projectName,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          onOpen={(event) => {
            setPtaList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.projectName.label")}
              variant="outlined"
              onChange={(e, value) => {
                getPtaByMatch(
                  { matchOn: "projectName" },
                  e.target.value,
                  setPtaList,
                  setLoading,
                  setPtaError
                );
              }}
              error={ptaError.exist && ptaError.matchOn === "projectName"}
              helperText={
                ptaError.matchOn === "projectName" && ptaError.message
              }
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="projectOwnerFilters"
          options={ptaList || []}
          getOptionLabel={(option) => option}
          value={projectOwner}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const projectOwner = value;
            setProjectOwner(projectOwner);
            setPtaFilters({
              ...ptaFilters,
              projectOwner,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          onOpen={(event) => {
            setPtaList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.projectOwner.label")}
              variant="outlined"
              onChange={(e, value) => {
                getPtaByMatch(
                  { matchOn: "projectOwnerFullName" },
                  e.target.value,
                  setPtaList,
                  setLoading,
                  setPtaError
                );
              }}
              error={
                ptaError.exist && ptaError.matchOn === "projectOwnerFullName"
              }
              helperText={
                ptaError.matchOn === "projectOwnerFullName" && ptaError.message
              }
            />
          )}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Autocomplete
          id="taskOwnerFilters"
          options={ptaList || []}
          getOptionLabel={(option) => option}
          value={taskOwner}
          clearOnEscape={true}
          loading={loading}
          /**
           * On change event for Autocomplete
           *
           * 1. Setting up the values for Filter via React.useState variable.
           * */
          onChange={(e, value) => {
            const taskOwner = value;
            setTaskOwner(taskOwner);
            setPtaFilters({
              ...ptaFilters,
              taskOwner,
            });
            setPagination({
              ...pagination,
              page: 0,
            });
          }}
          onOpen={(event) => {
            setPtaList([]);
          }}
          clearIcon={false}
          blurOnSelect={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("PTAs.filterPanelItems.options.taskOwner.label")}
              variant="outlined"
              onChange={(e, value) => {
                getPtaByMatch(
                  { matchOn: "taskOwnerFullName" },
                  e.target.value,
                  setPtaList,
                  setLoading,
                  setPtaError
                );
              }}
              error={ptaError.exist && ptaError.matchOn === "taskOwnerFullName"}
              helperText={
                ptaError.matchOn === "taskOwnerFullName" && ptaError.message
              }
            />
          )}
        />
      </FormControl>
      <ResetFilter resetFn={resetFn} />
    </>
  );
};
