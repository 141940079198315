import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
    },
  },
  tableLayout: {
    "&.MuiTable-root": {},
  },
  navigationBar: { padding: "16px 16px" },
  progressBar: {
    textAlign: "center",
  },
  buttonPadding: { padding: 0 },
});
