import axios from "axios";

export const getDepartmentByMatch = async (
  showAllCommitmentType,
  setOrgs,
  setLoading,
  setOrgByCodeError,
  cancelSource
) => {
  setLoading(true);
  const params = {
    matchOn: "org",
    showAllCommitmentType,
  };
  try {
    setOrgByCodeError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/commitments/autocomplete`,
      cancelToken: cancelSource?.token,
      params,
    });
    if (response.data.values) {
      setOrgs(response.data.values || []);
    } else {
      setOrgs([]);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      setOrgByCodeError({
        exist: true,
        message: error.message,
      });
    }
  } finally {
    setLoading(false);
  }
};
