import React from "react";
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { DepartmentNameControl } from "./DepartmentNameControl";
import { BudgetTable } from "./BudgetTable";
import { PositionedSnackbar } from "../UI/SnackBar/SnackBar";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { useBudgetingContext } from "../../services/budgetingContext";
import { useStyles } from "./Budgeting.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../services/usersService";
import { useDownload } from "../UI/Download";
import axios from "axios";
import qs from "qs";

export const Budgeting = (props) => {
  const [snackbar, setSnackBar] = React.useState(false);
  const { alert } = useAlertContext();

  // context
  const {
    infoDialogOpen,
    budgetFiscalYearResponse,
    budgetType,
    setBudgetType,
    getQueryParams,
    loading,
  } = useBudgetingContext();

  const classes = useStyles();
  const { t } = useTranslation();

  const { getDocument, downloadInProgress } = useDownload();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  // XXX: This logic should be replaced with looking at the existance of a edit budget hateoas link
  const isBudgetEditableByCurrentUser = () => {
    const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");
    const isBudgetAdmin = roles.includes("BUDGET_ADMIN");
    if (isCommitmentAdmin) {
      return true;
    } else if (isBudgetAdmin && !!budgetFiscalYearResponse?.openEditStatus) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.container}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={6} container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <DepartmentNameControl />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              component="fieldset"
              className={classes.commitmentTypeFilter}
            >
              <FormLabel component="legend">
                {t("Budgeting.mainView.budgetTypeFilter.label")}
              </FormLabel>
              <RadioGroup
                row
                aria-label={t("Budgeting.mainView.budgetTypeFilter.label")}
                name="row-radio-buttons-group"
                value={budgetType}
                onChange={(_event, val) => {
                  setBudgetType(val);
                }}
              >
                <FormControlLabel
                  value="ALL"
                  control={<Radio color="primary" />}
                  label={t("Budgeting.mainView.budgetTypeFilter.options.all")}
                />
                <FormControlLabel
                  value="EXISTING"
                  control={<Radio color="primary" />}
                  label={t(
                    "Budgeting.mainView.budgetTypeFilter.options.existing"
                  )}
                />
                <FormControlLabel
                  value="NEW"
                  control={<Radio color="primary" />}
                  label={t("Budgeting.mainView.budgetTypeFilter.options.new")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={
              downloadInProgress ? (
                <CircularProgress size={15} />
              ) : (
                <FileDownloadIcon />
              )
            }
            disabled={!!downloadInProgress || loading}
            onClick={() => {
              getDocument(
                `${axios.defaults.baseURL}/budgets/excel?${qs.stringify(
                  getQueryParams()
                )}`,
                "budget_table_export.xlsx"
              );
            }}
          >
            Export
          </Button>
        </Grid>

        {snackbar ? (
          <PositionedSnackbar
            message={t("Budgeting.mainView.list.budgetUpdated")}
          />
        ) : null}

        {!infoDialogOpen && alert.exists && (
          <Grid item xs={12}>
            <ASAlert />
          </Grid>
        )}

        <Grid item xs={12}>
          <BudgetTable
            setSnackBar={setSnackBar}
            isBudgetEditableByCurrentUser={isBudgetEditableByCurrentUser}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

const getLastTwoDigits = (int) => parseInt(int, 10) % 100;

/**
 * @returns {string} the `FY{-3} Actuals` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getThreeYearsAgoActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsThree`, {
    fyActualsThree: fiscalYear && getLastTwoDigits(fiscalYear - 3),
  });
};
/**
 * @returns {string} the `FY{-2} Actuals` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearBeforeLastActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsTwo`, {
    fyActualsTwo: fiscalYear && getLastTwoDigits(fiscalYear - 2),
  });
};

/**
 * @returns {string} the `FY{-1} Actuals thru Feb` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearActualsHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyActualsOne`, {
    fyActualsOne: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};
/**
 * @returns {string} the `FY{-1} Budget` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearBudgetHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetOne`, {
    fyBudgetOne: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};
/**
 * @returns {string} the `FY{-1} Projection` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getLastYearProjectionHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyProjection`, {
    fyProjection: fiscalYear && getLastTwoDigits(fiscalYear - 1),
  });
};

/**
 * @returns {string} the `FY{XX} Budget` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getCurrentYearBudgetHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetTwo`, {
    fyBudgetTwo: fiscalYear && getLastTwoDigits(fiscalYear),
  });
};
/**
 * @returns {string} the `FY{xx} BudgetCap` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getCurrentYearBudgetCapHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyBudgetCap`, {
    fyBudgetCap: fiscalYear && getLastTwoDigits(fiscalYear),
  });
};

/**
 * @returns {string} the `FY{+1} Plan` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getNextYearPlanHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyPlanOne`, {
    fyPlanOne: fiscalYear && getLastTwoDigits(fiscalYear + 1),
  });
};
/**
 * @returns {string} the `FY{-+2} Plan` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearAfterNextPlanHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.fyPlanTwo`, {
    fyPlanTwo: fiscalYear && getLastTwoDigits(fiscalYear + 2),
  });
};
/**
 * @returns {string} the `FY{+2} Available After` heading
 * @param {function} t - translation function
 * @param {number} fiscalYear - current fiscal year
 */
export const getYearAfterNextAvailableHeading = (t, fiscalYear) => {
  return t(`Budgeting.mainView.list.tableColumns.availableAfter`, {
    availableAfter: fiscalYear && getLastTwoDigits(fiscalYear + 2),
  });
};
