import React from "react";
import { IconButton, Typography } from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { FilterPanelStyle, DrawerStyle } from "./FilterPanel.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../../services/usersService";
import { useHistory } from "react-router-dom";

export const FilterPanel = ({
  open,
  handleDrawerToggle,
  location,
  changeFilterData,
  children,
}) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();
  // useHistory hook from "react-router-dom"
  const history = useHistory();
  // For fetching up users info which will be used to check for User Access Control
  const { currentUser } = useUsers();

  const [filterData, setFilterData] = React.useState({});

  return currentUser.permissions.VIEW_PTAS ||
    currentUser.permissions.VIEW_COMMITMENTS ||
    currentUser.permissions.VIEW_TRANSFER ? (
    <FilterPanelStyle open={open}>
      {/* Icon button styles will remain common in all the filter sections. */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        size="large"
      >
        <ChevronRightIcon fontSize="small" />
      </IconButton>

      {/* Drawer Style - Style Wrapper for FilterPanel section. */}
      <DrawerStyle
        variant={"persistent"}
        open={open}
        anchor={"left"}
        className={!open ? "drawer-root-close" : ""}
        mobile={0}
      >
        <div className="filterOptions">
          {/* PaperToolbar will remain common across all the filter sections. */}
          <div className="PaperToolbar">
            <Typography variant="subtitle1">
              {t("PTAs.filterPanelItems.title")}
            </Typography>
            <IconButton onClick={handleDrawerToggle} size="large">
              <ChevronLeftIcon fontSize="small" />
            </IconButton>
          </div>

          {/* Component Filters come here ... */}
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              location,
              history,
              changeFilterData,
              filterData,
              setFilterData,
            });
          })}
        </div>
      </DrawerStyle>
    </FilterPanelStyle>
  ) : (
    ""
  );
};
