import React from "react";
import { ASNavbar } from "@stanford-tds/as-components";
import { navbar } from "../../config";
import { useUsers } from "../../services/usersService";
import { useHistory, useLocation } from "react-router-dom";

export const NavbarWrapper = () => {
  const { currentUser } = useUsers();
  // useHistory hooks from "react-router-dom"
  const history = useHistory();
  // useLocation hooks from "react-router-dom"
  const location = useLocation();
  const route =
    location.pathname === "/"
      ? "/commitments"
      : location.pathname + location.search;
  const [navbarConfig, setNavbarConfig] = React.useState({
    route,
    navbarItems: navbar,
  });
  React.useEffect(() => {
    if (Object.keys(currentUser.permissions).length > 0) {
      let navbarItems = {};
      navbarItems.width = navbar.width;
      navbarItems.items = [];

      // Assigning permission, index wise as of now
      if (currentUser.permissions.VIEW_COMMITMENTS) {
        navbarItems.items.push(navbar.items[0]); // Commitments
      }
      if (currentUser.permissions.VIEW_EXPENSES) {
        navbarItems.items.push(navbar.items[1]); // Expenses
      }
      if (currentUser.permissions.VIEW_TRANSFER) {
        navbarItems.items.push(navbar.items[2]); // Transfers
      }
      if (currentUser.permissions.VIEW_BUDGET) {
        navbarItems.items.push(navbar.items[3]); // Budgeting
      }
      if (currentUser.permissions.VIEW_BUDGET) {
        navbarItems.items.push(navbar.items[4]); // Requests
      }
      if (currentUser.permissions.VIEW_PTAS) {
        navbarItems.items.push(navbar.items[5]); // PTAs
      }

      setNavbarConfig((state) => {
        return {
          route: state.route,
          navbarItems,
        };
      });
    }
  }, [currentUser]);

  return (
    navbarConfig.navbarItems.items.length > 0 && (
      <ASNavbar
        menuItems={navbarConfig.navbarItems.items}
        width={navbarConfig.navbarItems.width}
        navbarConfig={navbarConfig}
        history={history}
      />
    )
  );
};
