import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell, Tooltip } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { useStyles } from "./Budgeting.styles";
import {
  getThreeYearsAgoActualsHeading,
  getYearBeforeLastActualsHeading,
  getLastYearActualsHeading,
  getLastYearBudgetHeading,
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getCurrentYearBudgetCapHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
  getYearAfterNextAvailableHeading,
} from "./Budgeting";

export const BudgetTableHeader = ({ budgetFiscalYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonTranslationStr = "Budgeting.mainView.list.tableColumns";
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="commitmentName" width="280">
          {t(`${commonTranslationStr}.commitmentName`)}
        </TableCell>
        <TableCell align="right" key="totalCommitment" width="100">
          {t(`${commonTranslationStr}.totalCommitment`)}
        </TableCell>
        <TableCell align="right" key="fyActualsThree" width="100">
          {getThreeYearsAgoActualsHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyActualsTwo" width="100">
          {getYearBeforeLastActualsHeading(t, budgetFiscalYear)}
        </TableCell>

        <TableCell align="right" key="totalExpenditures" width="120">
          {t(`${commonTranslationStr}.totalExpenditures`)}
        </TableCell>

        <TableCell
          align="right"
          key="unspentCommitments"
          width="140"
          className={classes.newLine}
        >
          <Tooltip
            title={t("Budgeting.mainView.list.unspentCommitmentInfoTooltip")}
            placement="bottom"
            classes={{
              tooltip: classes.tooltipStyle,
            }}
          >
            <InfoIcon size="small" style={{ verticalAlign: "bottom" }} />
          </Tooltip>
          {t(`${commonTranslationStr}.unspentCommitmentsAsOf`, {
            unspentCommitmentsAsOfDate: `09/01/${budgetFiscalYear - 2}`,
          })}
        </TableCell>
        <TableCell
          align="right"
          key="fyActualsOne"
          width="120"
          className={classes.newLine}
        >
          {getLastYearActualsHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyBudgetOne" width="100">
          {getLastYearBudgetHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyProjection" width="140">
          {getLastYearProjectionHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyBudgetTwo" width="140">
          {getCurrentYearBudgetHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyPlanOne" width="140">
          {getNextYearPlanHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyPlanTwo" width="140">
          {getYearAfterNextPlanHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="availableAfter" width="100">
          {getYearAfterNextAvailableHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyBudgetCap" width="100">
          {getCurrentYearBudgetCapHeading(t, budgetFiscalYear)}
        </TableCell>
        <TableCell align="left" key="comments" width="186">
          {t(`${commonTranslationStr}.comments`)}
        </TableCell>
        <TableCell align="left" key="actions" width="160">
          {t(`${commonTranslationStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
