import axios from "axios";

export const getAllPtas = async (
  setPtas,
  setPtaCount,
  setLoading,
  setAlert,
  clearAlert,
  {
    page,
    rowsPerPage,
    orderBy,
    order,
    project,
    task,
    award,
    projectName,
    projectOwner,
    taskOwner,
  }
) => {
  const params = {
    p: page + 1,
    project: project,
    task: task,
    award: award,
    projectName: projectName,
    projectOwner: projectOwner,
    taskOwner: taskOwner,
    ps: rowsPerPage,
    s: `${orderBy}:${order}`,
  };
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `/ptas`,
      params,
    });
    setPtas(response.data.values || []);
    setPtaCount(response.data.totalCount);
    setLoading(false);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getPTAById = async (id, setPtaData, setAlert, clearAlert) => {
  try {
    clearAlert();
    const response = await axios(`ptas/${id}`);
    setPtaData(response.data || []);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getPtaByMatch = async (
  { matchOn },
  value,
  setPtaList,
  setLoading,
  setPtaError
) => {
  setLoading(true);
  const params = {
    matchOn: matchOn,
    value,
  };
  try {
    setPtaError({
      matchOn: null,
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/ptas/autocomplete`,
      params,
    });
    setPtaList(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setPtaError({
      matchOn: matchOn,
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getTaskByMatch = async (
  value,
  project,
  setTaskList,
  setLoading,
  setTaskError
) => {
  setLoading(true);
  try {
    setTaskError({
      exist: false,
      message: null,
    });
    const response = await axios(
      `project/${project}/task/autocomplete?value=${value}`
    );
    setTaskList(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setTaskError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};
