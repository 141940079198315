import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Typography,
  Paper,
  Box,
  InputAdornment,
  Tooltip,
  Grid,
  CircularProgress,
  OutlinedInput,
  Button,
} from "@mui/material";
import { FormButton } from "../UI/Button/FormButton";
import { useStyles } from "./RedistributeExpenses.styles";
import { Link as RouterLink } from "react-router-dom";
import {
  getRedistributeExpenses,
  postRedistributeExpenses,
} from "../../services/expensesService";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import NumberFormat from "react-number-format";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

const MonthlyExpenseTableHead = ({ showDetailsView }) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.montlyExpenseDetail";
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="glPeriod" width="28%">
          {showDetailsView &&
            `${t(`${tableColumnsStr}.source`)} \n ${t(
              `${tableColumnsStr}.sourceGlPeriod`
            )}`}
          {!showDetailsView && t(`${tableColumnsStr}.glPeriod`)}
        </TableCell>
        <TableCell align="left" key="pta" width="28%">
          {t(`${tableColumnsStr}.pta`)}
        </TableCell>
        <TableCell align="left" key="expenditureCategory" width="24%">
          {t(`${tableColumnsStr}.expenditureCategory`)}
        </TableCell>
        {showDetailsView && (
          <TableCell align="left" key="redistributedAmount" width="24%">
            {t(
              "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.componentDetail.redistributedAmount"
            )}
          </TableCell>
        )}
        <TableCell align="right" key="mtdAmount" width="20%">
          {t(`${tableColumnsStr}.mtdAmount`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const ComponentTableHead = ({ showDetailsView }) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.componentDetail";
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="commitmentName" width="28%">
          {showDetailsView && `${t(`${tableColumnsStr}.destination`)}\n`}
          {t(`${tableColumnsStr}.commitmentName`)}
        </TableCell>
        <TableCell align="left" key="componentName" width="28%">
          {t(`${tableColumnsStr}.componentName`)}
        </TableCell>
        <TableCell align="left" key="pta" width="24%">
          {t(`${tableColumnsStr}.pta`)}
        </TableCell>
        <TableCell align="left" key="redistributedAmount" width="20%">
          {t(`${tableColumnsStr}.redistributedAmount`)}
        </TableCell>
        {showDetailsView && (
          <TableCell align="right" key="mtdAmount" width="20%">
            {t(
              "UploadedExpenses.mainView.list.redistributeExpensesDialog.tableColumns.montlyExpenseDetail.mtdAmount"
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export const RedistributeExpenses = ({
  openDialog,
  setOpenDialog,
  expenseIdToRedistribute,
  setExpenses,
  expenseIndex,
  showDetailsView,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [redistributeExpenses, setRedistributeExpenses] = React.useState([]);
  const [monthlyExpenseDetails, setMonthlyExpenseDetails] = React.useState([]);
  const [redistributeExpensesCount, setRedistributeExpensesCount] =
    React.useState();
  const [remainingToDistribute, setRemainingToDistribute] = React.useState(0);
  const [mtdAmount, setMTDAmount] = React.useState(0);
  const [saveRedistributeExpenses, setSaveRedistributeExpenses] =
    React.useState([]);
  const [totalDistributeAmount, setTotalDistributeAmount] = React.useState(0);

  const [loading, setLoading] = React.useState(false);
  const { clearAlert, setAlert, alert } = useAlertContext();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const UploadedExpensesStr = "UploadedExpenses.mainView.list";

  const handleSubmit = () => {
    setOpenDialog(false);
    postRedistributeExpenses(
      monthlyExpenseDetails.id,
      saveRedistributeExpenses,
      setExpenses,
      expenseIndex,
      clearAlert,
      setAlert
    );
  };

  const remainingRedistributeAmountcalculation = (
    index,
    redistributedAmount,
    ptaNumber,
    componentName,
    componentId,
    commitmentName,
    commitmentId
  ) => {
    saveRedistributeExpenses[index] =
      redistributedAmount !== ""
        ? {
            ptaNumber: ptaNumber,
            redistributedAmount: redistributedAmount,
            componentName: componentName,
            componentId: componentId,
            commitmentName: commitmentName,
            commitmentId: commitmentId,
          }
        : null;

    const totalAmount = saveRedistributeExpenses
      .filter((key) => key !== null && key.redistributedAmount !== "-")
      .reduce(
        (totalAmount, redistributeExpenses) =>
          totalAmount + parseFloat(redistributeExpenses.redistributedAmount),
        0
      );
    setTotalDistributeAmount(totalAmount);
    setRemainingToDistribute(mtdAmount - totalAmount);
  };

  React.useEffect(() => {
    getRedistributeExpenses(
      setMonthlyExpenseDetails,
      setRedistributeExpenses,
      setRedistributeExpensesCount,
      setSaveRedistributeExpenses,
      setRemainingToDistribute,
      setMTDAmount,
      setLoading,
      clearAlert,
      setAlert,
      {
        expenseId: expenseIdToRedistribute,
      }
    );
    // eslint-disable-next-line
  }, [expenseIdToRedistribute]);
  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        className={classes.dialogMaxWidth}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {!showDetailsView &&
              t(`${UploadedExpensesStr}.redistributeExpensesDialog.title`)}
            {showDetailsView &&
              t(`${UploadedExpensesStr}.redistributedToolTip`)}
          </DialogTitle>

          {alert.exists && <ASAlert />}

          <DialogContent>
            <Grid
              container
              justifyContent="flex-end"
              direction="column"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-label="customized table"
                    stickyHeader
                    className={classes.headerBackgroundColor}
                  >
                    <MonthlyExpenseTableHead
                      showDetailsView={showDetailsView}
                    />
                    <TableBody>
                      {loading && (
                        <TableRow>
                          <TableCell colSpan="4">
                            <Box display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading && monthlyExpenseDetails && (
                        <TableRow key={0}>
                          <TableCell align="left" component="th" scope="row">
                            {monthlyExpenseDetails.glPeriod}
                          </TableCell>
                          <TableCell align="left">
                            {monthlyExpenseDetails.pta && (
                              <RouterLink
                                to={{
                                  pathname:
                                    "/expenses/" +
                                    monthlyExpenseDetails.id +
                                    "/ptas/" +
                                    monthlyExpenseDetails.ptaNumber,
                                }}
                                target={
                                  "blank_" + monthlyExpenseDetails.ptaNumber
                                }
                              >
                                <Typography variant="body2">
                                  {monthlyExpenseDetails.ptaNumber}
                                </Typography>
                              </RouterLink>
                            )}
                            {!monthlyExpenseDetails.pta && (
                              <Typography
                                variant="body2"
                                className={classes.wordBreak}
                              >
                                {!monthlyExpenseDetails.task &&
                                  `${
                                    monthlyExpenseDetails.project
                                  }-${"\u00A0\u00A0\u00A0"}-${
                                    monthlyExpenseDetails.award
                                  }`}
                                {monthlyExpenseDetails.task &&
                                  monthlyExpenseDetails.ptaNumber}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {monthlyExpenseDetails.expenditureCategory}
                          </TableCell>
                          {showDetailsView && (
                            <TableCell align="right"></TableCell>
                          )}
                          <TableCell align="right">
                            {CurrencyFormat(mtdAmount)}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {!showDetailsView && (
                <Grid item xs={12} className={classes.remainingRedistribute}>
                  <Typography align="right">
                    {t(
                      `${UploadedExpensesStr}.redistributeExpensesDialog.remainingToDistribute`
                    )}
                    {": "}
                    {CurrencyFormat(remainingToDistribute)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TableContainer component={Paper} className={classes.maxHeight}>
                  <Table
                    aria-label="customized table"
                    stickyHeader
                    className={classes.headerBackgroundColor}
                    sx={{ tableLayout: "fixed" }}
                  >
                    <ComponentTableHead showDetailsView={showDetailsView} />
                    <TableBody>
                      {loading && (
                        <TableRow>
                          <TableCell colSpan="4">
                            <Box display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        redistributeExpensesCount > 0 &&
                        redistributeExpenses.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left" component="th" scope="row">
                              {row.commitment && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/expenses/" +
                                      monthlyExpenseDetails.id +
                                      "/commitments/" +
                                      row.commitment.id +
                                      "/edit",
                                  }}
                                  target={"blank_" + row.commitment.id}
                                >
                                  <Tooltip title={row.commitment.name}>
                                    <Typography noWrap={true} variant="body2">
                                      {row.commitment.name}
                                    </Typography>
                                  </Tooltip>
                                </RouterLink>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.component && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/expenses/" +
                                      monthlyExpenseDetails.id +
                                      "/commitments/" +
                                      row.commitment.id +
                                      "/components/" +
                                      row.component.id +
                                      "/edit",
                                  }}
                                  target={`${row.commitment.id}${row.component.id}`}
                                >
                                  <Tooltip title={row.component.componentName}>
                                    <Typography noWrap={true} variant="body2">
                                      {row.component.componentName}
                                    </Typography>
                                  </Tooltip>
                                </RouterLink>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.pta && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/expenses/" +
                                      monthlyExpenseDetails.id +
                                      "/ptas/" +
                                      row.ptaNumber,
                                  }}
                                  target={"blank_" + row.ptaNumber}
                                >
                                  <Typography variant="body2">
                                    {row.ptaNumber}
                                  </Typography>
                                </RouterLink>
                              )}
                              {!row.pta && (
                                <Typography
                                  variant="body2"
                                  className={classes.wordBreak}
                                >
                                  {!row.task &&
                                    `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                      row.award
                                    }`}
                                  {row.task && row.ptaNumber}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              {!showDetailsView && (
                                <NumberFormat
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  customInput={OutlinedInput}
                                  variant={"outlined"}
                                  defaultValue={0.0}
                                  isAllowed={(values) => {
                                    const { value } = values;
                                    return (
                                      value === "-" ||
                                      (value >= -999999.99 &&
                                        value <= 999999.99)
                                    );
                                  }}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    remainingRedistributeAmountcalculation(
                                      index,
                                      value,
                                      row.ptaNumber,
                                      row.component.componentName,
                                      row.component.id,
                                      row.commitment.name,
                                      row.commitment.id
                                    );
                                  }}
                                  fixedDecimalScale={true}
                                  autoComplete="off"
                                  allowNegative={true}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                  className={classes.inputPadding}
                                />
                              )}
                            </TableCell>
                            {showDetailsView && (
                              <TableCell align="right"></TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {!loading && redistributeExpensesCount === 0 && (
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.noComponentsAttached}
                    >
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t(
                          `${UploadedExpensesStr}.redistributeExpensesDialog.noComponentsMessage`
                        )}
                      </Typography>
                    </Grid>
                  )}
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {!showDetailsView && (
              <Grid container justifyContent="flex-end" xs={3} spacing={2}>
                <FormButton
                  cancel={handleClose}
                  save={{
                    disabled:
                      parseFloat(mtdAmount) !==
                      parseFloat(totalDistributeAmount),
                  }}
                />
              </Grid>
            )}
            {showDetailsView && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleClose}
              >
                {t(
                  `${UploadedExpensesStr}.redistributeExpensesDialog.closebutton.label`
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
