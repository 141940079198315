import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
    },
  },
  collapseWidth: {
    width: "48%",
  },
  tableLayout: {
    "&.MuiTable-root": {
      tableLayout: "fixed",
    },
  },
  expandableRow: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiTableCell-head": {
      color: theme.palette.grey[700],
      fontSize: 13,
      fontWeight: 600,
    },
    "& .MuiTableCell-body": {
      borderBottom: "unset",
      fontSize: 13,
      fontWeight: 500,
    },
  },
  gridFlexBasis: { flexBasis: "12.5%" },
  backButtonPadding: { padding: 0 },
  manualEntryPopoverWidth: { width: "400px" },
  listItemPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
