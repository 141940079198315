import React from "react";
import { TableHead, TableRow, TableCell, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./FinancialHistoryFiscalYearTableHead.styles";

export const FinancialHistoryFiscalYearTableHead = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const tableColumnsStr =
    "Commitments.financialHistory.mainView.list.fiscalYearTable.tableColumns";

  return (
    <TableHead>
      <TableRow className={classes.borderBottom}>
        <TableCell />
        <TableCell />
        <TableCell align="center" key="actuals" colSpan={4}>
          <Box borderBottom={1} ml={2} height={30}>
            {t(`${tableColumnsStr}.actuals`)}
          </Box>
        </TableCell>

        <TableCell align="center" key="planning" colSpan={4}>
          <Box borderBottom={1} ml={4} height={30}>
            {t(`${tableColumnsStr}.planning`)}
          </Box>
        </TableCell>
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell width="2%"></TableCell>
        <TableCell align="left" key="fiscalYear" width="6%">
          {t(`${tableColumnsStr}.fiscalYear`)}
        </TableCell>
        <TableCell align="right" key="fytdExpenseAmt" width="10%">
          {t(`${tableColumnsStr}.fytdExpenseAmt`)}
        </TableCell>
        <TableCell align="right" key="fytdTransferAmt" width="12%">
          {t(`${tableColumnsStr}.fytdTransferAmt`)}
        </TableCell>
        <TableCell align="right" key="fytdActualAmt" width="12%">
          {t(`${tableColumnsStr}.fytdActualAmt`)}
        </TableCell>
        <TableCell align="right" key="fySpendCap" width="10%">
          {t(`${tableColumnsStr}.fySpendCap`)}
        </TableCell>

        <TableCell align="right" key="yepAmt" width="8%">
          {t(`${tableColumnsStr}.yepAmt`)}
        </TableCell>
        <TableCell align="right" key="budgetAmt" width="8%">
          {t(`${tableColumnsStr}.budgetAmt`)}
        </TableCell>
        <TableCell align="right" key="planAmt" width="8%">
          {t(`${tableColumnsStr}.planAmt`)}
        </TableCell>
        <TableCell align="right" key="lrff" width="8%">
          {t(`${tableColumnsStr}.lrff`)}
        </TableCell>
        <TableCell align="center" key="actions" width="5%">
          {t(`${tableColumnsStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
