import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from "@mui/material";
import { useStyles } from "./NotificationStatus.styles";
import { ExpensesNotification } from "../../constants";
import { Formik } from "formik";
import * as Yup from "yup";
import { patchNotificationStatus } from "../../services/expensesService";
import { useAlertContext } from "@stanford-tds/as-components";

export const NotificationStatus = ({
  openDialog,
  setOpenDialog,
  statusToChange,
  expenseId,
  setExpenses,
  expenseIndex,
}) => {
  const [open, setOpen] = React.useState(openDialog);
  const [, setLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlertContext();
  const notificationDialogStr =
    "UploadedExpenses.mainView.list.notificationStatusDialog";
  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false);
  };
  const getDefaultValue = (statusToChange) => {
    switch (statusToChange) {
      case "UNRESOLVED": {
        return t(ExpensesNotification["INPROCESS"]);
      }
      case "INPROCESS": {
        return t(ExpensesNotification["RESOLVED"]);
      }
      default:
        return "";
    }
  };

  // Controllable State for Notification Status
  const [notificationStatus, setNotificationStatus] = React.useState(
    getDefaultValue(statusToChange)
  );

  const getNotificationOptions = (statusToChange) => {
    switch (statusToChange) {
      case "UNRESOLVED": {
        return [
          t(ExpensesNotification["INPROCESS"]),
          t(ExpensesNotification["RESOLVED"]),
        ];
      }
      case "INPROCESS": {
        return [t(ExpensesNotification["RESOLVED"])];
      }
      default:
        return [];
    }
  };

  const updateNotificationStatus = (id, values) => {
    patchNotificationStatus(
      id,
      values,
      setLoading,
      setOpenDialog,
      setExpenses,
      expenseIndex,
      clearAlert,
      setAlert
    );
  };

  const formData = {
    notificationStatus,
    notes: "",
  };
  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);
  return (
    <Formik
      initialValues={formData}
      validationSchema={notificationStatusValidation}
    >
      {(props) => {
        const {
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Dialog open={open} fullWidth={true} maxWidth="xs">
              <DialogTitle>{t(`${notificationDialogStr}.title`)}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} paddingTop={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="notificationStatus"
                      options={getNotificationOptions(statusToChange)}
                      getOptionLabel={(option) => option}
                      value={
                        notificationStatus || getDefaultValue(statusToChange)
                      }
                      onChange={(event, value) => {
                        setNotificationStatus(value);
                        setFieldValue("notificationStatus", value);
                      }}
                      clearIcon={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            `${notificationDialogStr}.fields.notificationStatus.label`
                          )}`}
                          variant={"outlined"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="notes"
                      label={`${t(
                        `${notificationDialogStr}.fields.notes.label`
                      )}`}
                      onChange={handleChange}
                      multiline
                      variant={"outlined"}
                      autoComplete="off"
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      required={true}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                classes={{
                  root: classes.actionDialogRoot,
                }}
              >
                <Button onClick={handleClose} color="primary">
                  {t(`${notificationDialogStr}.actionButtons.dontChange.label`)}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    updateNotificationStatus(expenseId, values);
                  }}
                  disabled={!dirty || !isValid}
                >
                  {t(`${notificationDialogStr}.actionButtons.change.label`)}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        );
      }}
    </Formik>
  );
};

const notificationStatusValidation = Yup.object().shape({
  notificationStatus: Yup.string().required(),
  notes: Yup.string().required(),
});
