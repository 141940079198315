import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useStyles } from "./UploadTransfers.styles";
import { useTranslation } from "react-i18next";
import { postAdvanceGLPeriod } from "../../../services/transfersService";
import { useAlertContext } from "@stanford-tds/as-components";

export const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlertContext();
  const commonTranslationString =
    "UploadedTransfers.uploadTransfersDialog.advanceGLPeriod";

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      classes={{
        paperFullWidth: classes.confirmationDialogWidth,
      }}
    >
      <DialogTitle
        classes={{
          root: classes.title,
        }}
      >
        {t(`${commonTranslationString}.confirmationDialog.title`)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Typography color={"textSecondary"}>
              {t(`${commonTranslationString}.confirmationDialog.description`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color={"textSecondary"}>
              {t(
                `${commonTranslationString}.confirmationDialog.confirmationText`,
                {
                  glPeriod: props.advanceGLPeriod,
                }
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {t(`${commonTranslationString}.actionButtons.no`)}
        </Button>
        <Button
          color="primary"
          onClick={() =>
            postAdvanceGLPeriod(
              props.advanceGLPeriod,
              props.setShowConfirmationDialog,
              props.setOpenUploadTransfersDialog,
              props.history,
              clearAlert,
              setAlert
            )
          }
        >
          {t(`${commonTranslationString}.actionButtons.yes`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
