import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useStyles } from "./ASConfirmation.styles";

// this is ASConfirmation dialog
export const ASConfirmation = (props) => {
  const { open, title, message, okLabel, cancelLabel, handleCancel, handleOk } =
    props;
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle id="alert-dialog-title">{title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.newLine}
        >
          {message || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {cancelLabel ? cancelLabel : "CANCEL"}
        </Button>
        <Button onClick={handleOk} color="primary">
          {okLabel ? okLabel : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
