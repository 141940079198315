import React from "react";
import { useTranslation } from "react-i18next";
import { useUsers } from "../services/usersService";
import {
  ASHeader,
  ASMain,
  ASFooter,
  ASAlert,
  useAlertContext,
} from "@stanford-tds/as-components";
import { RoutesMapping } from "../Routes/Routes";
import { NavbarWrapper } from "../components/Wrapper/NavbarWrapper";

// src/components/AppPage.js
// AppPage is the top-level component that can be customized in your single-page application.
const AppPage = () => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { alert } = useAlertContext();

  return (
    <>
      <ASHeader
        sitenameTitle={t("app.title")}
        sitenameSubtitle={t("app.subtitle")}
        displayName={currentUser.displayName}
      />
      <ASMain>
        <NavbarWrapper />
        {currentUser.isLoaded && <RoutesMapping />}
        {!currentUser.isLoaded && alert.exists && <ASAlert />}
      </ASMain>
      <ASFooter
        currentYear={t("app.year")}
        buildVersion={`${process.env.REACT_APP_VERSION}`}
      />
    </>
  );
};

export default AppPage;
