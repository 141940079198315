import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { getAllPrograms } from "../../../services/commitmentsService";
import axios from "axios";

export const CommitmentProgramField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Programs
  const [programs, setPrograms] = React.useState([]);

  const [programError, setProgramError] = React.useState({
    exist: false,
    message: null,
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPrograms(setPrograms, setProgramError, cancelSource);
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {programs && (
        <Autocomplete
          options={programs}
          getOptionLabel={(option) => option && option.name}
          autoHighlight={true}
          disableClearable={true}
          handleHomeEndKeys={true}
          openOnFocus={true}
          fullWidth={true}
          clearIcon={false}
          required={required}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(`Commitments.create.form.fields.program.label`)}
              variant={"outlined"}
              required={required}
              error={programError.exist || error}
              helperText={programError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
