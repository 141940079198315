import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FinancialHistoryGLPeriodTableHead = () => {
  const { t } = useTranslation();
  const tableColumnsStr =
    "Commitments.financialHistory.mainView.list.glPeriodTable.tableColumns.";
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="glPeriod" width="20%">
          {t(`${tableColumnsStr}glPeriod`)}
        </TableCell>
        <TableCell align="right" key="mtdExpenses" width="24%">
          {t(`${tableColumnsStr}mtdExpenses`)}
        </TableCell>
        <TableCell align="right" key="mtdTransfers" width="30%">
          {t(`${tableColumnsStr}mtdTransfers`)}
        </TableCell>
        <TableCell align="right" key="mtdActuals">
          {t(`${tableColumnsStr}mtdActuals`)}
        </TableCell>
        <TableCell key="manualEntryDisplay"></TableCell>
      </TableRow>
    </TableHead>
  );
};
