import axios from "axios";
import { format } from "date-fns";

export const getBudget = async (
  getQueryParams,
  setBudget,
  setLoading,
  setAlert,
  clearAlert,
  cancelSource
) => {
  try {
    setLoading(true);
    const response = await axios({
      url: `budgets`,
      cancelToken: cancelSource?.token,
      params: getQueryParams(),
    });
    setBudget(response.data);
    setLoading(false);
  } catch (error) {
    if (!axios.isCancel(error)) {
      clearAlert();
      setAlert("error", error.message);
      setBudget([]);
    }
  }
};

export const getBudgetByComponentId = async (
  componentId,
  budgetFiscalYear,
  setAlert,
  clearAlert,
  setFormData,
  cancelSource
) => {
  try {
    clearAlert();
    const response = await axios({
      url: `budgets/components/${componentId}/budget-fiscal-year/${budgetFiscalYear}`,
      cancelToken: cancelSource?.token,
    });
    const values = response.data;
    setFormData({
      fyProjectionOne: values.fyProjectionOne,
      fyBudget: values.fyBudget,
      fyPlanOne: values.fyPlanOne,
      fyPlanTwo: values.fyPlanTwo,
      comments: values.comments,
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  }
};

export const putBudgetByComponentId = async (
  values,
  componentId,
  budgetFiscalYear,
  setEditComponent,
  setSnackBar,
  setRefresh,
  setInfoPanelDisabled,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    await axios.put(
      `/budgets/components/${componentId}/budget-fiscal-year/${budgetFiscalYear}`,
      values
    );
    setEditComponent(0);
    setRefresh((value) => value + 1);
    setSnackBar(true);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setInfoPanelDisabled(false);
  }
};

export const postBudgetFiscalYear = async ({
  newBudgetFiscalYear,
  budgetFiscalYearValues: formValues,
  setRefreshInformationPanel,
  setOpenDialog,
  setInfoDialogOpen,
  setAlert,
  clearAlert,
}) => {
  try {
    clearAlert();
    const values = {
      budgetFiscalYear: newBudgetFiscalYear,
      initialEditEndDate: toFormatedDate(formValues.initialEditPeriodEndDate),
      initialEditStartDate: toFormatedDate(
        formValues.initialEditPeriodStartDate
      ),
      revisionEditEndDate: toFormatedDate(formValues.revisionEditPeriodEndDate),
      revisionEditStartDate: toFormatedDate(
        formValues.revisionEditPeriodStartDate
      ),
    };

    await axios({
      method: "post",
      url: `/budgets/budget-fiscal-years`,
      data: values,
    });
    setInfoDialogOpen(false);
    setOpenDialog(false);
    setRefreshInformationPanel(true);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const putBudgetFiscalYear = async (
  budgetFiscalYear,
  values,
  setRefreshInformationPanel,
  setOpenDialog,
  setInfoDialogOpen,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    values = {
      initialEditEndDate: toFormatedDate(values.initialEditPeriodEndDate),
      initialEditStartDate: toFormatedDate(values.initialEditPeriodStartDate),
      revisionEditEndDate: toFormatedDate(values.revisionEditPeriodEndDate),
      revisionEditStartDate: toFormatedDate(values.revisionEditPeriodStartDate),
    };

    await axios({
      method: "put",
      url: `/budgets/budget-fiscal-years/${budgetFiscalYear}`,
      data: values,
    });
    setInfoDialogOpen(false);
    setOpenDialog(false);
    setRefreshInformationPanel(true);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getBudgetFiscalYear = async (
  setBudgetFiscalYearResponse,
  setBudgetFiscalYear,
  setAlert,
  setLoading,
  clearAlert,
  cancelSource
) => {
  try {
    setLoading(true);
    const response = await axios(`/budgets/budget-fiscal-years`, {
      cancelToken: cancelSource?.token,
    });
    setBudgetFiscalYear(response.data.budgetFiscalYear);
    setBudgetFiscalYearResponse(response.data);
    setLoading(false);
  } catch (error) {
    if (!axios.isCancel(error)) {
      clearAlert();
      setAlert("error", error.message);
    }
  }
};

/**
 * Converts a date to the format expected by the API
 * @param {*} date
 * @returns {string} a string in the "MM/dd/yyyy" format
 */
const toFormatedDate = (dt) => {
  if (dt) {
    return format(new Date(dt), "MM/dd/yyyy");
  } else {
    return dt;
  }
};
