import React from "react";
import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { PriceChange as PriceChangeIcon } from "@mui/icons-material";
import { ComponentRequestTableRowForm } from "./ComponentRequestTableRowForm";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { useUsers } from "../../services/usersService";
import { ComponentDispositionTypes } from "../../constants";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";

export const ComponentRequestTableRow = ({
  component,
  commitmentId,
  commitmentName,
  commitmentFinalized,
  budgetFiscalYear,
  inlineEditRow,
  setInlineEditRow,
  pushSnackbarMessage,
  setRefresh,
  setLoading,
}) => {
  const classes = useStyles();
  const { currentUser } = useUsers();
  const { permissions, roles } = currentUser;
  const { t } = useTranslation();

  const [formData, setFormData] = React.useState({
    requestOperation: "DISPOSITION_REQUEST",
    budgetFiscalYear: budgetFiscalYear,
    componentId: component?.componentId,
    componentName: component?.componentName || "",
    componentType: component?.componentType || "REQUEST",
    dispositionComments: component?.dispositionComments || "",
    dispositionStatus: component.dispositionStatus,
    dispositionNotes: component?.dispositionNotes || "",
    fyBudget: component?.fyBudget || 0,
    fyProjectionOne: component?.fyProjectionOne || 0,
    totalComponentAmountRequested:
      component?.totalComponentAmountRequested || 0,
    totalComponentAmountApproved: component?.totalComponentAmountApproved || 0,
  });

  const rowId = `component${component.componentId}`;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <>
      <TableRow>
        {rowId === inlineEditRow ? (
          <ComponentRequestTableRowForm
            component={component}
            componentId={component.componentId}
            commitmentId={commitmentId}
            commitmentName={commitmentName}
            budgetFiscalYear={budgetFiscalYear}
            pushSnackbarMessage={pushSnackbarMessage}
            setLoading={setLoading}
            formData={formData}
            setFormData={setFormData}
            setRefresh={setRefresh}
            setInlineEditRow={setInlineEditRow}
          />
        ) : (
          <>
            {/* Year */}
            <TableCell>{component.budgetFiscalYear}</TableCell>

            {/* Disposition Status */}
            <TableCell>
              <span className="fauxInput">
                {component.dispositionStatus &&
                  t(ComponentDispositionTypes[component.dispositionStatus])}
              </span>
            </TableCell>

            {/* Component Name */}
            <TableCell>
              {component.componentName && (
                <Tooltip title={component.componentName} placement="left">
                  <Typography noWrap={true} variant="caption" component="div">
                    <span>{component.componentName}</span>
                  </Typography>
                </Tooltip>
              )}
            </TableCell>

            {/* Amounts */}
            <TableCell align="right">
              <span className="fauxInput">
                {CurrencyFormat(component.totalComponentAmountRequested || 0)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="fauxInput">
                {CurrencyFormat(component.totalComponentAmountApproved || 0)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="fauxInput">
                {CurrencyFormat(component.fyProjectionOne || 0)}
              </span>
            </TableCell>
            <TableCell align="right">
              <span className="fauxInput">
                {CurrencyFormat(component.fyBudget || 0)}
              </span>
            </TableCell>

            {/* Note Columns */}
            {isCommitmentAdmin && (
              <>
                <TableCell
                  align="left"
                  classes={{ root: classes.childCellSecondary }}
                >
                  {component.dispositionNotes && (
                    <Tooltip
                      title={component.dispositionNotes}
                      placement="left"
                    >
                      <Typography
                        noWrap={true}
                        variant="caption"
                        component="div"
                        className="fauxInput"
                      >
                        <span>{component.dispositionNotes}</span>
                      </Typography>
                    </Tooltip>
                  )}
                </TableCell>
              </>
            )}
            <TableCell
              align="left"
              classes={{ root: classes.childCellSecondary }}
            >
              {component.dispositionComments && (
                <Tooltip title={component.dispositionComments} placement="left">
                  <Typography
                    noWrap={true}
                    variant="caption"
                    component="div"
                    className="fauxInput"
                  >
                    <span>{component.dispositionComments}</span>
                  </Typography>
                </Tooltip>
              )}
            </TableCell>

            {/* Actions */}
            <TableCell align="left">
              <Grid container justifyContent="flex-start" wrap="nowrap">
                {component._links?.disposition &&
                  permissions.DISPOSITION_REQUESTS && (
                    <Grid item>
                      <Tooltip
                        title={getEditComponentTooltipText(
                          component,
                          commitmentFinalized,
                          t
                        )}
                        placement="top"
                        classes={{
                          tooltip: classes.tooltipStyle,
                        }}
                        disableFocusListener={rowId === inlineEditRow}
                        disableHoverListener={rowId === inlineEditRow}
                        disableTouchListener={rowId === inlineEditRow}
                      >
                        <span>
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (rowId !== inlineEditRow) {
                                setInlineEditRow(rowId);
                              }
                            }}
                            className={classes.actionButton}
                            disabled={
                              !!inlineEditRow ||
                              commitmentFinalized ||
                              component.componentType !== "REQUEST"
                            }
                          >
                            <PriceChangeIcon size="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
              </Grid>
            </TableCell>
          </>
        )}
      </TableRow>
    </>
  );
};

const getEditComponentTooltipText = (component, commitmentFinalized, t) => {
  return component.componentType === "REQUEST" && !commitmentFinalized
    ? t("Requests.mainView.list.editComponentTooltip")
    : t("Requests.mainView.list.editComponentDisabledTooltip");
};
