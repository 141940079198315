import makeStyles from "@mui/styles/makeStyles";

// useStyles - Contains styles for variaous parts of User Interface
export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: "8px 0",
  },
  formContainer: {
    gap: "16px 16px",
    "&.MuiGrid-root > .MuiGrid-item": {
      maxWidth: "83.333333%",
    },
  },
  calloutBox: {
    "&.MuiPaper-root": {
      padding: "10px 16px",
      marginBottom: "16px",
      backgroundColor: theme.palette.primary.light,
    },
  },
  downloadButton: {
    "&.MuiButton-outlinedError": {
      color: theme.palette.error.main,
    },
  },
}));
