import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
      width: "calc(100% - 32px)",
    },
  },
  formContainer: {
    "&.MuiGrid-root": {
      marginTop: 16,
    },
  },
});
