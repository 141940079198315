import React from "react";
import {
  Breadcrumbs,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  Button,
  Grid,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";
import { useStyles } from "./PTADetails.styles";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import { useUsers } from "../../services/usersService";
import { getPTAById } from "../../services/ptasService";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

export const PTADetails = (props) => {
  const { t } = useTranslation();
  // useLocation hook from "react-router-dom"
  const location = useLocation();
  const params = useParams();
  const [ptaData, setPtaData] = React.useState({});
  const { clearAlert, setAlert, alert } = useAlertContext();
  const { currentUser } = useUsers();
  const classes = useStyles();

  const ptasDetailView = "PTAs.detailsView";

  React.useEffect(() => {
    getPTAById(params.PtaId, setPtaData, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  return (
    currentUser.permissions.VIEW_PTA_DETAILS && (
      <Grid container>
        {props.pageType !== "newTab" && (
          <Grid container item xs={12} className={classes.mainContainer}>
            <Grid item container xs={6} justifyContent="flex-start">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink to={`/ptas${location.search}`}>
                  <Typography color="textSecondary" variant="body2">
                    {t("PTAs.mainView.title")}
                  </Typography>
                </RouterLink>
                <Typography color="textPrimary" variant="subtitle1">
                  {t(`${ptasDetailView}.title`)}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <RouterLink to={`/ptas${location.search}`}>
                <Button
                  color="inherit"
                  startIcon={<ArrowBackIcon />}
                  classes={{
                    root: classes.arrowBackButtonPadding,
                  }}
                >
                  {t(`${ptasDetailView}.goBackButtonLabel`)}
                </Button>
              </RouterLink>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className={classes.mainContainer}>
          <Typography variant="h1">
            {t(`PTAs.mainView.list.tableColumns.pta`)}{" "}
            {ptaData && ptaData.project && ptaData.task && ptaData.award
              ? `${ptaData.project.projectNumber}-${ptaData.task.taskNumber}-${ptaData.award.awardNumber}`
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          <Typography
            variant="subtitle1"
            display="inline"
            color="textSecondary"
          >
            {t(`Commitments.mainView.list.tableColumns.status`)}:&nbsp;
          </Typography>
          <Typography display="inline" variant="subtitle1">
            {ptaData && ptaData.ptaStatus}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          {alert.exists && <ASAlert />}
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          <PTAs location={location} tableData={ptaData} />
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          {props.pageType !== "newTab" && (
            <Grid
              container
              justifyContent="flex-end"
              classes={{
                root: classes.padding,
              }}
            >
              <RouterLink to={`/ptas${location.search}`}>
                <Button
                  variant="outlined"
                  size="large"
                  color="inherit"
                  startIcon={<ArrowBackIcon />}
                >
                  {t(`${ptasDetailView}.goBackButtonLabel`)}
                </Button>
              </RouterLink>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  );
};

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const classes = useStyles();

  const ptaDetailsTableStr = "PTAs.detailsView.tableLabels";

  return (
    currentUser.permissions.VIEW_PTA_DETAILS && (
      <TableHead>
        <TableRow>
          <TableCell
            align="center"
            key="blank"
            width="200px"
            className={classes.backgroundColor}
          >
            &nbsp;
          </TableCell>
          <TableCell
            align="center"
            key="project"
            className={classes.backgroundColor}
          >
            {t(`${ptaDetailsTableStr}.project`)}
          </TableCell>
          <TableCell
            align="center"
            key="task"
            className={classes.backgroundColor}
          >
            {t(`${ptaDetailsTableStr}.task`)}
          </TableCell>
          <TableCell
            align="center"
            key="award"
            className={classes.backgroundColor}
          >
            {t(`${ptaDetailsTableStr}.award`)}
          </TableCell>
        </TableRow>
      </TableHead>
    )
  );
};

const PTAs = (props) => {
  const { t } = useTranslation();
  const tableData = props.tableData;
  const classes = useStyles();
  const ptaDetailsTableStr = "PTAs.detailsView.tableLabels";

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="customized table"
        stickyHeader
        className={classes.borderRight}
      >
        <EnhancedTableHead />
        <TableBody>
          {tableData &&
          tableData.project &&
          tableData.task &&
          tableData.award ? (
            <>
              <TableRow key={1}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.number`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectNumber}
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskNumber}
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardNumber}
                </TableCell>
              </TableRow>

              <TableRow key={2}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.name`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectName}
                </TableCell>
                <TableCell align="center">{tableData.task.taskName}</TableCell>
                <TableCell align="center">
                  {tableData.award.awardName}
                </TableCell>
              </TableRow>

              <TableRow key={3}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.startDate`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectStartDate &&
                    new Date(
                      tableData.project.projectStartDate
                    ).toLocaleDateString()}
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskStartDate &&
                    new Date(tableData.task.taskStartDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardStartDate &&
                    new Date(
                      tableData.award.awardStartDate
                    ).toLocaleDateString()}
                </TableCell>
              </TableRow>

              <TableRow key={4}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.endDate`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectEndDate &&
                    new Date(
                      tableData.project.projectEndDate
                    ).toLocaleDateString()}
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskEndDate &&
                    new Date(tableData.task.taskEndDate).toLocaleDateString()}
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardEndDate &&
                    new Date(tableData.award.awardEndDate).toLocaleDateString()}
                </TableCell>
              </TableRow>

              <TableRow key={6}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.org`)} (
                  {t(`${ptaDetailsTableStr}.orgCodeName`)})
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectOrgCodeName} (
                  {tableData.project.projectOrgCode})
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskOrgCodeName} ({tableData.task.taskOrgCode}
                  )
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardOrgCodeName} (
                  {tableData.award.awardOrgCode})
                </TableCell>
              </TableRow>

              <TableRow key={7}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.ownerFullName`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectOwnerFullName}
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskOwnerFullName}
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardOwnerFullName}
                </TableCell>
              </TableRow>

              <TableRow key={8}>
                <TableCell align="left" className={classes.backgroundColor}>
                  {t(`${ptaDetailsTableStr}.managerFullName`)}
                </TableCell>
                <TableCell align="center">
                  {tableData.project.projectManagerFullName}
                </TableCell>
                <TableCell align="center">
                  {tableData.task.taskManagerFullName}
                </TableCell>
                <TableCell align="center">
                  {tableData.award.awardManagerFullName}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan="4">
                <Typography
                  variant="body1"
                  color="inherit"
                  align="center"
                  paragraph={false}
                >
                  {t("globals.list.messages.noData")}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
