import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  childRow: {
    backgroundColor: theme.palette.grey[100],
    "&:last-child td": {
      paddingBottom: 20,
    },
  },
  tableLayout: {
    "&.MuiTable-root": {
      tableLayout: "fixed",
      "& .MuiTableCell-root": { maxWidth: "none" },
    },
  },
  footerCell: {
    borderBottomStyle: "none",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
  },
  childCellPrimary: {
    "&.MuiTableCell-root": {
      textIndent: 20,
      borderBottomStyle: "none",
      padding: "2px 2px",
    },
  },
  childCellSecondary: {
    "&.MuiTableCell-root": {
      borderBottomStyle: "none",
      padding: "2px 8px",
    },
  },
  progressBar: {
    textAlign: "center",
  },
  tooltipStyle: {
    maxWidth: 200,
    backgroundColor: theme.palette.grey[700],
    padding: "5px",
  },
  right: {
    marginLeft: "auto",
  },
  inputPadding: { "& .MuiOutlinedInput-input": { padding: "8px 0px" } },
  multilineInput: {
    "&.MuiOutlinedInput-root": {
      padding: 8,
    },
  },
  numberFormatInput: {
    width: 130,
  },
  parentCell: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  budgetTableFooter: {
    boxShadow: `0 12px 20px 12px ${theme.palette.grey[500]}`,
  },
  commitmentTypeFilter: {
    "& .MuiFormControlLabel-label": { fontSize: "15px" },
  },
  container: { "&.MuiGrid-root": { marginTop: 24, width: "100%" } },
  newLine: { whiteSpace: "pre-line" },
  yellow: {
    color: theme.palette.warning.main,
    verticalAlign: "text-bottom",
    marginRight: "5px",
  },

  compositeIcon: {
    position: "relative",
    "& .decorativeIcon": {
      height: "16px",
      width: "16px",
      fontWeight: "bold",
      bottom: 0,
      right: "-6px",
      border: "1px solid transparent",
      borderRadius: "300px",
      position: "absolute",
    },
  },
  actionLinks: {
    padding: "0 2px",
  },
  actionButton: {
    ".MuiButtonBase-root&": {
      padding: "0px 2px",
    },
  },
}));
