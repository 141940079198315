import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { getLastUploadedInfo } from "../services/transfersService";
import { useAlertContext } from "@stanford-tds/as-components";

export const TransfersContext = React.createContext();

export const TransfersProvider = ({ children }) => {
  // Getting history object from useHistory (react-router-dom)
  const history = useHistory();

  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State variable to store last uploaded info to dispaly and set as default filters
  const [lastUploadedInfo, setLastUploadedInfo] = React.useState("");

  // State for filters
  const qs = queryString.parse(location.search);
  const [filters, setFilters] = React.useState({
    fiscalYear: qs.fiscalYear || "",
    glPeriod: qs.glPeriod || "",
    posted: qs.posted || "",
    conditionType: qs.conditionType || "",
    notification: qs.notification || "",
    journalSource: qs.journalSource || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "validateStatus",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [initialLoad, setInitialLoad] = React.useState(true);

  const { clearAlert, setAlert } = useAlertContext();

  // React useEfffect with dependency of page change (via history and location object)
  React.useEffect(() => {
    // "If" condition to run only when user moves out of `transfers` menu
    if (history.location.pathname.split("/")[1] !== "transfers") {
      // Resetting context
      setFilters({
        fiscalYear: lastUploadedInfo.lastUploadFiscalYear,
        glPeriod: lastUploadedInfo.lastUploadGLPeriod,
        posted: "",
        conditionType: "",
        notification: "",
        journalSource: "",
      });
      setSort({
        column: "validateStatus",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 10,
      });
    }
  }, [history.location, qs.fiscalYear, qs.glPeriod, lastUploadedInfo]);

  React.useEffect(() => {
    if (history.location.pathname.split("/")[1] === "transfers") {
      getLastUploadedInfo(
        setLastUploadedInfo,
        setFilters,
        setInitialLoad,
        clearAlert,
        setAlert
      );
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    <TransfersContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        lastUploadedInfo,
        setLastUploadedInfo,
        initialLoad,
      }}
    >
      {children}
    </TransfersContext.Provider>
  );
};

export const useTransfersContext = () => {
  return React.useContext(TransfersContext);
};
