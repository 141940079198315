import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import { useStyles } from "./DropZone.styles";

export const DropZone = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const options = {
    acceptedFiles: props.acceptedFiles || [
      "image/png", // png
      "image/jpeg", // jpeg
      "image/gif", // giff
      "application/pdf", // pdf
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xls
      "application/vnd.ms-excel", // xlsx
      "application/vnd.ms-outlook", // msg
      ".msg", // msg via extension
    ],
    filesLimit: 1,
    dropzoneText:
      props.dropzoneText ||
      t(
        "Commitments.commitmentDocuments.create.form.fields.uploadedDocument.label"
      ),
    disableRejectionFeedback: false,
    showPreviews: false,
    showPreviewsInDropzone: false,
    showFileNamesInPreview: false,
    previewText: "",
    showAlerts: false,
  };

  return (
    <DropzoneArea
      onChange={props.handleDocumentUpload}
      onDrop={props.handleDrop}
      dropzoneClass={classes.dropZone}
      {...options}
    />
  );
};
