import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ComponentTableHead = (props) => {
  const { t } = useTranslation();

  const tableColumnsStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.tableColumns.componentDetail.";

  const detailsPageCommonStr =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog.detailsPage";
  return (
    <TableHead>
      <TableRow>
        {!props.showDetailsView && (
          <TableCell align="left" key="recordNumber" width="6%">
            {t(`${tableColumnsStr}rec`)}
          </TableCell>
        )}
        <TableCell align="left" key="commitmentName" width="25%">
          {props.showDetailsView &&
            `${t(`${detailsPageCommonStr}.destinationLabel`)} \n`}
          {t(`${tableColumnsStr}commitmentName`)}
        </TableCell>
        <TableCell align="left" key="componentName" width="25%">
          {t(`${tableColumnsStr}componentName`)}
        </TableCell>
        <TableCell align="left" key="pta" width="20%">
          {t(`${tableColumnsStr}pta`)}
        </TableCell>
        <TableCell align="left" key="redistributedAmount" width="20%">
          {t(`${tableColumnsStr}redistributedAmount`)}
        </TableCell>
        {props.showDetailsView && (
          <TableCell align="left" key="mtdAmount" width="15%">
            {t(`${detailsPageCommonStr}.mtdAmount`)}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
