import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  componentTable: {},

  progressBar: {
    "&.MuiTableCell-root": {
      textAlign: "center",
    },
  },

  formContainer: {
    "&.MuiDialogContent-root .MuiGrid-container": {
      padding: "8px 0",
    },
  },

  inputPadding: {
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
    "&.MuiOutlinedInput-root": {
      padding: 8,
    },
    ".MuiAutocomplete-root & .MuiOutlinedInput-root": {
      padding: 0,
    },
  },
  numericInput: {
    "& .MuiOutlinedInput-input, & .MuiInput-input": {
      textAlign: "right",
    },
  },
  multilineInput: {
    "& .MuiOutlinedInput-root": {
      padding: 8,
    },
  },
}));
