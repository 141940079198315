import React from "react";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useStyles } from "./ListPopover.styles";
import { useTranslation } from "react-i18next";

export const ListPopover = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    props.trigger ? setAnchorEl(props.trigger.target) : setAnchorEl(null);
  }, [props.trigger]);

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.paper}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: 20,
        horizontal: -40,
      }}
      TransitionProps={{
        onExited: () => {
          props.setListEvent(undefined);
        },
      }}
    >
      <List className={classes.root}>
        {/* Details Header */}
        <ListItem className={classes.header}>
          <ListItemText
            primary={
              <Typography align="center">
                {`${capitalize(props.header)} ${props.entityValue}`}
              </Typography>
            }
          />
        </ListItem>
        <ListItems items={props.data} entity={props.entity} />
      </List>
    </Popover>
  );
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const ListItems = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const items = t("Commitments.commitmentComponents.create.ptaDetails", {
    returnObjects: true,
  });
  return Object.entries(items).map((item, index) => (
    <ListItem className={classes.item} key={index}>
      <ListItemText
        className={classes.listItemText}
        primary={
          <Typography color="textSecondary" className={classes.primary}>
            {item[1]}
          </Typography>
        }
        secondary={
          <Typography color="textPrimary" className={classes.secondary}>
            {item[0] === "org"
              ? `${props.items[`${props.entity}OrgCodeName`]} (${
                  props.items[`${props.entity}OrgCode`]
                })`
              : props.items[props.entity + capitalize(item[0])]}
          </Typography>
        }
      />
    </ListItem>
  ));
};
