import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useStyles } from "./Transfers.styles";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  TablePagination,
  TableContainer,
  TableCell,
  TableSortLabel,
  Button,
  Grid,
  Box,
  IconButton,
  CircularProgress,
  // Link,
} from "@mui/material";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  FormatListNumberedRtl as FormatListNumberedRtlIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  CloudUpload as CloudUploadIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import PropTypes from "prop-types";
import { UploadTransfers } from "./UploadTransfers/UploadTransfers";
import { RedistributeTransfers } from "./RedistributeTransfers/RedistributeTransfers";
import { getAllTransfers } from "../../services/transfersService";
import { useTransfersContext } from "../../services/transfersContext";
import { TransferTypes } from "../../constants";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { sort } = useTransfersContext();
  const tableColumnsStr = "UploadedTransfers.mainView.list.tableColumns";

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          key="notification"
          width="98px"
          sortDirection={
            sort.column === "notification.status" ? sort.orderBy : false
          }
        >
          <TableSortLabel
            active={sort.column === "notification.status"}
            direction={
              sort.column === "notification.status" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("notification.status")}
          >
            {t(`${tableColumnsStr}.notification`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="fiscalYear"
          width="65px"
          sortDirection={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "fiscalYear"}
            direction={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
            onClick={createSortHandler("fiscalYear")}
          >
            {t(`${tableColumnsStr}.fiscalYear`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="glPeriod"
          width="67px"
          sortDirection={sort.column === "glPeriod" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "glPeriod"}
            direction={sort.column === "glPeriod" ? sort.orderBy : "asc"}
            onClick={createSortHandler("glPeriod")}
          >
            {t(`${tableColumnsStr}.glPeriod`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="commitmentName"
          width="108px"
          sortDirection={
            sort.column === "commitment.name" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "commitment.name"}
            direction={sort.column === "commitment.name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("commitment.name")}
          >
            {t(`${tableColumnsStr}.commitmentName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="componentName"
          width="102px"
          sortDirection={
            sort.column === "component.componentName" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "component.componentName"}
            direction={
              sort.column === "component.componentName" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("component.componentName")}
          >
            {t(`${tableColumnsStr}.componentName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="pta"
          width="52px"
          sortDirection={sort.column === "pta" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "pta"}
            direction={sort.column === "pta" ? sort.orderBy : "asc"}
            onClick={createSortHandler("pta")}
          >
            {t(`${tableColumnsStr}.pta`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="journalSource"
          width="70px"
          sortDirection={sort.column === "journalSource" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "journalSource"}
            direction={sort.column === "journalSource" ? sort.orderBy : "asc"}
            onClick={createSortHandler("journalSource")}
          >
            {t(`${tableColumnsStr}.journalSource`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="journalName"
          width="70px"
          sortDirection={sort.column === "journalName" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "journalName"}
            direction={sort.column === "journalName" ? sort.orderBy : "asc"}
            onClick={createSortHandler("journalName")}
          >
            {t(`${tableColumnsStr}.journalName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="transferType"
          width="75px"
          sortDirection={sort.column === "transferType" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "transferType"}
            direction={sort.column === "transferType" ? sort.orderBy : "asc"}
            onClick={createSortHandler("transferType")}
          >
            {t(`${tableColumnsStr}.transferType`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="right"
          key="amount"
          width="85px"
          sortDirection={sort.column === "amount" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "amount"}
            direction={sort.column === "amount" ? sort.orderBy : "asc"}
            onClick={createSortHandler("amount")}
          >
            {t(`${tableColumnsStr}.amount`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="center"
          key="posted"
          width="65px"
          sortDirection={sort.column === "isPosted" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "isPosted"}
            direction={sort.column === "isPosted" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isPosted")}
          >
            {t(`${tableColumnsStr}.posted`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="errorWarnings"
          width="113px"
          sortDirection={
            sort.column === "validateStatus" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "validateStatus"}
            direction={sort.column === "validateStatus" ? sort.orderBy : "asc"}
            onClick={createSortHandler("validateStatus")}
          >
            {t(`${tableColumnsStr}.errorWarnings`)}
          </TableSortLabel>
        </TableCell>
        <TableCell align="center" key="actions" width="55px">
          {t(`${tableColumnsStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Transfers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("validateStatus");
  const [, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [transfers, setTransfers] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const { alert, clearAlert, setAlert } = useAlertContext();

  // Open/Close redistribute dialog
  const [openRedistributeDialog, setOpenRedistributeDialog] =
    React.useState(false);

  const [showDetailsView, setShowDetailsView] = React.useState(false);

  // Upload Transfers dialog
  const [openUploadTransfersDialog, setOpenUploadTransfersDialog] =
    React.useState(false);

  const history = useHistory();

  const {
    filters,
    sort,
    setSort,
    pagination,
    setPagination,
    lastUploadedInfo,
    initialLoad,
  } = useTransfersContext();

  const [transferIdToRedistribute, setTransferIdToRedistribute] =
    React.useState();

  const [transferIndexToUpdate, setTransferIndexToUpdate] = React.useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setPagination({
      page: pageValue,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const displayLastGLPeriod = () => {
    return `${t("UploadedTransfers.mainView.subTitle")} ${
      lastUploadedInfo.lastUploadGLPeriod || ""
    }`;
  };

  React.useEffect(() => {
    !initialLoad &&
      getAllTransfers(
        filters,
        pagination,
        sort,
        setTransfers,
        setTotalCount,
        setLoading,
        clearAlert,
        setAlert
      );
    // eslint-disable-next-line
  }, [filters, pagination, sort]);

  const getStatusName = (status) => {
    const commontTranslationString =
      "UploadedTransfers.filterPanelItems.options.notificationDropdownValues";
    switch (status) {
      case "UNRESOLVED":
        return t(`${commontTranslationString}.unprocessed`);
      case "INPROCESS":
        return t(`${commontTranslationString}.inProcess`);
      case "RESOLVED":
        return t(`${commontTranslationString}.resolved`);
      default:
        return "";
    }
  };

  const commonTranslationString = "UploadedTransfers.mainView.list";

  return (
    <Grid>
      <Typography variant="subtitle1" className={classes.subtitleColor}>
        {displayLastGLPeriod()}
      </Typography>

      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        spacing={2}
      >
        <Grid item xs={12}>
          {!openUploadTransfersDialog &&
            !openRedistributeDialog &&
            alert.exists && <ASAlert />}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                setOpenUploadTransfersDialog(true);
              }}
            >
              {t("UploadedTransfers.mainView.uploadTransfersButton")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              stickyHeader
              className={classes.tableLayout}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell
                      colSpan="13"
                      classes={{ root: classes.progressBar }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  transfers.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" component="th" scope="row">
                        <Box display="flex" flexDirection="row">
                          <Box>
                            <Typography variant="body2">
                              {row.transfersNotification &&
                                getStatusName(row.transfersNotification.status)}
                            </Typography>
                          </Box>

                          {row.transfersNotification &&
                            row.transfersNotification.status.toLowerCase() !==
                              "resolved" && (
                              <Box>
                                <Tooltip
                                  title={t(
                                    `${commonTranslationString}.editNotificationToolTip`
                                  )}
                                >
                                  <IconButton size="small" color="inherit">
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.fiscalYear}</TableCell>
                      <TableCell align="left" nowrap="nowrap">
                        {row.glPeriod}
                      </TableCell>
                      <TableCell align="left">
                        {row.commitment && (
                          <RouterLink
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/edit",
                            }}
                            target={"blank_" + row.commitment.id}
                          >
                            <Tooltip
                              title={row.commitment.name}
                              placement="left"
                            >
                              <Typography noWrap={true} variant="body2">
                                {row.commitment.name}
                              </Typography>
                            </Tooltip>
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.component && (
                          <RouterLink
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/components/" +
                                row.component.id +
                                "/edit",
                            }}
                            target={`${row.commitment.id}${row.component.id}`}
                          >
                            <Tooltip
                              title={row.component.componentName}
                              placement="left"
                            >
                              <Typography noWrap={true} variant="body2">
                                {row.component.componentName}
                              </Typography>
                            </Tooltip>
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.pta && (
                          <RouterLink
                            to={{
                              pathname:
                                "/transfers/" +
                                row.id +
                                "/ptas/" +
                                row.pta._links.self.href.split("/").pop(),
                            }}
                            target={"blank_" + row.ptaNumber}
                          >
                            {row.ptaNumber}
                          </RouterLink>
                        )}
                        {!row.pta && (
                          <Typography
                            variant="body2"
                            className={classes.wordBreak}
                          >
                            {!row.task &&
                              `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                row.award
                              }`}
                            {row.task && row.ptaNumber}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">{row.journalSource}</TableCell>
                      <TableCell align="left">
                        <Tooltip title={row.journalName} placement="left">
                          <Typography noWrap={true} variant="body2">
                            {row.journalName}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        {t(TransferTypes[row.transferType])}
                      </TableCell>
                      <TableCell align="right">
                        {CurrencyFormat(row.amount)}
                      </TableCell>
                      <TableCell align="center">
                        {row.posted ? <CheckIcon /> : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.transfersNotification &&
                          row.transfersNotification.errorWarnings &&
                          row.transfersNotification.errorWarnings.map(
                            (anObjectMapped, index) => {
                              return (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  key={index}
                                >
                                  {row.validateStatus &&
                                    row.validateStatus.toLowerCase() ===
                                      "warning" && (
                                      <Box>
                                        <Tooltip
                                          title={t(
                                            `${commonTranslationString}.warningToolTip`
                                          )}
                                        >
                                          <WarningIcon
                                            className={classes.yellow}
                                          />
                                        </Tooltip>
                                      </Box>
                                    )}{" "}
                                  {row.validateStatus &&
                                    row.validateStatus.toLowerCase() ===
                                      "error" && (
                                      <Box>
                                        <Tooltip
                                          title={t(
                                            `${commonTranslationString}.errorToolTip`
                                          )}
                                        >
                                          <ErrorIcon className={classes.red} />
                                        </Tooltip>
                                      </Box>
                                    )}
                                  <Box>
                                    <Typography gutterBottom variant="body2">
                                      {anObjectMapped === "UNPROCESSED"
                                        ? t(
                                            `${commonTranslationString}.unprocessed`
                                          )
                                        : anObjectMapped}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() ===
                            "redistributed" && (
                            <Grid container>
                              <Tooltip
                                title={t(
                                  `${commonTranslationString}.redistributedDetailsTooltip`
                                )}
                              >
                                <InfoIcon className={classes.blue} />
                              </Tooltip>
                              {/* <Link
                                component={"button"}
                                onClick={() => {
                                  setShowDetailsView(true);
                                  setOpenRedistributeDialog(true);
                                  setTransferIdToRedistribute(4);
                                  setTransferIndexToUpdate(index);
                                }}
                                classes={{
                                  root: classes.redistributedIcon,
                                }}
                              > */}
                              <Typography
                                gutterBottom
                                variant="body2"
                                display={"inline"}
                              >
                                {t(
                                  `${commonTranslationString}.redistributedLabel`
                                )}
                              </Typography>
                              {/* </Link> */}
                            </Grid>
                          )}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() === "error" && (
                            <Tooltip
                              title={t(
                                `${commonTranslationString}.redistributeToolTip`
                              )}
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setShowDetailsView(false);
                                  setOpenRedistributeDialog(true);
                                  setTransferIdToRedistribute(row.id);
                                  setTransferIndexToUpdate(index);
                                }}
                              >
                                <FormatListNumberedRtlIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                {!loading && !transfers.length && (
                  <TableRow>
                    <TableCell colSpan="13">
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      {openRedistributeDialog && (
        <RedistributeTransfers
          openDialog={openRedistributeDialog}
          setOpenDialog={setOpenRedistributeDialog}
          transferIdToRedistribute={transferIdToRedistribute}
          setTransfers={setTransfers}
          transferIndex={transferIndexToUpdate}
          showDetailsView={showDetailsView}
        />
      )}

      {openUploadTransfersDialog && (
        <UploadTransfers
          openDialog={openUploadTransfersDialog}
          setOpenDialog={setOpenUploadTransfersDialog}
          glPeriod={lastUploadedInfo.lastUploadGLPeriod}
          history={history}
        />
      )}
    </Grid>
  );
};
