import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Tooltip, IconButton } from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { BudgetingFormDialog } from "./BudgetingFormDialog/BudgetingFormDialog";
import { useBudgetingContext } from "../../services/budgetingContext";

export const InformationPanel = () => {
  const { t } = useTranslation();

  const {
    infoPanelDisabled,
    setInfoDialogOpen,
    budgetFiscalYearResponse,
    setRefreshInformationPanel,
  } = useBudgetingContext();

  // Open/Close add/edit budget fiscal year dialog
  const [
    openAddEditBudgetFiscalYearDialog,
    setOpenAddEditBudgetFiscalyearDialog,
  ] = React.useState(false);

  return (
    <>
      <Grid
        container
        component={Paper}
        style={{ padding: "16px 18px" }}
        justifyContent={"space-between"}
      >
        <Grid item xs={2}>
          <Typography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.budgetFiscalYear")}
          </Typography>
          :{" "}
          <Typography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.budgetFiscalYear}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.editStatus")}:{" "}
          </Typography>
          <Typography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.openEditStatus &&
              t("Commitments.mainView.list.tableValues.statusOpenText")}
            {!budgetFiscalYearResponse.openEditStatus &&
              t("Commitments.mainView.list.tableValues.statusClosedText")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.initialEditPeriod")}:{" "}
          </Typography>
          <Typography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.initialEditStartDate} -{" "}
            {budgetFiscalYearResponse.initialEditEndDate}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            component="span"
            color={"textSecondary"}
            variant={"body2"}
          >
            {t("Budgeting.mainView.informationPanel.revisionEditPeriod")}:{" "}
          </Typography>
          <Typography component="span" variant={"body2"}>
            {budgetFiscalYearResponse.revisionEditStartDate} -{" "}
            {budgetFiscalYearResponse.revisionEditEndDate}
          </Typography>
        </Grid>
        <Grid item xs={1} container justifyContent="flex-end">
          {budgetFiscalYearResponse._links &&
            budgetFiscalYearResponse._links.edit !== undefined && (
              <Tooltip
                title={t(
                  "Budgeting.mainView.informationPanel.editBudgetFiscalYearToolTip"
                )}
                disableFocusListener={infoPanelDisabled}
                disableHoverListener={infoPanelDisabled}
                disableTouchListener={infoPanelDisabled}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!infoPanelDisabled) {
                      setOpenAddEditBudgetFiscalyearDialog("EDIT");
                      setRefreshInformationPanel(false);
                      setInfoDialogOpen(true);
                    }
                  }}
                >
                  <EditIcon
                    color={infoPanelDisabled ? "disabled" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            )}
          {budgetFiscalYearResponse._links &&
            budgetFiscalYearResponse._links.create !== undefined && (
              <Tooltip
                title={t(
                  "Budgeting.mainView.informationPanel.addBudgetFiscalYearToolTip"
                )}
                disableFocusListener={infoPanelDisabled}
                disableHoverListener={infoPanelDisabled}
                disableTouchListener={infoPanelDisabled}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!infoPanelDisabled) {
                      setOpenAddEditBudgetFiscalyearDialog("ADD");
                      setRefreshInformationPanel(false);
                      setInfoDialogOpen(true);
                    }
                  }}
                >
                  <AddCircleIcon
                    color={infoPanelDisabled ? "disabled" : "inherit"}
                  />
                </IconButton>
              </Tooltip>
            )}
        </Grid>
      </Grid>

      {openAddEditBudgetFiscalYearDialog && (
        <BudgetingFormDialog
          openDialog={openAddEditBudgetFiscalYearDialog}
          setOpenDialog={setOpenAddEditBudgetFiscalyearDialog}
        />
      )}
    </>
  );
};
