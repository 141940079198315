import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import commonEN from "./locales/en/common.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
    common: commonEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    //debug: true, // uncommenting this will log missing translations

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
