import React from "react";
import { 
  InputAdornment,
  TextField,
  Typography, 
} from "@mui/material";
import NumberFormat from "react-number-format";

//Created a separate function to convert number into Currency
//which removes negative sign from number and added comma for thousandseparator
//and show negative numbers in small bracket

export function CurrencyFormat(value) {
  return <>
    <Typography variant="inherit" component="span">
      {value !== undefined && "$"}
    </Typography>
    <Typography
      component="span"
      variant="inherit"
      color={`${(value < 0 && "error") || "inherit"}`}
    >
      {value !== undefined &&
        `${(parseFloat(value) < 0 && "(") || ""}${
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(Math.abs(value))
            .split("$")[1]
            .replace(/\D00$/, "")
        }${(parseFloat(value) < 0 && ")") || ""}`}
    </Typography>
  </>;
}

export const FormattedCurrencyField = (props) => {
  const isViewOnly = props.isViewOnly || false;
  const shrinkProps = isViewOnly && { shrink: true };
  let otherProps = Object.assign({}, props); 
  (Object.hasOwn(otherProps, "isViewOnly") && delete otherProps.isViewOnly);
  (Object.hasOwn(otherProps, "setFieldValue") && delete otherProps.setFieldValue);
  return (
    <NumberFormat
      decimalScale={0}
      fixedDecimalScale={true}
      thousandSeparator={false}
      customInput={TextField}
      isAllowed={(args) => {
        const { value } = args;
        return value >= 0 && value <= 999999999;
      }}
      allowNegative={false}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">$</InputAdornment>
        ),
      }}
      autoComplete="off"
      fullWidth={true}
      required={false}
      InputLabelProps={{ ...shrinkProps }}
      {...otherProps}
    />
  );
};