import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export const RequestsContext = React.createContext();

export const RequestsProvider = ({ children }) => {
  // Getting history object from useHistory (react-router-dom)
  const history = useHistory();

  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const qs = queryString.parse(location.search);
  const [filters, setFilters] = React.useState({
    budgetYear: qs.budgetYear || "",
    name: qs.name || "",
    departmentName: qs.deptName || "",
    departmentCode: qs.deptName
      ? qs.deptName.split("(").pop().split(")")[0].trim()
      : "",
    dispositionStatus: qs.dispositionStatus || "",
  });

  // State for Sort
  const [sort, setSort] = React.useState({
    column: "name",
    orderBy: "asc",
  });

  // State for pagination
  const [pagination, setPagination] = React.useState({
    page: 0,
    rowsPerPage: 25,
  });

  // State for keeping commitment id for opened component drawer
  const [openCommitmentId, setOpenCommitmentId] = React.useState(0);

  const getQueryParams = () => {
    return {
      budgetYear: filters.budgetYear ?? "",
      name: filters.name ?? "",
      departmentName: filters.departmentCode ?? "",
      dispositionStatus: filters.dispositionStatus ?? "",
    };
  };

  React.useEffect(() => {
    // Clear filter states when not on requests tab
    if (history.location.pathname.split("/")[1] !== "requests") {
      setFilters({
        budgetYear: qs.budgetYear || "",
        name: qs.name || "",
        departmentName: qs.deptName || "",
        departmentCode: qs.deptCode || "",
        dispositionStatus: qs.dispositionStatus || "",
      });
      setSort({
        column: "name",
        orderBy: "asc",
      });
      setPagination({
        page: 0,
        rowsPerPage: 25,
      });
    }
  }, [
    history.location,
    qs.budgetYear,
    qs.name,
    qs.deptName,
    qs.deptCode,
    qs.dispositionStatus,
  ]);

  return (
    <RequestsContext.Provider
      value={{
        filters,
        setFilters,
        sort,
        setSort,
        pagination,
        setPagination,
        openCommitmentId,
        setOpenCommitmentId,
        getQueryParams,
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequestsContext = () => {
  return React.useContext(RequestsContext);
};
