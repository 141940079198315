import styled from "styled-components";
import { Drawer } from "@mui/material";

export const FilterPanelStyle = styled.span`
  button[aria-label="open drawer"] {
    margin-left: 20px;
    padding: 0;
    margin-top: 24px;
  }
  .show {
    display: flex;
  }
  .hide {
    display: none;
  }
  && .MuiDrawer-root {
    width: 300px;
    min-height: 480px;
    flex-shrink: 0;
    white-space: nowrap;

    .MuiPaper-root {
      width: 300px;
      min-height: 480px;
      position: absolute;
      z-index: 100;

      .MuiTypography-root {
        color: #333333;
        font-family: "Source sans pro";
      }
      .MuiTypography-h6 {
        font-size: 1.1rem;
      }

      .MuiIconButton-root {
        margin-left: 0px;
        color: #333;
      }

      .PaperToolbar {
        display: flex;
        align-items: center;
        min-height: 0;
        justify-content: space-between;
      }
    }
    .PaperToolbar .MuiIconButton-root {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.04);
    }
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.6rem;
    }
  }
  .drawer-root-close {
    overflow-x: hidden;
    display: none;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.6rem;
  }
  .PaperToolbar .MuiIconButton-root {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .MuiDrawer-paper {
    z-index: 0;
  }
  .filterOptions {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    margin: 0 24px 0px 24px;
    justify-content: space-around;
    .MuiInputBase-root {
      height: 40px;
      width: 252px;
      border-radius: 6px;
      text-align: center;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, 10px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
  .filterOptions > div {
    flex-basis: 60px;
  }
  .filterOptions > div:last-child {
    flex-basis: 10px;
  }
  .filterOptions .MuiInputBase-formControl {
    text-align: left;
  }
  .filterOptions .MuiFormControl-root {
  }
  .filterOptions .MuiFormLabel-root {
    line-height: 1.5;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding-top: 1.5px;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 30px;
  }
`;
export const DrawerStyle = styled(Drawer)`
  ${(props) =>
    props.mobile
      ? `
    .MuiDrawer-paperAnchorTop {
      top: 10px;
      left: 10px;
      bottom: 10px;
      right: 10px;
    }
    .MuiIconButton-root {
      flex:  1
      justify-content: flex-end;
    }
    .PaperToolbar {
      display: flex;
    }
    .MuiTypography-h6 {
      align-self: center;
      margin-left: 8px;
    }
    `
      : ""}
`;

export const ResetFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 6px;
  align-items: center;
`;
