import makeStyles from "@mui/styles/makeStyles";

// useStyles - Contains styles for variaous parts of User Interface
export const useStyles = makeStyles((theme) => ({
  mainHeading: {
    padding: "8px 0",
  },
  formContainer: {
    gap: "16px 16px",
    "&.MuiGrid-root > .MuiGrid-item": {
      maxWidth: "83.333333%",
    },
  },
  componentTable: {
    "& > tbody > tr > td": {
      verticalAlign: "top",
    },
  },
  columnTotal: {
    "&.MuiTableCell-root": {
      paddingRight: "20px",
    },
  },
  currencyField: {
    "& input": {
      textAlign: "right",
    },
  },
  inputLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "15px",
    },
  },
  readOnlyPadding: {
    "& .MuiInput-underline": {
      padding: "8.5px 14px",
    },
  },
}));
