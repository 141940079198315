import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  inputPadding: { "& .MuiOutlinedInput-input": { padding: "8px 0px" } },
  noComponentsAttached: {
    minHeight: "125px",
    alignItems: "center",
    color: theme.palette.grey[500],
  },
  headerBackgroundColor: {
    "& .MuiTableCell-stickyHeader": {
      background: theme.palette.primary.light,
    },
    "& .MuiTableCell-head": {
      color: theme.palette.text.primary,
      padding: "16px 16px",
      fontSize: "15px",
      fontWeight: "600",
      whiteSpace: "pre-line",
    },
    "& .MuiTableCell-root": {
      padding: "10px 16px",
    },
  },
  maxHeight: { maxHeight: "220px" },
  dialogMaxWidth: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1048px",
      maxHeight: "none",
    },
  },
  remainingRedistribute: {
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  dialogActions: {
    padding: "24px",
  },
}));
