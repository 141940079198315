import React from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Box,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./FinancialTransactionForm.styles";
import {
  NavigateNext as NavigateNextIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { FormButton } from "../../../../UI/Button/FormButton";
import { Formik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { useUsers } from "../../../../../services/usersService";
import {
  getGLPeriodsByFiscalYear,
  postFinancialTransaction,
} from "../../../../../services/componentsService";
import { TransactionTypes } from "../../../../../constants";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

export const FinancialTransactionForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // useStyles classes
  const classes = useStyles();
  // params using useParams
  const params = useParams();
  // useHistory hook to get history
  const history = useHistory();
  // current user object
  const { currentUser } = useUsers();
  // de-structuring currentUser object from permissions
  const { permissions } = currentUser;
  // de-structuring context
  const { alert, clearAlert, setAlert } = useAlertContext();
  // handle close for form
  const handleClose = () => {
    history.push(
      `/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`
    );
  };
  // state for fiscalYear
  const [fiscalYear] = React.useState(params.fiscalYear || "");
  // state for formData for Formik library
  const [formData] = React.useState({
    type: "",
    glPeriod: "",
    amount: "",
    note: "",
  });
  // state for GL Period for Autocomplete input
  const [glPeriodOptions, setGLPeriodOptions] = React.useState([]);
  // de-structuring pageType prop
  const pageType = props.pageType;
  // Fn formatCurrency to render amount
  const formatCurrency = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    } else {
      if (pageType === "new") {
        return "";
      } else {
        return "0.00";
      }
    }
  };

  // React useEffect
  React.useEffect(() => {
    clearAlert();
    getGLPeriodsByFiscalYear(
      params.fiscalYear,
      setGLPeriodOptions,
      clearAlert,
      setAlert
    );
    // eslint-disable-next-line
  }, [params.fiscalYear]);

  // Common Translation String
  const commonTranslationString =
    "Commitments.financialHistory.financialTransaction.create";

  return (
    props.pageType === "new" &&
    permissions.CREATE_COMPONENT_FINANCIAL_PLAN && (
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          postFinancialTransaction(
            params.commitmentId,
            params.componentId,
            params.fiscalYear,
            values,
            clearAlert,
            setAlert,
            t,
            history
          );
        }}
        validationSchema={finacialTransactionValidation}
      >
        {(props) => {
          const {
            values,
            handleSubmit,
            dirty,
            isValid,
            setFieldValue,
            handleChange,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                classes={{
                  root: classes.mainContainer,
                }}
              >
                <Grid item xs={12}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <RouterLink to={`/commitments`}>
                      <Typography color="textSecondary">
                        {t("Commitments.mainView.title")}
                      </Typography>
                    </RouterLink>
                    <RouterLink
                      to={`/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`}
                    >
                      <Typography color="textSecondary">
                        {t("Commitments.financialHistory.mainView.title")}
                      </Typography>
                    </RouterLink>
                    <Typography color="textPrimary">
                      {pageType === "new" &&
                        t(`${commonTranslationString}.breadcrumbTitle`)}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    {pageType === "new" &&
                      `${t(`${commonTranslationString}.title`, {
                        fiscalYear,
                      })}`}
                  </Typography>
                  {alert.exists && (
                    <Box mt={2}>
                      <ASAlert />
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  classes={{
                    container: classes.formContainer,
                  }}
                >
                  <Grid item xs={4}>
                    <Autocomplete
                      id="type"
                      options={Object.values(TransactionTypes)}
                      getOptionLabel={(option) => t(option)}
                      onChange={(event, value) => {
                        setFieldValue(
                          "type",
                          Object.keys(TransactionTypes).find(
                            (key) => TransactionTypes[key] === value
                          )
                        );
                        setFieldValue("glPeriod", "");
                      }}
                      onInputChange={(event, value) => {}}
                      clearIcon={false}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant={"outlined"}
                            required
                            label={`${t(
                              `${commonTranslationString}.form.fields.type.label`
                            )}`}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      id="amount"
                      label={`${t(
                        `${commonTranslationString}.form.fields.amount.label`
                      )}`}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.amount)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      required
                      allowNegative={true}
                      onValueChange={(value) => {
                        setFieldValue("amount", value.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  classes={{
                    container: classes.formContainer,
                  }}
                >
                  <Grid item xs={4}>
                    <Autocomplete
                      id="glPeriod"
                      options={glPeriodOptions}
                      getOptionLabel={(option) => option.glPeriod || ""}
                      onChange={(event, value) => {
                        setFieldValue("glPeriod", value);
                      }}
                      value={values.glPeriod}
                      onInputChange={(event, value) => {}}
                      clearIcon={false}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant={"outlined"}
                            required
                            label={`${t(
                              `${commonTranslationString}.form.fields.glPeriod.label`
                            )}`}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="note"
                      label={`${t(
                        `${commonTranslationString}.form.fields.note.label`
                      )}`}
                      required
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      multiline
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  classes={{
                    container: classes.formContainer,
                  }}
                >
                  <Grid item xs={8} container justifyContent="flex-end">
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      xs={7}
                      spacing={2}
                    >
                      <FormButton
                        cancel={handleClose}
                        save={{ disabled: !dirty || !isValid }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    )
  );
};

// Yup validation object for Formik library
const finacialTransactionValidation = Yup.object().shape({
  type: Yup.string().required(),
  glPeriod: Yup.object().required(),
  amount: Yup.number().required(),
  note: Yup.string().required(),
});
