import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  textOpen: {
    color: theme.palette.success.main,
  },
  textClosed: {
    color: theme.palette.error.main,
  },
  tableLayout: {
    "&.MuiTable-root": {
      tableLayout: "fixed",
    },
  },
  expandableRow: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiTableCell-head": {
      color: theme.palette.grey[700],
      fontSize: 13,
      fontWeight: 600,
    },
    "& .MuiTableCell-body": {
      borderBottom: "unset",
      fontSize: 13,
      fontWeight: 500,
    },
    "& .MuiTableCell-sizeSmall": { padding: "6px 5px 6px 5px" },
  },
  progressBar: {
    textAlign: "center",
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  popoverWidth: { width: "515px" },
  denseListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemRoot: { marginTop: 0, marginBottom: 0 },
  listHeader: {
    background: theme.palette.grey[700],
    color: theme.palette.common.white,
  },
  paddingTop: { paddingTop: "0px" },

  columnDrawerHandle: {
    width: "42px",
  },
  columnStatus: {
    width: "65px",
  },
  columnDocs: {
    width: "50px",
  },
  columnName: {},
  columnPlaceholder: {
    width: "95px",
  },
  columnDept: {},
  columnMemo: {
    width: "70px",
  },
  columnOutstanding: {
    width: "120px",
  },
  columnActions: {
    width: "115px",
  },
}));
