import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  blue: {
    color: theme.palette.primary.main,
  },
  subtitleColor: { color: theme.palette.grey[700] },
  tableLayout: {
    tableLayout: "fixed",
    "& .MuiTableCell-head": {
      padding: "10px 2px",
    },
    "& .MuiTableCell-root": {
      padding: "10px 2px",
    },
  },
  wordBreak: { overflowWrap: "break-word" },
  progressBar: {
    textAlign: "center",
  },
  redistributedIcon: {
    verticalAlign: "top",
  },
}));
