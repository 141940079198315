import React from "react";
import { TableCell, TableFooter, TableRow } from "@mui/material";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import { useStyles } from "./Requests.styles";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../services/usersService";

export const CommitmentRequestTableFooter = ({ totals, budgetFiscalYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentUser } = useUsers();
  const { roles } = currentUser;

  const isCommitmentAdmin = roles.includes("COMMITMENT_ADMIN");

  return (
    <>
      <TableFooter classes={{ root: classes.budgetTableFooter }}>
        <TableRow>
          <TableCell
            colSpan={3}
            classes={{ root: classes.footerCell }}
          ></TableCell>
          <TableCell align="right" classes={{ root: classes.footerCell }}>
            {t(`Requests.mainView.list.tableColumns.totalCommitmentRequested`)}
          </TableCell>
          <TableCell align="right" classes={{ root: classes.footerCell }}>
            {t(`Requests.mainView.list.tableColumns.totalCommitmentApproved`)}
          </TableCell>

          <TableCell align="right" classes={{ root: classes.footerCell }}>
            {t(`Requests.mainView.list.tableColumns.totalFyProjection`, {
              fyProjection: (parseInt(budgetFiscalYear, 10) % 100) - 1,
            })}
          </TableCell>
          <TableCell align="right" classes={{ root: classes.footerCell }}>
            {t(`Requests.mainView.list.tableColumns.totalFyBudget`, {
              fyBudgetOne: parseInt(budgetFiscalYear, 10) % 100,
            })}
          </TableCell>
          <TableCell
            align="right"
            key="fyActualsOne"
            classes={{ root: classes.footerCell }}
            colSpan={isCommitmentAdmin ? 3 : 2}
          ></TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="right"
            classes={{ root: classes.footerCell }}
            colSpan={3}
          >
            {t(`Requests.mainView.list.tableColumns.grandTotal`)}
          </TableCell>
          <TableCell
            align="right"
            key="totalCommitment"
            classes={{ root: classes.footerCell }}
          >
            {getAmount(totals?.totalRequested)}
          </TableCell>
          <TableCell
            align="right"
            key="fYTotalActualsThree"
            classes={{ root: classes.footerCell }}
          >
            {getAmount(totals?.totalApproved)}
          </TableCell>
          <TableCell
            align="right"
            key="fYTotalActualsTwo"
            classes={{ root: classes.footerCell }}
          >
            {getAmount(totals?.totalFyProjection)}
          </TableCell>
          <TableCell
            align="right"
            key="totalExpendituresTotal"
            classes={{ root: classes.footerCell }}
          >
            {getAmount(totals?.totalFyBudget)}
          </TableCell>
          <TableCell
            align="left"
            key="actions"
            classes={{ root: classes.footerCell }}
            colSpan={isCommitmentAdmin ? 3 : 2}
          ></TableCell>
        </TableRow>
      </TableFooter>
    </>
  );
};

const getAmount = (amount) => {
  const roundupFloat = Math.round(amount);
  return CurrencyFormat(isNaN(roundupFloat) ? 0 : roundupFloat);
};
