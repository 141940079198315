import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  dropZone: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "79px",
    border: "1px dashed #c6c6c6",
    borderRadius: "5px",
    "& .MuiSvgIcon-root": {
      display: "none",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      whiteSpace: "pre-line",
    },
    "&.MuiDropzoneArea-root": {
      minHeight: "79px",
    },
    "& .MuiSvgIcon-root ~ .MuiTypography-root": {
      whiteSpace: "nowrap",
    },
  },
});
