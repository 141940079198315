import React from "react";
import { useAuth } from "./services/authService";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { UsersProvider } from "./services/usersService";
import AppPage from "./components/AppPage";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { ASAlertProvider, ASAppPage } from "@stanford-tds/as-components";
//import {MedCommitTheme} from "./theme"
import { ASTheme } from "./components/MedCommitTheme";

// src/App.js
// App component containing the standard components and configuration for all applications.
// Applications should modify the AppPage component in components/AppPage and should leave this file unmodified.
const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <div style={{ display: "grid" }}>
      {auth.isAuthenticated && (
        <>
          <Helmet>
            <title>{t("app.title")}</title>
            <meta name="description" content={t("app.description")} />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,200italic,300italic,400italic,700italic|Fjord+One"
            />
          </Helmet>
          <BrowserRouter>
            <StylesProvider injectFirst>
              <StyledEngineProvider>
                <ThemeProvider theme={ASTheme}>
                  <ASAlertProvider>
                    <UsersProvider>
                      <ASAppPage>
                        <AppPage />
                      </ASAppPage>
                    </UsersProvider>
                  </ASAlertProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </StylesProvider>
          </BrowserRouter>
        </>
      )}
    </div>
  );
};

export default App;
