import { createTheme } from "@mui/material/styles";

const FONT_FAMILY = "Source sans pro";
const STANFORD_ACCENT_BLUE = "#009ABB";
const STANFORD_BACKGROUND_BLUE = "#F0F4F5";
const STANFORD_GRAY = "#999999";
const STANFORD_LIGHT_GRAY = "#CCCCCC";
const STANFORD_LINK_BLUE = "#007C92";
const STANFORD_LINE_GRAY = "#DDDDDD";
const STANFORD_MEDIUM_GRAY = "#666666";
const STANFORD_TEXT_GRAY = "#333333";
const WHITE = "#FFFFFF";

export const ASTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 15,
    fontWeight: 500,
  },
  palette: {
    primary: {
      main: STANFORD_ACCENT_BLUE,
      light: STANFORD_BACKGROUND_BLUE,
      dark: STANFORD_LINK_BLUE,
    },
    grey: {
      300: STANFORD_LINE_GRAY,
      400: STANFORD_LIGHT_GRAY,
      500: STANFORD_GRAY,
      700: STANFORD_MEDIUM_GRAY,
    },
    text: {
      primary: STANFORD_TEXT_GRAY,
      secondary: STANFORD_MEDIUM_GRAY,
    },
  },
  components: {
    /** Styling for Table component **/
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: STANFORD_MEDIUM_GRAY,
          color: WHITE,
        },
        head: {
          padding: "10px 8px",
        },
        root: {
          padding: "10px 8px",
          fontSize: "15px",
          "& a": {
            color: STANFORD_ACCENT_BLUE,
            "& .MuiSvgIcon-root": {
              color: STANFORD_TEXT_GRAY,
            },
          },
        },
        sizeSmall: {
          padding: "8px",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "hidden",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          alignSelf: "baseline",
        },
        root: {
          "&.Mui-active": {
            color: WHITE,
            opacity: 1,
            "&& .MuiSvgIcon-root": {
              opacity: 1,
              color: WHITE,
            },
          },
          "&:hover": {
            opacity: 1,
            color: WHITE,
            "&& .MuiSvgIcon-root": {
              opacity: 1,
              color: WHITE,
            },
          },
        },
      },
    },
    /** Styling for Checkbox component **/
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: STANFORD_TEXT_GRAY,
          },
        },
      },
    },
    /** Styling for Button component **/
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
        outlinedPrimary: {
          color: STANFORD_ACCENT_BLUE,
          borderColor: STANFORD_ACCENT_BLUE,
        },
        textPrimary: {
          color: STANFORD_ACCENT_BLUE,
        },
      },
    },
    /** Styling for Typography**/
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 500,
          fontSize: "25px",
          lineHeight: "37.5px",
        },
        h2: {
          fontSize: "21px",
          fontWeight: 600,
          lineHeight: "31.5px",
        },
        subtitle1: {
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "22.5px",
        },
        body1: {
          fontSize: "17px",
          fontWeight: 500,
          lineHeight: "25.5px",
        },
        body2: {
          fontSize: "15px",
          fontWeight: 500,
          lineHeight: "22.5px",
        },
        caption: {
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "19.5px",
        },
      },
    },
    /** Styling for MUI Link**/
    MuiLink: {
      styleOverrides: {
        root: {
          color: STANFORD_ACCENT_BLUE,
        },
      },
    },
    /** Styling for MUI Dialog Title**/
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "21px",
          fontWeight: 600,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.Mui-colorPrimary": {
            color: STANFORD_ACCENT_BLUE,
          },
        },
      },
    },
    /** Styling for MUI Icon Button**/
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: STANFORD_TEXT_GRAY,
        },
      },
    },
    /** Styling for MUI Tooltip**/
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "13px",
        },
      },
    },
    /** Styling for MUI Alert**/
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: 500,
        },
      },
    },
  },
});
