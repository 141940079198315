import React from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./FinancialHistoryForm.styles";
import {
  NavigateNext as NavigateNextIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { FormButton } from "../../../../UI/Button/FormButton";
import { Formik } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import {
  postFinancialHistory,
  getFiscalYear,
  putFinancialHistory,
  getFinancialHistoryById,
} from "../../../../../services/componentsService";
import { useUsers } from "../../../../../services/usersService";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

export const FinancialHistoryForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  // useStyles classes
  const classes = useStyles();
  // params using useParams
  const params = useParams();

  // useHistory hook to get history
  const history = useHistory();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  const { clearAlert, setAlert, alert } = useAlertContext();

  // handle close for form
  const handleClose = () => {
    history.push(
      `/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`
    );
  };

  const _handleSubmit = (values) => {
    params.fiscalYear
      ? putFinancialHistory(
          params.commitmentId,
          params.componentId,
          params.componentPlanId,
          values,
          fiscalYear,
          setLoading,
          clearAlert,
          setAlert,
          history,
          t
        )
      : postFinancialHistory(
          params.commitmentId,
          params.componentId,
          values,
          fiscalYear,
          setLoading,
          clearAlert,
          setAlert,
          history,
          t
        );
  };

  const [fiscalYear, setFiscalYear] = React.useState(params.fiscalYear || "");

  const [formDataResponse, setFormDataResponse] = React.useState({
    // Object keys use to create/edit form data
    budgetAmount: "",
    planAmount: "",
    longRangeFinancialForecast: "",
    yepAmount: "",
    fySpendCap: "",
  });

  const [, setLoading] = React.useState(false);

  const pageType = props.pageType;

  const financialHistoryFormFieldStr =
    "Commitments.financialHistory.create.form.fields";
  const financialHistoryCreateStr = "Commitments.financialHistory.create";
  const financialHistoryEditStr = "Commitments.financialHistory.edit";

  React.useEffect(() => {
    clearAlert();
    !params.fiscalYear &&
      getFiscalYear(setFiscalYear, params.componentId, setAlert, clearAlert);
    params.componentPlanId &&
      getFinancialHistoryById(
        params.componentPlanId,
        params.commitmentId,
        params.componentId,
        setFormDataResponse,
        clearAlert,
        setAlert
      );
    // eslint-disable-next-line
  }, [
    params.componentId,
    params.fiscalYear,
    params.commitmentId,
    params.componentPlanId,
  ]);

  const formatCurrency = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2);
    } else {
      if (pageType === "new") {
        return "";
      } else {
        return "0.00";
      }
    }
  };

  return (
    ((props.pageType === "new" &&
      permissions.CREATE_COMPONENT_FINANCIAL_PLAN) ||
      (props.pageType === "edit" &&
        permissions.EDIT_COMPONENT_FINANCIAL_PLAN)) && (
      <Formik
        initialValues={formDataResponse}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          // on submit handler
          _handleSubmit(values);
        }}
        validationSchema={finacialHistoryValidation}
      >
        {(props) => {
          const { values, handleSubmit, setFieldValue, dirty, isValid } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                classes={{
                  root: classes.mainContainer,
                }}
              >
                <Grid item xs={12}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <RouterLink to={`/commitments`}>
                      <Typography color="textSecondary">
                        {t("Commitments.mainView.title")}
                      </Typography>
                    </RouterLink>
                    <RouterLink
                      to={`/commitments/${params.commitmentId}/components/${params.componentId}/financial-history`}
                    >
                      <Typography color="textSecondary">
                        {t("Commitments.financialHistory.mainView.title")}
                      </Typography>
                    </RouterLink>
                    <Typography color="textPrimary">
                      {pageType === "new"
                        ? t(`${financialHistoryCreateStr}.breadcrumbTitle`)
                        : t(`${financialHistoryEditStr}.breadcrumbTitle`)}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h1">
                    {pageType === "new"
                      ? `${t(`${financialHistoryCreateStr}.title`, {
                          fiscalYear,
                        })}`
                      : `${t(`${financialHistoryEditStr}.title`, {
                          fiscalYear,
                        })}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {alert.exists && <ASAlert />}
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  classes={{
                    container: classes.formContainer,
                  }}
                >
                  <Grid item xs={4}>
                    <NumberFormat
                      id="budgetAmount"
                      label={t(
                        `${financialHistoryFormFieldStr}.budgetAmount.label`
                      )}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.budgetAmount)}
                      isNumericString={true}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      allowNegative={true}
                      onValueChange={(values) => {
                        setFieldValue("budgetAmount", values.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      id="planAmount"
                      label={t(
                        `${financialHistoryFormFieldStr}.planAmount.label`
                      )}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.planAmount)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      allowNegative={true}
                      onValueChange={(values) => {
                        setFieldValue("planAmount", values.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      id="longRangeFinancialForecast"
                      label={t(
                        `${financialHistoryFormFieldStr}.longRangeFinancialForecast.label`
                      )}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.longRangeFinancialForecast)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      allowNegative={true}
                      onValueChange={(values) => {
                        setFieldValue(
                          "longRangeFinancialForecast",
                          values.value
                        );
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  classes={{
                    container: classes.formContainer,
                  }}
                >
                  <Grid item xs={4}>
                    <NumberFormat
                      id="yepAmount"
                      label={t(
                        `${financialHistoryFormFieldStr}.yepAmount.label`
                      )}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.yepAmount)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      allowNegative={true}
                      onValueChange={(values) => {
                        setFieldValue("yepAmount", values.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      id="fySpendCap"
                      label={t(
                        `${financialHistoryFormFieldStr}.fySpendCap.label`
                      )}
                      customInput={TextField}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={formatCurrency(values.fySpendCap)}
                      isAllowed={(values) => {
                        const { value } = values;
                        return (
                          value === "-" ||
                          (value >= -999999999.99 && value <= 999999999.99)
                        );
                      }}
                      allowNegative={true}
                      onValueChange={(values) => {
                        setFieldValue("fySpendCap", values.value);
                      }}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      xs={7}
                      spacing={2}
                    >
                      <FormButton
                        cancel={handleClose}
                        save={{ disabled: !dirty || !isValid }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    )
  );
};

const finacialHistoryValidation = Yup.object().shape(
  {
    budgetAmount: Yup.number().when(
      ["planAmount", "longRangeFinancialForecast", "yepAmount", "fySpendCap"],
      {
        is: (planAmount, longRangeFinancialForecast, yepAmount, fySpendCap) =>
          !planAmount &&
          !longRangeFinancialForecast &&
          !yepAmount &&
          !fySpendCap,
        then: Yup.number().required(),
      }
    ),
    planAmount: Yup.number().when(
      ["budgetAmount", "longRangeFinancialForecast", "yepAmount", "fySpendCap"],
      {
        is: (budgetAmount, longRangeFinancialForecast, yepAmount, fySpendCap) =>
          !budgetAmount &&
          !longRangeFinancialForecast &&
          !yepAmount &&
          !fySpendCap,
        then: Yup.number().required(),
      }
    ),
    longRangeFinancialForecast: Yup.number().when(
      ["budgetAmount", "planAmount", "yepAmount", "fySpendCap"],
      {
        is: (budgetAmount, planAmount, yepAmount, fySpendCap) =>
          !budgetAmount && !planAmount && !yepAmount && !fySpendCap,
        then: Yup.number().required(),
      }
    ),
    yepAmount: Yup.number().when(
      [
        "budgetAmount",
        "planAmount",
        "longRangeFinancialForecast",
        "fySpendCap",
      ],
      {
        is: (
          budgetAmount,
          planAmount,
          longRangeFinancialForecast,
          fySpendCap
        ) =>
          !budgetAmount &&
          !planAmount &&
          !longRangeFinancialForecast &&
          !fySpendCap,
        then: Yup.number().required(),
      }
    ),
    fySpendCap: Yup.number().when(
      ["budgetAmount", "planAmount", "longRangeFinancialForecast", "yepAmount"],
      {
        is: (budgetAmount, planAmount, longRangeFinancialForecast, yepAmount) =>
          !budgetAmount &&
          !planAmount &&
          !longRangeFinancialForecast &&
          !yepAmount,
        then: Yup.number().required(),
      }
    ),
  },
  [
    ["budgetAmount", "planAmount"],
    ["budgetAmount", "longRangeFinancialForecast"],
    ["budgetAmount", "yepAmount"],
    ["budgetAmount", "fySpendCap"],
    ["planAmount", "longRangeFinancialForecast"],
    ["planAmount", "yepAmount"],
    ["planAmount", "fySpendCap"],
    ["longRangeFinancialForecast", "yepAmount"],
    ["longRangeFinancialForecast", "fySpendCap"],
    ["yepAmount", "fySpendCap"],
  ]
);
