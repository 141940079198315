import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { getPIOrComponentOwnerByMatch } from "../../../services/componentsService";

export const ComponentOwnerField = ({
  value,
  onChange,
  setFieldError,
  labelText,
  helperText,
  error,
  className,
  isViewOnly = false,
  ...passedParams
}) => {
  const { t } = useTranslation();

  const shrinkProps = isViewOnly && { shrink: true };
  const label =
    labelText ??
    t(
      `Commitments.commitmentComponents.create.form.fields.piOrComponentOwner.label`
    );

  const [selectedValue, setSelectedValue] = React.useState(value);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <Autocomplete
        options={options || []}
        loading={loading}
        freeSolo={true}
        loadingText={t(
          `Commitments.commitmentComponents.create.form.fieldDataLoadingText`
        )}
        getOptionLabel={(option) =>
          option.displayText ? option.displayText : ""
        }
        value={selectedValue}
        onChange={(event, val) => {
          setSelectedValue(val);
          onChange(event, val);
        }}
        onInputChange={(event, val) => {
          // to allow only on synthetic events
          if (event) {
            const cancelSource = axios.CancelToken.source();

            getPIOrComponentOwnerByMatch(
              val,
              () => {
                //onBeforeSend
                setFieldError(passedParams.id);
                setLoading(true);
              },
              () => {
                //onError
                setLoading(false);
              },
              (responseData) => {
                //onSuccess
                setOptions(responseData.values || []);
                setLoading(false);
              },
              cancelSource
            );

            onChange(event, val);
          }
        }}
        clearIcon={false}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              variant={isViewOnly ? "standard" : "outlined"}
              InputLabelProps={{ ...shrinkProps }}
              className={className}
              helperText={helperText}
              error={error}
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
                notched: label?.length > 0 ? undefined : false,
                onBlur: (event) => {
                  setSelectedValue({
                    displayText: event.target.value,
                  });
                },
              }}
            />
          );
        }}
        {...passedParams}
      />
    </>
  );
};
