import axios from "axios";

/**
 * Gets a collection of Commitment Requests.
 *
 * @param {object} params
 * @param {function} onBeforeSend
 * @param {function} onError
 * @param {function} onSuccess
 * @param {object} cancelSource
 */
export const getCommitmentRequests = async (
  {
    page,
    rowsPerPage,
    orderBy,
    order,
    budgetYear,
    name,
    departmentName,
    dispositionStatus,
  },
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  const params = {
    p: page + 1,
    budgetYear,
    name,
    departmentName,
    dispositionStatus,
    ps: rowsPerPage,
    s: `${orderBy}:${order}`,
  };
  try {
    onBeforeSend();

    const response = await axios({
      url: `/requests`,
      cancelToken: cancelSource?.token,
      params,
    });

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};

/**
 * Updates a collection of Commitment Requests.
 * @param {*} values
 * @param {*} onBeforeSend
 * @param {*} onError
 * @param {*} onSuccess
 * @param {*} cancelSource
 */
export const patchCommitmentRequests = async (
  values,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  try {
    onBeforeSend(values);

    await axios({
      method: "patch",
      url: `/requests`,
      data: values,
      cancelToken: cancelSource?.token,
    });

    onSuccess(values);
  } catch (error) {
    onError(error);
  }
};

/**
 * Gets a Commitment Request.
 *
 * @param {number} commitmentId
 * @param {function} onBeforeSend
 * @param {function} onError
 * @param {function} onSuccess
 * @param {object} cancelSource
 */
export const getCommitmentRequestById = async (
  commitmentId,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  try {
    onBeforeSend();

    const response = await axios({
      url: `/requests/${commitmentId}`,
      cancelToken: cancelSource?.token,
    });

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};

/**
 * Updated a Commitment Request.
 *
 * @param {object} values
 * @param {number} commitmentId
 * @param {function} onBeforeSend
 * @param {function} onError
 * @param {function} onSuccess
 * @param {object} cancelSource
 */
export const patchCommitmentRequest = async (
  values,
  commitmentId,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  try {
    onBeforeSend(values);

    await axios({
      method: "patch",
      url: `/requests/${commitmentId}`,
      data: values,
      cancelToken: cancelSource?.token,
    });

    onSuccess(values);
  } catch (error) {
    onError(error);
  }
};

/**
 * Updates a Component Request.
 *
 * @param {object} values
 * @param {number} commitmentId
 * @param {number} componentId
 * @param {function} onBeforeSend
 * @param {function} onError
 * @param {function} onSuccess
 * @param {object} cancelSource
 */
export const patchComponentRequest = async (
  values,
  commitmentId,
  componentId,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  try {
    onBeforeSend(values);

    await axios({
      method: "patch",
      url: `/requests/${commitmentId}/components/${componentId}`,
      data: values,
      cancelToken: cancelSource?.token,
    });

    onSuccess(values);
  } catch (error) {
    onError(error);
  }
};

/**
 * Autocomplete endpoint used by the Commitment Name Filter.
 *
 * @param {string} query
 * @param {string} matchOn
 * @param {string} budgetYear
 * @param {function} setCommitmentNameOptions
 * @param {function} setLoading
 * @param {function} setCommitmentNameFilterError
 */
export const getCommitmentByMatch = async (
  query,
  matchOn,
  budgetYear,
  setCommitmentNameOptions,
  setLoading,
  setCommitmentNameFilterError
) => {
  setLoading(true);
  const params = { matchOn, query, budgetYear };
  try {
    setCommitmentNameFilterError({
      exist: false,
      message: null,
    });
    const response = await axios({
      url: `/requests/autocomplete`,
      params,
    });
    setCommitmentNameOptions(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setCommitmentNameFilterError({
      exist: true,
      message: error.message,
    });
  } finally {
    setLoading(false);
  }
};

export const getInitialRequestsData = async (
  setBudgetYearOptions,
  setBudgetYear,
  filters,
  setFilters,
  setBudgetYearFilterError,
  { size, value }
) => {
  const budgetYearRequest = {
    url: `/requests/budget-years/autocomplete`,
    params: {
      size,
      value,
    },
  };

  const requests = [budgetYearRequest];
  try {
    let response = await Promise.all(
      requests.map((request) => axios(request).then((response) => response))
    );

    if (response[0].data.count) {
      if (response[0].data.values) {
        setBudgetYearOptions(response[0].data.values);
        let responseValue = response[0].data.values[0];
        if (responseValue) {
          setBudgetYear(responseValue.displayText);
          setFilters({
            ...filters,
            budgetYear: responseValue.displayText,
          });
        }
      }
    }
  } catch (error) {
    setBudgetYearFilterError({
      exist: true,
      message: error.message,
    });
  }
};
