import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell } from "@mui/material";
import {
  getLastYearProjectionHeading,
  getCurrentYearBudgetHeading,
  getNextYearPlanHeading,
  getYearAfterNextPlanHeading,
} from "../../../Budgeting/Budgeting";

export const CommitmentComponentRequestHeaders = (props) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" key="commitmentName" width="250">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.componentName"
          )}
          {!props.isViewOnly && <sup>*</sup>}
        </TableCell>
        <TableCell align="right" key="amount" width="100">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.amount"
          )}
        </TableCell>
        <TableCell align="right" key="projection" width="100">
          {getLastYearProjectionHeading(t, props.currentFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyBudgetTwoBudget" width="100">
          {getCurrentYearBudgetHeading(t, props.currentFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyPlanOnePlan" width="100">
          {getNextYearPlanHeading(t, props.currentFiscalYear)}
        </TableCell>
        <TableCell align="right" key="fyPlanTwoPlan" width="100">
          {getYearAfterNextPlanHeading(t, props.currentFiscalYear)}
        </TableCell>
        <TableCell align="left" key="startDate" width="120">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.startDate"
          )}
        </TableCell>
        <TableCell align="left" key="endDate" width="120">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.endDate"
          )}
        </TableCell>
        <TableCell align="left" key="requestComments" width="140">
          {t(
            "Commitments.commitmentComponents.mainView.list.budgetTableHeaders.requestComments"
          )}
        </TableCell>
        {!props.isViewOnly && (
          <TableCell align="center" key="actions" width="50">
            {t(`Budgeting.mainView.list.tableColumns.actions`)}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
