import React, { Fragment } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  Paper,
  Grid,
  Button,
  Link,
} from "@mui/material";
import { useStyles } from "./Budgeting.styles";
import { Link as RouterLink } from "react-router-dom";
import { BudgetTableHeader } from "./BudgetTableHeader";
import { BudgetTableFooter } from "./BudgetTableFooter";
import {
  getBudget,
  putBudgetByComponentId,
} from "../../services/budgetingService";
import { ComponentTypes, LinkToFacultyNewHireTemplate } from "../../constants";
import {
  CloudDownload as CloudDownloadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PlaylistAddRounded,
  NewReleases as NewReleasesIcon,
  PriceChange as PriceChangeIcon,
  AddCircle as AddCircleIcon,
  Attachment as AttachmentIcon,
  AssignmentReturnedOutlined as AssignmentReturnedOutlinedIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useDownload } from "../UI/Download";
import { useTranslation } from "react-i18next";
import { InformationPanel } from "./InformationPanel";
import { useBudgetingContext } from "../../services/budgetingContext";
import { InlineForm } from "./InlineForm";
import { useAlertContext } from "@stanford-tds/as-components";
import { useUsers } from "../../services/usersService";
import axios from "axios";
import fileTypes from "../../Utils/DropZone/fileTypes.json";

export const BudgetTable = ({
  setSnackBar,
  isBudgetEditableByCurrentUser,
  loading: inhertiedLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const { getDocument, downloadInProgress } = useDownload();

  const { setAlert, clearAlert } = useAlertContext();

  // State variables
  const [budget, setBudget] = React.useState([]);
  const [loading, setLoading] = React.useState(inhertiedLoading);

  const [editComponent, setEditComponent] = React.useState(0);

  const [refresh, setRefresh] = React.useState(0);

  // context
  const {
    orgCode,
    orgName,
    setInfoPanelDisabled,
    budgetFiscalYear,
    budgetType,
    getQueryParams,
  } = useBudgetingContext();

  const colorForAllOtherButtons = editComponent ? "disabled" : "inherit";

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    orgCode && budgetFiscalYear
      ? getBudget(
          getQueryParams, // includes the fiscalYear value
          setBudget,
          setLoading,
          setAlert,
          clearAlert,
          cancelSource
        )
      : setBudget([]);
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [budgetFiscalYear, orgCode, budgetType, refresh]);

  return (
    <>
      <Grid container item xs={12} mb={2} justifyContent="space-between">
        <Grid item xs={10}>
          <Paper children={<InformationPanel />} elevation={2} />
        </Grid>
        <Grid item>
          {isBudgetEditableByCurrentUser() &&
            permissions.CREATE_REQUEST_COMMITMENTS && (
              <RouterLink
                to={{
                  pathname: "/commitments/requests/new",
                  state: {
                    orgCode,
                    orgName,
                    currentFiscalYear: budgetFiscalYear,
                    budgetEditableByCurrentUser:
                      isBudgetEditableByCurrentUser(),
                  },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                >
                  {t(`Budgeting.mainView.newRequest`)}
                </Button>
              </RouterLink>
            )}
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          aria-label="Commitments for the current fiscal year"
          aria-live="polite"
          stickyHeader
          classes={{ root: classes.tableLayout }}
        >
          <BudgetTableHeader budgetFiscalYear={budgetFiscalYear} />
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan="16" classes={{ root: classes.progressBar }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading && !budget.commitments && (
              <TableRow>
                <TableCell colSpan="16" classes={{ root: classes.progressBar }}>
                  {t("globals.list.messages.noData")}
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              budget.commitments &&
              budget.commitments.values.map((i, indexI) => (
                <Fragment key={indexI}>
                  <TableRow>
                    <TableCell
                      align="left"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography variant="body2" noWrap={true}>
                        {i.commitmentType === "REQUEST" && (
                          <Tooltip title={t(`Budgeting.mainView.newRequest`)}>
                            <NewReleasesIcon
                              size="small"
                              className={classes.yellow}
                            />
                          </Tooltip>
                        )}

                        <Tooltip
                          title={i.commitmentName}
                          placement="top"
                          classes={{
                            tooltip: classes.tooltipStyle,
                          }}
                        >
                          {getCommitmentName(
                            editComponent,
                            i.commitmentId,
                            i.commitmentName
                          )}
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.totalCommitmentRequested)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsThree)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsTwo)}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.totalExpenditure)}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.unspentCommitment)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyActualsOne)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudgetOne)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyProjectionOne)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudget)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyPlanOne)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyPlanTwo)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.availableAfterFYPlanTwo)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={{ root: classes.parentCell }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        align="right"
                      >
                        {getAmount(i.fyBudgetCap)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.parentCell }}
                    ></TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.parentCell }}
                    >
                      <Grid container justifyContent="flex-start" wrap="nowrap">
                        {isBudgetEditableByCurrentUser() &&
                          permissions.CREATE_REQUEST_COMPONENTS && (
                            <Grid item>
                              <RouterLink
                                to={{
                                  pathname: `/commitments/${i.commitmentId}/components/requests/new`,
                                  state: {
                                    currentFiscalYear: budgetFiscalYear,
                                  },
                                }}
                                className={classes.actionLinks}
                              >
                                <Tooltip
                                  title={t("Budgeting.mainView.newComponent")}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <PlaylistAddRounded
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.EDIT_REQUEST_COMMITMENTS &&
                          i.commitmentType === "REQUEST" && (
                            <Grid item>
                              <RouterLink
                                to={{
                                  pathname: `/commitments/requests/${i.commitmentId}/edit`,
                                  state: {
                                    currentFiscalYear: budgetFiscalYear,
                                  },
                                }}
                                className={classes.actionLinks}
                              >
                                <Tooltip
                                  title={t("Budgeting.mainView.editRequest")}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <EditIcon
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.DELETE_REQUEST_COMMITMENTS &&
                          i.commitmentType === "REQUEST" && (
                            <Grid item>
                              <RouterLink
                                to={{
                                  pathname: `/commitments/requests/${i.commitmentId}/delete`,
                                  state: {
                                    currentFiscalYear: budgetFiscalYear,
                                  },
                                }}
                                className={classes.actionLinks}
                              >
                                <Tooltip
                                  title={t("Budgeting.mainView.deleteRequest")}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  <DeleteIcon
                                    size="small"
                                    color={colorForAllOtherButtons}
                                  />
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                        {isBudgetEditableByCurrentUser() &&
                          permissions.UPLOAD_COMPLETED_NEW_HIRE_EXCEL_TEMPLATE &&
                          i.commitmentRequestType === "RECRUITMENT" && (
                            <>
                              <Grid item>
                                <RouterLink
                                  to={{
                                    pathname: getRecruitmentDocumentPageUrl(i),
                                    state: {
                                      docVariant: "NEW_HIRE_EXCEL_TEMPLATE",
                                      documentType: "RECRUITMENT",
                                      documentName:
                                        "New Hire Template Complete",
                                      documentNotes:
                                        "New Hire Template Complete",
                                      departmentAdminView: true,
                                      acceptedFiles: [
                                        "application/vnd.ms-excel",
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                      ],
                                      dropzoneText: t(
                                        "Commitments.commitmentDocuments.create.form.fields.uploadedDocument.label2",
                                        {
                                          extensionList: fileTypes[
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                          ]
                                            .map((x) => `.${x}`)
                                            .join(", "),
                                        }
                                      ),
                                    },
                                  }}
                                  className={classes.actionLinks}
                                >
                                  <Tooltip
                                    title={t(
                                      "Commitments.commitmentDocuments.mainView.addNewHireTemplate"
                                    )}
                                    placement="top"
                                    classes={{
                                      tooltip: classes.tooltipStyle,
                                    }}
                                    disableFocusListener={Boolean(
                                      editComponent
                                    )}
                                    disableHoverListener={Boolean(
                                      editComponent
                                    )}
                                    disableTouchListener={Boolean(
                                      editComponent
                                    )}
                                  >
                                    <span className={classes.compositeIcon}>
                                      <AttachmentIcon
                                        size="small"
                                        color={colorForAllOtherButtons}
                                        className="mainIcon"
                                      />
                                      <AddIcon
                                        size="small"
                                        color={colorForAllOtherButtons}
                                        className="decorativeIcon"
                                      />
                                    </span>
                                  </Tooltip>
                                </RouterLink>
                              </Grid>
                              <Grid item>
                                <Tooltip
                                  title={
                                    t(
                                      "Commitments.commitmentDocuments.mainView.list.downloadCommitmentDocumentsTooltip"
                                    ) +
                                    " " +
                                    t(
                                      "Commitments.create.recruitment.templateDownloadTooltip"
                                    )
                                  }
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                  disableFocusListener={Boolean(editComponent)}
                                  disableHoverListener={Boolean(editComponent)}
                                  disableTouchListener={Boolean(editComponent)}
                                >
                                  {/* This button triggers a file download from a cloud CDN location */}
                                  <IconButton
                                    size="small"
                                    component={Link}
                                    href={
                                      LinkToFacultyNewHireTemplate.downloadUrl
                                    }
                                    download={
                                      LinkToFacultyNewHireTemplate.filename
                                    }
                                    className={classes.actionButton}
                                  >
                                    <AssignmentReturnedOutlinedIcon
                                      size="small"
                                      color={colorForAllOtherButtons}
                                      className="mainIcon"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </>
                          )}
                        {i._links?.completedNewHireDocument && (
                          <Grid item justifyContent="flex-end">
                            <Tooltip
                              title={t(
                                "Commitments.commitmentDocuments.mainView.viewNewHireTemplate"
                              )}
                              placement="top"
                              classes={{
                                tooltip: classes.tooltipStyle,
                              }}
                              disableFocusListener={Boolean(editComponent)}
                              disableHoverListener={Boolean(editComponent)}
                              disableTouchListener={Boolean(editComponent)}
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  className={classes.downloadButton}
                                  disabled={!!downloadInProgress}
                                  onClick={() => {
                                    let url = parseUrl(
                                      i._links?.completedNewHireDocument.href
                                    );
                                    getDocument(
                                      axios.defaults.baseURL + url.pathname,
                                      i._links?.completedNewHireDocument?.title
                                    );
                                  }}
                                >
                                  {downloadInProgress ? (
                                    <CircularProgress size={15} />
                                  ) : (
                                    <CloudDownloadIcon />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                  {i?.componentType?.values?.map((j, indexJ) => (
                    <Fragment key={indexJ}>
                      {/* console.log(j, indexJ) */}

                      {/* Component Type Label/Header Row */}
                      <TableRow classes={{ root: classes.childRow }}>
                        <TableCell
                          classes={{ root: classes.childCellPrimary }}
                          align="left"
                        >
                          <Typography
                            align="left"
                            variant="body2"
                            style={{ fontWeight: 600 }}
                          >
                            {t(ComponentTypes[j.componentType])}
                          </Typography>
                        </TableCell>
                        <TableCell
                          colSpan="15"
                          classes={{ root: classes.childCellPrimary }}
                        ></TableCell>
                      </TableRow>

                      {/* Component Rows */}
                      {j.components.values.map((k, indexK) =>
                        k.componentId === editComponent ? (
                          <InlineForm
                            row={k}
                            commitmentId={i.commitmentId}
                            setEditComponent={setEditComponent}
                            putBudgetByComponentId={putBudgetByComponentId}
                            componentId={k.componentId}
                            budgetFiscalYear={budgetFiscalYear}
                            setSnackBar={setSnackBar}
                            setRefresh={setRefresh}
                            editComponent={editComponent}
                            setInfoPanelDisabled={setInfoPanelDisabled}
                            key={indexK}
                          />
                        ) : (
                          <TableRow
                            key={indexK}
                            classes={{ root: classes.childRow }}
                          >
                            <TableCell
                              classes={{ root: classes.childCellPrimary }}
                            >
                              <Typography variant="body2" noWrap={true}>
                                {k.componentType === "REQUEST" && (
                                  <Tooltip
                                    title={t(`Budgeting.mainView.newComponent`)}
                                  >
                                    <NewReleasesIcon
                                      size="small"
                                      className={classes.yellow}
                                    />
                                  </Tooltip>
                                )}
                                <RenderPTAs
                                  component={k}
                                  editComponent={editComponent}
                                  viewPtas={permissions.VIEW_PTAS}
                                />{" "}
                                <Tooltip
                                  title={k.componentName}
                                  placement="top"
                                  classes={{
                                    tooltip: classes.tooltipStyle,
                                  }}
                                >
                                  {getComponentLink(
                                    editComponent,
                                    i.commitmentId,
                                    k.componentId,
                                    k.componentName
                                  )}
                                </Tooltip>
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalComponentAmountRequested)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsThree)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsTwo)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalExpenditure)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.totalUnspentAmount)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyActualsOne)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudgetOne)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyProjectionOne)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudget)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyPlanOne)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyPlanTwo)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.availableAfterFYPlanTwo)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="right"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="right"
                              >
                                {getAmount(k.fyBudgetCap)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                align="left"
                              >
                                {k.comments}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              classes={{ root: classes.childCellSecondary }}
                            >
                              <Grid
                                container
                                justifyContent="flex-start"
                                wrap="nowrap"
                              >
                                {k._links?.edit && (
                                  <Grid item>
                                    <Tooltip
                                      title={t(
                                        "Budgeting.mainView.list.editBudgetTooltip"
                                      )}
                                      placement="top"
                                      classes={{
                                        tooltip: classes.tooltipStyle,
                                      }}
                                      disableFocusListener={Boolean(
                                        editComponent
                                      )}
                                      disableHoverListener={Boolean(
                                        editComponent
                                      )}
                                      disableTouchListener={Boolean(
                                        editComponent
                                      )}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          if (!editComponent) {
                                            setEditComponent(k.componentId);
                                            setInfoPanelDisabled(true);
                                          }
                                        }}
                                        className={classes.actionButton}
                                      >
                                        <PriceChangeIcon
                                          size="small"
                                          color={colorForAllOtherButtons}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}

                                {k.componentType === "REQUEST" &&
                                  k._links?.edit && (
                                    <Grid item>
                                      <RouterLink
                                        to={{
                                          pathname: `/commitments/${i.commitmentId}/components/requests/${k.componentId}/edit`,
                                          state: {
                                            currentFiscalYear: budgetFiscalYear,
                                          },
                                        }}
                                        className={classes.actionLinks}
                                      >
                                        <Tooltip
                                          title={t(
                                            "Budgeting.mainView.editComponent"
                                          )}
                                          placement="top"
                                          classes={{
                                            tooltip: classes.tooltipStyle,
                                          }}
                                          disableFocusListener={Boolean(
                                            editComponent
                                          )}
                                          disableHoverListener={Boolean(
                                            editComponent
                                          )}
                                          disableTouchListener={Boolean(
                                            editComponent
                                          )}
                                        >
                                          <EditIcon
                                            size="small"
                                            color={colorForAllOtherButtons}
                                          />
                                        </Tooltip>
                                      </RouterLink>
                                    </Grid>
                                  )}

                                {k.componentType === "REQUEST" &&
                                  k._links?.edit && (
                                    <Grid item>
                                      <RouterLink
                                        to={{
                                          pathname: `/commitments/${i.commitmentId}/components/requests/${k.componentId}/delete`,
                                          state: {
                                            currentFiscalYear: budgetFiscalYear,
                                          },
                                        }}
                                        className={classes.actionLinks}
                                      >
                                        <Tooltip
                                          title={t(
                                            "Budgeting.mainView.deleteComponent"
                                          )}
                                          placement="top"
                                          classes={{
                                            tooltip: classes.tooltipStyle,
                                          }}
                                          disableFocusListener={Boolean(
                                            editComponent
                                          )}
                                          disableHoverListener={Boolean(
                                            editComponent
                                          )}
                                          disableTouchListener={Boolean(
                                            editComponent
                                          )}
                                        >
                                          <DeleteIcon
                                            size="small"
                                            color={colorForAllOtherButtons}
                                          />
                                        </Tooltip>
                                      </RouterLink>
                                    </Grid>
                                  )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
          </TableBody>
          <BudgetTableFooter
            budget={budget}
            budgetFiscalYear={budgetFiscalYear}
          />
        </Table>
      </TableContainer>
    </>
  );
};

// HELPER FUNCTIONS

// getAmount - to format amount
export const getAmount = (amount) => {
  if (amount) {
    return CurrencyFormatMarkup(amount);
  } else {
    return `$0`;
  }
};

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  return `${project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-${
    task ? task : "\u00A0\u00A0\u00A0"
  }-${award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"}`;
}

export const RenderPTAs = ({ component, editComponent, viewPtas }) => {
  if (component.validProject && component.validTask && component.validAward) {
    const pta = PTAFormat(component.project, component.task, component.award);
    if (!editComponent && viewPtas) {
      return (
        <RouterLink target="_blank" to={`/budgeting/ptas/${pta}`}>
          <Typography variant="body2" component="span">
            {pta}
          </Typography>
        </RouterLink>
      );
    } else {
      return (
        <Typography variant="body2" component="span">
          {pta}
        </Typography>
      );
    }
  } else {
    return PTAFormat(component.project, component.task, component.award);
  }
};

export const getComponentLink = (
  editComponent,
  commitmentId,
  componentId,
  componentName
) => {
  if (!editComponent) {
    return (
      <RouterLink
        target="_blank"
        to={`/commitments/${commitmentId}/components/${componentId}/financial-history`}
      >
        <Typography
          display="inline"
          component={"span"}
          style={{ marginLeft: 10 }}
          variant="body2"
        >
          {componentName}
        </Typography>
      </RouterLink>
    );
  } else {
    return (
      <Typography
        display="inline"
        component={"span"}
        style={{ marginLeft: 10 }}
        variant="body2"
      >
        {componentName}
      </Typography>
    );
  }
};

const getCommitmentName = (editComponent, commitmentId, commitmentName) => {
  if (!editComponent) {
    return (
      <RouterLink
        target="_blank"
        to={`/budgeting/commitments/${commitmentId}/view`}
      >
        <Typography
          noWrap={true}
          variant="body2"
          display="inline"
          component={"span"}
        >
          {commitmentName}
        </Typography>
      </RouterLink>
    );
  } else {
    return (
      <Typography
        noWrap={true}
        variant="body2"
        display="inline"
        component={"span"}
      >
        {commitmentName}
      </Typography>
    );
  }
};

export function CurrencyFormatMarkup(value) {
  return (
    <>
      <Typography variant="inherit" component="span">
        {value !== undefined && "$"}
      </Typography>
      <Typography
        component="span"
        variant="inherit"
        color={`${(value < 0 && "error") || "inherit"}`}
      >
        {value !== undefined &&
          `${(parseFloat(value) < 0 && "(") || ""}${new Intl.NumberFormat(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )
            .format(Math.abs(value))
            .split("$")[1]
            .replace(/\D00$/, "")}${(parseFloat(value) < 0 && ")") || ""}`}
      </Typography>
    </>
  );
}

const getRecruitmentDocumentPageUrl = (commitment) => {
  const documentId = !!commitment?.recruitmentDocument?._links?.edit
    ? new URL(commitment.recruitmentDocument._links.edit.href).pathname
        .split("/")
        .pop()
    : null;

  return documentId
    ? `/commitments/${commitment.commitmentId}/documents/${documentId}/edit`
    : `/commitments/${commitment.commitmentId}/documents/new`;
};

/**
 * Parses any URL into it's component parts.
 *
 * @param {string} url to be parsed
 * @returns {object} map of URL components
 */
const parseUrl = (url) => {
  var tmpLink = document.createElement("a");
  tmpLink.href = url;

  let { protocol, host, hostname, port, pathname, hash, search, origin } =
    tmpLink;

  // IE does not prefix with a slash
  if (pathname && pathname[0] !== "/") {
    pathname = "/" + pathname;
  }
  return { protocol, host, hostname, port, pathname, hash, search, origin };
};
