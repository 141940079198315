import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./Expenses.styles";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Paper,
  Typography,
  Tooltip,
  TablePagination,
  CircularProgress,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  Check as CheckIcon,
  FormatListNumberedRtl as FormatListNumberedRtlIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { CurrencyFormat } from "../../Utils/CurrencyFormat";
import PropTypes from "prop-types";
import {
  getAllExpenses,
  getRevalidateAndPost,
} from "../../services/expensesService";
import { RedistributeExpenses } from "./RedistributeExpenses";
import { useExpensesContext } from "../../services/expensesContext";
import { NotificationStatus } from "./NotificationStatus";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const tableColumnsStr = "UploadedExpenses.mainView.list.tableColumns";
  const filterPanelStr = "UploadedExpenses.filterPanelItems.options";

  const { sort } = useExpensesContext();

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          key="notification"
          width="105"
          sortDirection={
            sort.column === "notification.status" ? sort.orderBy : false
          }
        >
          <TableSortLabel
            active={sort.column === "notification.status"}
            direction={
              sort.column === "notification.status" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("notification.status")}
          >
            {t(`${filterPanelStr}.notification.label`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="fiscalYear"
          width="65"
          sortDirection={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "fiscalYear"}
            direction={sort.column === "fiscalYear" ? sort.orderBy : "asc"}
            onClick={createSortHandler("fiscalYear")}
          >
            {t(`${filterPanelStr}.fiscalYear.label`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="glPeriod"
          width="70"
          sortDirection={sort.column === "glPeriod" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "glPeriod"}
            direction={sort.column === "glPeriod" ? sort.orderBy : "asc"}
            onClick={createSortHandler("glPeriod")}
          >
            {t(`${tableColumnsStr}.glPeriod`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="commitmentName"
          width="110"
          sortDirection={
            sort.column === "commitment.name" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "commitment.name"}
            direction={sort.column === "commitment.name" ? sort.orderBy : "asc"}
            onClick={createSortHandler("commitment.name")}
          >
            {t(`${tableColumnsStr}.commitmentName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="componentName"
          width="110"
          sortDirection={
            sort.column === "component.componentName" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "component.componentName"}
            direction={
              sort.column === "component.componentName" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("component.componentName")}
          >
            {t(`${tableColumnsStr}.componentName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="pta"
          width="70"
          sortDirection={sort.column === "pta" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "pta"}
            direction={sort.column === "pta" ? sort.orderBy : "asc"}
            onClick={createSortHandler("pta")}
          >
            {t(`${tableColumnsStr}.pta`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="expenditureCategory"
          width="110"
          sortDirection={
            sort.column === "expenditure.name" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "expenditure.name"}
            direction={
              sort.column === "expenditure.name" ? sort.orderBy : "asc"
            }
            onClick={createSortHandler("expenditure.name")}
          >
            {t(`${tableColumnsStr}.expenditureCategory`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="right"
          key="mtdAmount"
          width="90"
          sortDirection={sort.column === "mtdAmount" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "mtdAmount"}
            direction={sort.column === "mtdAmount" ? sort.orderBy : "asc"}
            onClick={createSortHandler("mtdAmount")}
          >
            {t(`${tableColumnsStr}.mtdAmount`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="center"
          key="posted"
          width="70"
          sortDirection={sort.column === "isPosted" ? sort.orderBy : "asc"}
        >
          <TableSortLabel
            active={sort.column === "isPosted"}
            direction={sort.column === "isPosted" ? sort.orderBy : "asc"}
            onClick={createSortHandler("isPosted")}
          >
            {t(`${filterPanelStr}.posted.label`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="errorWarnings"
          width="120"
          sortDirection={
            sort.column === "validateStatus" ? sort.orderBy : "asc"
          }
        >
          <TableSortLabel
            active={sort.column === "validateStatus"}
            direction={sort.column === "validateStatus" ? sort.orderBy : "asc"}
            onClick={createSortHandler("validateStatus")}
          >
            {t(`${tableColumnsStr}.errorWarnings`)}
          </TableSortLabel>
        </TableCell>
        <TableCell align="center" key="actions" width="70">
          {t(`${tableColumnsStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const Expenses = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("validateStatus");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [expenses, setExpenses] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [openNotificationDialog, setOpenNotificationDialog] =
    React.useState(false);
  const [openRedistributeDialog, setOpenRedistributeDialog] =
    React.useState(false);
  const [statusToChange, setStatusToChange] = React.useState();
  const [expenseIdToUpdate, setExpenseIdToUpdate] = React.useState();
  const [expenseIndexToUpdate, setExpenseIndexToUpdate] = React.useState();

  const [expenseIdToRedistribute, setExpenseIdToRedistribute] =
    React.useState();

  const [showDetailsView, setShowDetailsView] = React.useState(false);

  const {
    expensesFilters,
    pagination,
    sort,
    setPagination,
    setSort,
    lastUploadedInfo,
    initialLoad,
  } = useExpensesContext();

  const { clearAlert, setAlert, alert } = useAlertContext();

  const uploadExpensesFilterPanelItemsOptions =
    "UploadedExpenses.filterPanelItems.options.notificationDropdownValues";

  const uploadExpensesMainViewList = "UploadedExpenses.mainView.list";

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: pageValue,
    });
  };

  const _handleRedistribute = (rowId, index) => {
    setOpenRedistributeDialog(true);
    setExpenseIdToRedistribute(rowId);
    setExpenseIndexToUpdate(index);
    setShowDetailsView(false);
  };

  const _handleEdit = (rowId, index, notificationStatus) => {
    setExpenseIdToUpdate(rowId);
    setExpenseIndexToUpdate(index);
    setStatusToChange(notificationStatus);
    setOpenNotificationDialog(true);
  };

  const StatusInfo = ({ notification }) => {
    const unresolvedString = (
      <span className={classes.notificationStatusTooltipTitle}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.unprocessed`)}
      </span>
    );
    const inProcessString = (
      <span className={classes.notificationStatusTooltipTitle}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.inProcess`)}
      </span>
    );
    const resolvedString = (
      <span className={classes.notificationStatusTooltipTitle}>
        {t(`${uploadExpensesFilterPanelItemsOptions}.resolved`)}
      </span>
    );
    const byString = t(
      `${uploadExpensesMainViewList}.notificationStatusTooltip.by`
    );
    const onString = t(
      `${uploadExpensesMainViewList}.notificationStatusTooltip.on`
    );
    switch (notification.status) {
      case "UNRESOLVED":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
          </>
        );
      case "INPROCESS":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
            {"\n\n"}
            {notification.inProcessBy && (
              <>
                {inProcessString}{" "}
                {`${t(byString)} ${notification.inProcessBy} ${t(onString)} ${
                  notification.inProcessDate
                }
            ${notification.inProcessNote}`}
              </>
            )}
          </>
        );
      case "RESOLVED":
        return (
          <>
            {unresolvedString}{" "}
            {`${t(byString)} ${notification.unresolvedBy} ${t(onString)} ${
              notification.unresolvedDate
            }`}
            {notification.inProcessBy && (
              <>
                {"\n\n"}
                {inProcessString}{" "}
                {`${t(byString)} ${notification.inProcessBy} ${t(onString)} ${
                  notification.inProcessDate
                }
            ${notification.inProcessNote}`}
              </>
            )}
            {notification.resolvedBy && (
              <>
                {"\n\n"}
                {resolvedString}{" "}
                {`${t(byString)} ${notification.resolvedBy} ${t(onString)} ${
                  notification.resolvedDate
                }
            ${notification.resolvedNote}`}
              </>
            )}
          </>
        );
      default:
        return "";
    }
  };

  const getStatusName = (status) => {
    switch (status) {
      case "UNRESOLVED":
        return t(`${uploadExpensesFilterPanelItemsOptions}.unprocessed`);
      case "INPROCESS":
        return t(`${uploadExpensesFilterPanelItemsOptions}.inProcess`);
      case "RESOLVED":
        return t(`${uploadExpensesFilterPanelItemsOptions}.resolved`);
      default:
        return "";
    }
  };

  React.useEffect(() => {
    !initialLoad &&
      getAllExpenses(
        expensesFilters,
        pagination,
        sort,
        setExpenses,
        setTotalCount,
        setLoading,
        clearAlert,
        setAlert
      );
    // eslint-disable-next-line
  }, [expensesFilters, pagination, sort]);

  const displayLastGLPeriod = (preference) => {
    return `${t("UploadedExpenses.mainView.subTitle")} ${
      lastUploadedInfo.lastUploadGLPeriod || ""
    }`;
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subtitleColor}>
            {displayLastGLPeriod()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!openNotificationDialog &&
            !openRedistributeDialog &&
            alert.exists && <ASAlert />}
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              stickyHeader
              className={classes.tableLayout}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {/* {error && `Error: ${error}`} */}
                {loading && (
                  <TableRow>
                    <TableCell
                      colSpan="11"
                      classes={{ root: classes.progressBar }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  expenses.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" component="th" scope="row">
                        <span className={classes.actionContent}>
                          <Tooltip
                            classes={{
                              tooltip: classes.notificationStatusTooltip,
                            }}
                            title={
                              row.notification && (
                                <StatusInfo notification={row.notification} />
                              )
                            }
                          >
                            <Typography variant="body2">
                              {row.notification &&
                                getStatusName(row.notification.status)}
                            </Typography>
                          </Tooltip>
                          {row.notification &&
                            row.notification.status.toLowerCase() !==
                              "resolved" && (
                              <Tooltip
                                title={t(
                                  `${uploadExpensesMainViewList}.editNotificationToolTip`
                                )}
                              >
                                <EditIcon
                                  classes={{
                                    root: classes.icon,
                                  }}
                                  onClick={() => {
                                    _handleEdit(
                                      row.id,
                                      index,
                                      row.notification.status
                                    );
                                  }}
                                />
                              </Tooltip>
                            )}
                        </span>
                      </TableCell>
                      <TableCell align="left">{row.fiscalYear}</TableCell>
                      <TableCell align="left" nowrap="nowrap">
                        {row.glPeriod}
                      </TableCell>
                      <TableCell align="left">
                        {row.commitment && (
                          <RouterLink
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/edit",
                            }}
                            target={"blank_" + row.commitment.id}
                          >
                            <Tooltip
                              title={row.commitment.name}
                              placement="left"
                            >
                              <Typography noWrap={true} variant="body2">
                                {row.commitment.name}
                              </Typography>
                            </Tooltip>
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.component && (
                          <RouterLink
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/commitments/" +
                                row.commitment.id +
                                "/components/" +
                                row.component.id +
                                "/edit",
                            }}
                            target={`${row.commitment.id}${row.component.id}`}
                          >
                            <Tooltip
                              title={row.component.componentName}
                              placement="left"
                            >
                              <Typography noWrap={true} variant="body2">
                                {row.component.componentName}
                              </Typography>
                            </Tooltip>
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.pta && (
                          <RouterLink
                            to={{
                              pathname:
                                "/expenses/" +
                                row.id +
                                "/ptas/" +
                                row.ptaNumber,
                            }}
                            target={"blank_" + row.ptaNumber}
                          >
                            <Typography variant="body2">
                              {row.ptaNumber}
                            </Typography>
                          </RouterLink>
                        )}
                        {!row.pta && (
                          <Typography
                            variant="body2"
                            className={classes.wordBreak}
                          >
                            {!row.task &&
                              `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                row.award
                              }`}
                            {row.task && row.ptaNumber}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.expenditureCategory}
                      </TableCell>
                      <TableCell align="right">
                        {CurrencyFormat(row.mtdAmount)}
                      </TableCell>
                      <TableCell align="center">
                        {row.posted ? <CheckIcon /> : ""}
                      </TableCell>
                      <TableCell align="left">
                        {row.notification &&
                          row.notification.errorWarnings &&
                          row.notification.errorWarnings.map(
                            (anObjectMapped, index) => {
                              return (
                                <span
                                  key={index}
                                  className={classes.actionContent}
                                >
                                  {row.validateStatus &&
                                  row.validateStatus.toLowerCase() ===
                                    "warning" ? (
                                    <Tooltip
                                      title={t(
                                        `${uploadExpensesMainViewList}.warningToolTip`
                                      )}
                                    >
                                      <WarningIcon className={classes.yellow} />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={t(
                                        `${uploadExpensesMainViewList}.errorToolTip`
                                      )}
                                    >
                                      <ErrorIcon className={classes.red} />
                                    </Tooltip>
                                  )}

                                  <Typography
                                    className={classes.ml5}
                                    variant="body2"
                                    gutterBottom
                                  >
                                    {anObjectMapped}
                                  </Typography>
                                </span>
                              );
                            }
                          )}
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() ===
                            "redistributed" && (
                            <span className={classes.actionContent}>
                              <Tooltip
                                title={t(
                                  `${uploadExpensesMainViewList}.redistributedToolTip`
                                )}
                              >
                                <InfoIcon className={classes.blue} />
                              </Tooltip>

                              {/* <RouterLink
                              onClick={() => {
                                setOpenRedistributeDialog(true);
                                setExpenseIdToRedistribute(row.id);
                                setShowDetailsView(true);
                              }}
                            > */}
                              <Typography
                                className={classes.ml5}
                                variant="body2"
                              >
                                {t(
                                  "UploadedExpenses.filterPanelItems.options.conditionTypeDropdownValues.redistributed"
                                )}
                              </Typography>
                              {/* </RouterLink> */}
                            </span>
                          )}
                      </TableCell>
                      <TableCell align="center">
                        {row.validateStatus &&
                          row.validateStatus.toLowerCase() === "error" && (
                            <span className={classes.actionContent}>
                              <span>
                                <Tooltip
                                  title={t(
                                    `${uploadExpensesMainViewList}.revalidateAndPostToolTip`
                                  )}
                                >
                                  <PlaylistAddCheckIcon
                                    className={classes.icon}
                                    onClick={() => {
                                      getRevalidateAndPost(
                                        setExpenses,
                                        index,
                                        clearAlert,
                                        setAlert,
                                        {
                                          expenseId: row.id,
                                        }
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </span>

                              <span>
                                <Tooltip
                                  title={t(
                                    `${uploadExpensesMainViewList}.redistributeToolTip`
                                  )}
                                >
                                  <FormatListNumberedRtlIcon
                                    fontSize="small"
                                    classes={{ root: classes.icon }}
                                    onClick={() => {
                                      _handleRedistribute(row.id, index);
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                {!loading && !expenses.length && (
                  <TableRow>
                    <TableCell colSpan="11">
                      <Typography
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      {openRedistributeDialog && (
        <RedistributeExpenses
          openDialog={openRedistributeDialog}
          setOpenDialog={setOpenRedistributeDialog}
          expenseIdToRedistribute={expenseIdToRedistribute}
          setExpenses={setExpenses}
          expenseIndex={expenseIndexToUpdate}
          showDetailsView={showDetailsView}
        />
      )}
      {openNotificationDialog && (
        <NotificationStatus
          openDialog={openNotificationDialog}
          setOpenDialog={setOpenNotificationDialog}
          statusToChange={statusToChange}
          expenseId={expenseIdToUpdate}
          setExpenses={setExpenses}
          expenseIndex={expenseIndexToUpdate}
        />
      )}
    </>
  );
};
