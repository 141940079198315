import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
    width: 250,
  },
  item: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[500],
    paddingTop: 0,
    paddingBottom: 0,
  },
  primary: {
    fontSize: "10px",
  },
  secondary: {
    fontSize: "12px",
  },
  header: {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
  },
  listItemText: { marginTop: 0, marginBottom: 0 },
}));
