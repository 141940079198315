import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  InputAdornment,
  Tooltip,
  Grid,
  OutlinedInput,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import { FormButton } from "../../UI/Button/FormButton";
import { useStyles } from "./RedistributeTransfers.styles";
import { Link as RouterLink } from "react-router-dom";
import { MonthlyExpenseTableHead } from "./MonthlyExpenseTableHead";
import { ComponentTableHead } from "./ComponentTableHead";
import {
  getRedistributeTransfers,
  postRedistributeTransfers,
} from "../../../services/transfersService";
import { CurrencyFormat } from "../../../Utils/CurrencyFormat";
import NumberFormat from "react-number-format";
import { TransferTypes } from "../../../constants";
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";

export const RedistributeTransfers = ({
  openDialog,
  setOpenDialog,
  transferIdToRedistribute,
  setTransfers,
  transferIndex,
  showDetailsView,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpenDialog(false);
  };
  const classes = useStyles();

  const [redistributeTransfers, setRedistributeTransfers] = React.useState([]);
  const [transferDetails, setTransferDetails] = React.useState([]);
  const [redistributeTransfersCount, setRedistributeTransfersCount] =
    React.useState();
  const [remainingToDistribute, setRemainingToDistribute] = React.useState(0);
  const [transferInAmount, setTransferInAmount] = React.useState(0);

  const [saveRedistributeTransfers, setSaveRedistributeTransfers] =
    React.useState([]);
  const [totalDistributeAmount, setTotalDistributeAmount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const { clearAlert, setAlert, alert } = useAlertContext();

  const commonTranslationString =
    "UploadedTransfers.mainView.list.redistributeTransfersDialog";

  const remainingRedistributeAmountcalculation = (
    index,
    redistributedAmount,
    ptaNumber,
    componentName,
    componentId,
    commitmentName,
    commitmentId
  ) => {
    saveRedistributeTransfers[index] =
      redistributedAmount !== ""
        ? {
            ptaNumber: ptaNumber,
            redistributedAmount: redistributedAmount,
            componentName: componentName,
            componentId: componentId,
            commitmentName: commitmentName,
            commitmentId: commitmentId,
          }
        : null;

    const totalAmount = saveRedistributeTransfers
      .filter((key) => key !== null && key.redistributedAmount !== "-")
      .reduce(
        (totalAmount, redistributeExpenses) =>
          totalAmount + parseFloat(redistributeExpenses.redistributedAmount),
        0
      );

    setTotalDistributeAmount(totalAmount);
    setRemainingToDistribute(transferInAmount - totalAmount);
  };

  const handleSubmit = () => {
    setOpenDialog(false);
    postRedistributeTransfers(
      transferDetails.id,
      saveRedistributeTransfers,
      setTransfers,
      transferIndex,
      clearAlert,
      setAlert
    );
  };

  React.useEffect(() => {
    getRedistributeTransfers(
      setTransferDetails,
      setRedistributeTransfers,
      setRedistributeTransfersCount,
      setSaveRedistributeTransfers,
      setRemainingToDistribute,
      setTransferInAmount,
      setLoading,
      clearAlert,
      setAlert,
      {
        transferId: transferIdToRedistribute,
      }
    );
    // eslint-disable-next-line
  }, [transferIdToRedistribute]);

  const DialogTitleMarkup = () => {
    const title = !showDetailsView
      ? `${commonTranslationString}.title`
      : `${commonTranslationString}.detailsPage.title`;

    return <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>;
  };

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        className={classes.dialogMaxWidth}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitleMarkup />
          {openDialog && alert.exists && <ASAlert />}
          <DialogContent>
            <Grid
              container
              justifyContent="flex-end"
              direction="column"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-label="customized table"
                    stickyHeader
                    className={classes.headerBackgroundColor}
                  >
                    <MonthlyExpenseTableHead
                      showDetailsView={showDetailsView}
                    />
                    <TableBody>
                      {loading && (
                        <TableRow>
                          <TableCell colSpan="9">
                            <Box display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading && transferDetails && (
                        <TableRow key={0}>
                          <TableCell align="left" component="th" scope="row">
                            {transferDetails.glPeriod}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.project}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.award}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.journalSource}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.journalName}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.journalLineDepartment}
                          </TableCell>
                          <TableCell align="left">
                            {transferDetails.objectCode}
                          </TableCell>
                          {!showDetailsView && (
                            <TableCell align="left">
                              {transferDetails.journalLineDescriptive}
                            </TableCell>
                          )}
                          {showDetailsView && (
                            <TableCell align="right"></TableCell>
                          )}
                          {showDetailsView && (
                            <TableCell align="right"></TableCell>
                          )}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {!showDetailsView && (
                <Grid item xs={12} className={classes.remainingRedistribute}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    direction="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography align="right" variant="body1">
                        {t(TransferTypes[transferDetails.transferType])}
                        {": "}
                        {CurrencyFormat(transferInAmount)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="right" variant="body1">
                        {t(`${commonTranslationString}.remainingToDistribute`)}
                        {": "}
                        {CurrencyFormat(remainingToDistribute)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainerMaxHeight}
                >
                  <Table
                    aria-label="customized table"
                    stickyHeader
                    className={classes.headerBackgroundColor}
                    sx={{ tableLayout: "fixed" }}
                  >
                    <ComponentTableHead showDetailsView={showDetailsView} />
                    <TableBody>
                      {loading && (
                        <TableRow>
                          <TableCell colSpan="5">
                            <Box display="flex" justifyContent="center">
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        redistributeTransfersCount > 0 &&
                        redistributeTransfers.map((row, index) => (
                          <TableRow key={index}>
                            {!showDetailsView && (
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                {row.component.recordNumber}
                              </TableCell>
                            )}
                            <TableCell align="left">
                              {row.commitment && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/transfers/" +
                                      transferDetails.id +
                                      "/commitments/" +
                                      row.commitment.id +
                                      "/edit",
                                  }}
                                  target={"blank_" + row.commitment.id}
                                >
                                  <Tooltip title={row.commitment.name}>
                                    <Typography noWrap={true} variant="body2">
                                      {row.commitment.name}
                                    </Typography>
                                  </Tooltip>
                                </RouterLink>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.component && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/transfers/" +
                                      transferDetails.id +
                                      "/commitments/" +
                                      row.commitment.id +
                                      "/components/" +
                                      row.component.id +
                                      "/edit",
                                  }}
                                  target={`${row.commitment.id}${row.component.id}`}
                                >
                                  <Tooltip title={row.component.componentName}>
                                    <Typography noWrap={true} variant="body2">
                                      {row.component.componentName}
                                    </Typography>
                                  </Tooltip>
                                </RouterLink>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.pta && (
                                <RouterLink
                                  to={{
                                    pathname:
                                      "/transfers/" +
                                      transferDetails.id +
                                      "/ptas/" +
                                      row.pta._links.self.href.split("/").pop(),
                                  }}
                                  target={
                                    "blank_" +
                                    row.pta._links.self.href.split("/").pop()
                                  }
                                >
                                  <Typography variant="body2">
                                    {row.ptaNumber}
                                  </Typography>
                                </RouterLink>
                              )}
                              {!row.pta && (
                                <Typography
                                  variant="body2"
                                  className={classes.wordBreak}
                                >
                                  {!row.task &&
                                    `${row.project}-${"\u00A0\u00A0\u00A0"}-${
                                      row.award
                                    }`}
                                  {row.task && row.ptaNumber}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              {!showDetailsView && (
                                <NumberFormat
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  customInput={OutlinedInput}
                                  variant={"outlined"}
                                  defaultValue={0.0}
                                  isAllowed={(values) => {
                                    const { value } = values;
                                    return (
                                      value === "-" ||
                                      (value >= -99999999.99 &&
                                        value <= 99999999.99)
                                    );
                                  }}
                                  onValueChange={(values) => {
                                    const { value } = values;
                                    remainingRedistributeAmountcalculation(
                                      index,
                                      value,
                                      row.ptaNumber,
                                      row.component.componentName,
                                      row.component.id,
                                      row.commitment.name,
                                      row.commitment.id
                                    );
                                  }}
                                  fixedDecimalScale={true}
                                  autoComplete="off"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                  className={classes.inputPadding}
                                />
                              )}
                            </TableCell>
                            {showDetailsView && (
                              <TableCell align="right"></TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {!loading && redistributeTransfersCount === 0 && (
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.noComponentsAttached}
                    >
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t(
                          "UploadedExpenses.mainView.list.redistributeExpensesDialog.noComponentsMessage"
                        )}
                      </Typography>
                    </Grid>
                  )}
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {!showDetailsView && (
              <Grid container justifyContent="flex-end" xs={3} spacing={2}>
                <FormButton
                  cancel={handleClose}
                  save={{
                    disabled:
                      parseFloat(transferInAmount) !==
                      parseFloat(totalDistributeAmount),
                  }}
                />
              </Grid>
            )}
            {showDetailsView && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleClose}
              >
                {t(`${commonTranslationString}.detailsPage.closeButtonText`)}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
