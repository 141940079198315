import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { getAllPresentationSubcategories } from "../../../services/commitmentsService";
import axios from "axios";

export const CommitmentPresentationSubcategoryField = ({
  required,
  error,
  helperText,
  className,
  InputLabelProps,
  variant = "outlined",
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Presentation Subcategories
  const [presentationSubcategories, setPresentationSubcategories] =
    React.useState([]);

  const [presentationSubcategoryError, setPresentationSubcategoryError] =
    React.useState({
      exist: false,
      message: null,
    });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPresentationSubcategories(
      setPresentationSubcategories,
      setPresentationSubcategoryError,
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {presentationSubcategories && (
        <Autocomplete
          freeSolo
          options={presentationSubcategories}
          getOptionLabel={(option) => option.name ?? ""}
          autoHighlight={true}
          handleHomeEndKeys={true}
          openOnFocus={true}
          fullWidth={true}
          required={required}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                `Commitments.create.form.fields.presentationSubCategory.label`
              )}
              required={required}
              variant={variant}
              InputLabelProps={InputLabelProps}
              className={className}
              error={presentationSubcategoryError.exist || error}
              helperText={presentationSubcategoryError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
