import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  IconButton,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  NavigateNext as NavigateNextIcon,
} from "@mui/icons-material";
import { useStyles } from "./CommitmentDocuments.styles";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getAllDocuments,
  deleteDocument,
} from "../../../services/commitmentsService";
import { useUsers } from "../../../services/usersService";
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
import { PositionedSnackbar } from "../../UI/SnackBar/SnackBar";
import { DocumentTypes } from "../../../constants";
import { useCommitmentContext } from "../../../services/commitmentContext";
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
import { useDownload } from "../../UI/Download";

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { docSort } = useCommitmentContext();
  const { currentUser } = useUsers();

  const prefColumn = docSort.column;
  const prefOrderBy = docSort.orderBy;

  const commitmentDocumentFieldStr =
    "Commitments.commitmentDocuments.mainView.list.tableColumns";

  return (
    <TableHead>
      <TableRow>
        <TableCell
          align="left"
          key="type"
          sortDirection={prefColumn === "documentType" ? prefOrderBy : "asc"}
        >
          <TableSortLabel
            active={prefColumn === "documentType"}
            direction={prefColumn === "documentType" ? prefOrderBy : "asc"}
            onClick={createSortHandler("documentType")}
          >
            {t(`${commitmentDocumentFieldStr}.type`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="name"
          sortDirection={prefColumn === "documentDetail" ? prefOrderBy : "asc"}
        >
          <TableSortLabel
            active={prefColumn === "documentDetail"}
            direction={prefColumn === "documentDetail" ? prefOrderBy : "asc"}
            onClick={createSortHandler("documentDetail")}
          >
            {t(`${commitmentDocumentFieldStr}.detail`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="fileName"
          sortDirection={prefColumn === "fileName" ? prefOrderBy : "asc"}
        >
          <TableSortLabel
            active={prefColumn === "fileName"}
            direction={prefColumn === "fileName" ? prefOrderBy : "asc"}
            onClick={createSortHandler("fileName")}
          >
            {t(`${commitmentDocumentFieldStr}.fileName`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="uploadedDate"
          sortDirection={prefColumn === "uploadedDate" ? prefOrderBy : "asc"}
        >
          <TableSortLabel
            active={prefColumn === "uploadedDate"}
            direction={prefColumn === "uploadedDate" ? prefOrderBy : "asc"}
            onClick={createSortHandler("uploadedDate")}
          >
            {t(`${commitmentDocumentFieldStr}.uploadedDate`)}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          key="uploadedBy"
          sortDirection={prefColumn === "uploadedBy" ? prefOrderBy : "asc"}
        >
          <TableSortLabel
            active={prefColumn === "uploadedBy"}
            direction={prefColumn === "uploadedBy" ? prefOrderBy : "asc"}
            onClick={createSortHandler("uploadedBy")}
          >
            {t(`${commitmentDocumentFieldStr}.uploadedBy`)}
          </TableSortLabel>
        </TableCell>
        {currentUser.permissions.ADD_DOCUMENTS && (
          <TableCell
            align="left"
            key="adminView"
            sortDirection={
              prefColumn === "departmentAdminView" ? prefOrderBy : "asc"
            }
          >
            <TableSortLabel
              active={prefColumn === "departmentAdminView"}
              direction={
                prefColumn === "departmentAdminView" ? prefOrderBy : "asc"
              }
              onClick={createSortHandler("departmentAdminView")}
            >
              {t(`${commitmentDocumentFieldStr}.adminView`)}
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell align="center" key="actions" width="105px">
          {t(`${commitmentDocumentFieldStr}.actions`)}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export const CommitmentDocuments = (props) => {
  const location = useLocation();
  const params = useParams();
  const { commitmentId } = params;
  const classes = useStyles();
  const { getDocument, downloadInProgress } = useDownload();
  const [commitmentName, setCommitmentName] = React.useState();
  const [, setMessageObj] = React.useState();
  const { t } = useTranslation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("documentDetail");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [documents, setDocuments] = React.useState([]);
  const [, setOffset] = React.useState(page * rowsPerPage);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [deleteDocumentRecord, setDeleteDocumentRecord] = React.useState({});
  const [snackbar, setSnackBar] = React.useState(false);
  const { currentUser } = useUsers();
  const { setDocSort, docPagination, setDocPagination } =
    useCommitmentContext();

  const { clearAlert, setAlert, alert } = useAlertContext();
  const [refresh, setRefresh] = React.useState(0);

  const { permissions } = currentUser;
  const commitmentDocumentsDelete = "Commitments.commitmentDocuments.delete";
  const commitmentDocumentsMainView =
    "Commitments.commitmentDocuments.mainView";

  React.useEffect(() => {
    if (permissions.VIEW_DOCUMENTS) {
      getAllDocuments(
        setDocuments,
        setTotalCount,
        setLoading,
        setCommitmentName,
        setAlert,
        clearAlert,
        {
          page,
          rowsPerPage,
          orderBy,
          order,
          commitmentId,
        }
      );
      location.state && setMessageObj(location.state);
    }
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    orderBy,
    order,
    commitmentId,
    permissions,
    location.state,
    refresh,
  ]);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);
    setDocSort({
      column: property,
      orderBy: isAsc,
    });
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    setOffset(page * rowsPerPage);
    setDocPagination({
      ...docPagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDocPagination({
      ...docPagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
    }
  };

  //Function calls as per the confirmation from user
  const handleDeleteDocument = () => {
    setLoading(true);
    deleteDocument(
      commitmentId,
      deleteDocumentRecord.documentId,
      setRefresh,
      setSnackBar,
      setDeleteDocumentRecord,
      setAlert,
      clearAlert
    );
  };

  return (
    permissions.VIEW_DOCUMENTS && (
      <Grid container>
        {snackbar && (
          <PositionedSnackbar
            message={t(`${commitmentDocumentsDelete}.notification.success`)}
          />
        )}
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item container xs={6} justifyContent="flex-start">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <RouterLink to={`/commitments`}>
                <Typography color="textSecondary" variant="body2">
                  {t("Commitments.mainView.title")}
                </Typography>
              </RouterLink>
              <Typography color="textPrimary" variant="subtitle1">
                {permissions.VIEW_DEPARTMENT_DOCUMENT
                  ? t(`${commitmentDocumentsMainView}.view.breadcrumbTitle`)
                  : t(`${commitmentDocumentsMainView}.documentsBreadCrumbLink`)}
              </Typography>
            </Breadcrumbs>
          </Grid>
          {permissions.VIEW_DEPARTMENT_DOCUMENT && (
            <Grid item container xs={6} justifyContent="flex-end">
              <RouterLink to={`/commitments`}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  className={classes.buttonPadding}
                >
                  {t("Commitments.view.goBackButtonLabel")}
                </Button>
              </RouterLink>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          <Typography variant="h1">
            {t(`${commitmentDocumentsMainView}.title`)} {commitmentName}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mainContainer}>
          {alert.exists && (
            <Box mt={1} mb={1}>
              <ASAlert />
            </Box>
          )}
        </Grid>

        {permissions.ADD_DOCUMENTS && (
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            className={classes.navigationBar}
          >
            <RouterLink
              to={
                commitmentId
                  ? `/commitments/${commitmentId}/documents/new`
                  : "/commitments"
              }
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon />}
              >
                {t(`${commitmentDocumentsMainView}.textForAddDocumentButton`)}
              </Button>
            </RouterLink>
          </Grid>
        )}

        <Grid item xs={12} className={classes.mainContainer}>
          <TableContainer component={Paper}>
            <Table
              className={classes.tableLayout}
              aria-label="customized table"
              stickyHeader
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {Object.keys(deleteDocumentRecord).length > 0 && (
                  <ASConfirmation
                    open={true}
                    title={t(`${commitmentDocumentsDelete}.dialog.title`)}
                    message={t(`${commitmentDocumentsDelete}.dialog.content`, {
                      documentName: `"${deleteDocumentRecord.name}"`,
                    })}
                    okLabel={t(
                      `${commitmentDocumentsDelete}.dialog.deleteButton`
                    )}
                    cancelLabel={t(
                      `${commitmentDocumentsDelete}.dialog.cancelButton`
                    )}
                    handleCancel={() => {
                      setDeleteDocumentRecord({});
                    }}
                    handleOk={() => {
                      handleDeleteDocument();
                    }}
                  />
                )}
                {loading && (
                  <TableRow>
                    <TableCell
                      colSpan={permissions.ADD_DOCUMENTS ? "7" : "6"}
                      classes={{ root: classes.progressBar }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!loading &&
                  documents.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" component="th" scope="row">
                        {t(DocumentTypes[row.documentType])}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={
                            <div>
                              {t(
                                `${commitmentDocumentsMainView}.list.documentNotesTitle`
                              )}
                              <br />
                              <br />
                              {row.documentNotes}
                            </div>
                          }
                          placement="bottom"
                        >
                          <Typography variant="body2">
                            {row.documentDetail}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <Link
                          component={"button"}
                          disabled={!!downloadInProgress}
                          onClick={() => {
                            const docId = row._links.self.href.substring(
                              row._links.self.href.lastIndexOf("/") + 1,
                              row._links.self.href.length
                            );
                            getDocument(
                              `/commitments/${commitmentId}/documents/${docId}/download`,
                              row.fileName
                            );
                          }}
                        >
                          <Typography variant="body2">
                            {row.fileName}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {row.uploadedDate &&
                          new Date(row.uploadedDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="left">{row.uploadedBy}</TableCell>
                      {permissions.ADD_DOCUMENTS && (
                        <TableCell align="left">
                          {row.departmentAdminView === true && <CheckIcon />}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        <Grid container justifyContent="space-around">
                          {row._links.edit && (
                            <Grid item>
                              <RouterLink
                                to={{
                                  pathname: `/commitments/${commitmentId}/documents/${new URL(
                                    row._links.edit.href
                                  ).pathname
                                    .split("/")
                                    .pop()}/edit`,
                                }}
                              >
                                <Tooltip
                                  title={t(
                                    `${commitmentDocumentsMainView}.list.editCommitmentDocumentsTooltip`
                                  )}
                                >
                                  <IconButton size="small">
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </RouterLink>
                            </Grid>
                          )}
                          {row._links.delete && (
                            <Grid item>
                              <Tooltip
                                title={t(
                                  `${commitmentDocumentsMainView}.list.deleteCommitmentDocumentsTooltip`
                                )}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setDeleteDocumentRecord({
                                      name: row.documentDetail,
                                      documentId:
                                        row._links.delete.href.substring(
                                          row._links.delete.href.lastIndexOf(
                                            "/"
                                          ) + 1,
                                          row._links.delete.href.length
                                        ),
                                    });
                                    setSnackBar(false);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {row._links.view && (
                            <Grid item>
                              <Tooltip
                                title={t(
                                  `${commitmentDocumentsMainView}.list.downloadCommitmentDocumentsTooltip`
                                )}
                              >
                                <span>
                                  <IconButton
                                    size="small"
                                    className={classes.downloadButton}
                                    disabled={!!downloadInProgress}
                                    onClick={() => {
                                      const docId =
                                        row._links.self.href.substring(
                                          row._links.self.href.lastIndexOf(
                                            "/"
                                          ) + 1,
                                          row._links.self.href.length
                                        );
                                      getDocument(
                                        `/commitments/${commitmentId}/documents/${docId}/download`,
                                        row.fileName
                                      );
                                    }}
                                  >
                                    {downloadInProgress ? (
                                      <CircularProgress size={15} />
                                    ) : (
                                      <CloudDownloadIcon fontSize="small" />
                                    )}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}

                {!loading && !documents.length && (
                  <TableRow>
                    <TableCell colSpan={permissions.ADD_DOCUMENTS ? "7" : "6"}>
                      <Typography
                        variant="body1"
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t("globals.list.messages.noData")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={docPagination.rowsPerPage}
              page={docPagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        {permissions.VIEW_DEPARTMENT_DOCUMENT && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.navigationBar}
            >
              <RouterLink to={`/commitments`}>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<ArrowBackIcon />}
                >
                  {t("Commitments.view.goBackButtonLabel")}
                </Button>
              </RouterLink>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  );
};
