// useStyles - Contains styles for variaous parts of User Interface
export const styles = (theme) => ({
  checkbox: {
    color: theme.palette.text.primary,
  },
  error: {
    color: theme.palette.error.main,
  },
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
      width: "calc(100% - 32px)",
    },
  },
  mainHeading: {
    padding: "8px 0",
  },
  formContainer: {
    "&.MuiGrid-root > .MuiGrid-item": {
      padding: "8px 0",
    },
  },
  downloadButton: {
    "&.MuiButton-root": {
      paddingTop: 0,
      paddingBottom: 0,
      whiteSpace: "nowrap",
      lineHeight: "22.5px",
    },
  },
  linkColor: { color: theme.palette.text.primary },
});
