import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { getAllPresentations } from "../../../services/commitmentsService";
import axios from "axios";

export const CommitmentPresentationField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  // Presentations
  const [presentations, setPresentations] = React.useState([]);

  const [presentationError, setPresentationError] = React.useState({
    exist: false,
    message: null,
  });

  React.useEffect(() => {
    const cancelSource = axios.CancelToken.source();

    getAllPresentations(setPresentations, setPresentationError, cancelSource);
    return () => {
      cancelSource.cancel();
    };
  }, []);

  return (
    <>
      {presentations && (
        <Autocomplete
          options={presentations}
          getOptionLabel={(option) => option && option.name}
          autoHighlight={true}
          disableClearable={true}
          handleHomeEndKeys={true}
          openOnFocus={true}
          fullWidth={true}
          clearIcon={false}
          required={required}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              label={t(`Commitments.create.form.fields.presentation.label`)}
              variant={"outlined"}
              error={presentationError.exist || error}
              helperText={presentationError.message || helperText}
            />
          )}
          {...passedParams}
        />
      )}
    </>
  );
};
