import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    "&.MuiGrid-root": {
      margin: "0px 16px",
    },
  },
  padding: { padding: "16px 0" },
  arrowBackButtonPadding: { padding: 0 },
  backgroundColor: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,
  },
  borderRight: {
    "& .MuiTableCell-root": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));
