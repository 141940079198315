import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

export const CommitmentProjectGroupField = ({
  required,
  error,
  helperText,
  ...passedParams
}) => {
  const { t } = useTranslation();

  const [projectGroupError] = React.useState({
    exist: false,
    message: null,
  });

  return (
    <>
      {
        <TextField
          id="projectGroup"
          label={t(`Commitments.create.form.fields.projectGroup.label`)}
          placeholder={"Select Unit"}
          fullWidth
          variant="outlined"
          required={required}
          readOnly
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          helperText={projectGroupError.message || helperText}
          {...passedParams}
        />
      }
    </>
  );
};
